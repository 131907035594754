import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { atm_GlobalLoading, atm_globalFilterValue } from "../../../state/atoms";

import {
  atm_InsightsMerchantList4Category,
  atm_InsightsCategoryList,
  atm_SelectedCategoryAmount,
  atm_SelectedCategoryPercent,
  atm_InsightLocationStats,
  atm_InsightCategoryStats,
  atm_CategoryDataLoading,
  atm_IsCategoryClicked,
  atm_MerchentDataLoading,
  atm_SelectedCategoryValue,
  atm_InsightPopStats,
  atm_SelectedCatDateFilter,
  atm_CategoryChartLoading,
  atm_globalCategory,
  atm_categoryTab,
  atm_categoryParentSelectors,
  atm_LocationChartLoading,
  atm_PopChartLoading,
} from "src/state/insightsCategories";
import { compareIds, getAuthToken, usdUnformat } from "src/global/central";
import useCategoryFilter from "src/components/shared/CategoryFilter/useCategoryFilter";
import axios from "axios";

const useSpendByCategory = () => {
  const data = {};

  const categoryFilterFns = useCategoryFilter();

  const [selectedCategoryAmount, setSelectedCategoryAmount] = useRecoilState(
    atm_SelectedCategoryAmount
  );
  data.selectedCategoryAmount = selectedCategoryAmount;

  const [categoryDataLoading, setCategoryDataLoading] = useRecoilState(
    atm_CategoryDataLoading
  );
  data.categoryDataLoading = categoryDataLoading;

  const [categoryListData, setCategoryListData] = useRecoilState(
    atm_InsightsCategoryList
  );
  data.categoryListData = categoryListData;

  const [merchantDataLoading, setMerchantDataLoading] = useRecoilState(
    atm_MerchentDataLoading
  );
  data.merchantDataLoading = merchantDataLoading;

  const [categoryChartLoading, setCategoryChartLoading] = useRecoilState(
    atm_CategoryChartLoading
  );
  data.categoryChartLoading = categoryChartLoading;

  const [locationChartLoading, setLocationChartLoading] = useRecoilState(atm_LocationChartLoading);
  data.locationChartLoading = locationChartLoading;  

  const [popChartLoading, setPopChartLoading] = useRecoilState(atm_PopChartLoading);
  data.popChartLoading = popChartLoading;  

  const [merchantDetails, setMerchantDetails] = useRecoilState(
    atm_InsightsMerchantList4Category
  );
  data.merchantDetails = merchantDetails;

  const [locationStats, setLocationStats] = useRecoilState(
    atm_InsightLocationStats
  );
  data.locationStats = locationStats;

  const [selectedCategoryPercent, setSelectedCategoryPercent] = useRecoilState(
    atm_SelectedCategoryPercent
  );
  data.selectedCategoryPercent = selectedCategoryPercent;

  const [categoryStats, setCategoryStats] = useRecoilState(
    atm_InsightCategoryStats
  );
  data.categoryStats = categoryStats;

  const [popStats, setPopStats] = useRecoilState(atm_InsightPopStats);
  data.popStats = popStats;

  // is Clicked on Category ?
  const [isCategoryClicked, setIsCategoryClicked] = useRecoilState(
    atm_IsCategoryClicked
  );
  data.isCategoryClicked = isCategoryClicked;

  const [selectedCategoryValue, setSelectedCategoryValue] = useRecoilState(
    atm_SelectedCategoryValue
  );
  data.selectedCategoryValue = selectedCategoryValue;

  const [globalCategory, setGlobalCategory] =
    useRecoilState(atm_globalCategory);
  data.globalCategory = globalCategory;

  const [selectedDateFilterState, setSelectedDateFilterState] = useRecoilState(
    atm_SelectedCatDateFilter
  );
  data.selectedDateFilterState = selectedDateFilterState;

  const [globalFilterValue, setGlobalFilterValue] = useRecoilState(
    atm_globalFilterValue
  );
  data.globalFilterValue = globalFilterValue;

  const [globalLoading, setGlobalLoading] = useRecoilState(atm_GlobalLoading);
  data.globalLoading = globalLoading;

  const tab = useRecoilValue(atm_categoryTab);

  const setCategoryParentSelectors = useSetRecoilState(
    atm_categoryParentSelectors
  );

  let selectedDateFilter = globalFilterValue.dateFilter;
  let selectedCategoryFilter =
    globalFilterValue.categoryFilter.selectedCategoryFilter;
  let excludedCategoryFilter =
    globalFilterValue.categoryFilter.excludedCategoryFilter;

  const jwt = getAuthToken();

  function getCategoryListData() {
    let endPoint =
      process.env.REACT_APP_ACCOUNT_API_END_POINT +
      "/accounts/insights/transactions/v2/getSpendByCategory";

    setCategoryDataLoading(true);
    setGlobalLoading(true);

    let payloads = {
      dateFilter: +selectedDateFilter,
      subCategoryIds: selectedCategoryFilter,
      excludeSubCategoryIds: excludedCategoryFilter,
      categoryCount: -1,
    };

    axios
      .post(endPoint, payloads, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + jwt,
        },
      })
      .then((res) => {
        let data = res.data.responseData;
        setCategoryListData(data);

        if (data.length > 0) {
          setSelectedCategoryPercent(Math.round(data[0].spendPercentage));
          setSelectedCategoryAmount(Math.round(data[0].spend));

          setSelectedCategoryValue([
            data[0].categoryName,
            data[0].subCategoryName,
          ]);

          setGlobalCategory(data[0].subCategoryId);

          refreshRightScreen(data[0].subCategoryId);
        }else{
          setCategoryStats([]);
          setMerchantDetails([]);
          setLocationStats([]);
          setPopStats([]);
          setSelectedCategoryPercent(null);
          setSelectedCategoryAmount(null);
          setSelectedCategoryValue([]);
          setGlobalCategory(null);          
        }
      })
      .catch((err) => {
        console.log("inside catch block with error : " + err);
      }).finally(() => {
        setCategoryDataLoading(false);
        setGlobalLoading(false);
      });
  }

  function getMerchantDetails(id) {
    setGlobalLoading(true);
    setMerchantDataLoading(true);
    let endPoint =
      process.env.REACT_APP_ACCOUNT_API_END_POINT +
      "/accounts/insights/transactions/category/v2/getMerchantDetails";

    let payloads = {
      dateFilter: selectedDateFilter,
      selectedSubCategory: id,
    };

    axios
      .post(endPoint, payloads, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + jwt,
        },
      })
      .then((res) => {
        let data = res.data.responseData;
        setMerchantDetails(data);
      })
      .catch((err) => {
        console.log("inside catch block with error : " + err);
      })
      .finally(() => {
        setGlobalLoading(false);
        setMerchantDataLoading(false);
      });
  }

  function getLocationStats(id) {
    setLocationChartLoading(true);
    setGlobalLoading(true);

    let endPoint =
      process.env.REACT_APP_ACCOUNT_API_END_POINT +
      "/accounts/insights/transactions/category/v2/getLocationStats";

    let payloads = {
      dateFilter: selectedDateFilter,
      selectedSubCategory: id,
    };

    axios
      .post(endPoint, payloads, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + jwt,
        },
      })
      .then((res) => {
        let data = res.data.responseData;
        setLocationStats(data);
      })
      .catch((err) => {
        console.log("inside catch block with error : " + err);
      }).finally(() => {
        setLocationChartLoading(false);
        setGlobalLoading(false);
      });
  }

  function getCategoryStats(id) {
    setCategoryChartLoading(true);
    setGlobalLoading(true);

    let endPoint =
      process.env.REACT_APP_ACCOUNT_API_END_POINT +
      "/accounts/insights/transactions/category/v2/getMoMTrend";

    axios
      .post(
        endPoint,
        {
          dateFilter: selectedDateFilter,
          selectedSubCategory: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + jwt,
          },
        }
      )
      .then((res) => {
        let data = res.data.responseData;
        setCategoryStats(data);
      })
      .catch((err) => {
        console.log("inside catch block with error : " + err);
      }).finally(() => {
        setCategoryChartLoading(false);
        setGlobalLoading(false);
      });
  }

  function getPopDensityStats(id) {
    setPopChartLoading(true);
    setGlobalLoading(true);

    let endPoint =
      process.env.REACT_APP_ACCOUNT_API_END_POINT +
      "/accounts/insights/transactions/category/v2/getPopDensityTrend";

    let payloads = {
      dateFilter: selectedDateFilter,
      selectedSubCategory: id,
    };

    axios
      .post(endPoint, payloads, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + jwt,
        },
      })
      .then((res) => {
        let data = res.data.responseData;
        setPopStats(data);
      })
      .catch((err) => {
        console.log("inside catch block with error : " + err);
      }).finally(() => {
        setPopChartLoading(false);
        setGlobalLoading(false);
      });
  }

  function refreshRightScreen(category) {
    if (tab === 1) {
      getCategoryStats(category);
      getMerchantDetails(category);
      setCategoryParentSelectors((data) => ({ ...data, mom: tab }));
    } else if (tab === 2) {
      getLocationStats(category);
      getPopDensityStats(category);
      setCategoryParentSelectors((data) => ({ ...data, byLocation: tab }));
    }
  }

  function onCategorySelected(params) {
    if (globalCategory != params.row.id) {
      setGlobalCategory(`${params.row.id}`);
      setSelectedCategoryAmount(usdUnformat(params.row.col3.price))
      setSelectedCategoryPercent(Math.round(params.row.col3.percentage));
      setSelectedCategoryValue([
        params.row.col2.category,
        params.row.col2.subCategory,
      ]);

      setIsCategoryClicked(true);
      refreshRightScreen(params.row.id);
    }
  }

  function onChangeDateFilter(dateFilterValue) {
    selectedCategoryFilter = [];
    excludedCategoryFilter = [];
    setGlobalFilterValue({
      ...globalFilterValue,
      dateFilter: dateFilterValue,
      categoryFilter: {
        selectedCategoryFilter: [],
        excludedCategoryFilter: [],
      },
      merchantFilter: {
        includeMerchantIds: [],
        excludeMerchantIds: []
      }
    });

    setSelectedDateFilterState(dateFilterValue);
    selectedDateFilter = dateFilterValue;

    categoryFilterFns.fns.getCategorySubCategoryFilterData(dateFilterValue);

    getCategoryListData();
  }

  function onChangeCategoryFilter(includedIds, excludedIds) {
    const {includedId, excludedId} = compareIds(includedIds, excludedIds)

    selectedCategoryFilter = includedId;
    excludedCategoryFilter = excludedId;

    setGlobalFilterValue({
      ...globalFilterValue,
      categoryFilter: {
        selectedCategoryFilter,
        excludedCategoryFilter,
      },
    });

    getCategoryListData();
  }

  let fns = {
    getCategoryListData,
    getMerchantDetails,
    onCategorySelected,
    getLocationStats,
    getCategoryStats,
    getPopDensityStats,
    onChangeDateFilter,
    onChangeCategoryFilter,
    refreshRightScreen,
  };
  return { data, fns };
};

export default useSpendByCategory;

import {
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import {
  atm_dataProviderId,
  atm_globalFilterValue,
  atm_GlobalLoading,
  atm_isCategoryFilterModified
} from "../../../state/atoms";


import { atm_HoldingsChartLoading, atm_HoldingsChartStats, atm_SelectedHoldingsDateFilter, atm_SelectedSecurityDetails, atm_SelectedSecurityDetailsLoading, atm_isSecuritiesByUserListLoading, atm_lstSecuritiesByUser } from "../../../state/atomsHoldingData";
import useCategoryFilter from "src/components/shared/CategoryFilter/useCategoryFilter";
import { atm_selectedAccountIds } from "src/state/atomsAccountList";
import { getAuthToken } from "src/global/central";


const useHoldingsLanding = () => {
  let data = {};

  const dataProviderId = useRecoilValue(atm_dataProviderId);

  const categoryFilterFns = useCategoryFilter()

  const setSecuritiesByUserList = useSetRecoilState(
    atm_lstSecuritiesByUser
  );

  let [isSecuritiesByUserListLoading, setIsSecuritiesByUserLoading] =
    useRecoilState(atm_isSecuritiesByUserListLoading);
  data.isSecuritiesByUserListLoading = isSecuritiesByUserListLoading;

  const [holdingsChartStats, setHoldingsChartStats] = useRecoilState(atm_HoldingsChartStats);
  data.holdingsChartStats = holdingsChartStats;

  const [holdingsChartLoading, setHoldingsChartLoading] = useRecoilState(atm_HoldingsChartLoading);
  data.holdingsChartLoading = holdingsChartLoading;

  const [selectedSecurityDetails, setSelectedSecurityDetails] = useRecoilState(atm_SelectedSecurityDetails);
  data.selectedSecurityDetails = selectedSecurityDetails;

  const [selectedSecurityDetailsLoading, setSelectedSecurityDetailsLoading] = useRecoilState(atm_SelectedSecurityDetailsLoading);
  data.selectedSecurityDetailsLoading = selectedSecurityDetailsLoading;

  const [selectedHoldingsFilterState, setSelectedHoldingsFilterState] = useRecoilState(atm_SelectedHoldingsDateFilter);
  data.selectedHoldingsFilterState = selectedHoldingsFilterState

  const [globalFilterValue, setGlobalFilterValue] = useRecoilState(atm_globalFilterValue);
  data.globalFilterValue = globalFilterValue

  const [globalLoading, setGlobalLoading] = useRecoilState(atm_GlobalLoading)
  data.globalLoading = globalLoading

  const setIsCategoryFilterModified = useSetRecoilState(atm_isCategoryFilterModified)

  const selectedAccountIds = useRecoilValue(atm_selectedAccountIds)
  data.selectedAccountIds = selectedAccountIds


  // let selectedDateFilter = data.selectedHoldingsFilterState;
  let selectedDateFilter = globalFilterValue.dateFilter;
  let selectedCategoryFilter = [];
  let excludedCategoryFilter = [];
  let includedAccountIds = selectedAccountIds.includedIds;
  let excludedAccountIds = selectedAccountIds.excludedIds;

  const onHoldingsLandingPageLoad = (e) => {
    // console.log("inside new onHoldingsLandingPageLoad ");
    setSecuritiesByUserList([]);
    refreshHoldingsDataFromProvider();
  };

  function refreshHoldingsDataFromProvider() {
    setIsSecuritiesByUserLoading(true);
    setGlobalLoading(true)

    let endPoint = "";

    if (dataProviderId === 1) // Yodlee
      endPoint = "/accounts/investments/v2/updateHoldingsDataFromProvider";
    else if (dataProviderId === 2) // Plaid
      endPoint = "/accounts/investments/v1/updateHoldingsDataFromProvider";


    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getAuthToken(),
      },
    };

    fetch(
      process.env.REACT_APP_ACCOUNT_API_END_POINT +
      endPoint,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          if (response.status === 401) {
            console.log("Unable to refresh investment holdings data");
          }
          throw response.text();
        }
        return response.json();
      })
      .then((responseData) => processHoldingsDataRefresh(responseData))
      .catch((err) => {
        console.log("inside catch block with error : " + err);
      });
  }

  function processHoldingsDataRefresh(response) {
    if (!response.success) {
      var errorMessage = response.responseMessage;
      console.log("Received error message : " + errorMessage);
    }
    getSecuritiesByUserData();
    getHoldingsChart();
  }

  function getSecuritiesByUserData() {
    setIsSecuritiesByUserLoading(true);
    setGlobalLoading(true)

    let endPoint = "";

  //  if (dataProviderId === 1) // Yodlee
      endPoint = "/accounts/holdings/v2/getAllSecuritiesByUser";
   // else if (dataProviderId === 2) // Plaid
    //  endPoint = "/accounts/investments/v1/getAllSecuritiesByUser";

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getAuthToken(),
      },
      body: JSON.stringify({
        noOfMonths: selectedDateFilter,
        subCategoryIds: selectedCategoryFilter,
        excludeSubCategoryIds: excludedCategoryFilter,
        accountIds: includedAccountIds,
        excludeAccountIds: excludedAccountIds,
      }
      )
    };

    fetch(
      process.env.REACT_APP_ACCOUNT_API_END_POINT +
      endPoint,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          if (response.status === 401) {
            // setIsErrorMessage("Unable to get account details");
            console.log("Unable to get securitiesByUser details");
          }
          throw response.text();
        }
        return response.json();
      })
      .then((responseData) => processHoldingsDataResponse(responseData))
      .catch((err) => {
        console.log("inside catch block with error : " + err);
      });
  }

  function processHoldingsDataResponse(response) {
    var holdingsList = [];
    var hasMoreData = false;

    if (!response.success) {
      var errorMessage = response.responseMessage;
      console.log(
        "SecuritiesByUser : Received error message : " + errorMessage
      );
    } else {
      holdingsList = response.responseData.securities;
      hasMoreData = response.responseData.hasMore;
    }

    setSecuritiesByUserList(holdingsList);
    setIsSecuritiesByUserLoading(false);
    setGlobalLoading(false)
    if (hasMoreData) {
      setTimeout(() => {
        getSecuritiesByUserData();
      }, 5000);
    } else {
      setIsSecuritiesByUserLoading(false);
      setGlobalLoading(false)
    }
  }

  // CHART -> Holdings stats fetching 👇

  function getHoldingsChart() {

    setHoldingsChartLoading(true)
    setGlobalLoading(true)

    let endPoint = "";

  //  if (dataProviderId === 1) // Yodlee
      endPoint = "/accounts/holdings/trends/mom/v2/holdings";
  //  else if (dataProviderId === 2) // Plaid
  //    endPoint = "/accounts/holdings/trends/mom/v1/holdings";

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': "Bearer " + getAuthToken(),
      },
      body: JSON.stringify({
        noOfMonths: selectedDateFilter,
        subCategoryIds: selectedCategoryFilter,
        excludeSubCategoryIds: excludedCategoryFilter,
        accountIds: includedAccountIds,
        excludeAccountIds: excludedAccountIds,
      }
      )
    };

    fetch(
      process.env.REACT_APP_ACCOUNT_API_END_POINT +
      endPoint,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          if (response.status === 401) {
            console.log("Unable to get Spending details");
          }
          throw response.text();
        }
        return response.json();
      })
      .then((responseData) => {
        processHoldingsChart(responseData);
      })
      .catch((err) => {
        console.log("inside catch block with error : " + err);
        setHoldingsChartLoading(false);

      });

  }

  function processHoldingsChart(response) {
    if (response.success !== true) {
      var errorMessage = response.responseMessage;
      console.log("Received error message : " + errorMessage);
    } else {
      setHoldingsChartStats(response.responseData);
    }
    setHoldingsChartLoading(false);
    setGlobalLoading(false)

  }


  function getSecurityByUserId(id, value) {

    setSelectedSecurityDetailsLoading(true)
    setGlobalLoading(true)

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': "Bearer " + getAuthToken(),
      },
      body: JSON.stringify({
        "securityId": id,
        "securityValue": value
      }
      )
    };

    fetch(
      process.env.REACT_APP_ACCOUNT_API_END_POINT +
      "/accounts/holdings/v1/getSecurityDetailsByUserAccounts",
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          if (response.status === 401) {
            console.log("Unable to get Spemning details");
          }
          throw response.text();
        }
        return response.json();
      })
      .then((responseData) => {

        processSecurityByUserId(responseData);

      })
      .catch((err) => {
        console.log("inside catch block with error : " + err);
      });

  }

  function processSecurityByUserId(response) {
    if (response.success !== true) {
      var errorMessage = response.responseMessage;
      console.log("Received error message : " + errorMessage);
    } else {
      setSelectedSecurityDetails(response.responseData);
    }

    setSelectedSecurityDetailsLoading(false)
    setGlobalLoading(false)
  }

  function onChangeDateFilter(dateFilterValue) {
    setGlobalFilterValue({
      ...globalFilterValue, dateFilter: dateFilterValue, categoryFilter: {
        selectedCategoryFilter: [],
        excludedCategoryFilter: []
      },
      merchantFilter: {
        includeMerchantIds: [],
        excludeMerchantIds: []
      }
    });

    setSelectedHoldingsFilterState(dateFilterValue)
    selectedDateFilter = dateFilterValue;

    setIsCategoryFilterModified(false)

    refreshHoldingsDataFromProvider();

    categoryFilterFns.fns.getCategorySubCategoryFilterData(dateFilterValue)

  }

  function onChangeCategoryFilter(includedIds, excludedIds) {
    if ((includedIds.length <= excludedIds.length) && (includedIds.length && excludedIds.length)) {
      selectedCategoryFilter = includedIds
      excludedCategoryFilter = []
    } else if (includedIds.length > excludedIds.length) {
      selectedCategoryFilter = []
      excludedCategoryFilter = excludedIds
    } else if (!includedIds.length && !excludedIds.length) {
      selectedCategoryFilter = []
      excludedCategoryFilter = []
    }

    setGlobalFilterValue({
      ...globalFilterValue, categoryFilter: {
        selectedCategoryFilter, excludedCategoryFilter
      }
    })
    refreshHoldingsDataFromProvider();
  }

  let fns = {
    onHoldingsLandingPageLoad,
    onChangeDateFilter,
    getHoldingsChart,
    getSecurityByUserId,
    onChangeCategoryFilter,
    refreshHoldingsDataFromProvider
  };


  return { dataHoldings: data, fnsHoldings: fns };
};

export default useHoldingsLanding;

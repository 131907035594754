import { useRecoilState } from "recoil";
import { atm_DateListFilters } from "src/state/filters";

import axios from "axios";
import { getAuthToken } from "src/global/central";

const useDateFilter = () => {

    let data = {};
    
    const jwt = getAuthToken();

    const[dateFilter, setDateFilter] = useRecoilState(atm_DateListFilters);
    data.dateFilter = dateFilter;

    function onDateFilterLoad() {
         if(data.dateFilter.length === 0)
            getDataFilter();
      }

      function getDataFilter() {

        let url =
          process.env.REACT_APP_ACCOUNT_API_END_POINT +
          "/accounts/transactions/filters/v1/getDateFilter";
        axios
          .post(
            url,
            {},
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + jwt,
              },
            }
          )
          .then((res) => {
            // console.log(res["data"]["responseData"]);
            setDateFilter(res["data"]["responseData"]);
          })
          .catch((res) => {
            console.log(res);
          });
      }

      let fns = {
        onDateFilterLoad,

     };
    
      return { data, fns };
      
};

export default useDateFilter;
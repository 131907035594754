import React, { useRef, useState } from "react";
import CategoryPopUp from "./CategoryPopUp";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useRecoilState, useSetRecoilState } from "recoil";
import { atm_selectedDataForBulkEdit } from "src/state/atomsTransactionData";

const BulkEditCategoryDropdown = () => {
  const menuRef = useRef();
  const [selectedCategoryForBulkEdit, setSelectedDataForBulkEdit] = useRecoilState(atm_selectedDataForBulkEdit)
  const [isBulkCategorySelectionMenu, setIsBulkCategorySelectionMenu] =
    useState(false);

  const handleOpen = () => {
    setIsBulkCategorySelectionMenu(true);
  };

  const handleClose = () => {
    setIsBulkCategorySelectionMenu(false);
  };

  return (
    <FormControl
      fullWidth
      sx={{
        maxWidth: 250,
      }}
    >
      <InputLabel>Category</InputLabel>
      <Select
        ref={menuRef}
        open={isBulkCategorySelectionMenu}
        label="Category"
        onOpen={handleOpen}
        onClose={handleClose}
        value={selectedCategoryForBulkEdit?.categoryName ? 0 : ''}
      >
        <MenuItem value={0} sx={{
          display: "none"
        }}>{selectedCategoryForBulkEdit?.categoryName}</MenuItem>
      </Select>
      <CategoryPopUp
        anchorValue={menuRef.current}
        isOpen={isBulkCategorySelectionMenu}
        onClose={handleClose}
        setSelectedDataForBulkEdit={setSelectedDataForBulkEdit}
        bulkEdit={true}
      />
    </FormControl>
  );
};

export default BulkEditCategoryDropdown;

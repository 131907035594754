import { Box, LinearProgress, Modal, useMediaQuery } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import useTransactionsLanding from "src/components/Accounts/Transactions/useTransactionsLanding";
import {
  atm_isReviewTransBoxOpen,
  atm_pageOptions,
  atm_transactionsListTable,
} from "src/state/atomsTransactionData";
import useSpendByMerchant from "./useSpendByMerchant";
import CategoryChart from "./CategoryChart";
import MobTransTable from "src/components/Accounts/Transactions/MobTransTable";
import { DataGrid } from "@mui/x-data-grid";
import TransUserReview from "src/components/Accounts/Transactions/ParentReview";
import {
  atm_globalMerchant,
  atm_merchantParentSelectors,
} from "src/state/insightsMerchants";

const MoM = () => {
  const mobile = useMediaQuery("(max-width: 900px)");
  const { data, fns } = useSpendByMerchant();
  const { dataTransactions, fnsTransactions } = useTransactionsLanding();
  const transactionsListTable = useRecoilValue(atm_transactionsListTable);

  const [isTransBoxOpen, setIsTransBoxOpen] = useRecoilState(
    atm_isReviewTransBoxOpen
  );

  const [pageOptions, setPageOptions] = useState({
    pageNumber: 0,
    pageSize: 25,
  });

  const modelStyle = {
    position: "relative",
    top: mobile ? "5%" : "10%",
    left: "50%",
    transform: "translateX(-50%)",
    maxWidth: "90%",
    width: 773,
  };

  const setGlobalPageOption = useSetRecoilState(atm_pageOptions);
  const parentScroller = useRef(null);

  const handlePaginationChange = (options) => {
    const {page: pageNumber, pageSize} = options;
    fnsTransactions.getTransactionsData(
      pageNumber,
      pageSize,
      null,
      null,
      null,
      globalMerchant
    );
    setPageOptions({ pageNumber, pageSize });
    setGlobalPageOption({ pageNumber, pageSize });
  }

  const globalMerchant = useRecoilValue(atm_globalMerchant);

  const merchantParentSelectors = useRecoilValue(atm_merchantParentSelectors);

  useEffect(() => {
    if (!merchantParentSelectors.mom && data.merchantListData.length) {
      fns.refreshRightScreen(globalMerchant);
    }
  }, [merchantParentSelectors]);

  return (
    <>
      <div
        className={mobile ? "scroller" : "scrollable-content scroller"}
        id="infinite-scroller"
        ref={parentScroller}
      >
        <div className="inside-scroller">
          {data.merchantChartLoading && (
            <LinearProgress
              style={{
                width: "100%",
                position: "absolute",
                top: 0,
                left: 0,
              }}
            />
          )}
          <CategoryChart />
          {mobile ? (
            <MobTransTable merchant={true} parentScroller={parentScroller} />
          ) : (
            <div
              className="transactionTable"
              style={{
                width: "100%",
                position: "relative",
              }}
            >
              {dataTransactions.isTransactionListLoading && (
                <LinearProgress
                  style={{
                    width: "100%",
                    position: "absolute",
                    top: 0,
                    left: 0,
                  }}
                />
              )}
              <DataGrid
                getRowHeight={() => "auto"}
                getRowClassName={(params) => {
                  if (params.row.pending) {
                    return "pending";
                  }
                }}
                disableRowSelectionOnClick
                autoHeight
                rows={transactionsListTable}
                columns={data.clmnTransactions}
                sx={{
                  borderLeft: 0,
                  borderRight: 0,
                }}
                pagination
                paginationModel={{page: pageOptions.pageNumber, pageSize: pageOptions.pageSize}}
                pageSizeOptions={[25, 50, 100]}
                paginationMode="server"
                onPaginationModelChange={handlePaginationChange}
                loading={dataTransactions.isTransactionListLoading}
                rowCount={dataTransactions.allTransactionDataCount}
              />
            </div>
          )}
        </div>
      </div>
      <Modal open={isTransBoxOpen} onClose={() => setIsTransBoxOpen(false)}>
        <Box sx={modelStyle}>
          <TransUserReview />
        </Box>
      </Modal>
    </>
  );
};

export default MoM;

import { LinearProgress } from "@mui/material";
import React, { useEffect } from "react";
import ChildReview from "src/components/Accounts/Transactions/ChildReview";
import RatingData from "./RatingData";
import useSpendByMerchant from "./useSpendByMerchant";
import { useRecoilValue } from "recoil";
import {
  atm_globalMerchant,
  atm_merchantParentSelectors,
} from "src/state/insightsMerchants";

const RatingReviews = () => {
  const { data, fns } = useSpendByMerchant();
  const globalMerchant = useRecoilValue(atm_globalMerchant);

  const merchantParentSelectors = useRecoilValue(atm_merchantParentSelectors);

  useEffect(() => {
    if (!merchantParentSelectors.ratingReviews && data.merchantListData.length) {
      fns.refreshRightScreen(globalMerchant);
    }
  }, [merchantParentSelectors]);
  return (
    <div className="scroller">
      <div className="review-and-graph">
        {data.merchantRatingLoading && (
          <LinearProgress
            style={{
              width: "100%",
              position: "absolute",
              top: 0,
              left: 0,
            }}
          />
        )}
        <RatingData />
        <div className="all-review-box user-review-box scroller">
          {data.globalLoading && (
            <LinearProgress
              style={{
                width: "100%",
                position: "absolute",
                top: 0,
                left: 0,
              }}
            />
          )}
          {data.parentReviews?.length ? (
            <>
              <div className="all-review-title">Reviews</div>
              {data.parentReviews.map((data, index) => {
                return <ChildReview data={data} index={index} key={index} />;
              })}
            </>
          ) : (
            <span className="nothing">No Reviews</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default RatingReviews;

import React, { useState } from "react";
import CloseIcon from "../../assets/images/close.svg";

import { useRecoilState } from "recoil";
import { DropdownDate } from "src/components/shared/DateFilter/DropdownDate";
import { atm_IsMobHoldingsFilter } from "src/state/atomsHoldingData";
import useHoldingsLanding from "./useHoldingsLanding";
import { DropdownCategory } from "src/components/shared/CategoryFilter/DropdownCategory";

const FiltersHoldings = () => {
  const { fnsHoldings } = useHoldingsLanding();

  const [dropdownActive, setdropdownActive] = useState(null);

  const [isMobFilter, setIsMobFilter] = useRecoilState(atm_IsMobHoldingsFilter);

  return (
    <>
      <div
        className={`selection-areas filters ${
          isMobFilter === false && "hidden"
        }`}
      >
        <header>
          <span>Filters</span>
          <img
            onClick={() => setIsMobFilter(false)}
            src={CloseIcon}
            alt=""
            className="close-icon"
          />
        </header>
        <div className="row">
          <DropdownDate
            setdropdownActive={setdropdownActive}
            dropdownActive={dropdownActive}
            callBackFunction={fnsHoldings.onChangeDateFilter}
          />

          {/* <DropdownCategory
            initial_value="All"
            setdropdownActive={setdropdownActive}
            dropdownActive={dropdownActive}
            callBackFunction={fnsHoldings.onChangeCategoryFilter}
          /> */}
        </div>
      </div>

      {isMobFilter && (
        <div
          className="mob-filter-backdrop"
          onClick={() => setIsMobFilter(false)}
        ></div>
      )}
    </>
  );
};

export default FiltersHoldings;

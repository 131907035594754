import { TextField } from "@mui/material";
import React, { useState } from "react";
import ErrorIcon from "../assets/images/error.svg";
import { useNavigate } from "react-router-dom";
import useForgotPassword from "./useForgotPassword";
import { useRecoilState, useResetRecoilState } from "recoil";
import {
  atm_forgotPasswordErrors,
  atm_resetCode,
  atm_responseMessage,
} from "src/state/atomForgotPassword";

const ForgotPasswordCode = ({ email }) => {
  const { dataPassword, fnsPassword } = useForgotPassword();
  const [code, setCode] = useRecoilState(atm_resetCode);
  const [error, setError] = useState(false);
  const resetErrors = useResetRecoilState(atm_forgotPasswordErrors);
  const resetResponses = useResetRecoilState(atm_responseMessage);
  const navigate = useNavigate();

  const handleCodeSubmit = (e) => {
    e.preventDefault();
    if (!code.trim()) {
      setError(true);
      return;
    }
    fnsPassword.validateEmailCodeForPasswordReset({ email, code });
  };
  return (
    <form onSubmit={handleCodeSubmit} style={{ width: "100%" }}>
      <div className="head">Enter verification</div>
      <div className="subHead">
        We have sent you an email at the specified address. If the address you
        mentioned is valid and registered with Kamunity, the email will contain
        a verification code.
      </div>
      <TextField
        label="Email verification code"
        className="verificationInput"
        type="number"
        sx={{
          width: "100%",
          marginTop: "14px",
        }}
        error={error || dataPassword.errors.codeError}
        onChange={(e) => {
          setCode(e.target.value);
          setError(false);
          resetErrors();
          resetResponses();
        }}
        onInput={(e) => {
          e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 6);
        }}
      />
      <p
        className="error-custom grid-2"
        style={{
          margin: "10px 0px",
          opacity: error || dataPassword.errors.codeError ? 1 : 0,
          textAlign: "left",
        }}
      >
        <span>
          <img src={ErrorIcon} style={{ marginRight: 5 }} alt="error" />
          {dataPassword.errors.codeError || "Code is Invalid"}
        </span>
      </p>
      <div className="btns">
        <button onClick={() => navigate("/login")} className="cancelBtn btn">
          Cancel
        </button>
        <button
          disabled={dataPassword.passwordLoading}
          type="submit"
          className="confirmBtn btn"
        >
          Confirm
        </button>
      </div>
    </form>
  );
};

export default ForgotPasswordCode;

/**********************************************************************
 *
 *   Component generated by Quest
 *
 *   WARNING: By editing this component by hand, you will lose the ability to regenerate the code without conflicts.
 *   To preseve that abilty, always export from Quest to regenerate this file.
 *   To setup props, bindings and actions, use the Quest editor
 *   Code Logic goes in the hook associated with this component
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/

import React from "react";
import { Alert, Snackbar, LinearProgress, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Helmet } from "react-helmet";
import useMyProfile from "./useMyProfile";
import "../assets/css/register.css";
import boy from "../assets/images/boy.webp";
import girl from "../assets/images/girl.webp";

import EditProfileForm from "../MainMenu/EditProfileForm";
import { useSetRecoilState } from "recoil";
import { atm_alertType } from "src/state/atomMyProfile";
import MetaTags from "../shared/MetaTags";

const AlertFilled = styled(Alert)({
  alignSelf: `stretch`,
  marginBottom: `20px`,
});

export default function MyProfile() {
  const { data, profileFns } = useMyProfile();
  const setAlertType = useSetRecoilState(atm_alertType);

  const handleClose = () => {
    setAlertType(false);
  };

  return (
    <div className="profilePage" style={{ height: "calc(100vh - 100px)" }}>
      <MetaTags index="MyProfile" />

      <div
        className="sign-up-main-wrapper"
        style={{ height: "100%", display: "flex", justifyContent: "center" }}
      >
        <div className="sign-up-box" style={{ margin: 0 }}>
          {data.profileLoading && (
            <Box sx={{ width: "100%" }}>
              <LinearProgress
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                }}
              />
            </Box>
          )}
          <img src={boy} className="boy-image" alt="" />

          <div className="initial-text">
            <h1>{`Edit Your Profile`}</h1>
          </div>
          {data.sysUserError && (
            <AlertFilled
              variant="filled"
              severity={"error"}
              onClose={profileFns.clearErrorMessage}
            >
              {data.sysUserHelperText}
            </AlertFilled>
          )}
          <EditProfileForm />
          <Alert severity="warning" sx={{ marginTop: "10px" }}>
            Changing your email address will make you re-login to the website
          </Alert>
          <img src={girl} className="girl-image" alt="" />
        </div>
        <Snackbar
          open={data.alertType}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert severity="success" sx={{ width: "100%" }}>
            Changes Updated Successfully!
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
}

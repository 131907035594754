import React, { useEffect, useState } from "react";
import CheckboxCustom from "./CheckboxCustom";
import headerArrow from "../assets/images/headerArrow.svg";
import activeArrow from "../assets/images/arrow-up-active.svg";
import depo from "../assets/images/depo.svg";
import inves from "../assets/images/inves.svg";
import credit from "../assets/images/credit.svg";
import loan from "../assets/images/loan.svg";
import { usdFormate, dateFormateShort } from "src/global/central";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  atm_accountsList,
  atm_accountsTotalAmount,
  atm_editAccountModal,
  atm_isAccountSelectionModified,
  atm_isSelectAllAccountList,
  atm_selectedAccountIds,
  atm_selectedAccountType,
  atm_selectedEditAccount,
  atm_selectedUnchangedAccountIds,
} from "src/state/atomsAccountList";
import PencilIcon from "../assets/images/pencil2.svg";
import { Col } from "../shared";
import { useMediaQuery } from "@mui/material";

export const DropdownLeftSide = ({ EachList, multiIndex }) => {
  const [active, setActive] = useState(false);
  const setTotalAmount = useSetRecoilState(atm_accountsTotalAmount);
  const [groupTotal, setGroupTotal] = useState(0);

  const setSelectedAccountIds = useSetRecoilState(atm_selectedAccountIds);
  const setEditAccountModal = useSetRecoilState(atm_editAccountModal);
  const accountList = useRecoilValue(atm_accountsList)
  const setIsAccountSelectionModified = useSetRecoilState(
    atm_isAccountSelectionModified
  );

  const setIsCheckedAll = useSetRecoilState(atm_isSelectAllAccountList);

  const selectUnchangedAccountIds = useSetRecoilState(
    atm_selectedUnchangedAccountIds
  );

  const setSelectedEditAccount = useSetRecoilState(atm_selectedEditAccount);
  const setSelectedAccountType = useSetRecoilState(atm_selectedAccountType);
  
  const isMobile = useMediaQuery('(max-width: 750px)')

  const handleTotal = () => {
    setTotalAmount(0);

    let allInputs = document.getElementsByName("accountChecks");
    let allInputsFalseCount = 0;
    let allInputsTrueCount = 0;

    let includedIds = [];
    let excludedIds = [];
    allInputs.forEach((input) => {
      if (input.checked) {
        setTotalAmount((prevAmount) => prevAmount + Number(input.value));
        allInputsTrueCount++;

        includedIds.push(+input.id);
      } else {
        allInputsFalseCount++;
        excludedIds.push(+input.id);
      }
    });

    selectUnchangedAccountIds({ includedIds, excludedIds });

    if (allInputsTrueCount) {
      setIsCheckedAll(true);
    } else {
      setIsCheckedAll(false);
    }

    if (
      includedIds.length <= excludedIds.length &&
      excludedIds.length != allInputs.length
    ) {
      setSelectedAccountIds({ includedIds, excludedIds: [] });
    } else if (includedIds.length > excludedIds.length) {
      setSelectedAccountIds({ includedIds: [], excludedIds });
    } else if (excludedIds.length == allInputs.length) {
      setSelectedAccountIds({ includedIds: excludedIds, excludedIds: [] });
    } else if (!includedIds.length && !excludedIds.length) {
      setSelectedAccountIds({ includedIds: [], excludedIds: [] });
    }
    setIsAccountSelectionModified(true);

    let dropdownLeftSide = document.querySelectorAll(".DropdownLeftSide");
    let sidebar = document.querySelector(".account-list");

    let isAllGroupChildInputs = true;
    let isAllGroupChildInputsTrueCount = 0;
    let isAllGroupChildInputsFalseCount = 0;

    let groundMain = document.getElementById(
      `dropdownleftside_${multiIndex + 1}`
    );

    let allGroupChildInputs = document.querySelectorAll(
      `.group${multiIndex + 1}`
    );

    allGroupChildInputs.forEach((input) => {
      if (!input.checked) {
        isAllGroupChildInputs = false;
        isAllGroupChildInputsFalseCount++;
      } else {
        isAllGroupChildInputsTrueCount++;
      }
    });

    if (!isAllGroupChildInputs) {
      sidebar.classList.add("minus-sign");
      dropdownLeftSide[multiIndex].classList.add("minus-sign");
    }

    if (isAllGroupChildInputsTrueCount === allGroupChildInputs.length) {
      sidebar.classList.remove("minus-sign");
      dropdownLeftSide[multiIndex].classList.remove("minus-sign");
    }

    if (isAllGroupChildInputsFalseCount === allGroupChildInputs.length) {
      groundMain.checked = false;
    } else if (isAllGroupChildInputsFalseCount < allGroupChildInputs.length) {
      groundMain.checked = true;
    }

    let selectAll = document.getElementById("select-all");
    if (allInputsFalseCount === allInputs.length) {
      selectAll.checked = false;
    } else {
      selectAll.checked = true;
      if (allInputsTrueCount === allInputs.length) {
        sidebar.classList.remove("minus-sign");
      } else {
        sidebar.classList.add("minus-sign");
      }
    }
  };

  const handleGroupTotal = () => {
    setGroupTotal(0);

    let groupInputs = document.querySelectorAll(`.group${multiIndex + 1}`);

    groupInputs.forEach((input) => {
      if (input.checked) {
        setGroupTotal(
          (prevGroupAmount) => prevGroupAmount + Number(input.value)
        );
      }
    });
  };

  useEffect(() => {
    handleGroupTotal();
  },[accountList])

  useEffect(() => {
    let accountList = document.querySelector(".account-list");

    let groupMain = document.getElementsByName("group")[multiIndex];

    let groupInputs = groupMain
      .closest(".DropdownLeftSide")
      .querySelectorAll(".EachListWrapper input");

    let groupInputCheckCount = 0;

    groupInputs.forEach((input) => {
      if (input.checked) {
        groupInputCheckCount++;
      }
    });

    if (groupInputCheckCount == 0) {
      groupMain.checked = false;
      accountList.classList.add("minus-sign");
    } else if (
      groupInputCheckCount < groupInputs.length &&
      groupInputCheckCount != 0
    ) {
      groupMain.closest(".DropdownLeftSide").classList.add("minus-sign");

      accountList.classList.add("minus-sign");
    }

    if (groupInputCheckCount == groupInputs.length) {
      groupMain.checked = true;
    }
  }, []);

  const handleEditAccount = (account, accountType) => {
    setEditAccountModal(true);
    setSelectedEditAccount(account);
    setSelectedAccountType(accountType);
  }

  return (
    <div className="DropdownLeftSide">
      <div
        className="dropdownHead"
        onClick={() => {
          setActive(!active);
        }}
      >
        <img src={active ? activeArrow : headerArrow} alt="" />
        <CheckboxCustom
          id={`dropdownleftside_${multiIndex + 1}`}
          groupIds={EachList.accountDetails.map((data) => {
            return data.accountId;
          })}
          value={EachList.accountTypeBalance}
          accountName={"group"}
          onclick={(e) => {
            let dropdown = e.target.closest(".DropdownLeftSide");
            let allcheckbox = dropdown.querySelectorAll(
              ".EachListWrapper input"
            );

            allcheckbox.forEach((EachCheckbox) => {
              if (e.target.checked == true) {
                EachCheckbox.checked = true;
              } else {
                EachCheckbox.checked = false;
              }
            });

            handleTotal();
            handleGroupTotal();
          }}
        />
        {EachList.accountType === "depository" && (
          <img src={depo} alt="Depository" />
        )}
        {EachList.accountType === "investment" && (
          <img src={inves} alt="Investment" />
        )}
        {EachList.accountType === "credit" ||
          (EachList.accountType === "creditCard" && (
            <img src={credit} alt="Credit Card" />
          ))}
        {EachList.accountType === "loan" && <img src={loan} alt="Loan" />}
        <h1>{EachList.accountType}</h1>
        <p
          style={
            EachList.accountTypeBalance < 0 ? { color: "var(--color14)" } : {}
          }
        >
          {usdFormate(groupTotal)}
        </p>
      </div>
      <div className={`EachListWrapper ${active && "active"}`}>
        {EachList.accountDetails.map((EachListItem) => {
          return (
            <div
              className={"dropdown-cal-innner " + (EachListItem.dateClosedByUser && "closed")}
              key={`dropdownInner_${EachListItem.accountId}`}
            >
              <Col>
                <CheckboxCustom
                  id={EachListItem.accountId}
                  accountName={"accountChecks"}
                  multiIndex={multiIndex}
                  onclick={() => {
                    handleTotal();
                    handleGroupTotal();
                  }}
                  singleAccount={EachListItem}
                  value={EachListItem.accountBalance}
                />
                {!EachListItem.dateClosedByUser && <img
                  hidden={!isMobile}
                  className="edit-icon"
                  onClick={() => handleEditAccount(EachListItem, EachList.accountType)}
                  src={PencilIcon}
                  alt=""
                />}
              </Col>
              <div className="right-side-cover">
                <div className="right-side">
                  <div className="first-area">
                    <h1>{EachListItem.accountFinInstitution}</h1>
                    <p>
                      {EachListItem.accountName}
                      {/* - **{" "}
                      {EachListItem.accountNumberMask} */}
                    </p>
                  </div>
                  <div className="second-area">
                    <h1
                      style={
                        EachListItem.accountBalance < 0
                          ? { color: "var(--color14)" }
                          : {}
                      }
                    >
                      {usdFormate(EachListItem.accountBalance)}
                    </h1>
                    <p>
                      {dateFormateShort(EachListItem.accountBalanceTimestamp)}
                    </p>
                  </div>
                </div>
                {EachListItem.dateClosedByUser && <div className="closed-text">(Closed)</div>}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

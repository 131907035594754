import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

import { List, Divider, ListItem, ListItemButton } from "@mui/material";
import useMainMenu from "./useMainMenu";
import {
  atm_mainMenuButtonPressed,
  atm_menuInsightsType,
} from "src/state/atoms";
import { useRecoilValue } from "recoil";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));

export default function InsightsDropdown() {
  const [expanded, setExpanded] = React.useState("panel1");

  const { fns } = useMainMenu();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const mainMenuButtonPressed = useRecoilValue(atm_mainMenuButtonPressed);
  const mainMenuButtonType = useRecoilValue(atm_menuInsightsType);

  return (
    <div>
      <Accordion
        onChange={handleChange("panel1")}
      >
        <AccordionSummary disablePadding>
          <ListItemButton
            sx={{
              borderLeft: mainMenuButtonPressed == "Insights" ? 5 : 0,
              borderLeftColor: "#A4379E",
              borderRadius: "5px",
              fontWeight: "bold",
            }}
          >
            <strong style={{ fontFamily: "Nunito" }}>Insights</strong>
          </ListItemButton>
        </AccordionSummary>
        <AccordionDetails sx={{ margin: 2, backgroundColor: "#F9F9F9" }}>
          <List sx={{ width: 180, margin: 0, padding: 0 }}>
            {" "}
            <ListItem
              onClick={fns.onClickInsightNewSpendByCategory}
              sx={{
                color:
                  mainMenuButtonType == "SpendByCategory" &&
                  mainMenuButtonPressed == "Insights"
                    ? "#A4379E"
                    : undefined,
              }}
            >
              <ListItemButton>
                <strong style={{ fontFamily: "Nunito" }}>By Category</strong>
              </ListItemButton>
            </ListItem>
            <Divider variant="middle" />
            <ListItem
              onClick={fns.onClickInsightNewSpendByMerchant}
              sx={{
                color:
                  mainMenuButtonType == "SpendByMerchant"
                    ? "#A4379E"
                    : undefined,
              }}
            >
              <ListItemButton>
                <strong style={{ fontFamily: "Nunito" }}>By Merchant</strong>
              </ListItemButton>
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import CloseIcon from "../../assets/images/close.svg";
import { DropdownDate } from "src/components/shared/DateFilter/DropdownDate";
import useTransactionsLanding from "./useTransactionsLanding";
import { atm_IsMobTransactionFilter } from "src/state/atomsTransactionData";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { atm_globalFilterCount, atm_globalFilterValue } from "src/state/atoms";
import useCategoryFilter from "src/components/shared/CategoryFilter/useCategoryFilter";
import NewMerchantFilter from "src/components/shared/MerchantFilter/NewMerchantFilter";
import NewCategoryFilter from "src/components/shared/CategoryFilter/NewCategoryFilter";
import TagsFilter from "src/components/shared/TagsFilter/TagsFilter";

const FiltersTransaction = () => {
  const [dropdownActive, setdropdownActive] = useState(null);

  const { data, fns } = useCategoryFilter();
  const { dataTransactions, fnsTransactions } = useTransactionsLanding();

  const [isMobFilter, setIsMobFilter] = useRecoilState(
    atm_IsMobTransactionFilter
  );
  const globalFilterValue = useRecoilValue(atm_globalFilterValue);

  const setGlobalFilterCount = useSetRecoilState(atm_globalFilterCount);

  useEffect(() => {
    fns.onCategoryFilterLoad();
  }, []);

  useEffect(() => {
    let filter = 1;

    const categoryFilter =
      globalFilterValue.categoryFilter.selectedCategoryFilter?.length > 0 ||
      globalFilterValue.categoryFilter.excludedCategoryFilter?.length > 0;

    const merchantFilter =
      globalFilterValue.merchantFilter.includeMerchantIds?.length > 0 ||
      globalFilterValue.merchantFilter.excludeMerchantIds?.length > 0;

    const tagFilter =
      globalFilterValue.tagFilter.includedTagIds?.length > 0 ||
      globalFilterValue.tagFilter.excludedTagIds?.length > 0;

    filter = filter + Number(categoryFilter) + Number(merchantFilter) + Number(tagFilter);
    
    setGlobalFilterCount(filter);
  }, [globalFilterValue]);

  return (
    <>
      <div
        className={`selection-areas filters filters-transaction ${
          isMobFilter === false && "hidden"
        }`}
      >
        <header>
          <span>Filters</span>
          <img
            onClick={() => setIsMobFilter(false)}
            src={CloseIcon}
            alt=""
            className="close-icon"
          />
        </header>

        <div className="row">
          <DropdownDate
            setdropdownActive={setdropdownActive}
            dropdownActive={dropdownActive}
            callBackFunction={fnsTransactions.onChangeDateFilter}
          />

          <NewCategoryFilter
            data={data.categoryFilter}
            callBackFunction={fnsTransactions.onChangeCategoryFilter}
          />

          <NewMerchantFilter
            data={dataTransactions.MerchantState}
            callBackFunction={fnsTransactions.onChangeMerchantFilter}
            loading={dataTransactions.globalLoading}
          />

          <TagsFilter data={dataTransactions.transactionTags} callBackFunction={fnsTransactions.onChangeTagsFilter} loading={false} />
        </div>

      </div>

      {isMobFilter && (
        <div
          className="mob-filter-backdrop"
          onClick={() => setIsMobFilter(false)}
        ></div>
      )}
    </>
  );
};

export default FiltersTransaction;

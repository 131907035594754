import { Modal } from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "../assets/images/close.svg";
import Loader from "../assets/images/legals-loader.svg"

const LegalsModal = ({ isOpen, setIsOpen, title, link }) => {
  const [isLoading, setIsLoading] = useState(true);
  const handleClose = () => {
    setIsOpen(false);
    setIsLoading(true);
  };
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
    >
      <div className="legalsModal">
        <img
          className="close-icon"
          src={CloseIcon}
          alt="close"
          onClick={handleClose}
        />
        {isLoading && <img className="loader" src={Loader} alt="loader"/>}
        <iframe
          title={title}
          src={link}
          frameborder="0"
          onLoadCapture={() => setIsLoading(false)}
        ></iframe>
      </div>
    </Modal>
  );
};

export default LegalsModal;

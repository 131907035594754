import { CircularProgress } from "@mui/material";
import React from "react";

const baseStyles = {
  primary: {
    borderRadius: "6px",
    width: "158px",
    height: "49px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    border: "1px solid",
    whiteSpace: "nowrap",
    fontWeight: 700,
  },
  primary_large: {
    borderRadius: "6px",
    width: "149px",
    height: "46px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    border: "1px solid",
    whiteSpace: "nowrap",
    fontWeight: 700,
  },
  primary_thick: {
    borderRadius: "6px",
    width: "158px",
    height: "57px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    border: "2px solid",
    whiteSpace: "nowrap",
    fontWeight: 700,
    padding: "0 10px",
    fontSize: "16px"
  },
  primary_free: {
    borderRadius: "6px",
    width: "100%",
    height: "49px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    border: "1px solid",
    whiteSpace: "nowrap",
    padding: "10px",
    fontWeight: 700
  },
  secondary: {
    borderRadius: "6px",
    width: "93px",
    height: "37px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    border: "1px solid",
    whiteSpace: "nowrap",
    fontSize: 12,
    fontWeight: 700,
    padding: "0 7px"
  },
  secondary_small: {
    borderRadius: "6px",
    width: "82px",
    height: "37px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    border: "1px solid",
    whiteSpace: "nowrap",
    fontSize: 12,
    fontWeight: 700,
    padding: "0 7px"
  },
  tertiary: {
    borderRadius: "6px",
    width: "120.7px",
    height: "41px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    border: "1px solid",
    whiteSpace: "nowrap",
    fontSize: 14,
    fontWeight: 700
  },
};

const colorStyles = {
  primary: {
    background: "var(--color1)",
    color: "#000",
    borderColor: "var(--color1)",
  },
  primary_outlined: {
    background: "#ffffff",
    color: "#000",
    borderColor: "#000",
  },
  secondary_outlined: {
    background: "#ffffff",
    color: "#000",
    borderColor: "var(--color1)",
  },
  danger: {
    background: "#ffffff",
    color: "var(--colorWarning)",
    borderColor: "var(--colorWarning)",
  },
  disabled: {
    background: "var(--colorBlack9)",
    cursor: "not-allowed",
    borderColor: "var(--colorBlack9)",
    color: "#fff",
  },
};

const KmnButton = ({
  children,
  onClick,
  variant,
  color = "primary",
  isDisabled,
  isLoading,
  b,
  style,
  ...props
}) => {
  const styles = {
    ...(baseStyles[variant] || {}),
    ...(colorStyles[color] || {}),
    ...(isDisabled || isLoading ? colorStyles["disabled"] : {}),
    ...(style || {}),
  };

  return (
    <button
      style={styles}
      onClick={onClick}
      disabled={isDisabled || isLoading}
      {...props}
    >
      {isLoading && (
        <CircularProgress size={22} sx={{ color: "#fff", mr: 1 }} />
      )}
      {children}
    </button>
  );
};

export default KmnButton;

import { atom } from "recoil";

export const atm_DateListFilters = atom({
    key: "dateListFilters",
    default: [],
}
);

export const atm_CategorySubCategoryListFilters = atom({
    key: "categorySubCategoryListFilters",
    default: [],
}
);
import React, { useState } from "react";
import ArrowDown from "../assets/images/arrow-down.svg";
import ArrowUpActive from "../assets/images/arrow-up-active2.svg";
import AddIcon from "../assets/images/add-icon.svg";

import { useSetRecoilState } from "recoil";
import { atm_isManageCategoryBox } from "src/state/atomsTransactionData";
import SubCategoryBox from "./SubCategoryBox";
import useManegeCategories from "./useManageCategories";

const ManageCategoryBox = () => {
  const { data } = useManegeCategories();

  const setManageCategoryBox = useSetRecoilState(atm_isManageCategoryBox);

  const [expandedCategories, setExpandedCategories] = useState([]);

  const handleCategory = (index) => {
    const isRowExpanded = expandedCategories.includes(index);

    if (isRowExpanded) {
      setExpandedCategories(expandedCategories.filter((ind) => ind !== index));
    } else {
      setExpandedCategories([...expandedCategories, index]);
    }
  };

  return (
    <div className="manageCategoryBox">
      <div className="close-box">
        <svg
          onClick={() => setManageCategoryBox(false)}
          className="close-icon"
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.11371 7.5L0.494221 12.1195C-0.164742 12.7784 -0.164742 13.8468 0.494221 14.5058C1.15322 15.1647 2.22152 15.1647 2.88051 14.5058L7.5 9.88629L12.1195 14.5058C12.7784 15.1647 13.8468 15.1647 14.5058 14.5058C15.1647 13.8468 15.1647 12.7784 14.5058 12.1195L9.88629 7.5L14.5058 2.88051C15.1647 2.22155 15.1647 1.15318 14.5058 0.494221C14.1763 0.164774 13.7445 -9.53674e-07 13.3126 -9.53674e-07C12.8808 -9.53674e-07 12.449 0.16474 12.1195 0.494221L7.5 5.11371L2.88051 0.494221C2.55103 0.164774 2.11921 -9.53674e-07 1.68739 -9.53674e-07C1.25556 -9.53674e-07 0.823736 0.16474 0.494255 0.494221C-0.164707 1.15318 -0.164707 2.22155 0.494255 2.88051L5.11371 7.5Z"
            fill="#2B3B47"
          />
        </svg>
      </div>
      <div className="heading">Manage Categories</div>

      <div className="categoryCover">
        {data.categoriesData.map((category, index) => {
          return (
            <div
              key={index}
              className={`category ${
                expandedCategories.includes(index) && "expanded"
              }`}
            >
              <div className="category-list">
                <div onClick={() => handleCategory(index)} className="title">
                  <img
                    src={
                      expandedCategories.includes(index)
                        ? ArrowUpActive
                        : ArrowDown
                    }
                    alt=""
                  />
                  <div className="text">{category.name}</div>
                </div>
                <div className="addNewCatBtn">
                  <img src={AddIcon} alt="" />
                  <div className="btn">
                    <span>Add</span> New Category
                  </div>
                </div>
              </div>

              {expandedCategories.includes(index) && (
                <div className="categoriesBoxMain">
                  {data.categoriesData[index].categories.map((category, index) => {
                    return (
                      <SubCategoryBox
                        key={index}
                        data={category}
                        index={index}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ManageCategoryBox;

import React from "react";
import control from "../assets/images/control.svg";
import MissionSlide2 from "../assets/images/mission-slide2.png";
const Experience = () => {
  return (
    <div className="experience">
      <div className="circle"></div>
      <h2>Un-cluttered experience</h2>
      <p>
        At Kamunity, we believe that simplicity is the ultimate sophistication.
        That's why we've created an experience that focuses on what matters
        most: you and your needs. Our clean and intuitive interface is designed
        to streamline your experience, so you can quickly and easily find what
        you're looking for without any distractions. Say goodbye to cluttered
        pages, confusing menus, and irrelevant content. With Kamunity, you can
        enjoy a seamless, hassle-free experience that puts you in control. So
        why wait? Try it out for yourself and see the difference that an
        un-cluttered experience can make.
      </p>
      <img src={MissionSlide2} alt="Bar graph of a consumer income and expense trends with ratings and reviews of a merchant/provider" />
    </div>
  );
};

export default Experience;

const { atom } = require("recoil");

export const atm_trendsCategories = atom({
  key: "trendsCategories",
  default: [],
});

export const atm_trendsData = atom({
  key: "trendsData",
  default: null,
});

import { Rating } from "@mui/material";
import React from "react";
import useSpendByMerchant from "./useSpendByMerchant";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  atm_globalMerchant,
  atm_selectedRatings,
} from "src/state/insightsMerchants";

const RatingData = () => {
  const { data, fns } = useSpendByMerchant();

  const {
    totalUserWithRatings = 0,
    avgUserRatings = 0,
    count1Ratings = 0,
    count2Ratings = 0,
    count3Ratings = 0,
    count4Ratings = 0,
    count5Ratings = 0,
  } = data.selectedMerchant || {};

  const [selectedRatings, setSelectedRatings] =
    useRecoilState(atm_selectedRatings);

  const globalMerchant = useRecoilValue(atm_globalMerchant);

  const handleRatingSelection = (rating) => {
    if (data.globalLoading || !data.merchantListData.length) return;
    const isAlreadySelected = selectedRatings.includes(rating);
    if (isAlreadySelected) {
      let filteredData = selectedRatings.filter((value) => value !== rating);
      fns.getParentReviews(globalMerchant, filteredData);
      setSelectedRatings(filteredData);
    } else {
      fns.getParentReviews(globalMerchant, [...selectedRatings, rating]);
      setSelectedRatings([...selectedRatings, rating]);
    }
  };

  return (
    <div className="review-box">
      <div className="review-header">
        <div className="title">Ratings</div>
        <div className="overall-rating-cover">
          <Rating
            name="read-only"
            value={Math.round(avgUserRatings * 10) / 10}
            precision={0.5}
            readOnly
          />
          <span className="overall-rating">
            {Math.round(avgUserRatings * 10) / 10} out of 5
          </span>
        </div>
        <div className="global-rating total-rating">
          {totalUserWithRatings} global ratings
        </div>
      </div>

      <ul>
        <li
          onClick={() => handleRatingSelection(5)}
          className={selectedRatings.includes(5) ? "active" : ""}
        >
          <div className="star-count">5 star</div>
          <div className="star-bar">
            <div
              className="fill"
              style={{
                width: `${
                  totalUserWithRatings &&
                  (count5Ratings / totalUserWithRatings) * 100
                }%`,
              }}
            ></div>
          </div>
          <div className="rating-count">{count5Ratings} ratings</div>
        </li>
        <li
          onClick={() => handleRatingSelection(4)}
          className={selectedRatings.includes(4) ? "active" : ""}
        >
          <div className="star-count">4 star</div>
          <div className="star-bar">
            <div
              className="fill"
              style={{
                width: `${
                  totalUserWithRatings &&
                  (count4Ratings / totalUserWithRatings) * 100
                }%`,
              }}
            ></div>
          </div>
          <div className="rating-count">{count4Ratings} ratings</div>
        </li>
        <li
          onClick={() => handleRatingSelection(3)}
          className={selectedRatings.includes(3) ? "active" : ""}
        >
          <div className="star-count">3 star</div>
          <div className="star-bar">
            <div
              className="fill"
              style={{
                width: `${
                  totalUserWithRatings &&
                  (count3Ratings / totalUserWithRatings) * 100
                }%`,
              }}
            ></div>
          </div>
          <div className="rating-count">{count3Ratings} ratings</div>
        </li>
        <li
          onClick={() => handleRatingSelection(2)}
          className={selectedRatings.includes(2) ? "active" : ""}
        >
          <div className="star-count">2 star</div>
          <div className="star-bar">
            <div
              className="fill"
              style={{
                width: `${
                  totalUserWithRatings &&
                  (count2Ratings / totalUserWithRatings) * 100
                }%`,
              }}
            ></div>
          </div>
          <div className="rating-count">{count2Ratings} ratings</div>
        </li>
        <li
          onClick={() => handleRatingSelection(1)}
          className={selectedRatings.includes(1) ? "active" : ""}
        >
          <div className="star-count">1 star</div>
          <div className="star-bar">
            <div
              className="fill"
              style={{
                width: `${
                  totalUserWithRatings &&
                  (count1Ratings / totalUserWithRatings) * 100
                }%`,
              }}
            ></div>
          </div>
          <div className="rating-count">{count1Ratings} ratings</div>
        </li>
      </ul>
    </div>
  );
};

export default RatingData;

import React from "react";
import SelectAction from "./SelectAction";
import SelectAim from "./SelectAim";
import SelectMonth from "./SelectMonth";
import SelectYear from "./SelectYear";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  useMediaQuery,
} from "@mui/material";
import useDashboard from "src/components/Dashboard/useDashboard";
import SelectFamily from "./SelectFamily";
import { atm_editGoal, atm_selectedGeneralData } from "src/state/atomDashboard";
import { useRecoilState, useRecoilValue } from "recoil";

const General = () => {
  const { dataDashboard } = useDashboard();
  const isEditMode = useRecoilValue(atm_editGoal);
  const [selectedGeneralData, setSelectedGeneralData] = useRecoilState(
    atm_selectedGeneralData
  );
  const isMobile = useMediaQuery("(max-width: 750px)");

  const handleRadioChange = (e) => {
    if (e.target.value === "true") {
      setSelectedGeneralData((prevData) => ({
        ...prevData,
        option: true,
      }));
    } else {
      setSelectedGeneralData((prevData) => ({
        ...prevData,
        option: false,
      }));
    }
  };

  return (
    <div className="generalBox">
      {!isMobile && (
        <>
          <div>
            <div className="head">General Information</div>
            <div className="subHead">
              Let’s define what this new goal is about
            </div>
          </div>
          <div className="hr"></div>
        </>
      )}

      <FormControl disabled={isEditMode} className="generalForm choiceForm">
        <RadioGroup
          value={selectedGeneralData.option}
          name="radio-buttons-group"
          onChange={handleRadioChange}
        >
          <div className="goalText mainText option1">
            <div className="text text1">
              By
              <SelectMonth option={"month"} />
              <SelectYear option={"year"} />
              <span style={{ display: "flex", flexGrow: 1 }}>
                <span
                  className="pc"
                  style={{
                    marginLeft: 30,
                    marginRight: 10,
                    position: "relative",
                    top: 10,
                  }}
                >
                  ,
                </span>
                <SelectFamily data={dataDashboard.familyMembers} /> want(s) to
              </span>
            </div>
            <div className="text text2">
              {isMobile && <div className="mob-heading">Choose Your Goal</div>}
              <div style={{ width: "100%", display: "flex" }}>
                <FormControlLabel
                  value={"true"}
                  control={
                    <Radio
                      disabled={isEditMode}
                      sx={{
                        color: "var(--color1)",
                        "&.Mui-checked": {
                          color: !isEditMode && "var(--color1)",
                        },
                      }}
                    />
                  }
                />
                <SelectAction data={dataDashboard.goalsAction} />
                <SelectAim data={dataDashboard.goalsActionTypes} />
              </div>
            </div>
          </div>

          <div className="addOwnGoal">
            {isMobile ? (
              <div className="mob-heading">Add your own goal</div>
            ) : (
              <div className="head">Or, add your own free form goal</div>
            )}

            <div className="option2">
              <FormControlLabel
                value={"false"}
                control={
                  <Radio
                    disabled={isEditMode}
                    sx={{
                      color: "var(--color1)",
                      "&.Mui-checked": {
                        color: !isEditMode && "var(--color1)",
                      },
                    }}
                  />
                }
              />
              <TextField
                aria-disabled={isEditMode}
                label="Action"
                className="actionInput"
                value={selectedGeneralData.actionText}
                onChange={(e) =>
                  setSelectedGeneralData({
                    ...selectedGeneralData,
                    actionText: e.target.value,
                  })
                }
                sx={{
                  width: isMobile ? "100%" : 350,
                }}
                placeholder="e.g. Start Business"
                focused
                variant="standard"
                disabled={selectedGeneralData.option || isEditMode}
              />
            </div>
          </div>
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export default General;

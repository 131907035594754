import {
  Alert,
  Snackbar,
  useMediaQuery
} from "@mui/material";
import React from "react";

import { useRecoilState } from "recoil";

import HouseHoldList from "./HouseHoldList";
import { atm_isSavehouseHold } from "src/state/atomHouseHold";
import HouseHoldDrawer from "../MainMenu/HouseHoldDrawer";
import MetaTags from "../shared/MetaTags";

const HouseHold = () => {
  const isMobile = useMediaQuery('(max-width: 750px)');
  const [isSavehousehold, setIsSavehousehold] =
    useRecoilState(atm_isSavehouseHold);

  return (
    <div className="householdPage" style={{ overflow: "hidden" }}>
      <MetaTags index={"Household"} />
      <img
        src="/static/media/bg-transaction.9ee82bb60f3ef91391a4.png"
        alt=""
        className="globalBg householdBg"
      ></img>
      {isMobile ? <HouseHoldDrawer openDrawer={true} /> : <div
        className="newGoalModal"
        style={{ boxShadow: "none", border: "none" }}
      >
        <HouseHoldList />
      </div>}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={Boolean(isSavehousehold)}
        autoHideDuration={3000}
        onClose={() => setIsSavehousehold(0)}
      >
        <Alert
          onClose={() => setIsSavehousehold(0)}
          severity={isSavehousehold == 1 ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {isSavehousehold == 1
            ? "Success !"
            : isSavehousehold == -1
            ? "Error !"
            : ""}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default HouseHold;

import axios from "axios";
import { useRecoilState, useSetRecoilState } from "recoil";
import { getAuthToken } from "src/global/central";
import {
  atm_accountDetailsLoading,
  atm_accountSubTypes,
  atm_accounts,
  atm_accountsList,
  atm_accountsTotalAmount,
  atm_editAccountLoading,
  atm_editAccountModal,
} from "src/state/atomsAccountList";
import useAccountList from "../useAccountList";
import useApi from "src/global/useApi";

export default function useEditAccount() {
  let data = {};
  
  const fnsAccountList = useAccountList();
  const [editAccountLoading, setEditAccountLoading] = useRecoilState(
    atm_editAccountLoading
  );
  data.editAccountLoading = editAccountLoading;

  const [accountDetailsLoading, setAccountDetailsLoading] = useRecoilState(
    atm_accountDetailsLoading
  );
  data.accountDetailsLoading = accountDetailsLoading;

  const [accountSubTypes, setAccountSubTypes] =
    useRecoilState(atm_accountSubTypes);
  data.accountSubTypes = accountSubTypes;

  const setEditAccountModal = useSetRecoilState(atm_editAccountModal);

  const setAccounts = useSetRecoilState(atm_accounts)
  const setAccountList = useSetRecoilState(atm_accountsList)

  const setTotalAmount = useSetRecoilState(atm_accountsTotalAmount)

  const { callApi: callApiRemoveAccount, loading: isRemoveAccountLoading } = useApi();
  data.isRemoveAccountLoading = isRemoveAccountLoading;

  const isLoading = isRemoveAccountLoading || editAccountLoading || accountDetailsLoading;
  data.isLoading = isLoading;

  async function removeAccount(id, isManual) {
    let api =
      process.env.REACT_APP_ACCOUNT_API_END_POINT + `/accounts/provider/v1/${isManual ? 'removeManualAccount' : 'removeAccount'}`;
    
    let payload = {
      userItemAccountId: id,
    }

    await callApiRemoveAccount(api, payload)
    fnsAccountList.fns.getAccountsData();
  }

  async function getAccountSubTypes(id) {
    setAccountDetailsLoading(true);

    try {
      let api =
        process.env.REACT_APP_ACCOUNT_API_END_POINT +
        "/accounts/v1/getAccountSubTypes";

      const response = await axios.post(
        api,
        {
          accountTypeId: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + getAuthToken(),
          },
        }
      );

      let data = response.data.responseData;
      setAccountSubTypes(data);
    } catch (error) {
      console.error("An error occurred: " + error);
    } finally {
      setAccountDetailsLoading(false);
    }
  }

  async function saveEditAccountDetails(data) {
    const {
      isManual,
      accountBalance,
      accountType,
      accountId,
      overrideAccountSubTypeId,
      overrideAccountName,
      isAccountClosed,
      accountCloseDay,
      accountCloseMonth,
      accountCloseYear,
    } = data;
    setEditAccountLoading(true);

    try {
      let api =
        process.env.REACT_APP_ACCOUNT_API_END_POINT + `/accounts/v1/${isManual ? 'editManualAccountDetails' : 'editAccountDetails'}`;

      const response = await axios.post(
        api,
        {
          balanceAmount: isManual ? +accountBalance : undefined,
          overrideAccountTypeId: isManual ? accountType : undefined,
          accountId,
          overrideAccountSubTypeId,
          overrideAccountName,
          isAccountClosed: isAccountClosed || undefined,
          accountCloseDay: isAccountClosed ? accountCloseDay : undefined,
          accountCloseMonth: isAccountClosed ? accountCloseMonth : undefined,
          accountCloseYear: isAccountClosed ? accountCloseYear : undefined,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + getAuthToken(),
          },
        }
      );
      setEditAccountModal(false);
      let data = response.data.responseData;
      setTotalAmount(0);
      setAccounts(data);
      setAccountList(data.accountTypes);
    } catch (error) {
      console.error("An error occurred: " + error);
    } finally {
      setEditAccountLoading(false);
    }
  }

  const fns = {
    removeAccount,
    getAccountSubTypes,
    saveEditAccountDetails,
  };

  return { data, fns };
}

import CalenderIcon from "../../../../assets/images/calender.svg";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  atm_editGoal,
  atm_financialData,
  atm_selectedGeneralData,
} from "src/state/atomDashboard";

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const SelectMonth = ({ option }) => {
  const [selectedGeneralData, setSelectedGeneralData] = useRecoilState(
    atm_selectedGeneralData
  );
  const isEditMode = useRecoilValue(atm_editGoal);

  const setFinancialData = useSetRecoilState(atm_financialData);

  return (
    <FormControl
      className="selectMonth select"
      variant="standard"
      sx={{ width: 100, textAlign: "center" }}
    >
      <InputLabel
        htmlFor="selectMonth"
        sx={{
          fontSize: 12,
          textAlign: "center !important",
          left: 20,
          top: -12,
        }}
      >
        Month
      </InputLabel>
      <Select
        disabled={isEditMode}
        label="Month"
        id="selectMonth"
        value={selectedGeneralData.month}
        onChange={(e) => {
          setFinancialData((prevData) =>
            prevData.map((data) => ({
              ...data,
              endMonth: e.target.value,
            }))
          );

          setSelectedGeneralData({
            ...selectedGeneralData,
            [option]: e.target.value,
          });
        }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton edge="end">
              <img src={CalenderIcon} alt="" />
            </IconButton>
          </InputAdornment>
        }
        inputProps={{ IconComponent: () => null }}
      >
        {monthNames.map((month, index) => (
          <MenuItem key={index} value={index + 1}>
            {month}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectMonth;

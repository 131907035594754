import { atom, selector } from "recoil";

export const atm_transactionsList = atom({
  key: "transactions_list",
  default: [],
});

export const atm_isTransactionListLoading = atom({
  key: "isTransactionListLoading",
  default: false,
});

export const atm_transactionsChartStats = atom({
  key: "transactionChartStats",
  default: [],
});

export const atm_transactionsChartStatsLoading = atom({
  key: "transactionChartStatsLoading",
  default: false,
});

export const atm_transactionsParentReviews = atom({
  key: "transactionsReviewData",
  default: [],
});

export const atm_transactionChildReviews = atom({
  key: "transactionChildReviews",
  default: [],
});

export const atm_transactionInnerChildReviews = atom({
  key: "transactionInnerChildReviews",
  default: [],
});

export const atm_transactionInnerChildL4Reviews = atom({
  key: "transactionInnerChildL4Reviews",
  default: [],
});

export const atm_transactionInnerChildL5Reviews = atom({
  key: "transactionInnerChildL5Reviews",
  default: [],
});

export const atm_merchantState = atom({
  key: "merchantState",
  default: [],
});

export const atm_IsMobTransactionFilter = atom({
  key: "isMobTransactionFilter",
  default: false,
});

export const atm_showChildReplyIndex = atom({
  key: "showChildReplyIndex",
  default: null,
});

export const atm_showInnerChildReplyIndex = atom({
  key: "showInnerChildReplyIndex",
  default: null,
});

export const atm_showInnerChildL4ReplyIndex = atom({
  key: "showInnerChildL4ReplyIndex",
  default: null,
});

export const atm_showInnerChildL5ReplyIndex = atom({
  key: "showInnerChildL5ReplyIndex",
  default: null,
});

export const atm_isChildShowReplyClicked = atom({
  key: "isChildShowReplyClicked",
  default: false,
});

export const atm_isInnerChildShowReplyClicked = atom({
  key: "isInnerChildShowReplyClicked",
  default: false,
});

export const atm_onlyChildViewAllClicked = atom({
  key: "onlyChildViewAllClicked",
  default: false,
});

export const atm_onlyInnerChildViewAllClicked = atom({
  key: "onlyInnerChildViewAllClicked",
  default: false,
});

export const atm_SelectedTransDateFilter = atom({
  key: "selectedTransDateFilter",
  default: 12, // YTD
});

export const atm_isReviewTransBoxOpen = atom({
  key: "isReviewTransBoxOpen",
  default: false,
});

export const atm_reviewClickedRowData = atom({
  key: "reviewClickedRowData",
  default: {},
});

export const atm_parentReviewTextValue = atom({
  key: "parentReviewTextValue",
  default: "",
});

export const atm_childReviewTextValue = atom({
  key: "childReviewTextValue",
  default: "",
});

export const atm_innerChildReviewTextValue = atom({
  key: "innerChildReviewTextValue",
  default: "",
});

export const atm_transReviewParentId = atom({
  key: "transReviewParentId",
  default: null,
});

export const atm_addReviewLoading = atom({
  key: "addReviewLoading",
  default: false,
});

export const atm_reviewsLoading = atom({
  key: "reviewsLoading",
  default: {
    parentReview: false,
    childReview: false,
    innerChildReview: false,
    innerChildLevel4: false,
    innerChildLevel5: false,
  }
})

export const atm_allTransactionDataCount = atom({
  key: "allTransactionDataCount",
  default: 0,
});

export const atm_pageOptions = atom({
  key: "pageOptions",
  default: {
    pageNumber: 0,
    pageSize: 25,
  },
});

export const atm_categoryUpdatingLoading = atom({
  key: "categoryUpdatingLoading",
  default: false,
});

export const atm_totalTransData = atom({
  key: "totalTransData",
  default: [],
});

export const atm_fetchMorePage = atom({
  key: "fetchMorePage",
  default: 1,
});

export const atm_transactionCategoryID = atom({
  key: "transactionCategoryID",
  default: null,
});

export const atm_isManageCategoryBox = atom({
  key: "isManageCategoryBox",
  default: false,
});

export const atm_isMoreDataLoaderActive = atom({
  key: "isMoreDataLoaderActive",
  default: false,
});

export const atm_isBulkEditMode = atom({
  key: "isBulkEditMode",
  default: false,
});

export const atm_selectedDataForBulkEdit = atom({
  key: "selectedDataForBulkEdit",
  default: null,
});

export const atm_isEditSubCatModalOpen = atom({
  key: "isEditSubCatModalOpen",
  default: "",
});

export const atm_transactionTags = atom({
  key: "transactionTags",
  default: [],
});

export const atm_selectedSubCatForEdit = atom({
  key: "selectedSubCatForEdit",
  default: null,
});

export const atm_isManageTagsModal = 
  atom({
    key: "isManageTagsModal",
    default: false
  })

export const atm_isAddManualAccountModal = atom({
  key: "isAddManualAccountModal",
  default: false
})

export const atm_isManageRulesModal = atom({
  key: "isManageRulesModal",
  default: false
})

// convert transaction list into table format
export const atm_transactionsListTable = selector({
  key: "transactionsListTable",
  get: ({ get }) => {
    var transactionListTable = [];
    get(atm_transactionsList).forEach((transaction, index) => {
      transactionListTable.push({
        index: index,
        id: transaction.transactionId,
        localProviderId: transaction.localProviderId,
        hqProviderId: transaction.hqProviderId,
        date: transaction.txnDateFormatted,
        fi: transaction.accountFI,
        AccountNameNumber: transaction.accountName, // + " - ** " + transaction.maskedAccountNumber,
        merchantName: transaction.merchantName,
        category: transaction.transactionCategory,
        subCategory: transaction.transactionSubCategory,
        userRating: transaction.userRating,
        noOfReviews: transaction.noOfReviews,
        amount: transaction.transactionAmount,
        pending: transaction.pending,
        merchantLogo: transaction.merchantLogoUrl,
        categoryLogo: transaction.pfcCategoryLogoUrl,
        tags: transaction.tagsList,
      });
    });
    return transactionListTable;
  },
});

import { AddCircleRounded } from "@mui/icons-material";
import React from "react";
import KmnTextfield from "src/components/shared/KmnTextfield";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

const MerchantRuleFields = ({
  merchantRuleFields,
  addMerchantField,
  removeMerchantField,
  index,
}) => {
  const showAddIcon =
    merchantRuleFields?.length - 1 === index && merchantRuleFields?.length < 4;
  const showRemoveIcon = merchantRuleFields?.length > 1;

  const conditionData = [
    {
      label: "Contains",
      value: "contains",
    },
  ];

  const merchantData = [
    {
      label: "Merchant 1",
      value: "merchant 1",
    },
    {
      label: "Merchant 2",
      value: "merchant 2",
    },
    {
      label: "Merchant 3",
      value: "merchant 3",
    },
  ];
  return (
    <div className="ruleFields">
      <KmnTextfield select label="Attribute" options={merchantData} />
      <div className="fieldCover">
        <KmnTextfield
          select
          fullWidth
          label="condition"
          options={conditionData}
        />
        {showRemoveIcon && (
          <RemoveCircleIcon
            className="removeIcon"
            onClick={() => removeMerchantField(index)}
          />
        )}
      </div>
      <KmnTextfield label="Value/Keyword" />
      <div className="fieldActionBtns">
        {showAddIcon && (
          <AddCircleRounded className="addIcon" onClick={addMerchantField} />
        )}
      </div>
    </div>
  );
};

export default MerchantRuleFields;

import { useEffect, useState } from "react";
import { usePlaidLink } from "react-plaid-link";
import KmnButton from "./Transactions/KmnButton";

const PlaidLinkButton = (props) => {
  const { children, className = "", getToken, onSuccess, ...otherProps } = props;
  const [plaidToken, setPlaidToken] = useState("");

  const { open, ready } = usePlaidLink({
    onSuccess,
    onExit: (err, metadata) => {
      setPlaidToken("");
      console.log("on exit " + err, metadata);
    },
    token: plaidToken,
  });
  
  const getPlaidToken = async () => {
    let token = await getToken();
    setPlaidToken(token);
  };

  useEffect(() => {
    if (ready) {
      open();
      let iframe = document.getElementsByTagName("iframe")[0];
      if(iframe?.hasAttribute("data-src")){
        console.log("fixing plaid");
        let src = iframe.getAttribute("data-src");
        iframe.setAttribute("src",src);
        iframe.removeAttribute("data-src");
      }
    }
  }, [open,ready,plaidToken]);

  return <KmnButton className={className} onClick={getPlaidToken} {...otherProps}>{children}</KmnButton>

};
export default PlaidLinkButton;
import React, { useEffect, useState } from "react";
import TrendsChart from "./TrendsChart";
import { LinearProgress } from "@mui/material";

const TrendsChartContainer = ({ data }) => {
  const [hasData, setHasData] = useState(false);

  useEffect(() => {
    const validDataExists = data.trendsData?.trendDetailCategories?.some((d) =>
      d?.categoryValues?.some(
        (value) =>
          value?.maxTxnAmount ||
          value?.avgTxnAmount ||
          value?.minTxnAmount ||
          value?.maxPercentage ||
          value?.avgPercentage ||
          value?.minPercentage
      )
    );
    setHasData(validDataExists);
  }, [data]);

  return (
    <div className="graph-container">
      {data.trendsLoading && (
        <LinearProgress
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
          }}
        />
      )}
      <div className="graph-wrapper">
        {((data.trendsData && !data.trendsData?.trendDetailCategories?.length) || !hasData) && !data.trendsLoading ? (
          <h4 className="no-data">No data found for the given input</h4>
        ) : (
          <>
            {data.trendsData?.trendDetailCategories?.map(
              (d,idx) =>
                d?.categoryValues?.length > 0 && (
                  <TrendsChart
                    key={idx}
                    categoryName={d?.detailCategoryName}
                    data={d?.categoryValues}
                    type={data.trendsData?.trendType}
                  />
                )
            )}
          </>
        )}
      </div>
      <p className="trends-disclaimer">
        *Trends presented are based on the aggregated information from data
        collected across a section of resident users. This may or may not,
        represent the trend across entire population within the location
      </p>
    </div>
  );
};

export default TrendsChartContainer;

import {
  FormControl,
  InputLabel,
  Input,
  Tooltip,
} from "@mui/material";
import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { yearFrom, yearTo } from "src/global/central";
import { atm_editGoal, atm_selectedGeneralData } from "src/state/atomDashboard";
import { validateYear } from "src/validations/Goals";

const SelectYear = ({ option }) => {
  const [selectedGeneralData, setSelectedGeneralData] = useRecoilState(
    atm_selectedGeneralData
  );
  const isEditMode = useRecoilValue(atm_editGoal);

  return (
    <FormControl
      className="selectYear select"
      variant="standard"
      sx={{ width: 100, textAlign: "center" }}
    >
      <InputLabel
        htmlFor="selectYear"
        sx={{
          fontSize: 12,
          textAlign: "center",
          left: 35,
          top: -12,
        }}
      >
        Year
      </InputLabel>
      <Tooltip
        open={isEditMode ? false : undefined}
        sx={{
          "& .MuiTooltip-tooltip": {
            display: "none",
          },
        }}
        title={`Enter value in range b/w ${yearFrom} - ${yearTo}`}
      >
        <Input
          disabled={isEditMode}
          id="selectYear"
          defaultValue={selectedGeneralData.year}
          onChange={(e) => {
            validateYear(e);
            setSelectedGeneralData({
              ...selectedGeneralData,
              [option]: +e.target.value,
            });
          }}
          type="tel"
          label="Year"
          inputProps={{ maxLength: 4 }}
        />
      </Tooltip>
    </FormControl>
  );
};

export default SelectYear;

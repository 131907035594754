import React from "react";
import { Link } from "react-router-dom";
import pic from "../assets/images/main-pic.svg";
import picmobile from "../assets/images/main-pic.svg";
import useMainMenu from "../MainMenu/useMainMenu";
import { useMediaQuery } from "@mui/material";
import TrustBadges from "./TrustBadges";

const Main = () => {
  const { fns } = useMainMenu();
  const isDesktop = useMediaQuery("(min-width: 1000px)");
  return (
    <>
      <div className="main-home">
        <div className="blue-circle"></div>
        <div className="yellow-circle"></div>

        <div className="circle"></div>
        <div className="triangle"></div>
        <div className="left-area">
          <h1>
            <span>Reimagine</span> how you <br /> make money <br /> decisions
          </h1>
          <div className="graph-cover">
            <img
              src={picmobile}
              className="mobile-pic"
              alt="Pie charts, line graphs, band graphs, representing individual and community-wide spending, investment behaviors and financial trends"
            />
          </div>
          <p>
            A platform that brings like-minded people together, to learn from
            one &nbsp;
            <br />
            another, and enhance their financial fulfillment
          </p>
          {isDesktop && (
            <div className="buttons-wrapper">
              <Link onClick={fns.onMenuClickSignUp} to="/signup">
                Sign Up
              </Link>
              <Link onClick={fns.onMenuClickLogin} to="/login">
                Login
              </Link>
            </div>
          )}
        </div>
        <img
          src={pic}
          alt="Pie charts, line graphs, band graphs, representing individual and community-wide spending, investment behaviors and financial trends"
          className="main-pic"
        />
      </div>
      <TrustBadges />
    </>
  );
};

export default Main;

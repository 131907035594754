import React, { useEffect, useState } from "react";
import MyGoals from "./MyGoals/MyGoals";
import "../assets/css/stats.css";
import NewGoalModal from "./MyGoals/NewGoalsModal/NewGoalModal";
import { usdFormate } from "src/global/central";
import { useRecoilValue } from "recoil";
import { atm_firstName } from "src/state/atoms";
import useDashboard from "./useDashboard";
import DashboardCharts from "./DashboardCharts";
import { Box, LinearProgress } from "@mui/material";
import MetaTags from "../shared/MetaTags";

const Stats = () => {
  const firstName = useRecoilValue(atm_firstName);
  const { dataDashboard, fnsDashboard } = useDashboard();
  const [welcomeBox, setWelcomeBox] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setWelcomeBox(false);
    }, 5000);
  });

  useEffect(() => {
    fnsDashboard.getIncomeExpense();
  }, []);

  return (
    <div className="statsPage dashboardPage">
      <MetaTags index="Dashboard" />
      <img
        src="/static/media/bg-transaction.9ee82bb60f3ef91391a4.png"
        alt=""
        className="statsbg"
      ></img>
      {welcomeBox && firstName && (
        <div className="welcome-box">
          <p className="head">
            Welcome to your Kamunity, <span>{firstName}</span>
          </p>
          <p className="subHead">
            Check out how you’re doing so far on your finances{" "}
          </p>
        </div>
      )}
      <div className="income-expense-row">
        <div className="income-box box">
          {dataDashboard.goalsLoading && (
            <Box sx={{ width: "100%" }}>
              <LinearProgress
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                }}
              />
            </Box>
          )}
          <span className="head">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13"
              height="10"
              viewBox="0 0 13 10"
              fill="none"
            >
              <path
                d="M6.5 0.771032L13 9.47852H0L6.5 0.771032Z"
                fill="#4ABE78"
              />
            </svg>
            INCOME
          </span>
          <div className="label">Month to Date (MTD)</div>
          <div className="value">
            {usdFormate(dataDashboard.incomeExpense?.currMonthIncome)}
          </div>
          <div className="label">Last Month</div>
          <div className="value">
            {usdFormate(dataDashboard.incomeExpense?.lastMonthIncome)}
          </div>
          <div className="label">Last 12 months</div>
          <div className="value">
            {usdFormate(dataDashboard.incomeExpense?.totalIncome)}
          </div>
          <div className="label">Avg per month/12 months</div>
          <div className="value">
            {usdFormate(dataDashboard.incomeExpense?.avgIncome)}
          </div>
          
        </div>
        <div className="expense-box box">
          {dataDashboard.goalsLoading && (
            <Box sx={{ width: "100%" }}>
              <LinearProgress
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                }}
              />
            </Box>
          )}
          <span className="head">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13"
              height="10"
              viewBox="0 0 13 10"
              fill="none"
            >
              <path
                d="M6.5 9.48092L0 0.773438H13L6.5 9.48092Z"
                fill="#FD4C5C"
              />
            </svg>
            EXPENSE
          </span>
          <div className="label">Month to Date (MTD)</div>
          <div className="value">
            {usdFormate(dataDashboard.incomeExpense?.currMonthExpense)}
          </div>
          <div className="label">Last Month</div>
          <div className="value">
            {usdFormate(dataDashboard.incomeExpense?.lastMonthExpense)}
          </div>
          <div className="label">Last 12 months</div>
          <div className="value">
            {usdFormate(dataDashboard.incomeExpense?.totalExpense)}
          </div>
          <div className="label">Avg per month/12 months</div>
          <div className="value">
            {usdFormate(dataDashboard.incomeExpense?.avgExpense)}
          </div>
          
        </div>
      </div>
      <DashboardCharts />
      <MyGoals />
      <p className="footerNote">* These graphs are currently in Beta, and released to get initial user feedback. Please reach us at  ContactUs@Kamunity.io with any questions, suggestions or feedback</p>
      <NewGoalModal />
    </div>
  );
};

export default Stats;

import { useState } from "react";
import {
  Box,
  Drawer,
  Button,
  List,
  Divider,
  ListItem,
  ListItemButton,
} from "@mui/material";

import burger from "../assets/images/burger.svg";
import useMainMenu from "./useMainMenu";
import { useRecoilValue } from "recoil";
import { atm_mainMenuButtonPressed } from "src/state/atoms";
import MainDropdown from "./MainDropdown";
import InsightsDropdown from "./InsightsDropdown";
import SearchIcon from "../assets/images/search.svg";
import BellIcon from "../assets/images/bell.svg";
import ProfileDrawer from "./ProfileDrawer";

export default function MobileMainMenu() {
  const { fns } = useMainMenu();
  const mainMenuButtonPressed = useRecoilValue(atm_mainMenuButtonPressed);

  const [state, setState] = useState(false);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: 250,
        color: "black",
      }}
      role="presentation"
    // onKeyDown={toggleDrawer("left", false)}
    >
      <List>
        <ListItem
          disablePadding
          sx={{ backgroundColor: "#F9F9F9", width: 250 }}
        >
          <ProfileDrawer />
        </ListItem>
        <Divider />
        <ListItem disablePadding>
          <MainDropdown />
        </ListItem>
        <Divider />

        <ListItem onClick={fns.onMenuClickDashboard}>
          <ListItemButton
            sx={{
              borderLeft: mainMenuButtonPressed == "Dashboard" ? 5 : 0,
              borderLeftColor: "#A4379E",
              borderRadius: "5px",
            }}
          >
            <strong style={{ fontFamily: "Nunito" }}>Dashboard</strong>
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem onClick={fns.onMenuClickTransactions}>
          <ListItemButton
            sx={{
              borderLeft:
                mainMenuButtonPressed == "Transactions" ||
                  mainMenuButtonPressed == "Holdings"
                  ? 5
                  : 0,
              borderLeftColor: "#A4379E",
              borderRadius: "5px",
            }}
          >
            <strong style={{ fontFamily: "Nunito" }}>Accounts</strong>
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem disablePadding>
          <InsightsDropdown />
        </ListItem>
        <Divider />
        <ListItem onClick={fns.onMenuClickCalculators}>
          <ListItemButton
            sx={{
              borderLeft:
                mainMenuButtonPressed == "Calculators"
                  ? 5
                  : 0,
              borderLeftColor: "#A4379E",
              borderRadius: "5px",
            }}
          >
            <strong style={{ fontFamily: "Nunito" }}>Calculators</strong>
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem onClick={fns.onMenuClickTrends}>
          <ListItemButton
            sx={{
              borderLeft:
                mainMenuButtonPressed == "Trends"
                  ? 5
                  : 0,
              borderLeftColor: "#A4379E",
              borderRadius: "5px",
            }}
          >
            <strong style={{ fontFamily: "Nunito" }}>Trends</strong>
          </ListItemButton>
        </ListItem>
        <Divider />
      </List>
    </Box>
  );

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <strong style={{ fontSize: "var(--fontSize20)", marginLeft: "15px" }}>
        {mainMenuButtonPressed == "Transactions" ||
          mainMenuButtonPressed == "Holdings"
          ? "Accounts"
          : mainMenuButtonPressed}
      </strong>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "auto",
        }}
      >
        {/* <img
          src={SearchIcon}
          style={{ marginRight: "12px" }}
          alt=""
          height={"20px"}
          width={"20px"}
        />
        <img
          src={BellIcon}
          style={{ marginRight: "12px" }}
          alt=""
          height={"22px"}
          width={"22px"}
        /> */}
        <Box sx={{ display: "flex" }}>
          <img
            className="burger"
            alt="hamburger"
            src={burger}
            onClick={toggleDrawer("left", true)}
            style={{ cursor: "pointer" }}
          />

          <Drawer
            anchor={"left"}
            open={state["left"]}
            onClose={toggleDrawer("left", false)}
          >
            {list("left")}

            <Button
              onClick={fns.onMenuClickLogOut}
              sx={{
                textAlign: "center",
                margin: "0px 15px 15px 15px",
                fontWeight: "bold",
                color: "var(--color1)",
              }}
            >
              Logout
            </Button>
          </Drawer>
        </Box>
      </Box>
    </Box>
  );
}

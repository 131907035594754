import React, { useEffect, useState } from "react";
import "../assets/css/stats.css";
import { useRecoilState } from "recoil";
import { atm_globalFilterValue } from "../../state/atoms";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import { getAuthToken, truncateString } from "src/global/central";
import axios from "axios";
import LineChartFilled from "../shared/Charts/LineChartFilled";
import LineChartOutlined from "../shared/Charts/LineChartOutlined";
import useDashboard from "./useDashboard";

function DashboardCharts() {
  const { dataDashboard } = useDashboard();
  const jwt = getAuthToken();
  const [categoryChartData, setCategoryChartData] = useState([]);
  const [merchantChartData, setMerchantChartData] = useState([]);
  const [globalFilterValue] = useRecoilState(atm_globalFilterValue);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const selectedCategoryFilter =
    globalFilterValue.categoryFilter.selectedCategoryFilter;
  const excludedCategoryFilter =
    globalFilterValue.categoryFilter.excludedCategoryFilter;

  useEffect(() => {
    getCategoryListData();
    getMerchantListData();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function getCategoryListData() {
    const endPoint =
      process.env.REACT_APP_ACCOUNT_API_END_POINT +
      "/accounts/dashboard/v1/getSpendByCategory";
    let payloads = {
      dateFilter: 12,
      // subCategoryIds: selectedCategoryFilter,
      // excludeSubCategoryIds: excludedCategoryFilter,
      categoryCount: 10,
    };
    axios
      .post(endPoint, payloads, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + jwt,
        },
      })
      .then((res) => {
        let data = res.data.responseData;
        setCategoryChartData(data);
      })
      .catch((err) => {
        console.log("inside catch block with error : " + err);
      });
  }

  function getMerchantListData() {
    const endPoint = "/accounts/insights/transactions/v2/getSpendByMerchant";
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      },
      body: JSON.stringify({
        dateFilter: 12,
        subCategoryIds: selectedCategoryFilter,
        excludeSubCategoryIds: excludedCategoryFilter,
        merchantCount: 10,
      }),
    };

    fetch(
      process.env.REACT_APP_ACCOUNT_API_END_POINT + endPoint,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          if (response.status === 401) {
            console.log("Unable to get Spending details");
          }
          throw response.text();
        }
        return response.json();
      })
      .then((responseData) => {
        setMerchantChartData(responseData.responseData);
      })
      .catch((err) => {
        console.log("inside catch block with error : " + err);
      });
  }

  const categoryLabels = categoryChartData.map((item) => item.categoryName);
  const categorySeries = categoryChartData.map((item) =>
    Math.round(item.spendPercentage)
  );
  const merchantLabels = merchantChartData.map((item) => item.merchantName);
  const merchantSeries = merchantChartData.map((item) =>
    Math.round(item.spendPercentage)
  );
  const formattedCategoryData = categoryLabels.map((label, index) => ({
    id: index,
    value: categorySeries[index],
    label: (location) =>
      location === "tooltip"
        ? label
        : truncateString(label,30),
  }));
  const formattedMerchantData = merchantLabels.map((label, index) => ({
    id: index,
    value: merchantSeries[index],
    label: (location) =>
      location === "tooltip"
        ? label
        : truncateString(label,30),
  }));

  return (
    <div className="chart-expense-row">
      <div className="box">
        <LineChartFilled data={dataDashboard.netWorthChartData} loader={dataDashboard.netWorthChartLoading} />
      </div>
      <div className="box">
        <LineChartOutlined data={dataDashboard.expenseComparisonTrendData} loader={dataDashboard.expenseComparisonTrendLoading} />
      </div>
      <div className="income-box box">
        <span className="chart-head">
          Total Expenses By Category [ Top 10 ]
        </span>
        <span className="chart-sub-head">Last 12 Months</span>
        {categoryChartData.length === 0 ? (
          <p></p>
        ) : (
          <>
            {!isMobile ? (
              <PieChart
                series={[
                  {
                    data: formattedCategoryData,
                    valueFormatter: (v) => `${v?.value}%`,
                    arcLabel: (item) => ` ${item.value}%`,
                    arcLabelMinAngle: 10,
                    innerRadius: 50,
                    outerRadius: 140,
                    paddingAngle: 0,
                    position: "bottom",
                    cornerRadius: 4,
                    // startAngle: -90,
                    endAngle: 360,
                    cx: "30%",
                    cy: "50%",
                  },
                ]}
                sx={{
                  [`& .${pieArcLabelClasses.root}`]: {
                    fill: "white",
                    fontWeight: "bold",
                    fontSize: "13px",
                  },
                }}
                width={650}
                height={350}
              />
            ) : (
              <PieChart
                margin={{ bottom: 250, top: -10 }}
                series={[
                  {
                    data: formattedCategoryData,
                    valueFormatter: (v) => `${v?.value}%`,
                    innerRadius: 50,
                    outerRadius: 130,
                    paddingAngle: 0,
                    arcLabel: (item) => ` ${item.value}%`,
                    arcLabelMinAngle: 10,
                    cornerRadius: 4,
                    // startAngle: -90,
                    endAngle: 360,
                    cx: "72%",
                    cy: "60%",
                  },
                ]}
                sx={{
                  [`& .${pieArcLabelClasses.root}`]: {
                    fill: "white",
                    fontWeight: "bold",
                    fontSize: "12px",
                    // marginBottom: "300px",
                  },
                }}
                slotProps={{
                  legend: {
                    direction: "row",
                    position: { vertical: "bottom", horizontal: "middle" },
                    padding: 3,
                    itemGap: 10,
                    labelStyle: {
                      fontSize: "14px",
                    },
                  },
                }}
                width={325}
                height={500}
              />
            )}
          </>
        )}
      </div>
      <div className="income-box box">
        <span className="chart-head">
          Total Expenses By Merchant [ Top 10 ]
        </span>
        <span className="chart-sub-head">Last 12 Months</span>
        {categoryChartData.length === 0 ? (
          <p></p>
        ) : (
          <>
            {!isMobile ? (
              <PieChart
                series={[
                  {
                    data: formattedMerchantData,
                    valueFormatter: (v) => `${v?.value}%`,
                    arcLabel: (item) => ` ${item.value}%`,
                    arcLabelMinAngle: 10,
                    innerRadius: 50,
                    outerRadius: 140,
                    paddingAngle: 0,
                    position: "bottom",
                    cornerRadius: 4,
                    endAngle: 360,
                    cx: "30%",
                    cy: "50%",
                  },
                ]}
                sx={{
                  [`& .${pieArcLabelClasses.root}`]: {
                    fill: "white",
                    fontWeight: "bold",
                    fontSize: "13px",
                  },
                }}
                width={650}
                height={350}
              />
            ) : (
              <PieChart
                margin={{ bottom: 250, top: -10 }}
                series={[
                  {
                    data: formattedMerchantData,
                    innerRadius: 50,
                    outerRadius: 130,
                    paddingAngle: 0,
                    arcLabel: (item) => ` ${item.value}%`,
                    valueFormatter: (v) => `${v?.value}%`,
                    arcLabelMinAngle: 10,
                    cornerRadius: 4,
                    // startAngle: -90,
                    endAngle: 360,
                    cx: "80%",
                    cy: "60%",
                  },
                ]}
                sx={{
                  [`& .${pieArcLabelClasses.root}`]: {
                    fill: "white",
                    fontWeight: "bold",
                    fontSize: "12px",
                    marginBottom: "300px",
                  },
                }}
                slotProps={{
                  legend: {
                    direction: "row",
                    position: { vertical: "bottom", horizontal: "middle" },
                    padding: 3,
                    labelStyle: {
                      fontSize: "14px",
                    },
                  },
                }}
                width={325}
                height={500}
              />
            )}{" "}
          </>
        )}
      </div>
    </div>
  );
}

export default DashboardCharts;

import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { getAuthToken } from "src/global/central";
import {
  atm_codeSentSubHeading,
  atm_forgotPasswordErrors,
  atm_isCodeSent,
  atm_passwordLoading,
  atm_responseMessage,
} from "src/state/atomForgotPassword";

export default function useForgotPassword() {
  let dataPassword = {};

  const navigate = useNavigate();
  const [passwordLoading, setPasswordLoading] =
    useRecoilState(atm_passwordLoading);
  dataPassword.passwordLoading = passwordLoading;

  const [errors, setErrors] = useRecoilState(atm_forgotPasswordErrors);
  dataPassword.errors = errors;

  const [responseMessage, setResponseMessage] =
    useRecoilState(atm_responseMessage);
  dataPassword.responseMessage = responseMessage;

  const setIsSetCodeSent = useSetRecoilState(atm_isCodeSent);

  function sendResetPasswordMail(email) {
    setPasswordLoading(true);

    let URL =
      process.env.REACT_APP_ACCOUNT_API_END_POINT +
      "/users/v1/sendResetPasswordEmail";
    axios
      .post(
        URL,
        { email: email },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + getAuthToken(),
          },
        }
      )
      .then(() => {
        setIsSetCodeSent(true);
      })
      .catch((err) => {
        console.log("got an error: ", err);
      })
      .finally(() => {
        setPasswordLoading(false);
      });
  }

  function validateEmailCodeForPasswordReset(data) {
    setPasswordLoading(true);

    let URL =
      process.env.REACT_APP_ACCOUNT_API_END_POINT +
      "/users/v1/validateEmailCodeForPasswordReset";
    axios
      .post(
        URL,
        { email: data.email, code: data.code },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + getAuthToken(),
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          let message = res.data.responseMessage;
          setResponseMessage((prevMessages) => ({
            ...prevMessages,
            codeValidation: message,
          }));
          navigate("/login/resetpassword");
        } else {
          let error = res?.data?.errors[0]?.errorMessage;
          setErrors((prevErrors) => ({
            ...prevErrors,
            codeError: error,
          }));
        }
      })
      .catch((err) => {
        console.log("got an error: ", err);
      })
      .finally(() => {
        setPasswordLoading(false);
      });
  }

  function resetPassword(data) {
    setPasswordLoading(true);

    let URL =
      process.env.REACT_APP_ACCOUNT_API_END_POINT + "/users/v1/resetPassword";
    axios
      .post(
        URL,
        {
          email: data.email,
          code: data.code,
          newPassword: data.password,
          newConfirmPassword: data.cPassword,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + getAuthToken(),
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          let message = res.data.responseMessage;
          setResponseMessage((prevMessages) => ({
            ...prevMessages,
            resetPassword: message,
          }));
          navigate("/login");
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            resetPasswordError: !res.data.success,
          }));
        }
      })
      .catch((err) => {
        console.log("got an error: ", err);
      })
      .finally(() => {
        setPasswordLoading(false);
      });
  }

  let fnsPassword = {
    sendResetPasswordMail,
    validateEmailCodeForPasswordReset,
    resetPassword,
  };

  return { dataPassword, fnsPassword };
}

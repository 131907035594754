  import React, { useEffect, useState } from "react";
  import ReactApexChart from "react-apexcharts";
  import { GetWindowWidth, getMaxInterval, usdFormate } from "src/global/central";
  import useSpendByMerchant from "./useSpendByMerchant";

  const CategoryChart = () => {
    const { data } = useSpendByMerchant();
    const [chartData, setChartData] = useState([]);

    const width = GetWindowWidth();

    useEffect(() => {
      if (data.categoryStats) {
        const updatedChartData = data.categoryStats.map((stats) => ({
          x: stats.monthYear,
          y: stats.spend,
        }));
        setChartData(updatedChartData);
      }
    }, [data.categoryStats]);
    const max = Math.max(...chartData.map((dataPoint) => dataPoint.y));
    const yMax = getMaxInterval(max);
    const hasNegativeValues = chartData.some(dataPoint => dataPoint.y < 0);

    const chartOptions = {
      chart: {
        height: 500,
        type: "bar",
        fontFamily: "Nunito",
        toolbar: {
          show: false,
        },
        dropShadow: {
          enabled: false,
        },
        events: {
          dataPointMouseEnter: function (event, chartContext, config) {
            if (
              width < 900
            ) {
              let labelUp = document.querySelectorAll(".apexcharts-data-labels")[
                config.dataPointIndex
              ];
  
              let labelDown = document
                .querySelectorAll(".apexcharts-data-labels")[
                config.dataPointIndex
              ];
  
              if (labelUp) {
                labelUp.style.opacity = 1;
              }
              if (labelDown) {
                labelDown.style.opacity = 1;
              }
            }
          },
          dataPointMouseLeave: function (event, chartContext, config) {
            if (width < 900) {
              let labelUp = document
                .querySelectorAll(".apexcharts-data-labels")[
                config.dataPointIndex
              ];
              let labelDown = document
                .querySelectorAll(".apexcharts-data-labels")[
                config.dataPointIndex
              ];
              
              if (labelUp) {
                labelUp.style.opacity = 0;
              }
              if (labelDown) {
                labelDown.style.opacity = 0;
              }
            }
          },
        },
      },
      plotOptions: {
        bar: {
          colors: {
            ranges: [
              {
                from: -yMax,
                to: 0,
                color: "var(--color1)"
              }
            ]
          },
          borderRadius: 2,
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          if (val === 0) {
            return;
          } else if (val > 0 && val < 100) {
            return "$ " + val;
          } else if (val > 100) {
            return "$ " + (val / 1000).toFixed(1) + "k";
          } else if (val < 0 && val > -100) {
            return "-$ " + Math.abs(val);
          } else if (val < -100) {
            return "-$ " + Math.abs(val / 1000).toFixed(1) + "k";
          } else return usdFormate(val);
        },
        offsetY: -25,
        style: {
          fontSize: "var(--fontSize12)",
          colors: ["#000"],
        },
      },
      xaxis: {
        categories: chartData.map((dataPoint) => dataPoint.x),
        position: "bottom",
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#D8E3F0",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
          style: {
            fontSize: "var(--fontSize18)",
          },
        },
        tooltip: {
          enabled: false,
        },
        axisBorder: {
          show: true,
          color: "#000",
          height: 1,
          width: "120%",
          offsetX: 0,
          offsetY: 0,
        },
        axisTicks: {
          show: true,
          borderType: "solid",
          color: "#000",
          height: 12,
          offsetX: 1,
          offsetY: 0,
        },
        labels: {
          rotate: width < 3000 && data.categoryStats.length > 10 ? -45 : 0,
          rotateAlways:
            width < 3000 && data.categoryStats.length > 10 ? true : false,
          hideOverlappingLabels: false,
          offsetX: 0,
          style: {
            fontSize:
              width < 1300 &&
              width >= 650 &&
              data.categoryStats.length < 15 &&
              data.categoryStats.length > 5
                ? 12
                : width < 650 &&
                  data.categoryStats.length < 15 &&
                  data.categoryStats.length > 5
                ? 8
                : width < 1700 && width >= 900 && data.categoryStats.length > 15
                ? 10
                : width < 900 && data.categoryStats.length > 15
                ? 6
                : 16,
            fontWeight: 700,
          },
        },
      },
      yaxis: {
        max: yMax,
        min: hasNegativeValues ? -yMax : undefined,
        tickAmount:
          (max > -100 && max < 100) || max > 1000 || max < -1000 ? 5 : 2,
        axisBorder: {
          show: true,
          color: "#000",
          offsetX: -3,
        },
        offsetY: 10,
        axisTicks: {
          show: true,
          borderType: "solid",
          color: "#000",
          width: 15,
          offsetX: 13,
          offsetY: -3.5,
        },
        labels: {
          show: true,
          style: {
            fontSize: "var(--fontSize14)",
            fontWeight: 700,
          },
          offsetY: 5,
          offsetX: 5,
          formatter: function (val) {
            if (max > -100 && max  < 100 ) {
              if (val === 0) {
                return;
              } else if (val > 0) {
                return "$ " + val;
              } else if (val < 0) {
                return "-$ " + Math.abs(val);
              } 
              else return usdFormate(val);
            } 
            else {
              if (val === 0) {
                return;
              } else if (val > 0 && val <= 100) {
                return "$ " + (val / 1000).toFixed(1) + "k";
              } else if (val > 100) {
                return "$ " + (val / 1000).toFixed(1) + "k";
              } else if (val < 0 && val >= -100) {
                return "-$ " + Math.abs(val / 1000).toFixed(1) + "k";
              } else if (val < -100) {
                return "-$ " + Math.abs(val / 1000).toFixed(1) + "k";
              } 
              else return usdFormate(val);
            }
          },
        },
      },
      annotations: {
        yaxis: [
          {
            y: 0,
            strokeDashArray: 0,
            borderColor: "var(--colorBlack)",
            label: {
              borderColor: "var(--colorBlack)",
              style: {
                color: "var(--colorWhite)",
                background: "var(--colorBlack)",
              },
              text: "0",
              position: "left",
              offsetX: -12,
              offsetY: 7,
            },
          },
        ],
      },
      fill: {
        colors: ["var(--color14)"],
      },
      grid: {
        show: true,
        padding: {
          top: 40,
          bottom: -10,
          right:
            data.categoryStats.pop && width >= 550
              ? 30
              : data.categoryStats.pop && width < 550
              ? 20
              : 0,
        },
      },
    };

    return (
      <div className="chart-container category-chart">
        <span>Month over month trend for my spend in the merchant</span>
        <ReactApexChart
          options={chartOptions}
          series={[
            {
              name: "Expense",
              data: chartData.map((dataPoint) => dataPoint.y),
            },
          ]}
          type="bar"
          height={340}
        />
      </div>
    );
  };

  export default CategoryChart;

import React, { useState } from "react";
import PencilOnEdit from "../assets/images/pencil1.svg";
import PencilOnNoEdit from "../assets/images/pencil2.svg";
import useManegeCategories from "./useManageCategories";

const SubCategoryRow = ({ data }) => {
  const { fns } = useManegeCategories();

  const [isSubCatEdit, setIsSubCatEdit] = useState(false);
  const [subCatText, setSubCatText] = useState(`${data.name}`);
  return (
    <li className={isSubCatEdit ? "subCatEditOn" : ""}>
      <input
        readOnly={!isSubCatEdit}
        onChange={(e) => setSubCatText(e.target.value)}
        type="text"
        value={subCatText}
      />
      <button
        style={{ position: "relative", zIndex: 20 , left: 300 }}
        onClick={() => fns.updateCategory()}
      >
        Change
      </button>
      <img
        onClick={() => setIsSubCatEdit(!isSubCatEdit)}
        className="pencil"
        src={isSubCatEdit ? PencilOnEdit : PencilOnNoEdit}
        alt=""
      />
    </li>
  );
};

export default SubCategoryRow;

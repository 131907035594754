/**********************************************************************
 *
 *   Component hook generated by Quest
 *
 *   Code Logic for the component goes in this hook
 *   To setup bindings that use the data here or call the functions here, use the Quest editor
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/
import { useState } from "react";
import { useRecoilState } from "recoil";
import {
  atm_jwt,
  atm_firstName,
  atm_lastName,
  atm_isUserLoggedIn,
  atm_mainMenuButtonPressed,
  atm_dataProviderId,
} from "../../state/atoms";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { setAuthTokens } from "src/global/central";

import { getAuthToken, removeAuthTokens } from "src/global/central";

const  useLogin = () => {
  let navigate = useNavigate();

  const [jwt, setJWT] = useRecoilState(atm_jwt);
  const [firstName, setFirstName] = useRecoilState(atm_firstName);
  const [lastName, setLastName] = useRecoilState(atm_lastName);

  const [dataProviderId, setDataProviderId] = useRecoilState(atm_dataProviderId);


  const [isUserLoggedIn, setIsUserLoggedIn] =
    useRecoilState(atm_isUserLoggedIn);
  const [mainMenuButtonPressed, setMainMenuButtonPressed] = useRecoilState(
    atm_mainMenuButtonPressed
  );

  let [isErrorMessage, setIsErrorMessage] = useState("");

  let [email, setEmail] = useState("");
  let [emailError, setEmailError] = useState(null);
  let [emailHelperText, setEmailHelperText] = useState(null);

  let [password, setPassword] = useState("");
  let [passwordError, setPasswordError] = useState(null);
  let [passwordHelperText, setPasswordHelperText] = useState(null);
  
  let [userLockError, setUserLockError] = useState(null);
  let [userLockErrorHelperText, setUserLockErrorHelperText] = useState(null);

  let [emailValidationRequired, setEmailValidationRequired] = useState(false);
  let [emailValidationCode, setEmailValidationCode] = useState("");
  let [alertType, setAlertType] = useState("");
  let [emailValidationErrorMessage, setEmailValidationErrorMessage] = useState(null);


  let data = {};

  data.isErrorMessage = isErrorMessage;

  data.email = email;
  data.emailError = emailError;
  data.emailHelperText = emailHelperText;

  data.password = password;
  data.passwordError = passwordError;
  data.passwordHelperText = passwordHelperText;

  data.userLockError = userLockError;
  data.userLockErrorHelperText = userLockErrorHelperText;

  data.emailValidationRequired = emailValidationRequired;
  data.emailValidationCode = emailValidationCode;
  data.emailValidationErrorMessage = emailValidationErrorMessage;

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
    setEmailError(null);
  };

  const onChangePassword = (e) => {
    setPassword(e.target.value);
    setPasswordError(null);
  };

  const clearErrorMessage = (e) => {
    setUserLockError(null);
    setUserLockErrorHelperText(null);
  };

  const onClickLogin = (e) => {
    setIsErrorMessage(null);

    let URL = process.env.REACT_APP_USER_API_END_POINT + "/users/v1/login";

    axios
      .post(
        URL,
        {
          email: email,
          password: password,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then((res) => {
        if (res["data"]["success"] == false) {
          console.log("error from server " + res["data"]["responseMessage"]);
          processLoginUserResponse(res["data"]);
          throw res["data"]["responseMessage"];
        } else {
          window.sessionStorage.setItem(
            "session-token",
            res["data"]["responseData"]["token"]
          );
          processLoginUserResponse(res["data"]);
        }
      })
      .catch((err) => {
        if (err.response.status == "401") {
          setIsErrorMessage(
            "Username/password combination does not match our records. Please verify the details before submitting again"
          );
        }
        console.log("inside catch block with error : " + err);
      });

  };

  function processLoginUserResponse(response) {
    if (!response.success) {
      var errorMessage = response.responseMessage;
      for (var i = 0; i < response.errors.length; i++) {
        var fieldWithError = response.errors[i].field;
        var fieldMessage = response.errors[i].errorMessage;

        switch (fieldWithError) {
          case "email":
            setEmailError(true);
            setEmailHelperText(fieldMessage);
            break;
          case "password":
            setPasswordError(true);
            setPasswordHelperText(fieldMessage);
            break;
          case "userLock":
            setUserLockError(true);
            setUserLockErrorHelperText(fieldMessage);
            break;
          default:
            break;
        }
      }
      setIsErrorMessage(errorMessage);
      setIsUserLoggedIn(false);
    } else {
      setJWT(response.responseData.token);
      setFirstName(response.responseData.firstName);
      setLastName(response.responseData.lastName);
      setIsUserLoggedIn(true);
      setDataProviderId(response.responseData.dataProviderId);
      localStorage.setItem("dataProvider", response.responseData.dataProviderId)
      setEmailValidationRequired(!response.responseData.emailValidated);
      setAuthTokens(response.responseData.token,response.responseData.accessTokenExpiryTime,response.responseData.refreshToken,response.responseData.refreshTokenExpiryTime);

      if(response.responseData.emailValidated)
      {
        setMainMenuButtonPressed("Dashboard");
        navigate("/dashboard");
      }
      

    }
  }

  const onChangeEmailValidationCode = (e) => {
    setEmailValidationCode(e.target.value);
  };

  function validateEmailCode()
  {

    let URL = process.env.REACT_APP_USER_API_END_POINT + "/users/v1/validateEmailCode";

    axios
      .post(
        URL,
        {
          code: data.emailValidationCode,
        },
        {
          headers: {
						'Content-Type': 'application/json',
						Authorization: 'Bearer ' + getAuthToken()
					}
        }
      )
      .then((res) => {
        console.log(res);
        if (res["data"]["success"] === false) {

          setEmailValidationErrorMessage(res["data"]["errors"][0]['errorMessage']);

        } else {
          setMainMenuButtonPressed("Dashboard");
          navigate("/dashboard");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function cancelEmailCodeValidationDialog()
  {
    // if the user does not submit the email code, we cannot have them continue.
      removeAuthTokens();
      setEmailValidationRequired(false);
      setIsErrorMessage("Email validation error. You'll be prompted to enter the code at login");
      setAlertType("error");
  }

  

  let fns = {
    onClickLogin,
    onChangeEmail,
    onChangePassword,
    clearErrorMessage,
    validateEmailCode,
    onChangeEmailValidationCode,
    cancelEmailCodeValidationDialog
  };
  return { data, fns };
};

export default useLogin;

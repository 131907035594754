import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { atm_GlobalLoading, atm_globalFilterValue } from "../../../state/atoms";

import {
  atm_SelectedTransDateFilter,
  atm_isReviewTransBoxOpen,
  atm_reviewClickedRowData,
  atm_transactionChildReviews,
  atm_transactionsChartStats,
  atm_transactionsList,
  atm_transactionsParentReviews,
  atm_parentReviewTextValue,
  atm_addReviewLoading,
  atm_childReviewTextValue,
  atm_transactionInnerChildReviews,
  atm_innerChildReviewTextValue,
  atm_transactionInnerChildL4Reviews,
  atm_transactionInnerChildL5Reviews,
  atm_allTransactionDataCount,
  atm_pageOptions,
  atm_isTransactionListLoading,
  atm_totalTransData,
  atm_transactionsChartStatsLoading,
  atm_transactionCategoryID,
  atm_isMoreDataLoaderActive,
  atm_reviewsLoading,
  atm_merchantState,
  atm_isBulkEditMode,
  atm_isEditSubCatModalOpen,
  atm_transactionTags,
} from "../../../state/atomsTransactionData";

import { atm_dataProviderId } from "../../../state/atoms";

import { useEffect, useRef, useState } from "react";
import FilterLogo from "../../assets/images/filterLogo.svg";
import ReviewLogo from "../../assets/images/reviewLogo.svg";
import BulkEditLogo from "../../assets/images/bulk-edit.svg";
import ArrowDownLogo from "../../assets/images/arrow-down2.svg";
import ReviewTrueLogo from "../../assets/images/reviewTrueLogo.svg";
import DisabledReviewLogo from "../../assets/images/disabled-review.svg";
import Rating from "@mui/material/Rating";
import axios from "axios";
import CategoryPopUp from "../../ManageCategories/CategoryPopUp";
import { compareIds, dateFormateShort, getHeaders, usdFormate } from "src/global/central";
import useCategoryFilter from "src/components/shared/CategoryFilter/useCategoryFilter";
import { atm_selectedAccountIds } from "src/state/atomsAccountList";

import { getAuthToken } from "src/global/central";
import { SUBCATEGORY_MODE } from "src/global/constants";
import Tags from "./Tags";

const useTransactionsLanding = () => {
  let data = {};
  
  const dataProviderId = useRecoilValue(atm_dataProviderId);

  const categoryFilterFns = useCategoryFilter();
  const {fns: fnsCategoryFilter} = useCategoryFilter();

  const [transactionList, setTransactionsList] =
    useRecoilState(atm_transactionsList);
  data.transactionList = transactionList;

  let [isTransactionListLoading, setIsTransactionListLoading] = useRecoilState(
    atm_isTransactionListLoading
  );
  data.isTransactionListLoading = isTransactionListLoading;

  let [MerchantState, setMerchantState] = useRecoilState(atm_merchantState);
  data.MerchantState = MerchantState;

  let [categoryFromApiState, setcategoryFromApiState] = useState([]);
  data.categoryFromApiState = categoryFromApiState;

  const [transactionChartStats, setTransactionChartStats] = useRecoilState(
    atm_transactionsChartStats
  );
  data.transactionChartStats = transactionChartStats;

  const [transactionChartStatsLoading, setTransactionChartStatsLoading] =
    useRecoilState(atm_transactionsChartStatsLoading);
  data.transactionChartStatsLoading = transactionChartStatsLoading;

  const [parentReviewsData, setParentReviewsData] = useRecoilState(
    atm_transactionsParentReviews
  );
  data.parentReviewsData = parentReviewsData;

  // child
  const [transactionChildReviews, setTransactionChildReviews] = useRecoilState(
    atm_transactionChildReviews
  );
  data.transactionChildReviews = transactionChildReviews;

  // inner child
  const [transactionInnerChildReviews, setTransactionInnerChildReviews] =
    useRecoilState(atm_transactionInnerChildReviews);
  data.transactionInnerChildReviews = transactionInnerChildReviews;

  // inner child Level 4
  const [transactionInnerChildL4Reviews, setTransactionInnerChildL4Reviews] =
    useRecoilState(atm_transactionInnerChildL4Reviews);
  data.transactionInnerChildL4Reviews = transactionInnerChildL4Reviews;

  // inner child Level 5
  const [transactionInnerChildL5Reviews, setTransactionInnerChildL5Reviews] =
    useRecoilState(atm_transactionInnerChildL5Reviews);
  data.transactionInnerChildL5Reviews = transactionInnerChildL5Reviews;

  const [isReviewTransBoxOpen, setIsReviewTransBoxOpen] = useRecoilState(
    atm_isReviewTransBoxOpen
  );
  data.isReviewTransBoxOpen = isReviewTransBoxOpen;

  const [reviewClickedRowData, setReviewClickedRowData] = useRecoilState(
    atm_reviewClickedRowData
  );
  data.reviewClickedRowData = reviewClickedRowData;

  const [allTransactionDataCount, setAllTransactionDataCount] = useRecoilState(
    atm_allTransactionDataCount
  );
  data.allTransactionDataCount = allTransactionDataCount;

  const [globalLoading, setGlobalLoading] = useRecoilState(atm_GlobalLoading);
  data.globalLoading = globalLoading;

  const [parentTextValue, setParentTextValue] = useRecoilState(
    atm_parentReviewTextValue
  );

  const [childTextValue, setChildTextValue] = useRecoilState(
    atm_childReviewTextValue
  );

  const [innerChildTextValue, setInnerChildTextValue] = useRecoilState(
    atm_innerChildReviewTextValue
  );

  const [addReviewLoading, setAddReviewLoading] =
    useRecoilState(atm_addReviewLoading);
  data.addReviewLoading = addReviewLoading;

  const [reviewsLoading, setReviewsLoading] =
    useRecoilState(atm_reviewsLoading);
  data.reviewsLoading = reviewsLoading;

  const [selectedTransFilterState, setSelectedTransFilterState] =
    useRecoilState(atm_SelectedTransDateFilter);
  data.selectedTransFilterState = selectedTransFilterState;

  const [totalTransData, setTotalTransData] =
    useRecoilState(atm_totalTransData);
  data.totalTransData = totalTransData;

  const [transactionCategoryID, setTransactionCategoryID] = useRecoilState(
    atm_transactionCategoryID
  );

  const [globalFilterValue, setGlobalFilterValue] = useRecoilState(
    atm_globalFilterValue
  );
  data.globalFilterValue = globalFilterValue;

  const selectedAccountIds = useRecoilValue(atm_selectedAccountIds);
  data.selectedAccountIds = selectedAccountIds;

  const setIsMoreDataLoaderActive = useSetRecoilState(
    atm_isMoreDataLoaderActive
  );

  const categoryElem = useRef(null);

  // const [transactionCategoryAnchor, setTransactionCategoryAnchor] = useRecoilState(atm_transactionCategoryAnchor);
  const [transactionCategoryAnchor, setTransactionCategoryAnchor] = useState(
    categoryElem.current
  );

  const [isBulkEditMode, setIsBulkEditMode] = useRecoilState(atm_isBulkEditMode);

  useEffect(() => {
    if (categoryElem.current && transactionCategoryAnchor == null) {
      setTransactionCategoryAnchor(categoryElem.current);
    }
  }, []);

  const pageOptions = useRecoilValue(atm_pageOptions);

  const [loading, setLoading] = useState(false);
  data.loading = loading;

  const [editCategoryModal, setEditCategoryModal] = useRecoilState(atm_isEditSubCatModalOpen)

  const [transactionTags, setTransactionTags] = useRecoilState(atm_transactionTags);
  data.transactionTags = transactionTags;

  const [error, setError] = useState("")
  data.error = error;

  // let selectedDateFilter = data.selectedTransFilterState;
  let selectedDateFilter = globalFilterValue.dateFilter;
  let selectedCategoryFilter =
    globalFilterValue.categoryFilter?.selectedCategoryFilter;
  let excludedCategoryFilter =
    globalFilterValue.categoryFilter?.excludedCategoryFilter;
  let includedAccountIds = selectedAccountIds.includedIds;
  let excludedAccountIds = selectedAccountIds.excludedIds;
  let includeMerchantIds = globalFilterValue.merchantFilter.includeMerchantIds;
  let excludeMerchantIds = globalFilterValue.merchantFilter.excludeMerchantIds;
  let includedTagIds = globalFilterValue.tagFilter.includedTagIds;
  let excludedTagIds = globalFilterValue.tagFilter.excludedTagIds;

  const onTransactionsLandingPageLoad = (e) => {
    // console.log("inside new onTransactionsLandingPageLoad ");
    setTransactionsList([]);
    refreshTransactionDataFromProvider();
    getMerchantListing();
  };

  const clmnTransactions = [
    {
      field: "bulkEdit",
      maxWidth: 30,
      minWidth: 30,
      sortable: false,
      disableColumnMenu: true,
      headerClassName: "bulkEditCell",
      cellClassName: "bulkEditCell",
      headerAlign: "center",
      renderHeader: () => (
        <div className="custom-header">
          {!isBulkEditMode && <img
            style={{cursor:"pointer"}}
            onClick={() => setIsBulkEditMode(true)}
            src={BulkEditLogo}
            alt=""
          />}
        </div>
      ),
    },
    {
      cellClassName: "date-cell",
      field: "date",
      minWidth: 100,
      flex: 1,
      renderHeader: () => (
        <div className="custom-header">
          <span className="">Date</span>
          <img src={FilterLogo} alt="" />
        </div>
      ),
      valueGetter: (value) => {
        const transactionDate = new Date(value);
        return dateFormateShort(transactionDate);
      },
      renderCell: (cellValues) => {
        return <div className="date-cell">{cellValues.value}</div>;
      },
    },
    {
      field: "fi",
      minWidth: 160,
      flex: 1,
      renderHeader: () => (
        <div className="custom-header">
          <span className="">Custodian</span>
          <img src={FilterLogo} alt="" />
        </div>
      ),
      renderCell: (cellValues) => {
        return (
          <div className="custodian-cell">
            <div className="custodian-name">{cellValues.row.fi}</div>
            <div className="account-number">
              {cellValues.row.AccountNameNumber}
            </div>
          </div>
        );
      },
    },
    {
      field: "userRating",
      minWidth: 170,
      flex: 1,
      renderCell: (cellValues) => {
        const reviewCount = cellValues.row.noOfReviews;
        return (
          <div className="rating-review-cell">
            <Rating
              readOnly={cellValues.row.pending}
              name="simple-controlled"
              value={cellValues.value}
              onChange={(event, newValue) => {
                !isBulkEditMode && handleUserRatingClick(event, cellValues, newValue);
              }}
            />

            <div
              onClick={() => {
                if (cellValues.row.pending) {
                  return;
                }
                !isBulkEditMode && handleUserReviewClick(cellValues.row);
              }}
              className={`review-cell ${reviewCount > 0 ? "review-true" : ""}`}
            >
              <img
                src={
                  cellValues.row.pending
                    ? DisabledReviewLogo
                    : reviewCount > 0
                    ? ReviewTrueLogo
                    : ReviewLogo
                }
                alt=""
              />
              <span className="review-count">
                Reviews {reviewCount > 0 && <span>{`(${reviewCount})`}</span>}
              </span>
            </div>
          </div>
        );
      },
      renderHeader: () => (
        <div className="custom-header">
          <span className="">Rating</span>
          <img src={FilterLogo} alt="" />
        </div>
      ),
    },
    {
      field: "merchantName",
      minWidth: 160,
      flex: 1,
      renderHeader: () => (
        <div className="custom-header">
          <span className="">Merchant</span>
          <img src={FilterLogo} alt="" />
        </div>
      ),
      renderCell: (cellValues) => {
        return (
          <div className="merchant-cell">
            {cellValues?.row?.merchantLogo && (
              <img
                className="merchantLogo"
                srcSet={cellValues.row.merchantLogo}
                alt="logo"
              />
            )}
            {cellValues.row.merchantName}
          </div>
        );
      },
    },
    {
      field: "category",
      minWidth: 200,
      flex: 1,
      renderHeader: () => (
        <div className="custom-header">
          <span className="">Category</span>
          <img src={FilterLogo} alt="" />
        </div>
      ),
      renderCell: (cellValues) => {
        return (
          <>
            <div
              className="category-cell"
              onClick={(e) => {
                if (cellValues.row.pending || isBulkEditMode) {
                  return;
                }
                setTransactionCategoryID(cellValues.row.id);
                setTransactionCategoryAnchor(e.currentTarget);
              }}
            >
              {cellValues?.row?.categoryLogo && (
                <img
                  className="categoryLogo"
                  srcSet={cellValues.row.categoryLogo}
                  alt="logo"
                />
              )}
              <div>
                <div className="category">
                  {cellValues.row.category} <img src={ArrowDownLogo} alt="" />
                </div>
                <div className="subCategory" ref={categoryElem}>{cellValues.row.subCategory}</div>
              </div>
            </div>

            {cellValues.row.id == transactionCategoryID && (
              <CategoryPopUp
                id={cellValues.row.id}
                anchorValue={transactionCategoryAnchor}
              />
            )}
          </>
        );
      },
    },
    {
      field: "amount",
      cellClassName: "amount-cell",
      minWidth: 100,

      renderHeader: () => (
        <div className="custom-header">
          <span className="">Amount</span>
          <img src={FilterLogo} alt="" />
        </div>
      ),
      renderCell: (cellValues) => {
        const amountColor = cellValues.value < 0 ? "#000" : "var(--color11)";
        return (
          <div className="amount-cell" style={{ color: amountColor }}>
            {usdFormate(cellValues.value)}
          </div>
        );
      },
    },
    {
      field: "tags",
      cellClassName: "tags-cell",
      minWidth: 220,
      renderHeader: () => (
        <div className="custom-header" style={{paddingLeft: 12}}>
          <span className="">Tags</span>
        </div>
      ),
      renderCell: (cellValues) => {
        return (
          <Tags isPending={cellValues.row.pending} transactionId={cellValues.row.id} associatedTags={cellValues.row.tags} />
        );
      },
    },
  ].slice(+isBulkEditMode);

  data.clmnTransactions = clmnTransactions;

  function getMerchantListing(dateFilter) {
    let url =
      process.env.REACT_APP_ACCOUNT_API_END_POINT +
      "/accounts/transactions/filters/v1/getProvidersForUser";
    axios
      .post(
        url,
        {
          noOfMonths: dateFilter || selectedDateFilter,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + getAuthToken(),
          },
        }
      )
      .then((res) => {
        setMerchantState(res["data"]["responseData"]);
      })
      .catch((res) => {
        console.log(res);
      });
  }

  // ******************************************************************************
  // TRANSACTION DATA Starts
  // ******************************************************************************

  function refreshTransactionDataFromProvider() {
    setIsTransactionListLoading(true);
    setGlobalLoading(true);

    let endPoint = "";

    if (dataProviderId === 1)
      // Yodlee
      endPoint = "/accounts/provider/v2/updateTransactionsFromProvider";
    else if (dataProviderId === 2)
      // Plaid
      endPoint =
        "/accounts/transactions/v1/updateTransactionalDataFromProvider";

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getAuthToken(),
      },
    };

    fetch(
      process.env.REACT_APP_ACCOUNT_API_END_POINT + endPoint,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          if (response.status === "401") {
            console.log("Unable to refresh transactions details");
          }
          throw response.text();
        }
        return response.json();
      })
      .then((responseData) => processTransactionsDataRefresh(responseData))
      .catch((err) => {
        console.log("inside catch block with error : " + err);
      });
  }

  function processTransactionsDataRefresh(response, pageSize, pageNumber) {
    if (!response.success) {
      var errorMessage = response.responseMessage;
      console.log("Received error message : " + errorMessage);
    }

    refreshTransactionDataElements(pageSize, pageNumber);
  }

  function refreshTransactionDataElements(pageSize, pageNumber) {
    setIsTransactionListLoading(true);
    setGlobalLoading(true);

    if (pageSize && pageNumber) {
      getTransactionsData(pageSize, pageNumber);
    } else {
      getTransactionsData(0, 25);
    }
    getTransactionChart();
    getAllTransactionsDataCount();
  }

  // get All Transactions Data

  function getTransactionsData(
    pageNumber,
    pageSize,
    transactionId,
    isRatingChange,
    fetchMore,
    merchantId
  ) {
    setIsTransactionListLoading(true);
    setGlobalLoading(true);
    // if (dataProviderId === 1) // Yodlee
    //   endPoint = "/accounts/transactions/v2/getAllTransactions";
    // else if (dataProviderId === 2) // Plaid
    let endPoint = "/accounts/transactions/v2/getAllTransactions";

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getAuthToken(),
      },
      body: JSON.stringify({
        noOfMonths: selectedDateFilter,
        custodianIds: [], // Leave empty if ALL is selected.
        accountIds: merchantId ? undefined : includedAccountIds,
        excludeAccountIds: merchantId ? undefined : excludedAccountIds,
        merchantIds: merchantId ? [+merchantId] : includeMerchantIds,
        excludeMerchantIds: merchantId ? undefined : excludeMerchantIds,
        subCategoryIds: merchantId ? undefined : selectedCategoryFilter,
        excludeSubCategoryIds: merchantId ? undefined : excludedCategoryFilter,
        amountGreaterThanOrEqualTo: -100.23,
        amountLessThanOrEqualTo: 5000.45,
        currentRecordNumber: 0,
        pageNumberToFetch: (pageNumber+1),
        pageSize: pageSize,
        tagIds: merchantId ? undefined : includedTagIds,
        excludeTagIds: merchantId ? undefined : excludedTagIds
      }),
    };

    let progressSpan = document.querySelector(
      ".linking-account-progress .progress-bar span"
    );

    if (progressSpan) {
      progressSpan.style.width = `120px`;
    }

    fetch(
      process.env.REACT_APP_ACCOUNT_API_END_POINT + endPoint,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          if (response.status === "401") {
            // setIsErrorMessage("Unable to get account details");
            console.log("Unable to get transactions details");
          }
          throw response.text();
        }

        return response.json();
      })
      .then((response) => {
        processTransactionsDataResponse(
          response,
          transactionId,
          isRatingChange,
          fetchMore,
          merchantId
        );

        if (progressSpan) {
          progressSpan.style.width = `100%`;
        }
      })
      .catch((err) => {
        console.log("inside catch block with error : " + err);
      });
  }

  function processTransactionsDataResponse(
    response,
    transactionId,
    isRatingChange,
    fetchMore
  ) {
    var transactionsList = [];
    var hasMoreData = false;

    if (!response.success) {
      var errorMessage = response.responseMessage;
      console.log("Received error message : " + errorMessage);
    } else {
      transactionsList = response.responseData.transactions;
      setIsMoreDataLoaderActive(false);

      if (transactionId && isRatingChange) {
        let newRatings = transactionsList.find(
          (item) => item.transactionId === transactionId
        ).userRating;

        setTotalTransData((prevData) => {
          return prevData.map((item) => {
            if (item.transactionId === transactionId) {
              return { ...item, userRating: newRatings };
            }
            return item;
          });
        });
      } else if (transactionId && !isRatingChange) {
        let newNoOfReviews = transactionsList.find(
          (item) => item.transactionId === transactionId
        ).noOfReviews;
        setTotalTransData((prevData) => {
          return prevData.map((item) => {
            if (item.transactionId === transactionId) {
              return { ...item, noOfReviews: newNoOfReviews };
            }
            return item;
          });
        });
      } else if (fetchMore) {
        setTotalTransData([...totalTransData, ...transactionsList]);
      } else {
        setTotalTransData(transactionsList);
      }
      hasMoreData = response.responseData.hasMore;
    }

    setTransactionsList(transactionsList);

    if (hasMoreData) {
      // console.log("has more");
      setTimeout(() => {
        refreshTransactionDataElements();
      }, 5000);
    } else {
      setIsTransactionListLoading(false);
      setGlobalLoading(false);
    }
  }

  // get total Count of All Transactions

  function getAllTransactionsDataCount(merchantId) {
    setGlobalLoading(true);

    let endPoint = "";

    //if (dataProviderId === 1) // Yodlee
    endPoint = "/accounts/transactions/v2/getAllTransactionsCount";
    //else if (dataProviderId === 2) // Plaid
    //  endPoint = "/accounts/transactions/v1/getAllTransactionsCount";

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getAuthToken(),
      },
      body: JSON.stringify({
        noOfMonths: selectedDateFilter,
        custodianIds: [], // Leave empty if ALL is selected.
        accountIds: merchantId ? undefined : includedAccountIds,
        excludeAccountIds: merchantId ? undefined : excludedAccountIds,
        merchantIds: merchantId ? [+merchantId] : includeMerchantIds,
        excludeMerchantIds: merchantId ? undefined : excludeMerchantIds,
        subCategoryIds: merchantId ? undefined : selectedCategoryFilter,
        excludeSubCategoryIds: merchantId ? undefined : excludedCategoryFilter,
        amountGreaterThanOrEqualTo: -100.23,
        amountLessThanOrEqualTo: 5000.45,
        currentRecordNumber: 0,
        tagIds: merchantId ? undefined : includedTagIds,
        excludeTagIds: merchantId ? undefined : excludedTagIds
      }),
    };

    fetch(
      process.env.REACT_APP_ACCOUNT_API_END_POINT + endPoint,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          if (response.status === "401") {
            // setIsErrorMessage("Unable to get account details");
            console.log("Unable to get transactions details");
          }
          throw response.text();
        }

        return response.json();
      })
      .then((responseData) => {
        setAllTransactionDataCount(responseData.responseData.transactionCount);
        setGlobalLoading(false);
      })
      .catch((err) => {
        console.log("inside catch block with error : " + err);
      });
  }

  const handleUserRatingClick = (event, cellValues, newValue) => {
    updateUserRating(cellValues.row.id, newValue, cellValues.row.index);
  };

  function updateUserRating(transactionId, userRating) {
    setGlobalLoading(true);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getAuthToken(),
      },
      body: JSON.stringify({
        userRating: userRating,
        transactionId: transactionId,
      }),
    };

    fetch(
      process.env.REACT_APP_ACCOUNT_API_END_POINT +
        "/accounts/transactions/v1/updateUserRating",
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          if (response.status === "401") {
            console.log("Unable to refresh account balance details");
          }
          throw response.text();
        }
        return response.json();
      })
      .then((responseData) => processUserRating(responseData, transactionId))
      .catch((err) => {
        console.log("inside catch block with error : " + err);
      });
  }

  function processUserRating(response, transactionId) {
    if (!response.success) {
      var errorMessage = response.responseMessage;
      console.log("Received error message : " + errorMessage);
    }
    // TO-DO : This is cheesy way to update the grid. We need to find a better way to
    // refresh the cell value
    else {
      getTransactionsData(
        pageOptions.pageNumber,
        pageOptions.pageSize,
        transactionId,
        1
      );
      setGlobalLoading(false);
    }
  }

  //  Transacation Chart Fetching ->

  function getTransactionChart() {
    setTransactionChartStatsLoading(true);
    setGlobalLoading(true);

    let endPoint = "";

    endPoint = "/accounts/transactions/trends/mom/v1/incomeexpense";

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getAuthToken(),
      },
      body: JSON.stringify({
        accountIds: includedAccountIds,
        excludeAccountIds: excludedAccountIds,
        subCategoryIds: selectedCategoryFilter,
        excludeSubCategoryIds: excludedCategoryFilter,
        merchantIds: includeMerchantIds,
        excludeMerchantIds,
        noOfMonths: selectedDateFilter,
      }),
    };

    fetch(
      process.env.REACT_APP_ACCOUNT_API_END_POINT + endPoint,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          if (response.status === "401") {
            console.log("Unable to get Spemning details");
          }
          throw response.text();
        }
        return response.json();
      })
      .then((responseData) => {
        processTransactionChart(responseData);
      })
      .catch((err) => {
        console.log("inside catch block with error : " + err);
      });
  }

  function processTransactionChart(response) {
    if (response.success !== true) {
      var errorMessage = response.responseMessage;
      alert("Received error message : " + errorMessage);
    } else {
      setTransactionChartStats(response.responseData);
      setTransactionChartStatsLoading(false);
      setGlobalLoading(false);
    }
  }

  //  Review Parent Data Fetching ->

  const handleUserReviewClick = (params) => {
    let merchantId = params.localProviderId;
    let transactionId = params.id || params.transactionId;
    let hqProviderId = params.hqProviderId;

    getParentReviews(hqProviderId, transactionId);
    setIsReviewTransBoxOpen(true);
    setReviewClickedRowData({ merchantId, transactionId, hqProviderId });
  };

  function getParentReviews(hqProviderId, transactionId) {
    setAddReviewLoading(true);
    setReviewsLoading({ ...reviewsLoading, parentReview: true });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getAuthToken(),
      },
      body: JSON.stringify({
        hqProviderId: hqProviderId,
        transactionId: transactionId,
      }),
    };

    fetch(
      process.env.REACT_APP_ACCOUNT_API_END_POINT +
        "/accounts/reviews/transaction/v1/getParentReviews",
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          if (response.status === "401") {
            console.log("Unable to get Spemning details");
          }
          throw response.text();
        }
        return response.json();
      })
      .then((responseData) => {
        processParentReviews(responseData);
      })
      .catch((err) => {
        setAddReviewLoading(false);
        setReviewsLoading({ ...reviewsLoading, parentReview: false });
        console.log("inside catch block with error : " + err);
      });
  }

  function processParentReviews(response) {
    if (response.success !== true) {
      var errorMessage = response.responseMessage;
      alert("Received error message : " + errorMessage);
    } else {
      setParentReviewsData(response.responseData);
      setAddReviewLoading(false);
      setReviewsLoading({ ...reviewsLoading, parentReview: false });
      setParentTextValue("");
    }
  }

  // Add Child Review

  function addParentReview(params, message) {
    setAddReviewLoading(true);
    setReviewsLoading({ ...reviewsLoading, parentReview: true });

    const { merchantId, transactionId, hqProviderId } = params;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getAuthToken(),
      },
      body: JSON.stringify({
        review: message,
        transactionId: transactionId,
        hqProviderId: hqProviderId,
        localProviderId: merchantId,
      }),
    };

    fetch(
      process.env.REACT_APP_ACCOUNT_API_END_POINT +
        "/accounts/reviews/v1/addParentReview",
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          if (response.status === "401") {
            console.log("Unable to get Spemning details");
          }
          throw response.text();
        }
        return response.json();
      })
      .then((responseData) => {
        processAddParentReview(responseData, hqProviderId, transactionId);
      })
      .catch((err) => {
        setAddReviewLoading(false);
        setReviewsLoading({ ...reviewsLoading, parentReview: false });
        console.log("inside catch block with error : " + err);
      });
  }

  function processAddParentReview(response, hqProviderId, transactionId) {
    if (response.success !== true) {
      var errorMessage = response.responseMessage;
      alert("Received error message : " + errorMessage);
    } else {
      getParentReviews(hqProviderId, transactionId);
      setAddReviewLoading(false);
      setReviewsLoading({ ...reviewsLoading, parentReview: false });
      setParentTextValue("");

      getTransactionsData(
        pageOptions.pageNumber,
        pageOptions.pageSize,
        transactionId
      );
    }
  }

  // All Child Reviews Fetching ->

  function getChildReviews(params, parentReviewId, level) {
    const { hqProviderId, transactionId } = params;
    setAddReviewLoading(true);
    setReviewsLoading({ ...reviewsLoading, childReview: true });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getAuthToken(),
      },
      body: JSON.stringify({
        hqProviderId: hqProviderId,
        parentReviewId: parentReviewId,
      }),
    };

    fetch(
      process.env.REACT_APP_ACCOUNT_API_END_POINT +
        "/accounts/reviews/v1/getChildReviews",
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          if (response.status === "401") {
            console.log("Unable to get Spemning details");
          }
          throw response.text();
        }
        return response.json();
      })
      .then((responseData) => {
        processChildReviews(responseData, hqProviderId, transactionId, level);
      })
      .catch((err) => {
        setAddReviewLoading(false);
        setReviewsLoading({ ...reviewsLoading, childReview: false });
        console.log("inside catch block with error : " + err);
      });
  }

  function processChildReviews(response, hqProviderId, transactionId, level) {
    if (response.success !== true) {
      var errorMessage = response.responseMessage;
      alert("Received error message : " + errorMessage);
    } else {
      if (level === 4) {
        setTransactionInnerChildReviews(response.responseData);
      } else if (level === 5) {
        setTransactionInnerChildL4Reviews(response.responseData);
      } else {
        setTransactionChildReviews(response.responseData);
        getParentReviews(hqProviderId, transactionId);
      }
      setAddReviewLoading(false);
      setReviewsLoading({ ...reviewsLoading, childReview: false });
    }
  }

  // Add Child Review

  function addChildReview(params, parentId, message) {
    setAddReviewLoading(true);
    setReviewsLoading({ ...reviewsLoading, childReview: true });

    const { merchantId, transactionId, hqProviderId } = params;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getAuthToken(),
      },
      body: JSON.stringify({
        transactionId: transactionId,
        hqProviderId: hqProviderId,
        localProviderId: merchantId,
        parentReviewId: parentId,
        review: message,
      }),
    };

    fetch(
      process.env.REACT_APP_ACCOUNT_API_END_POINT +
        "/accounts/reviews/v1/addChildReview",
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          if (response.status === "401") {
            console.log("Unable to get Spemning details");
          }
          throw response.text();
        }
        return response.json();
      })
      .then((responseData) => {
        processAddChildReview(responseData, params, parentId);
      })
      .catch((err) => {
        setAddReviewLoading(false);
        setReviewsLoading({ ...reviewsLoading, childReview: false });
        console.log("inside catch block with error : " + err);
      });
  }

  function processAddChildReview(response, params, parentId) {
    if (response.success !== true) {
      var errorMessage = response.responseMessage;
      alert("Received error message : " + errorMessage);
    } else {
      getChildReviews(params, parentId);
      setChildTextValue("");
      getTransactionsData(pageOptions.pageNumber, pageOptions.pageSize);
    }
    setAddReviewLoading(false);
    setReviewsLoading({ ...reviewsLoading, childReview: false });
  }

  // Inner Child Reviews Fetching ->

  function getInnerChildReviews(params, parentReviewId, innerParentId, level) {
    const { hqProviderId } = params;
    setAddReviewLoading(true);
    setReviewsLoading({ ...reviewsLoading, innerChildReview: true });

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getAuthToken(),
      },
      body: JSON.stringify({
        hqProviderId: hqProviderId,
        parentReviewId: parentReviewId,
      }),
    };

    fetch(
      process.env.REACT_APP_ACCOUNT_API_END_POINT +
        "/accounts/reviews/v1/getChildReviews",
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          if (response.status === "401") {
            console.log("Unable to get Spemning details");
          }
          throw response.text();
        }
        return response.json();
      })
      .then((responseData) => {
        processInnerChildReviews(responseData, params, innerParentId, level);
        return responseData;
      })
      .catch((err) => {
        setAddReviewLoading(false);
        setReviewsLoading({ ...reviewsLoading, innerChildReview: false });
        console.log("inside catch block with error : " + err);
      });
  }

  function processInnerChildReviews(response, params, innerParentId, level) {
    if (response.success !== true) {
      var errorMessage = response.responseMessage;
      alert("Received error message : " + errorMessage);
    } else {
      if (level === 4) {
        setTransactionInnerChildL4Reviews(response.responseData);
        getChildReviews(params, innerParentId, level);
      } else if (level === 5) {
        setTransactionInnerChildL5Reviews(response.responseData);
        getChildReviews(params, innerParentId, level);
      } else {
        setTransactionInnerChildReviews(response.responseData);
        getChildReviews(params, innerParentId);
      }
    }
    setAddReviewLoading(false);
    setReviewsLoading({ ...reviewsLoading, innerChildReview: false });
  }

  // Add Inner Child Review

  function addInnerChildReview(
    params,
    parentId,
    message,
    innerParentId,
    level
  ) {
    setAddReviewLoading(true);
    if (level === 4) {
      setReviewsLoading({ ...reviewsLoading, innerChildLevel4: true });
    } else if (level === 5) {
      setReviewsLoading({ ...reviewsLoading, innerChildLevel5: true });
    } else {
      setReviewsLoading({ ...reviewsLoading, innerChildReview: true });
    }

    const { merchantId, transactionId, hqProviderId } = params;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getAuthToken(),
      },
      body: JSON.stringify({
        transactionId: transactionId,
        hqProviderId: hqProviderId,
        localProviderId: merchantId,
        parentReviewId: parentId,
        review: message,
      }),
    };

    fetch(
      process.env.REACT_APP_ACCOUNT_API_END_POINT +
        "/accounts/reviews/v1/addChildReview",
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          if (response.status === "401") {
            console.log("Unable to get Spemning details");
          }
          throw response.text();
        }
        return response.json();
      })
      .then((responseData) => {
        processAddInnerChildReview(
          responseData,
          params,
          parentId,
          innerParentId,
          level
        );
      })
      .catch((err) => {
        setAddReviewLoading(false);
        setReviewsLoading({ ...reviewsLoading, innerChildReview: false });
        setReviewsLoading({ ...reviewsLoading, innerChildLevel4: false });
        setReviewsLoading({ ...reviewsLoading, innerChildLevel5: false });
        console.log("inside catch block with error : " + err);
      });
  }

  function processAddInnerChildReview(
    response,
    params,
    parentId,
    innerParentId,
    level
  ) {
    if (response.success !== true) {
      var errorMessage = response.responseMessage;
      alert("Received error message : " + errorMessage);
    } else {
      if (level) {
        getInnerChildReviews(params, parentId, innerParentId, level);
      } else {
        getInnerChildReviews(params, parentId, innerParentId);
        setInnerChildTextValue("");
        getTransactionsData(pageOptions.pageNumber, pageOptions.pageSize);
      }
    }
    setAddReviewLoading(false);
    setReviewsLoading({ ...reviewsLoading, innerChildReview: false });
    setReviewsLoading({ ...reviewsLoading, innerChildLevel4: false });
    setReviewsLoading({ ...reviewsLoading, innerChildLevel5: false });
  }

  function onCloseChildReview() {
    setTransactionChildReviews([]);
  }

  function onCloseInnerChildReview() {
    setTransactionInnerChildReviews([]);
  }

  async function modifyCustomCategory(args){
    setLoading(true);
    const { kmnId, name } = args;
    let api = process.env.REACT_APP_ACCOUNT_API_END_POINT + `/accounts/category/v1/${editCategoryModal === SUBCATEGORY_MODE.CREATE ? "addCustomCategory" : "editCustomCategory"}`

    let payload = {
      kmnCategoryMasterId: kmnId,
      newSubCategoryName: name
    }
    try {
      let res = await axios.post(api, payload, getHeaders())
      let isSuccess = res?.data?.success;
      if(!isSuccess && res?.data?.errors?.length){
        return res;
      }
      fnsCategoryFilter.getCategorySubCategoryFilterData();
      if(editCategoryModal === SUBCATEGORY_MODE.CONFIRM){
        getTransactionsData(pageOptions.pageNumber, pageOptions.pageSize);
      }
    }catch(err){
      console.log("error while modifying custom category: ", err);
    }finally{
      setLoading(false);
    }
    setEditCategoryModal("");
  }

  async function getTransactionTags(){
    let api = process.env.REACT_APP_ACCOUNT_API_END_POINT + "/accounts/tag/v1/getAllTagsForUser";
    try{
      let res = await axios.post(api, null, getHeaders());
      let data = res?.data?.responseData;
      setTransactionTags(data);

    } catch(err){
      console.log("error while fetching tags : ", err);
    }
  }

  async function editTransactionTag(tag){
    const {tagId, tagName} = tag;
    setLoading(true);
    let api = process.env.REACT_APP_ACCOUNT_API_END_POINT + "/accounts/tag/v1/editCustomTag";
    let payload = tag;
    try{
      let res = await axios.post(api, payload, getHeaders());
      let data = res?.data?.responseData;
      console.log(data);
      let isSuccess = res?.data?.success;
      if(isSuccess) {
        setTransactionTags(prevTags =>
          prevTags.map(tag =>
            tag.tagId === tagId ? { ...tag, tagName: tagName } : tag
          )
        );
      }else{
        setError(res?.data?.responseMessage)
      }
    } catch(err){
      console.log("error while editing tag : ", err);
    } finally{
      setLoading(false)
    }
  }

  async function deleteTransactionTag(id){
    setLoading(true);
    let api = process.env.REACT_APP_ACCOUNT_API_END_POINT + "/accounts/tag/v1/deleteCustomTag";
    let payload = {
      tagId: id
    }
    try{
      let res = await axios.post(api, payload, getHeaders());
      let data = res?.data?.responseData;
      console.log(data);
      let filteredTags = transactionTags.filter(d => d?.tagId !== id);
      let isSuccess = res?.data?.success;
      if(isSuccess) {
        setTransactionTags(filteredTags);
        getTransactionsData(pageOptions.pageNumber, pageOptions.pageSize);
      }else{
        setError(data)
      }
    } catch(err){
      console.log("error while deleting tags : ", err);
    } finally{
      setLoading(false);
    }
  }

  async function addTransactionTag(tag, transactionId){
    setLoading(true);
    let api = process.env.REACT_APP_ACCOUNT_API_END_POINT + "/accounts/tag/v1/addCustomTag";
    let payload = {
      tagName: tag,
      transactionId
    }
    try{
      let res = await axios.post(api, payload, getHeaders());
      let data = res?.data?.responseData;
      console.log(data);
      
      let isSuccess = res?.data?.success;

      
      if(isSuccess){
        getTransactionTags();
        if(transactionId) {
          getTransactionsData(pageOptions.pageNumber, pageOptions.pageSize);
        }
      }else{
        setError(res?.data?.responseMessage)
      }
      
    } catch(err){
      console.log("error while adding tag : ", err);
    } finally {
      setLoading(false);
    }
  }

  async function associateTagWithTransaction(transactionId, tagId){
      setLoading(true);
      let api = process.env.REACT_APP_ACCOUNT_API_END_POINT + "/accounts/tag/v1/assocTagWithTransaction"
      let payload = {
        transactionId,
        tagId
      }
      try{
        let res = await axios.post(api, payload, getHeaders());
        let data = res.data.responseData;
        console.log(data);
        let isSuccess = res?.data?.success;
        if(isSuccess){
          getTransactionsData(pageOptions.pageNumber, pageOptions.pageSize);
        }
      }catch(err) {
        console.log("error while associating transaction: ",err);
      }finally{
        setLoading(false);
      }
  }

  async function removeTagFromTransaction(transactionId, tagId){
      setLoading(true);
      let api = process.env.REACT_APP_ACCOUNT_API_END_POINT + "/accounts/tag/v1/removeTagFromTransaction"
      let payload = {
        transactionId,
        tagId
      }
      try{
        let res = await axios.post(api, payload, getHeaders());
        let data = res.data.responseData;
        console.log(data);
        let isSuccess = res?.data?.success;
        if(isSuccess){
          getTransactionsData(pageOptions.pageNumber, pageOptions.pageSize);
          if(includedTagIds?.length || excludedTagIds?.length) {
            getAllTransactionsDataCount();
          }
        }
      }catch(err) {
        console.log("error while removing tag from transaction: ",err);
      }finally{
        setLoading(false);
      }
  }

  function onChangeDateFilter(dateFilterValue) {
    selectedCategoryFilter = [];
    excludedCategoryFilter = [];
    includeMerchantIds = [];
    excludeMerchantIds = [];
    setGlobalFilterValue({
      ...globalFilterValue,
      dateFilter: dateFilterValue,
      categoryFilter: {
        selectedCategoryFilter: [],
        excludedCategoryFilter: [],
      },
      merchantFilter: {
        includeMerchantIds: [],
        excludeMerchantIds: [],
      },
    });

    setSelectedTransFilterState(dateFilterValue);
    selectedDateFilter = dateFilterValue;

    categoryFilterFns.fns.getCategorySubCategoryFilterData(dateFilterValue);

    getMerchantListing(dateFilterValue);

    refreshTransactionDataFromProvider(
      pageOptions.pageSize,
      pageOptions.pageNumber
    );
  }

  function onChangeCategoryFilter(includedIds, excludedIds) {
    const { includedId, excludedId } = compareIds(includedIds, excludedIds);

    selectedCategoryFilter = includedId;
    excludedCategoryFilter = excludedId;

    setGlobalFilterValue({
      ...globalFilterValue,
      categoryFilter: {
        selectedCategoryFilter,
        excludedCategoryFilter,
      },
    });

    refreshTransactionDataFromProvider(
      pageOptions.pageSize,
      pageOptions.pageNumber
    );
  }

  function onChangeMerchantFilter(includedIds, excludedIds) {
    const { includedId, excludedId } = compareIds(includedIds, excludedIds);

    includeMerchantIds = includedId;
    excludeMerchantIds = excludedId;

    setGlobalFilterValue({
      ...globalFilterValue,
      merchantFilter: {
        includeMerchantIds,
        excludeMerchantIds,
      },
    });

    refreshTransactionDataFromProvider(
      pageOptions.pageSize,
      pageOptions.pageNumber
    );
  }

  function onChangeTagsFilter(includedIds, excludedIds){

    includedTagIds = includedIds;
    excludedTagIds = excludedIds;

    setGlobalFilterValue({
      ...globalFilterValue,
      tagFilter: {
        includedTagIds,
        excludedTagIds,
      },
    });
    
    refreshTransactionDataFromProvider(
      pageOptions.pageSize,
      pageOptions.pageNumber
    );
  }

  function resetError(){
    if(error) {
      setError(false);
    }
  }

  let fnsTransactions = {
    onTransactionsLandingPageLoad,
    onChangeDateFilter,
    getTransactionChart,
    getTransactionsData,
    updateUserRating,
    getParentReviews,
    getChildReviews,
    getInnerChildReviews,
    addParentReview,
    addChildReview,
    addInnerChildReview,
    onCloseChildReview,
    onCloseInnerChildReview,
    handleUserReviewClick,
    onChangeCategoryFilter,
    refreshTransactionDataFromProvider,
    getAllTransactionsDataCount,
    onChangeMerchantFilter,
    getMerchantListing,
    modifyCustomCategory,
    getTransactionTags,
    onChangeTagsFilter,
    deleteTransactionTag,
    addTransactionTag,
    editTransactionTag,
    associateTagWithTransaction,
    resetError,
    removeTagFromTransaction
  };

  return { dataTransactions: data, fnsTransactions };
};

export default useTransactionsLanding;

/**********************************************************************
 *
 *   Component hook generated by Quest
 *
 *   Code Logic for the component goes in this hook
 *   To setup bindings that use the data here or call the functions here, use the Quest editor
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/
import { useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
    atm_jwt,
    atm_firstName,
    atm_lastName,
    atm_isUserLoggedIn,
    atm_mainMenuButtonPressed,
    atm_dataProviderId,
} from "../../state/atoms";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { arrOldhousehold, setAuthTokens } from "src/global/central";

import { getAuthToken, removeAuthTokens } from "src/global/central";
import { atm_houseHolding, atm_houseHolding2, atm_isSavehouseHold, atm_relationships } from "src/state/atomHouseHold";

const useHouseHold = () => {

    const [jwt, setJWT] = useRecoilState(atm_jwt);

    let houseHoldData = {};

    const [relationship, setRelationShip] = useRecoilState(atm_relationships)
    houseHoldData.relationship = relationship

    const [arrHouseholding, setarrHouseholding] = useRecoilState(atm_houseHolding)
    houseHoldData.arrHouseholding = arrHouseholding

    const setArrHouseholdings = useSetRecoilState(atm_houseHolding)


    const setIsSavehouseHold = useSetRecoilState(atm_isSavehouseHold)

    const setArrOldhousehold = useSetRecoilState(atm_houseHolding2)

    // Get RelationShip List Api Call
    function getRelationships() {

        let api = process.env.REACT_APP_GOAL_API_END_POINT +
            "/users/v1/getRelationshipType"

        axios.post(api,
            null, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + getAuthToken()
            }
        }).then(res => {
            if (res['data']['success'] == false) {
                console.log(
                    'error from server ' + res['data']['responseMessage']
                );
                throw res['data']['responseMessage'];
            } else {

                let data = res.data.responseData;



                setRelationShip(data)
            }
        })
    }


    // Get HouseHold List Api Call
    function getHouseholdList() {


        let api = process.env.REACT_APP_GOAL_API_END_POINT +
            "/users/v1/getHouseHoldInfo"

        axios.post(api,
            null, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + getAuthToken()
            }
        }).then(res => {
            if (res['data']['success'] == false) {
                console.log(
                    'error from server ' + res['data']['responseMessage']
                );
                throw res['data']['responseMessage'];
            } else {

                let data = res.data.responseData;

                let tmpData = data.map((val) => {
                    return {
                        "houseHoldId": val.houseHoldId,
                        "firstName": val.firstName,
                        "lastName": val.lastName,
                        "dayOfBirth": val.dayOfBirth,
                        "monthOfBirth": val.monthOfBirth,
                        "yearOfBirth": val.yearOfBirth,
                        "relationshipToPrimary": val.relationshipToPrimary,
                        "zipCode": val.zipCode,
                        "phoneNumber": val.phoneNumber,
                        "email": val.email,
                        "relationshipActive": true
                    }

                })


                setArrOldhousehold(tmpData)
                setarrHouseholding(tmpData)
            }
        })
    }


    // Save House Holdlist
    function saveHouseHoldlist() {

        console.log('cehckkkkkkkkkkkkk save houseee holdd count yssss', arrHouseholding.length);

        let param = arrHouseholding.map((val) => {

            return {
                "userHouseholdId": val.houseHoldId,
                "firstName": val.firstName,
                "lastName": val.lastName,
                "dayOfBirth": val.dayOfBirth,
                "monthOfBirth": val.monthOfBirth == "" ? 0 : val.monthOfBirth,
                "yearOfBirth": val.yearOfBirth,
                "relationshipToPrimary": val.relationshipToPrimary,
                "relationshipActive": val.relationshipActive,
                "zipCode": val.zipCode,
                "phoneNumber": val.phoneNumber
            }

        })

        console.log('cehjclllllllll arrrrrr houseeee holddinggggggg listt iss', param);


        let api = process.env.REACT_APP_GOAL_API_END_POINT +
            "/users/v1/saveHousehold"

        axios.post(api,
            param, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + getAuthToken()
            }
        }).then(res => {
            if (res['data']['success'] == false) {
                console.log(
                    'error from server ' + res['data']['responseMessage']
                );
                throw res['data']['responseMessage'];
            } else {

                setIsSavehouseHold(1)
                let data = res.data.responseData;

                console.log('Save Successfully Completed', data);
                getHouseholdList()
            }
        }).catch(e => {
            setIsSavehouseHold(-1)
        })

    }




    let fnsHousehold = {
        getHouseholdList,
        getRelationships,
        saveHouseHoldlist,
    };

    return { houseHoldData, fnsHousehold };
};

export default useHouseHold;

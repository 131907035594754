import React, { useEffect, useRef, useState } from "react";
import AddIcon from "../../../../assets/images/add-icon4.svg";
import CloseLogo from "../../../../assets/images/close3.svg";
import SaveLogo from "../../../../assets/images/done2.svg";
import {
  MenuItem,
  Popover,
  TextField,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { useRecoilValue } from "recoil";
import { atm_goalActivities } from "src/state/atomDashboard";
import {
  validateDay,
  validateYear,
  validateYearOnly,
} from "src/validations/Goals";
import { yearFrom, yearTo } from "src/global/central";

const AddNewActivityPopUp = ({ addNewActivity }) => {
  const [newMileStonePopup, setNewActivityPopup] = useState(false);
  const [dataLength, setDataLength] = useState(null);
  const addBtnRef = useRef(null);
  const [activityData, setActivityData] = useState({
    activityName: "",
    activityDate: 1,
    activityMonth: "",
    activityYear: "",
    status: 0,
  });
  const isMobile = useMediaQuery("(max-width: 750px)");

  const goalActivities = useRecoilValue(atm_goalActivities);
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const handleFieldChange = (e, myValue) => {
    let name = e.target.name;
    let value = e.target.value;

    if (name === "activityName" || name === "activityMonth") {
      setActivityData((prevData) => ({ ...prevData, [name]: value }));
    } else {
      if (myValue) {
        setActivityData((prevData) => ({ ...prevData, [name]: myValue }));
      } else {
        setActivityData((prevData) => ({ ...prevData, [name]: +value }));
      }
    }
  };

  const validateData = () => {
    const { activityName, activityMonth, activityYear } = activityData;
    return activityName && activityMonth && activityYear;
  };

  const handleSave = () => {
    if (!validateYearOnly(activityData.activityYear)) {
      return;
    }
    if (validateData()) {
      addNewActivity(activityData);
      setNewActivityPopup(false);
      setActivityData({
        activityName: "",
        activityDate: 1,
        activityMonth: "",
        activityYear: "",
        status: 0,
      });
    } else {
      console.error("Please fill in all fields");
    }
  };

  useEffect(() => {
    const countOfStatusZero = goalActivities.filter(
      (activity) => activity.status === 0
    ).length;
    setDataLength(countOfStatusZero);
    if (countOfStatusZero === 0) {
      setNewActivityPopup(true);
    }
  }, [goalActivities, setNewActivityPopup]);

  return (
    <>
      <div
        style={{ opacity: !dataLength ? 0 : 1 }}
        ref={addBtnRef}
        onClick={() => setNewActivityPopup(true)}
        className="addMilestoneBtn"
      >
        <img src={AddIcon} alt="" />
      </div>
      {addBtnRef.current && (
        <Popover
          hideBackdrop={true}
          sx={{
            height: !dataLength ? 0 : undefined,
            width: !dataLength ? 0 : undefined,
            ".MuiPaper-root": {
              boxShadow: 0,
              borderRadius: 0,
              width: 800,
              transform: !isMobile
                ? "translateX(55px) !important"
                : "translateX(0) !important",
              position: "fixed",
              maxWidth: "90.5%",
            },
          }}
          open={newMileStonePopup || !dataLength}
          anchorEl={addBtnRef.current}
          onClose={() => setNewActivityPopup(false)}
        >
          <div className="addNewActivity">
            <div className="heading">
              <div className="text">Add New Activity</div>
              <span className="btns" style={{ display: "flex", gap: 8 }}>
                <img
                  height={23.5}
                  className="save"
                  onClick={handleSave}
                  src={SaveLogo}
                  alt=""
                />
                <img
                  hidden={!dataLength}
                  height={23.5}
                  className="closeBtn"
                  onClick={() => {
                    if (!dataLength) {
                      return;
                    }
                    setNewActivityPopup(false);
                  }}
                  src={CloseLogo}
                  alt=""
                />
              </span>
            </div>
            <div className="fields">
              <div className="date-box" style={{ width: "50%" }}>
                <TextField
                  onChange={(e) => {
                    validateDay(e);
                    handleFieldChange(e);
                    if (e.target.value === "") {
                      setActivityData({ ...activityData, [e.target.name]: 1 });
                    }
                  }}
                  name="activityDate"
                  variant="standard"
                  type="tel"
                  sx={{ width: 80 }}
                  inputProps={{ maxLength: 2, placeholder: "DD", max: 2 }}
                />

                <div className="monthCover">
                  <label hidden={activityData.activityMonth}>MM</label>
                  <TextField
                    defaultValue=""
                    name="activityMonth"
                    onChange={handleFieldChange}
                    variant="standard"
                    sx={{
                      width: isMobile ? 50 : 80,
                    }}
                    select
                  >
                    {monthNames.map((month, index) => (
                      <MenuItem key={index} value={index + 1}>
                        {month}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>

                <Tooltip
                  title={`Enter value in range b/w ${yearFrom} - ${yearTo}`}
                >
                  <TextField
                    onChange={(e) => {
                      validateYear(e);
                      handleFieldChange(e);
                    }}
                    name="activityYear"
                    variant="standard"
                    type="tel"
                    sx={{ width: 80 }}
                    inputProps={{ maxLength: 4, placeholder: "YYYY" }}
                  />
                </Tooltip>
              </div>
              <TextField
                name="activityName"
                label="Activity title"
                sx={{ width: "50%" }}
                onChange={handleFieldChange}
                placeholder="e.g. Arrange accomodation"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </div>
        </Popover>
      )}
    </>
  );
};

export default AddNewActivityPopUp;

import React from "react";
import learnImage from "../assets/images/learn-image.webp";
import peers from "../assets/images/peers.svg";
import real from "../assets/images/real.svg";
import ignite from "../assets/images/ignite.svg";
const Learn = () => {
  return (
    <div className="learn-wrapper">
      <div className="circle"></div>
      <img src={learnImage} alt="" />
      <div className="right-side">
        <h2>Learn how others are managing their money</h2>{" "}
        <img src={learnImage} alt="Financial novice using personal finance management platform to understand spending patterns holistically" className="mobile-pic w-set" />
        <div className="row-area">
          <img src={peers} alt="Like-minded peers engaging in financial discussions regarding saving and investing" />
          <div className="presentation">
            <h3>Engage with your peers</h3>
            <p>
              The only platform that lets you discuss your burning concerns, and
              unanswered questions in a safe space of like- minded peers. You
              get to decide the level of details you want to share with your
              peers, and we ensure the data is always authentic. The intent is
              to build a Kamunity that supports your growth, and gets you
              inspired by others.
            </p>
          </div>
        </div>
        <div className="row-area">
          <img src={ignite} alt="Breaking the silence surrounding discussing personal finances, finding answers to our age-old questions, and discovering new ways to save" />
          <div className="presentation">
            <h3>Ignite your curiosity</h3>
            <p>
              We take down the age old barrier of not being able to discuss your
              finances with others. Learn newer ways to save and invest money,
              not just based on recommendation from a platform, but based on how
              real people in your own Kamunity are doing it.
            </p>
          </div>
        </div>
        <div className="row-area">
          <img src={real} alt="Crowd-sourced financial data, insights, ratings, and reviews; derived from real users and real transactions" />
          <div className="presentation">
            <h3>Real users, Real data</h3>
            <p>
              Your decisions are driven by the data and insights we present to
              you. Given that we get the data from your financial institution
              which cannot be manipulated by either us or the users, our data is
              as real as it gets. Reviews and ratings are provided on actual
              transactions and providers.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Learn;

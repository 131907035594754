import React, { useState, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";
import {
    Button,
    styled,
    Drawer,
    Box,
    ListItem,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    InputAdornment,
    TextField,
    Divider,
    MenuItem,
    Tooltip,
    FormControl,
    Select,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Snackbar,
    Alert,
} from "@mui/material";


import { ArrowForwardIosSharp } from "@mui/icons-material";
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import DeleteIcon from "../assets/images/delete.svg";
import PencilIcon from "../assets/images/pencil2.svg";
import CloseIcon from '../assets/images/close.svg'
import AddIcon from "../assets/images/add-icon4.svg";


import "../assets/css/household.css";

import axios from "axios";


import RelationshipSelection from "../Household/RelationshipSelection";
import { atm_houseHolding, atm_houseHolding2, atm_isSavehouseHold } from "src/state/atomHouseHold";
import useHouseHold from "../Household/useHouseHold";
import { GetWindowWidth, getAuthToken } from "src/global/central";

const ButtonContained = styled(Button)({
    margin: `0px`,
    textTransform: 'none'
});

export default function HouseHoldDrawer({openDrawer}) {


    const { houseHoldData, fnsHousehold } = useHouseHold();
    const [showModal, setShowModal] = useState(false)


    const [data, setData] = useRecoilState(atm_houseHolding);
    const data2 = useRecoilValue(atm_houseHolding2);


    const [cancelModal, setCancelModal] = useState(false)
    const [isSavehousehold, setIsSavehousehold] = useRecoilState(
        atm_isSavehouseHold
    );

    const [addNewDataObject, setAddNewDataObject] = useState({

        houseHoldId: null,
        firstName: "",
        lastName: "",
        dayOfBirth: 0,
        monthOfBirth: 0,
        yearOfBirth: 0,
        relationshipToPrimary: 0,
        zipCode: "",
        phoneNumber: "",
        email: "",
        relationshipActive: true,

    })


    const [HeightState, setHeight] = useState(0);

    const HandleResizeWork = () => {
        const WindowHeight = window.innerHeight - 100;
        setHeight(WindowHeight);
    };
    useEffect(() => {
        window.addEventListener("resize", HandleResizeWork);
        HandleResizeWork();
    }, []);


    const toggleDrawer = (open) => (event) => {
        if (event.type === "keydown") {
            return;
        }

        setAddNewModal(false)
        setActiveAccordion(null)
        setState(open);
    };


    const width = GetWindowWidth();
    const [state, setState] = useState(false);
    const [activeAccordion, setActiveAccordion] = useState(null);
    const [addNewModal, setAddNewModal] = useState(false)

    const handleAccordionClick = (index) => {
        setActiveAccordion(index == activeAccordion ? null : index);
        setAddNewModal(false)
    };
    useEffect(() => {
        if(openDrawer) setState(true);
    },[width])

    const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];

    // Days
    const days = Array.from({ length: 31 }, (_, index) => index + 1);

    // Years
    const currentYear = new Date().getFullYear();

    const years = Array.from(
        { length: currentYear - 1944 + 1 },
        (_, index) => 1944 + index
    );

    // ONLY Allow Alphabet not Numbers
    const handleInput = (e) => {
        const inputValue = e.target.value.replace(/[^a-zA-Z]/g, '');
        e.target.value = inputValue;
    };

    // Handle Numeric Input Mode For Only Numeric value allow
    const handleNumericInput = (e) => {
        const inputValue = e.target.value.replace(/[^0-9]/g, '');
        e.target.value = inputValue;
    };

    // Handle Selection Value Change
    const handleSelectionChange = (e, index, field) => {

        let newData = [...data];
        let value = e.target.value;

        if (value == data2[index]["dayOfBirth"] || value == data2[index]["monthOfBirth"] || value == data2[index]["yearOfBirth"]) {
            setShowModal(false)
        }
        else {
            setShowModal(true)
        }

        newData[index] = {
            ...newData[index],
            [field]: value,
        };

        setData(newData)
    }

    // Handle List Field Item Change
    const handleFieldChange = (e, index, field) => {
        let newData = [...data];
        let value = e.target.value;

        if (value == data2[index]["firstName"]
            && value == data2[index]["lastName"]
            && value == data2[index]["zipCode"]
            && value == data2[index]["phoneNumber"]) {
            setShowModal(false)
        }
        else {
            setShowModal(true)
        }


        newData[index] = {
            ...newData[index],
            [field]: value,
        };

        setData(newData);

    };

    // Handle View Add New Memeber Field
    const handleNewMemberField = (e, field) => {

        let newdata = { ...addNewDataObject }


        let value = e.target.value;

        let currentYear = new Date().getFullYear();
        let currentMonth = new Date().getMonth() + 1;

        newdata = {
            ...newdata,
            [field]: value
        }

        setAddNewDataObject(newdata)

    }

    // Handle Relation Ship Check
    const relationShipCheck = (value, index) => {

        if (value == data2[index]["relationshipToPrimary"]) {
            setShowModal(false)
        }
        else {
            setShowModal(true)
        }
    }


    useEffect(() => {

        if (!houseHoldData.relationship.length) {
            fnsHousehold.getRelationships()
        }

        // if (!houseHoldData.arrHouseholding.length) {
        //     fnsHousehold.getHouseholdList()
        // }

        if (!data.length) {
            setData([
                {
                    houseHoldId: null,
                    firstName: "",
                    lastName: "",
                    dayOfBirth: 0,
                    monthOfBirth: 0,
                    yearOfBirth: 0,
                    relationshipToPrimary: 0,
                    zipCode: "",
                    phoneNumber: "",
                    email: "",
                    relationshipActive: true,

                },
            ]);
        }

    }, []);




    // Remove Data 
    const removeData = (index, field) => {

        let newData = [...data];
        let value = false

        if (data2.length != 0) {
            if (value == data2[index]["relationshipActive"]) {
                setShowModal(false)
            }
            else {
                setShowModal(true)
            }
        }


        newData[index] = {
            ...newData[index],
            [field]: value,
        };

        setData(newData);
    };


    // Undo Data
    const undoData = (index, field) => {

        let newData = [...data];
        let value = true

        if (data2.length != 0) {
            if (value == data2[index]["relationshipActive"]) {
                setShowModal(false)
            }
            else {
                setShowModal(true)
            }
        }

        newData[index] = {
            ...newData[index],
            [field]: value,
        };

        setData(newData);

    };

    // Custom Toggle For Show Data
    function CustomToggle({ children, onClick, isCardOpen, index, removeClick }) {


        return (
            <div style={{ display: 'flex', alignItems: 'center', flex: 1, justifyContent: 'space-between' }} onClick={onClick} >

                <div style={{ cursor: 'pointer', justifyContent: 'space-between', display: 'flex', width: '100%', overflow: 'hidden', }} onClick={() => handleAccordionClick(index)}>

                    <p className="text" style={{ fontWeight: 'bold', textAlign: 'start', width: '60vw' }}>
                        {children}
                    </p>


                    {isCardOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}

                </div>

                <div style={{ cursor: 'pointer', padding: 10, paddingLeft: 13, paddingRight: 13, alignContent: 'center', justifyItems: 'center', width: '10%', }} onClick={removeClick} >

                    <img
                        height={20}
                        src={DeleteIcon}
                        alt=""
                    />

                </div>
            </div>
        );
    }

    // Custom Toggle For Undo Data
    function UndoToggle({ children, undoClick }) {

        return (
            <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}  >

                <div style={{ cursor: 'pointer', alignItems: 'center', justifyItems: 'center', display: 'flex' }}>
                    {children}
                    &nbsp;

                </div>

                <div style={{ cursor: 'pointer', padding: 10, paddingLeft: 13, paddingRight: 13, alignContent: 'center', justifyItems: 'center' }} onClick={undoClick} >

                    <span height={20} >
                        UNDO
                    </span>
                </div>

            </div>
        );
    }


    // Validation 
    const validation = (exit) => {

        let firstName = data.some((val) => val.firstName == "" && val.relationshipActive == true)
        let relationship = data.some((val) => val.relationshipToPrimary == 0 && val.relationshipActive == true)
        let zipcode = data.some((val) => val.zipCode == "" && val.relationshipActive == true)
        let zipcodeLngth = data.some((val) => val.zipCode.length < 5)
        let phoneNumberLength = data.some((val) => val.phoneNumber != "" && val.phoneNumber.length < 10 && val.relationshipActive == true)


        if (addNewDataObject.firstName == "" && addNewModal) {
            // alert('Firstname is required')
            return null
        }
        else if (addNewDataObject.relationshipToPrimary == 0 && addNewModal) {
            // alert('Relationship is required')
            return null
        }
        else if (addNewDataObject.zipCode == "" && addNewModal) {
            // alert('Zipcode is required')
            return null
        }
        else if (addNewDataObject.zipCode != "" && addNewDataObject.zipCode.length < 5 && addNewModal) {
            // alert('enter 5 digits for zipcode')
            return null
        }
        else if (addNewDataObject.phoneNumber != "" && addNewDataObject.phoneNumber.length < 5 && addNewModal) {
            // alert('enter 5 digits for phone number')
            return null
        }
        else if (firstName == true) {
            // alert('Firstname is required')
            return null
        }
        else if (relationship == true) {
            // alert('Relationship is required')
            return null
        }
        else if (zipcode == true) {
            // alert('Zipcode is required')
            return
        }
        else if (zipcode == false && zipcodeLngth == true) {
            // alert('Enter 5 digits for zipcode')
            return null
        }
        else if (phoneNumberLength == true) {
            // alert('Enter 10 digits for phone number')
            return null
        }
        else {
            if (addNewModal) {
                saveHouseHoldlist([...data, addNewDataObject], exit)

            }
            else {
                saveHouseHoldlist(data, exit);
            }
        }
    }

    const clearAddNewDataObject = () => {
        setAddNewDataObject({
            houseHoldId: null,
            firstName: "",
            lastName: "",
            dayOfBirth: 0,
            monthOfBirth: 0,
            yearOfBirth: 0,
            relationshipToPrimary: 0,
            zipCode: "",
            phoneNumber: "",
            email: "",
            relationshipActive: true,
        });
    };

    // Save House Holdlist
    function saveHouseHoldlist(updatedData, exit) {


        let param = updatedData.map((val) => {

            return {
                "userHouseholdId": val.houseHoldId,
                "firstName": val.firstName,
                "lastName": val.lastName,
                "dayOfBirth": val.dayOfBirth,
                "monthOfBirth": val.monthOfBirth == "" ? 0 : val.monthOfBirth,
                "yearOfBirth": val.yearOfBirth,
                "relationshipToPrimary": val.relationshipToPrimary,
                "relationshipActive": val.relationshipActive,
                "zipCode": val.zipCode,
                "phoneNumber": val.phoneNumber
            }
        })

        let api = process.env.REACT_APP_GOAL_API_END_POINT +
            "/users/v1/saveHousehold"

        axios.post(api,
            param, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + getAuthToken()
            }
        }).then(res => {
            if (res['data']['success'] == false) {
                console.log(
                    'error from server ' + res['data']['responseMessage']
                );

                throw res['data']['responseMessage'];
            } else {


                if (String(res.status) == "200") {
                    setIsSavehousehold(1)
                    setAddNewModal(false)
                    setActiveAccordion(null)
                    clearAddNewDataObject()
                    setShowModal(false)

                    if (exit) {
                        setState(false)
                    }
                    // navigate('/dashboard')
                    fnsHousehold.getHouseholdList()
                }

            }
        }).catch(e => {
            setIsSavehousehold(-1)
        })

    }

    const list = (anchor) => {

        return (
            <Box
                sx={{
                    minHeight: `${HeightState}px`,
                    marginTop: 5,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <div className="login-box" style={{ padding: '10px', }}>

                    <div className="content" style={{ display: 'flex', marginBottom: '20px' }}>

                        <div style={{ flex: 1, alignItems: 'center', justifyItems: 'center' }}>
                            <p
                                className="text"
                                style={{ fontWeight: 'bold' }}
                            >
                                Your Household members
                            </p>
                            <p
                                className="text"

                            >
                                List of your household members
                            </p>

                        </div>

                        <img
                            className="closeGoalsModalBtn"
                            src={CloseIcon}
                            alt=""
                            onClick={() => {

                                if (showModal) {
                                    setCancelModal(true)
                                }
                                else {
                                    setState(false)
                                }
                            }}
                        />

                    </div>

                    <div style={{ minHeight: `450px`, maxHeight: `${HeightState - 100}px`, overflowY: 'auto', padding: '6px', border: '0.5px solid #000000' }}>

                        {/* add New House Hold */}
                        <div style={{ marginBottom: 10, border: addNewModal ? '0.5px solid black' : 0, }}>
                            <Accordion style={{ boxShadow: 'none', }} key={1} expanded={addNewModal}  >

                                <AccordionSummary>

                                    <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}  >

                                        <div style={{ alignItems: 'center', display: 'flex', flex: 1, display: 'flex', }} onClick={() => { setAddNewModal(!addNewModal); setActiveAccordion(null) }}>

                                            {
                                                !addNewModal && (
                                                    <img src={AddIcon} height={25} style={{ marginLeft: -10 }} alt="" />
                                                )}

                                            &nbsp;
                                            <span className="text" style={{ color: !addNewModal ? '#B22795' : 'black', fontWeight: 'bolder', fontSize: 18 }}>Add New Member</span>

                                        </div>

                                        {
                                            addNewModal && (
                                                <div style={{ cursor: 'pointer', }} onClick={() => setAddNewModal(false)} >
                                                    <img
                                                        height={15}
                                                        src={CloseIcon}
                                                        alt=""
                                                    />
                                                </div>
                                            )
                                        }

                                    </div>

                                </AccordionSummary>

                                <AccordionDetails>


                                    <div style={{ display: 'flex', justifyContent: 'space-between', flex: 1, marginTop: 20, }}>

                                        {/* First Name */}
                                        <div style={{ flex: 1 }} >
                                            <p className="titleTxt" >
                                                FIRST NAME
                                            </p>

                                            <TextField
                                                placeholder="First Name"
                                                className="editable"
                                                variant="standard"
                                                value={addNewDataObject.firstName}
                                                onChange={(e) => {
                                                    if (e.target.value == addNewDataObject.firstName) {
                                                        return;
                                                    } else if (e.target.value === "") {
                                                        e.target.value = '';
                                                    }
                                                    handleNewMemberField(e, "firstName");
                                                }}
                                                sx={{ flex: 1, marginRight: 2 }}
                                                onInput={handleInput}
                                                InputProps={{

                                                    endAdornment: (
                                                        <InputAdornment
                                                            position="start"
                                                            className="dollar"
                                                        >
                                                            <img
                                                                className="pencilIcon"
                                                                src={PencilIcon}
                                                                alt=""
                                                            />
                                                        </InputAdornment>
                                                    ),
                                                    disableUnderline: true,
                                                }}
                                            />

                                            {
                                                addNewDataObject.firstName == '' && (
                                                    <p className="error-custom" style={{ fontSize: 'var(--fontSize12)' }}>

                                                        <svg
                                                            width="17"
                                                            height="17"
                                                            viewBox="0 0 17 17"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M8.5 0C6.81886 0 5.17547 0.498516 3.77766 1.43251C2.37984 2.3665 1.29037 3.69402 0.647028 5.24719C0.00368293 6.80036 -0.164645 8.50943 0.163329 10.1583C0.491303 11.8071 1.30085 13.3217 2.4896 14.5104C3.67834 15.6992 5.1929 16.5087 6.84174 16.8367C8.49057 17.1646 10.1996 16.9963 11.7528 16.353C13.306 15.7096 14.6335 14.6202 15.5675 13.2223C16.5015 11.8245 17 10.1811 17 8.5C17 6.24566 16.1045 4.08365 14.5104 2.48959C12.9163 0.895533 10.7543 0 8.5 0ZM7.44459 4.25C7.44459 3.96821 7.55653 3.69796 7.75578 3.4987C7.95504 3.29944 8.22529 3.1875 8.50709 3.1875C8.78888 3.1875 9.05913 3.29944 9.25839 3.4987C9.45764 3.69796 9.56959 3.96821 9.56959 4.25V9.13041C9.56959 9.26994 9.5421 9.40811 9.48871 9.53702C9.43531 9.66593 9.35705 9.78305 9.25839 9.88172C9.15972 9.98038 9.04259 10.0586 8.91369 10.112C8.78478 10.1654 8.64662 10.1929 8.50709 10.1929C8.36756 10.1929 8.22939 10.1654 8.10048 10.112C7.97158 10.0586 7.85445 9.98038 7.75578 9.88172C7.65712 9.78305 7.57886 9.66593 7.52546 9.53702C7.47207 9.40811 7.44459 9.26994 7.44459 9.13041V4.25ZM8.5 13.8125C8.25904 13.8125 8.02349 13.741 7.82313 13.6072C7.62278 13.4733 7.46662 13.283 7.37441 13.0604C7.2822 12.8378 7.25807 12.5928 7.30508 12.3565C7.35209 12.1201 7.46812 11.9031 7.63851 11.7327C7.8089 11.5623 8.02598 11.4463 8.26232 11.3992C8.49865 11.3522 8.74362 11.3764 8.96624 11.4686C9.18886 11.5608 9.37914 11.7169 9.51301 11.9173C9.64688 12.1176 9.71834 12.3532 9.71834 12.5942C9.71834 12.9173 9.58998 13.2272 9.36149 13.4557C9.13301 13.6841 8.82312 13.8125 8.5 13.8125Z"
                                                                fill="#E41616"
                                                            />
                                                        </svg>
                                                        {/* {data.emailError && data.emailHelperText} */}

                                                        First Name is Required
                                                    </p>
                                                )
                                            }

                                        </div>

                                        {/* Last Name */}
                                        <div style={{ flex: 1 }}>
                                            <p className="titleTxt" >
                                                LAST NAME
                                            </p>

                                            <TextField
                                                placeholder="Last Name"
                                                className="editable"
                                                variant="standard"
                                                value={addNewDataObject.lastName}
                                                onChange={(e) => {
                                                    if (e.target.value == addNewDataObject.lastName) {
                                                        return;
                                                    } else if (e.target.value === "") {
                                                        e.target.value = '';
                                                    }
                                                    handleNewMemberField(e, "lastName");
                                                }}
                                                sx={{ flex: 1 }}
                                                onInput={handleInput}
                                                InputProps={{

                                                    endAdornment: (
                                                        <InputAdornment
                                                            position="start"
                                                            className="dollar"
                                                        >
                                                            <img
                                                                className="pencilIcon"
                                                                src={PencilIcon}
                                                                alt=""
                                                            />
                                                        </InputAdornment>
                                                    ),
                                                    disableUnderline: true,
                                                }}
                                            />
                                        </div>

                                    </div>

                                    {/* DOB */}
                                    <div style={{ marginTop: 20, display: 'flex', flex: 1 }}>

                                        {/* Date ,Month , Year */}
                                        <div style={{ flex: 1, marginRight: 10 }}>
                                            <p className="titleTxt" style={{ marginBottom: 5 }} >
                                                DOB
                                            </p>

                                            <div style={{ display: 'flex', }}>

                                                {/* Date */}
                                                <div style={{ position: 'relative' }}>

                                                    {!addNewDataObject.dayOfBirth && (
                                                        <span
                                                            id={`date-span`}
                                                            className="dateFormateTxt"
                                                        >
                                                            DD
                                                        </span>
                                                    )}

                                                    <Select
                                                        className="dobTxt"
                                                        labelId={`date-label`}
                                                        value={addNewDataObject.dayOfBirth}
                                                        style={{ color: 'black' }}
                                                        placeholder="dd"
                                                        inputProps={{ placeholder: 'ddd' }}

                                                        variant="standard"
                                                        onChange={(e) => {
                                                            handleNewMemberField(e, "dayOfBirth");
                                                        }}
                                                        MenuProps={{
                                                            PaperProps: {
                                                                style: {
                                                                    maxHeight: 200, // Adjust the max height as needed
                                                                },
                                                            }
                                                        }}
                                                    >
                                                        {days.map((day, index) =>

                                                        (
                                                            <MenuItem key={index} value={index + 1}>
                                                                {day}
                                                            </MenuItem>
                                                        ))}

                                                    </Select>


                                                </div>

                                                {/* Months */}
                                                <div style={{ position: 'relative' }}>


                                                    {!addNewDataObject.monthOfBirth && (
                                                        <label
                                                            id={`month-label-end`}
                                                            className="dateFormateTxt"
                                                        >
                                                            MM
                                                        </label>
                                                    )}
                                                    <Select
                                                        className="dobTxt"
                                                        labelId={`month-label-end`}
                                                        value={addNewDataObject.monthOfBirth}

                                                        variant="standard"
                                                        onChange={(e) => {
                                                            handleNewMemberField(e, "monthOfBirth");
                                                        }}
                                                        MenuProps={{
                                                            PaperProps: {
                                                                style: {
                                                                    maxHeight: 200, // Adjust the max height as needed
                                                                },
                                                            }
                                                        }}
                                                    >
                                                        {monthNames.map((month, index) => (
                                                            <MenuItem key={index} value={index + 1}>
                                                                {month}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>

                                                </div>

                                                {/* Year */}
                                                <div style={{ position: 'relative' }}>

                                                    {!addNewDataObject.yearOfBirth && (
                                                        <label
                                                            id={`year-label-end`}
                                                            className="dateFormateTxt"
                                                        >
                                                            YYYY
                                                        </label>
                                                    )}
                                                    <Select
                                                        className="dobTxt"
                                                        labelId={`year-label-end`}
                                                        value={addNewDataObject.yearOfBirth}
                                                        sx={{ width: 51 }}
                                                        variant="standard"
                                                        onChange={(e) => {
                                                            handleNewMemberField(e, "yearOfBirth");
                                                        }}
                                                        MenuProps={{
                                                            PaperProps: {
                                                                style: {
                                                                    maxHeight: 200, // Adjust the max height as needed
                                                                },
                                                            }
                                                        }}
                                                    >
                                                        {years.map((year, index) => (
                                                            <MenuItem key={index} value={year}>
                                                                {year}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>

                                                </div>

                                            </div>
                                        </div>

                                        {/* RELATIONSHIP */}
                                        <div style={{ flex: 1, }}>
                                            <p className="titleTxt" >
                                                RELATIONSHIP
                                            </p>


                                            <FormControl
                                                className="RelationshipSelectionDropdown select"
                                                variant="standard"
                                                sx={{ border: "none", }}
                                            >

                                                <div style={{ position: 'relative' }}>
                                                    {!addNewDataObject.relationshipToPrimary && (
                                                        <span
                                                            id={`date-span`}
                                                            className="dateFormateTxt"
                                                        >
                                                            RELATIONSHIP
                                                        </span>
                                                    )}

                                                    <Select
                                                        style={{ display: 'flex', }}
                                                        value={addNewDataObject.relationshipToPrimary == "" ? "" : addNewDataObject.relationshipToPrimary}
                                                        onChange={(e) => handleNewMemberField(e, 'relationshipToPrimary')}
                                                        sx={{
                                                            paddingBottom: 0,
                                                            textAlign: "center",
                                                            width: width / 3.1
                                                        }}
                                                    >

                                                        {houseHoldData.relationship.map((data, index) => [
                                                            <MenuItem key={index} value={data.id}>

                                                                <div className="relationName">{data.value}</div>

                                                            </MenuItem>,
                                                            houseHoldData.relationship.length !== index + 1 && (
                                                                <Divider sx={{ mx: "10px" }} />
                                                            ),
                                                        ])}

                                                    </Select>

                                                </div>
                                                {
                                                    addNewDataObject.relationshipToPrimary == "" && (
                                                        <p className="error-custom" style={{ fontSize: 'var(--fontSize12)', marginTop: 10 }}>

                                                            <svg
                                                                width="17"
                                                                height="17"
                                                                viewBox="0 0 17 17"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M8.5 0C6.81886 0 5.17547 0.498516 3.77766 1.43251C2.37984 2.3665 1.29037 3.69402 0.647028 5.24719C0.00368293 6.80036 -0.164645 8.50943 0.163329 10.1583C0.491303 11.8071 1.30085 13.3217 2.4896 14.5104C3.67834 15.6992 5.1929 16.5087 6.84174 16.8367C8.49057 17.1646 10.1996 16.9963 11.7528 16.353C13.306 15.7096 14.6335 14.6202 15.5675 13.2223C16.5015 11.8245 17 10.1811 17 8.5C17 6.24566 16.1045 4.08365 14.5104 2.48959C12.9163 0.895533 10.7543 0 8.5 0ZM7.44459 4.25C7.44459 3.96821 7.55653 3.69796 7.75578 3.4987C7.95504 3.29944 8.22529 3.1875 8.50709 3.1875C8.78888 3.1875 9.05913 3.29944 9.25839 3.4987C9.45764 3.69796 9.56959 3.96821 9.56959 4.25V9.13041C9.56959 9.26994 9.5421 9.40811 9.48871 9.53702C9.43531 9.66593 9.35705 9.78305 9.25839 9.88172C9.15972 9.98038 9.04259 10.0586 8.91369 10.112C8.78478 10.1654 8.64662 10.1929 8.50709 10.1929C8.36756 10.1929 8.22939 10.1654 8.10048 10.112C7.97158 10.0586 7.85445 9.98038 7.75578 9.88172C7.65712 9.78305 7.57886 9.66593 7.52546 9.53702C7.47207 9.40811 7.44459 9.26994 7.44459 9.13041V4.25ZM8.5 13.8125C8.25904 13.8125 8.02349 13.741 7.82313 13.6072C7.62278 13.4733 7.46662 13.283 7.37441 13.0604C7.2822 12.8378 7.25807 12.5928 7.30508 12.3565C7.35209 12.1201 7.46812 11.9031 7.63851 11.7327C7.8089 11.5623 8.02598 11.4463 8.26232 11.3992C8.49865 11.3522 8.74362 11.3764 8.96624 11.4686C9.18886 11.5608 9.37914 11.7169 9.51301 11.9173C9.64688 12.1176 9.71834 12.3532 9.71834 12.5942C9.71834 12.9173 9.58998 13.2272 9.36149 13.4557C9.13301 13.6841 8.82312 13.8125 8.5 13.8125Z"
                                                                    fill="#E41616"
                                                                />
                                                            </svg>
                                                            {/* {data.emailError && data.emailHelperText} */}

                                                            Relationship is Required
                                                        </p>
                                                    )
                                                }

                                            </FormControl>
                                        </div>

                                    </div>


                                    {/* ZIP CODE AND PHONE */}
                                    <div style={{ display: 'flex', justifyContent: 'space-between', flex: 1, marginTop: 20, marginBottom: 20 }}>

                                        {/* ZIP CODE */}
                                        <div style={{ flex: 1 }} >
                                            <p className="titleTxt" >
                                                ZIPCODE
                                            </p>

                                            <TextField
                                                placeholder="Zip Code"
                                                className="editable"
                                                variant="standard"
                                                value={addNewDataObject.zipCode}
                                                onChange={(e) => {
                                                    if (e.target.value == addNewDataObject.zipCode) {
                                                        return;
                                                    } else if (e.target.value === "") {
                                                        e.target.value = '';
                                                    }
                                                    handleNewMemberField(e, "zipCode");
                                                }}
                                                sx={{ flex: 1, marginRight: 2 }}
                                                onInput={handleNumericInput}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment
                                                            position="start"
                                                            className="dollar"
                                                        >
                                                            <img
                                                                className="pencilIcon"
                                                                src={PencilIcon}
                                                                alt=""
                                                            />
                                                        </InputAdornment>
                                                    ),
                                                    disableUnderline: true,
                                                    inputProps: {
                                                        maxLength: 5
                                                    }
                                                }}
                                            />

                                            {
                                                addNewDataObject.zipCode == '' && (
                                                    <p className="error-custom" style={{ fontSize: 'var(--fontSize12)' }}>

                                                        <svg
                                                            width="17"
                                                            height="17"
                                                            viewBox="0 0 17 17"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M8.5 0C6.81886 0 5.17547 0.498516 3.77766 1.43251C2.37984 2.3665 1.29037 3.69402 0.647028 5.24719C0.00368293 6.80036 -0.164645 8.50943 0.163329 10.1583C0.491303 11.8071 1.30085 13.3217 2.4896 14.5104C3.67834 15.6992 5.1929 16.5087 6.84174 16.8367C8.49057 17.1646 10.1996 16.9963 11.7528 16.353C13.306 15.7096 14.6335 14.6202 15.5675 13.2223C16.5015 11.8245 17 10.1811 17 8.5C17 6.24566 16.1045 4.08365 14.5104 2.48959C12.9163 0.895533 10.7543 0 8.5 0ZM7.44459 4.25C7.44459 3.96821 7.55653 3.69796 7.75578 3.4987C7.95504 3.29944 8.22529 3.1875 8.50709 3.1875C8.78888 3.1875 9.05913 3.29944 9.25839 3.4987C9.45764 3.69796 9.56959 3.96821 9.56959 4.25V9.13041C9.56959 9.26994 9.5421 9.40811 9.48871 9.53702C9.43531 9.66593 9.35705 9.78305 9.25839 9.88172C9.15972 9.98038 9.04259 10.0586 8.91369 10.112C8.78478 10.1654 8.64662 10.1929 8.50709 10.1929C8.36756 10.1929 8.22939 10.1654 8.10048 10.112C7.97158 10.0586 7.85445 9.98038 7.75578 9.88172C7.65712 9.78305 7.57886 9.66593 7.52546 9.53702C7.47207 9.40811 7.44459 9.26994 7.44459 9.13041V4.25ZM8.5 13.8125C8.25904 13.8125 8.02349 13.741 7.82313 13.6072C7.62278 13.4733 7.46662 13.283 7.37441 13.0604C7.2822 12.8378 7.25807 12.5928 7.30508 12.3565C7.35209 12.1201 7.46812 11.9031 7.63851 11.7327C7.8089 11.5623 8.02598 11.4463 8.26232 11.3992C8.49865 11.3522 8.74362 11.3764 8.96624 11.4686C9.18886 11.5608 9.37914 11.7169 9.51301 11.9173C9.64688 12.1176 9.71834 12.3532 9.71834 12.5942C9.71834 12.9173 9.58998 13.2272 9.36149 13.4557C9.13301 13.6841 8.82312 13.8125 8.5 13.8125Z"
                                                                fill="#E41616"
                                                            />
                                                        </svg>
                                                        {/* {data.emailError && data.emailHelperText} */}

                                                        Zipcode is Required
                                                    </p>
                                                )
                                            }

                                            {
                                                addNewDataObject.zipCode != '' && addNewDataObject.zipCode.length < 5 && (
                                                    <p className="error-custom" style={{ fontSize: 'var(--fontSize12)' }}>

                                                        <svg
                                                            width="17"
                                                            height="17"
                                                            viewBox="0 0 17 17"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M8.5 0C6.81886 0 5.17547 0.498516 3.77766 1.43251C2.37984 2.3665 1.29037 3.69402 0.647028 5.24719C0.00368293 6.80036 -0.164645 8.50943 0.163329 10.1583C0.491303 11.8071 1.30085 13.3217 2.4896 14.5104C3.67834 15.6992 5.1929 16.5087 6.84174 16.8367C8.49057 17.1646 10.1996 16.9963 11.7528 16.353C13.306 15.7096 14.6335 14.6202 15.5675 13.2223C16.5015 11.8245 17 10.1811 17 8.5C17 6.24566 16.1045 4.08365 14.5104 2.48959C12.9163 0.895533 10.7543 0 8.5 0ZM7.44459 4.25C7.44459 3.96821 7.55653 3.69796 7.75578 3.4987C7.95504 3.29944 8.22529 3.1875 8.50709 3.1875C8.78888 3.1875 9.05913 3.29944 9.25839 3.4987C9.45764 3.69796 9.56959 3.96821 9.56959 4.25V9.13041C9.56959 9.26994 9.5421 9.40811 9.48871 9.53702C9.43531 9.66593 9.35705 9.78305 9.25839 9.88172C9.15972 9.98038 9.04259 10.0586 8.91369 10.112C8.78478 10.1654 8.64662 10.1929 8.50709 10.1929C8.36756 10.1929 8.22939 10.1654 8.10048 10.112C7.97158 10.0586 7.85445 9.98038 7.75578 9.88172C7.65712 9.78305 7.57886 9.66593 7.52546 9.53702C7.47207 9.40811 7.44459 9.26994 7.44459 9.13041V4.25ZM8.5 13.8125C8.25904 13.8125 8.02349 13.741 7.82313 13.6072C7.62278 13.4733 7.46662 13.283 7.37441 13.0604C7.2822 12.8378 7.25807 12.5928 7.30508 12.3565C7.35209 12.1201 7.46812 11.9031 7.63851 11.7327C7.8089 11.5623 8.02598 11.4463 8.26232 11.3992C8.49865 11.3522 8.74362 11.3764 8.96624 11.4686C9.18886 11.5608 9.37914 11.7169 9.51301 11.9173C9.64688 12.1176 9.71834 12.3532 9.71834 12.5942C9.71834 12.9173 9.58998 13.2272 9.36149 13.4557C9.13301 13.6841 8.82312 13.8125 8.5 13.8125Z"
                                                                fill="#E41616"
                                                            />
                                                        </svg>
                                                        {/* {data.emailError && data.emailHelperText} */}

                                                        Enter 5 digits for zipcode
                                                    </p>
                                                )
                                            }


                                        </div>

                                        {/* PHONE */}
                                        <div style={{ flex: 1 }}>
                                            <p className="titleTxt" >
                                                PHONE
                                            </p>

                                            <TextField
                                                placeholder="Phone"
                                                className="editable"
                                                variant="standard"
                                                value={addNewDataObject.phoneNumber}
                                                onChange={(e) => {
                                                    if (e.target.value == addNewDataObject.phoneNumber) {
                                                        return;
                                                    } else if (e.target.value === "") {
                                                        e.target.value = '';
                                                    }
                                                    handleNewMemberField(e, "phoneNumber");
                                                }}
                                                sx={{ flex: 1, }}
                                                onInput={handleNumericInput}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment
                                                            position="start"
                                                            className="dollar"
                                                        >
                                                            <img
                                                                className="pencilIcon"
                                                                src={PencilIcon}
                                                                alt=""
                                                            />
                                                        </InputAdornment>
                                                    ),
                                                    disableUnderline: true,
                                                    inputProps: {
                                                        maxLength: 10
                                                    }
                                                }}
                                            />

                                            {
                                                addNewDataObject.phoneNumber != '' && addNewDataObject.phoneNumber.length < 10 && (
                                                    <p className="error-custom" style={{ fontSize: 'var(--fontSize12)' }}>

                                                        <svg
                                                            width="17"
                                                            height="17"
                                                            viewBox="0 0 17 17"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M8.5 0C6.81886 0 5.17547 0.498516 3.77766 1.43251C2.37984 2.3665 1.29037 3.69402 0.647028 5.24719C0.00368293 6.80036 -0.164645 8.50943 0.163329 10.1583C0.491303 11.8071 1.30085 13.3217 2.4896 14.5104C3.67834 15.6992 5.1929 16.5087 6.84174 16.8367C8.49057 17.1646 10.1996 16.9963 11.7528 16.353C13.306 15.7096 14.6335 14.6202 15.5675 13.2223C16.5015 11.8245 17 10.1811 17 8.5C17 6.24566 16.1045 4.08365 14.5104 2.48959C12.9163 0.895533 10.7543 0 8.5 0ZM7.44459 4.25C7.44459 3.96821 7.55653 3.69796 7.75578 3.4987C7.95504 3.29944 8.22529 3.1875 8.50709 3.1875C8.78888 3.1875 9.05913 3.29944 9.25839 3.4987C9.45764 3.69796 9.56959 3.96821 9.56959 4.25V9.13041C9.56959 9.26994 9.5421 9.40811 9.48871 9.53702C9.43531 9.66593 9.35705 9.78305 9.25839 9.88172C9.15972 9.98038 9.04259 10.0586 8.91369 10.112C8.78478 10.1654 8.64662 10.1929 8.50709 10.1929C8.36756 10.1929 8.22939 10.1654 8.10048 10.112C7.97158 10.0586 7.85445 9.98038 7.75578 9.88172C7.65712 9.78305 7.57886 9.66593 7.52546 9.53702C7.47207 9.40811 7.44459 9.26994 7.44459 9.13041V4.25ZM8.5 13.8125C8.25904 13.8125 8.02349 13.741 7.82313 13.6072C7.62278 13.4733 7.46662 13.283 7.37441 13.0604C7.2822 12.8378 7.25807 12.5928 7.30508 12.3565C7.35209 12.1201 7.46812 11.9031 7.63851 11.7327C7.8089 11.5623 8.02598 11.4463 8.26232 11.3992C8.49865 11.3522 8.74362 11.3764 8.96624 11.4686C9.18886 11.5608 9.37914 11.7169 9.51301 11.9173C9.64688 12.1176 9.71834 12.3532 9.71834 12.5942C9.71834 12.9173 9.58998 13.2272 9.36149 13.4557C9.13301 13.6841 8.82312 13.8125 8.5 13.8125Z"
                                                                fill="#E41616"
                                                            />
                                                        </svg>
                                                        {/* {data.emailError && data.emailHelperText} */}

                                                        Enter 10 digits for phone number
                                                    </p>
                                                )
                                            }

                                        </div>

                                    </div>

                                </AccordionDetails>
                            </Accordion>
                        </div>


                        {/* Multiple HouseHold List */}
                        <div>

                            {data.map((val, index) => (
                                <Accordion sx={{ flex: 1, marginBottom: 2 }} key={index} expanded={activeAccordion === index} onChange={() => handleAccordionClick(index)}>

                                    <AccordionSummary>
                                        {
                                            val.relationshipActive == true
                                                ?

                                                <CustomToggle index={index} onClick={() => handleAccordionClick(index)} isCardOpen={activeAccordion == index} removeClick={() => removeData(index, 'relationshipActive')}>
                                                    {val.firstName} {val.lastName}
                                                </CustomToggle>
                                                :

                                                <UndoToggle index={index} isCardOpen={activeAccordion == index} undoClick={() => undoData(index, 'relationshipActive')}>

                                                    Data Removed Successfull

                                                </UndoToggle>
                                        }
                                    </AccordionSummary>


                                    <Divider variant="fullWidth" />

                                    <AccordionDetails >
                                        {/* <Typography>{val.content}</Typography> */}

                                        <div style={{ display: 'flex', flex: 1, marginTop: 20, }}>

                                            {/* First Name */}
                                            <div style={{ flex: 1, marginRight: 20 }}>
                                                <p className="titleTxt">
                                                    FIRST NAME
                                                </p>

                                                <Tooltip
                                                    title={(() => {
                                                        return `Enter characters Aa-Zz`
                                                    })()}
                                                >
                                                    <TextField
                                                        placeholder="First Name"
                                                        className="editable"
                                                        variant="standard"
                                                        error={true}
                                                        value={val.firstName}
                                                        maxRows={2}
                                                        onChange={(e) => {

                                                            handleFieldChange(e, index, "firstName");
                                                        }}
                                                        // sx={{ width: 120 }}
                                                        onInput={handleInput}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment
                                                                    position="start"
                                                                    className="dollar"
                                                                >
                                                                    <img
                                                                        className="pencilIcon"
                                                                        src={PencilIcon}
                                                                        alt=""
                                                                    />
                                                                </InputAdornment>
                                                            ),
                                                            disableUnderline: true,
                                                            inputProps: {
                                                                maxLength: 32, // Set your desired maximum length
                                                            },
                                                        }}
                                                    />

                                                </Tooltip>
                                                {
                                                    val.firstName == '' && (
                                                        <p className="error-custom" style={{ fontSize: 'var(--fontSize12)' }}>

                                                            <svg
                                                                width="17"
                                                                height="17"
                                                                viewBox="0 0 17 17"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M8.5 0C6.81886 0 5.17547 0.498516 3.77766 1.43251C2.37984 2.3665 1.29037 3.69402 0.647028 5.24719C0.00368293 6.80036 -0.164645 8.50943 0.163329 10.1583C0.491303 11.8071 1.30085 13.3217 2.4896 14.5104C3.67834 15.6992 5.1929 16.5087 6.84174 16.8367C8.49057 17.1646 10.1996 16.9963 11.7528 16.353C13.306 15.7096 14.6335 14.6202 15.5675 13.2223C16.5015 11.8245 17 10.1811 17 8.5C17 6.24566 16.1045 4.08365 14.5104 2.48959C12.9163 0.895533 10.7543 0 8.5 0ZM7.44459 4.25C7.44459 3.96821 7.55653 3.69796 7.75578 3.4987C7.95504 3.29944 8.22529 3.1875 8.50709 3.1875C8.78888 3.1875 9.05913 3.29944 9.25839 3.4987C9.45764 3.69796 9.56959 3.96821 9.56959 4.25V9.13041C9.56959 9.26994 9.5421 9.40811 9.48871 9.53702C9.43531 9.66593 9.35705 9.78305 9.25839 9.88172C9.15972 9.98038 9.04259 10.0586 8.91369 10.112C8.78478 10.1654 8.64662 10.1929 8.50709 10.1929C8.36756 10.1929 8.22939 10.1654 8.10048 10.112C7.97158 10.0586 7.85445 9.98038 7.75578 9.88172C7.65712 9.78305 7.57886 9.66593 7.52546 9.53702C7.47207 9.40811 7.44459 9.26994 7.44459 9.13041V4.25ZM8.5 13.8125C8.25904 13.8125 8.02349 13.741 7.82313 13.6072C7.62278 13.4733 7.46662 13.283 7.37441 13.0604C7.2822 12.8378 7.25807 12.5928 7.30508 12.3565C7.35209 12.1201 7.46812 11.9031 7.63851 11.7327C7.8089 11.5623 8.02598 11.4463 8.26232 11.3992C8.49865 11.3522 8.74362 11.3764 8.96624 11.4686C9.18886 11.5608 9.37914 11.7169 9.51301 11.9173C9.64688 12.1176 9.71834 12.3532 9.71834 12.5942C9.71834 12.9173 9.58998 13.2272 9.36149 13.4557C9.13301 13.6841 8.82312 13.8125 8.5 13.8125Z"
                                                                    fill="#E41616"
                                                                />
                                                            </svg>
                                                            {/* {data.emailError && data.emailHelperText} */}

                                                            First Name is Required
                                                        </p>
                                                    )
                                                }

                                            </div>

                                            {/* Last Name */}
                                            <div style={{ flex: 1 }}>
                                                <p className="titleTxt">

                                                    LAST NAME
                                                </p>

                                                <Tooltip
                                                    title={(() => {
                                                        return `Enter characters Aa-Zz`
                                                    })()}
                                                >
                                                    <TextField
                                                        placeholder="Last Name"
                                                        className="editable"
                                                        variant="standard"
                                                        value={val.lastName}
                                                        onChange={(e) => {

                                                            handleFieldChange(e, index, "lastName");
                                                        }}
                                                        // sx={{ width: 120 }}
                                                        onInput={handleInput}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment
                                                                    position="start"
                                                                    className="dollar"
                                                                >
                                                                    <img
                                                                        className="pencilIcon"
                                                                        src={PencilIcon}
                                                                        alt=""
                                                                    />
                                                                </InputAdornment>
                                                            ),
                                                            disableUnderline: true,
                                                            inputProps: {
                                                                maxLength: 64, // Set your desired maximum length
                                                            },
                                                        }}
                                                    />
                                                </Tooltip>

                                            </div>

                                        </div>

                                        {/* DOB */}
                                        <div style={{ marginTop: 20, display: 'flex', flex: 1 }}>

                                            {/* Date, Montrh, Year */}
                                            <div style={{ flex: 1, marginRight: 5 }}>
                                                <p className="titleTxt" style={{ marginBottom: 5 }}>

                                                    DOB
                                                </p>

                                                <div style={{ flex: 1, display: 'flex', }}>

                                                    {/* Date */}
                                                    <div style={{ position: 'relative' }}>

                                                        {!val.dayOfBirth && (
                                                            <label
                                                                id={`date-label-${index}`}
                                                                className="dateFormateTxt"
                                                            >
                                                                DD
                                                            </label>
                                                        )}

                                                        <Select
                                                            className="dobTxt"
                                                            labelId={`date-label-${index}`}
                                                            value={val.dayOfBirth}
                                                            style={{ color: 'black' }}
                                                            variant="standard"
                                                            onChange={(e) => {
                                                                handleSelectionChange(e, index, "dayOfBirth");
                                                            }}
                                                            MenuProps={{
                                                                PaperProps: {
                                                                    style: {
                                                                        maxHeight: 200, // Adjust the max height as needed
                                                                    },
                                                                }
                                                            }}
                                                        >
                                                            {days.map((day, index) =>

                                                            (
                                                                <MenuItem key={index} value={index + 1}>
                                                                    {day}
                                                                </MenuItem>
                                                            ))}

                                                        </Select>

                                                    </div>

                                                    {/* Months */}
                                                    <div style={{ position: 'relative' }}>


                                                        {!val.monthOfBirth && (
                                                            <label
                                                                id={`month-label-end-${index}`}
                                                                className="dateFormateTxt"

                                                            >
                                                                MM
                                                            </label>
                                                        )}
                                                        <Select
                                                            className="dobTxt"
                                                            labelId={`month-label-end-${index}`}
                                                            value={val.monthOfBirth}
                                                            variant="standard"
                                                            onChange={(e) => {
                                                                handleSelectionChange(e, index, "monthOfBirth");
                                                            }}
                                                            MenuProps={{
                                                                PaperProps: {
                                                                    style: {
                                                                        maxHeight: 200, // Adjust the max height as needed
                                                                    },
                                                                }
                                                            }}
                                                        >
                                                            {monthNames.map((month, index) => (
                                                                <MenuItem key={index} value={index + 1}>
                                                                    {month}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>

                                                    </div>

                                                    {/* Year */}
                                                    <div style={{ position: 'relative' }}>

                                                        {!val.yearOfBirth && (
                                                            <label
                                                                id={`month-label-end-${index}`}
                                                                className="dateFormateTxt"

                                                            >
                                                                YYYY
                                                            </label>
                                                        )}
                                                        <Select
                                                            className="dobTxt"
                                                            labelId={`month-label-end-${index}`}
                                                            value={val.yearOfBirth}
                                                            sx={{ width: 51 }}
                                                            variant="standard"
                                                            onChange={(e) => {
                                                                handleSelectionChange(e, index, "yearOfBirth");
                                                            }}
                                                            MenuProps={{
                                                                PaperProps: {
                                                                    style: {
                                                                        maxHeight: 200, // Adjust the max height as needed
                                                                    },
                                                                }
                                                            }}
                                                        >
                                                            {years.map((year, index) => (
                                                                <MenuItem key={index} value={year}>
                                                                    {year}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>

                                                    </div>

                                                </div>

                                            </div>

                                            {/* RELATIONSHIP */}
                                            <div style={{ flex: 1, }}>
                                                <p className="titleTxt">

                                                    RELATIONSHIP
                                                </p>

                                                <RelationshipSelection fontSize={'var(--fontSize11)'} width={width / 3.1} eachData={val} index={index} relationShipCheck={(val) => {

                                                    relationShipCheck(val, index)
                                                }} />

                                            </div>

                                        </div>



                                        {/* ZIP CODE AND PHONE */}
                                        <div style={{ display: 'flex', flex: 1, marginTop: 20, marginBottom: 20 }}>

                                            {/* ZIP CODE */}
                                            <div style={{ flex: 1, marginRight: 20 }}>
                                                <p className="titleTxt">

                                                    ZIPCODE
                                                </p>
                                                <Tooltip
                                                    title={(() => {
                                                        return `Enter numbers 0-9`
                                                    })()}
                                                >
                                                    <TextField
                                                        placeholder="Zip Code"
                                                        className="editable"
                                                        variant="standard"
                                                        value={val.zipCode}
                                                        onChange={(e) => {

                                                            handleFieldChange(e, index, "zipCode");
                                                        }}
                                                        sx={{ width: 120 }}
                                                        onInput={handleNumericInput}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment
                                                                    position="start"
                                                                    className="dollar"
                                                                >
                                                                    <img
                                                                        className="pencilIcon"
                                                                        src={PencilIcon}
                                                                        alt=""
                                                                    />
                                                                </InputAdornment>
                                                            ),
                                                            disableUnderline: true,
                                                            inputProps: {
                                                                maxLength: 5
                                                            }
                                                        }}
                                                    />
                                                </Tooltip>

                                                {
                                                    val.zipCode == '' && (
                                                        <p className="error-custom" style={{ fontSize: 'var(--fontSize12)' }}>

                                                            <svg
                                                                width="17"
                                                                height="17"
                                                                viewBox="0 0 17 17"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M8.5 0C6.81886 0 5.17547 0.498516 3.77766 1.43251C2.37984 2.3665 1.29037 3.69402 0.647028 5.24719C0.00368293 6.80036 -0.164645 8.50943 0.163329 10.1583C0.491303 11.8071 1.30085 13.3217 2.4896 14.5104C3.67834 15.6992 5.1929 16.5087 6.84174 16.8367C8.49057 17.1646 10.1996 16.9963 11.7528 16.353C13.306 15.7096 14.6335 14.6202 15.5675 13.2223C16.5015 11.8245 17 10.1811 17 8.5C17 6.24566 16.1045 4.08365 14.5104 2.48959C12.9163 0.895533 10.7543 0 8.5 0ZM7.44459 4.25C7.44459 3.96821 7.55653 3.69796 7.75578 3.4987C7.95504 3.29944 8.22529 3.1875 8.50709 3.1875C8.78888 3.1875 9.05913 3.29944 9.25839 3.4987C9.45764 3.69796 9.56959 3.96821 9.56959 4.25V9.13041C9.56959 9.26994 9.5421 9.40811 9.48871 9.53702C9.43531 9.66593 9.35705 9.78305 9.25839 9.88172C9.15972 9.98038 9.04259 10.0586 8.91369 10.112C8.78478 10.1654 8.64662 10.1929 8.50709 10.1929C8.36756 10.1929 8.22939 10.1654 8.10048 10.112C7.97158 10.0586 7.85445 9.98038 7.75578 9.88172C7.65712 9.78305 7.57886 9.66593 7.52546 9.53702C7.47207 9.40811 7.44459 9.26994 7.44459 9.13041V4.25ZM8.5 13.8125C8.25904 13.8125 8.02349 13.741 7.82313 13.6072C7.62278 13.4733 7.46662 13.283 7.37441 13.0604C7.2822 12.8378 7.25807 12.5928 7.30508 12.3565C7.35209 12.1201 7.46812 11.9031 7.63851 11.7327C7.8089 11.5623 8.02598 11.4463 8.26232 11.3992C8.49865 11.3522 8.74362 11.3764 8.96624 11.4686C9.18886 11.5608 9.37914 11.7169 9.51301 11.9173C9.64688 12.1176 9.71834 12.3532 9.71834 12.5942C9.71834 12.9173 9.58998 13.2272 9.36149 13.4557C9.13301 13.6841 8.82312 13.8125 8.5 13.8125Z"
                                                                    fill="#E41616"
                                                                />
                                                            </svg>
                                                            {/* {data.emailError && data.emailHelperText} */}

                                                            Zipcode is Required
                                                        </p>
                                                    )
                                                }

                                                {
                                                    val.zipCode != '' && val.zipCode.length < 5 && (
                                                        <p className="error-custom" style={{ fontSize: 'var(--fontSize12)' }}>

                                                            <svg
                                                                width="17"
                                                                height="17"
                                                                viewBox="0 0 17 17"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M8.5 0C6.81886 0 5.17547 0.498516 3.77766 1.43251C2.37984 2.3665 1.29037 3.69402 0.647028 5.24719C0.00368293 6.80036 -0.164645 8.50943 0.163329 10.1583C0.491303 11.8071 1.30085 13.3217 2.4896 14.5104C3.67834 15.6992 5.1929 16.5087 6.84174 16.8367C8.49057 17.1646 10.1996 16.9963 11.7528 16.353C13.306 15.7096 14.6335 14.6202 15.5675 13.2223C16.5015 11.8245 17 10.1811 17 8.5C17 6.24566 16.1045 4.08365 14.5104 2.48959C12.9163 0.895533 10.7543 0 8.5 0ZM7.44459 4.25C7.44459 3.96821 7.55653 3.69796 7.75578 3.4987C7.95504 3.29944 8.22529 3.1875 8.50709 3.1875C8.78888 3.1875 9.05913 3.29944 9.25839 3.4987C9.45764 3.69796 9.56959 3.96821 9.56959 4.25V9.13041C9.56959 9.26994 9.5421 9.40811 9.48871 9.53702C9.43531 9.66593 9.35705 9.78305 9.25839 9.88172C9.15972 9.98038 9.04259 10.0586 8.91369 10.112C8.78478 10.1654 8.64662 10.1929 8.50709 10.1929C8.36756 10.1929 8.22939 10.1654 8.10048 10.112C7.97158 10.0586 7.85445 9.98038 7.75578 9.88172C7.65712 9.78305 7.57886 9.66593 7.52546 9.53702C7.47207 9.40811 7.44459 9.26994 7.44459 9.13041V4.25ZM8.5 13.8125C8.25904 13.8125 8.02349 13.741 7.82313 13.6072C7.62278 13.4733 7.46662 13.283 7.37441 13.0604C7.2822 12.8378 7.25807 12.5928 7.30508 12.3565C7.35209 12.1201 7.46812 11.9031 7.63851 11.7327C7.8089 11.5623 8.02598 11.4463 8.26232 11.3992C8.49865 11.3522 8.74362 11.3764 8.96624 11.4686C9.18886 11.5608 9.37914 11.7169 9.51301 11.9173C9.64688 12.1176 9.71834 12.3532 9.71834 12.5942C9.71834 12.9173 9.58998 13.2272 9.36149 13.4557C9.13301 13.6841 8.82312 13.8125 8.5 13.8125Z"
                                                                    fill="#E41616"
                                                                />
                                                            </svg>
                                                            {/* {data.emailError && data.emailHelperText} */}

                                                            Enter 5 digits for zipcode
                                                        </p>
                                                    )
                                                }

                                            </div>

                                            {/* PHONE */}
                                            <div style={{ flex: 1 }}>
                                                <p className="textInput">
                                                    PHONE
                                                </p>

                                                <TextField
                                                    placeholder="Phone"
                                                    className="editable"
                                                    variant="standard"
                                                    value={val.phoneNumber}
                                                    onInput={handleNumericInput}
                                                    onChange={(e) => {

                                                        handleFieldChange(e, index, "phoneNumber");
                                                    }}

                                                    // sx={{ width: 120 }}
                                                    InputProps={{
                                                        // inputComponent: NumericFormatCustom,
                                                        endAdornment: (
                                                            <InputAdornment
                                                                position="start"
                                                                className="dollar"
                                                            >
                                                                <img
                                                                    className="pencilIcon"
                                                                    src={PencilIcon}
                                                                    alt=""
                                                                />
                                                            </InputAdornment>
                                                        ),
                                                        disableUnderline: true,
                                                        inputProps: {
                                                            maxLength: 10
                                                        }
                                                    }}
                                                />

                                                {
                                                    val.phoneNumber != '' && val.phoneNumber.length < 10 && (
                                                        <p className="error-custom" style={{ fontSize: 'var(--fontSize12)' }}>
                                                            <svg
                                                                width="17"
                                                                height="17"
                                                                viewBox="0 0 17 17"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M8.5 0C6.81886 0 5.17547 0.498516 3.77766 1.43251C2.37984 2.3665 1.29037 3.69402 0.647028 5.24719C0.00368293 6.80036 -0.164645 8.50943 0.163329 10.1583C0.491303 11.8071 1.30085 13.3217 2.4896 14.5104C3.67834 15.6992 5.1929 16.5087 6.84174 16.8367C8.49057 17.1646 10.1996 16.9963 11.7528 16.353C13.306 15.7096 14.6335 14.6202 15.5675 13.2223C16.5015 11.8245 17 10.1811 17 8.5C17 6.24566 16.1045 4.08365 14.5104 2.48959C12.9163 0.895533 10.7543 0 8.5 0ZM7.44459 4.25C7.44459 3.96821 7.55653 3.69796 7.75578 3.4987C7.95504 3.29944 8.22529 3.1875 8.50709 3.1875C8.78888 3.1875 9.05913 3.29944 9.25839 3.4987C9.45764 3.69796 9.56959 3.96821 9.56959 4.25V9.13041C9.56959 9.26994 9.5421 9.40811 9.48871 9.53702C9.43531 9.66593 9.35705 9.78305 9.25839 9.88172C9.15972 9.98038 9.04259 10.0586 8.91369 10.112C8.78478 10.1654 8.64662 10.1929 8.50709 10.1929C8.36756 10.1929 8.22939 10.1654 8.10048 10.112C7.97158 10.0586 7.85445 9.98038 7.75578 9.88172C7.65712 9.78305 7.57886 9.66593 7.52546 9.53702C7.47207 9.40811 7.44459 9.26994 7.44459 9.13041V4.25ZM8.5 13.8125C8.25904 13.8125 8.02349 13.741 7.82313 13.6072C7.62278 13.4733 7.46662 13.283 7.37441 13.0604C7.2822 12.8378 7.25807 12.5928 7.30508 12.3565C7.35209 12.1201 7.46812 11.9031 7.63851 11.7327C7.8089 11.5623 8.02598 11.4463 8.26232 11.3992C8.49865 11.3522 8.74362 11.3764 8.96624 11.4686C9.18886 11.5608 9.37914 11.7169 9.51301 11.9173C9.64688 12.1176 9.71834 12.3532 9.71834 12.5942C9.71834 12.9173 9.58998 13.2272 9.36149 13.4557C9.13301 13.6841 8.82312 13.8125 8.5 13.8125Z"
                                                                    fill="#E41616"
                                                                />
                                                            </svg>
                                                            {/* {data.emailError && data.emailHelperText} */}

                                                            Enter 10 digits for phone Number
                                                        </p>
                                                    )
                                                }

                                            </div>

                                        </div>

                                    </AccordionDetails>
                                </Accordion>
                            ))}

                        </div>
                    </div>

                    <Dialog open={cancelModal} onClick={() => setCancelModal(false)} >
                        <DialogTitle>{"Confirm"}</DialogTitle>
                        <DialogContent>
                            <p className="text">Are you sure you want to cancel? </p>
                        </DialogContent>
                        <DialogActions>
                            <ButtonContained size='large'
                                sx={{
                                    height: '50px',
                                    width: '100%',
                                    backgroundColor: '#979797 !important',
                                    borderRadius: '5px',
                                    fontFamily: 'Nunito',
                                    fontStyle: 'normal',
                                    fontWeight: 'bold',
                                    fontSize: "var(--fontSize14)",
                                    textAlign: 'center',
                                    color: 'var(--colorWhite)',
                                    marginRight: '10px'
                                }} onClick={() => { setState(false); setShowModal(false) }} >
                                exit without save
                            </ButtonContained>
                            <ButtonContained size='large'
                                sx={{
                                    height: '50px',
                                    width: '100%',
                                    background: '#21CAE7 !important',
                                    borderRadius: '5px',
                                    fontFamily: 'Nunito',
                                    fontStyle: 'normal',
                                    fontWeight: 'bold',
                                    fontSize: "var(--fontSize14)",
                                    textAlign: 'center',
                                    color: 'var(--colorBlack)',

                                }} onClick={() => {
                                    // setState(false);
                                    validation(true)

                                }} >
                                save changes and exit
                            </ButtonContained>
                        </DialogActions>
                    </Dialog>

                    <Box display={'flex'} sx={{ marginTop: 2 }} flexDirection={'row'}>
                        <ButtonContained
                            size='large'
                            sx={{
                                height: '50px',
                                width: '100%',
                                backgroundColor: '#979797 !important',
                                borderRadius: '5px',
                                fontFamily: 'Nunito',
                                fontStyle: 'normal',
                                fontWeight: 'bold',
                                fontSize: "var(--fontSize14)",
                                textAlign: 'center',
                                color: 'var(--colorWhite)',
                                marginRight: '10px'
                            }}
                            onClick={() => {
                                if (showModal) {
                                    setCancelModal(true)
                                }
                                else {
                                    setState(false)
                                }
                            }}
                        >
                            Cancel
                        </ButtonContained>

                        <ButtonContained
                            size='large'
                            sx={{
                                height: '50px',
                                width: '100%',
                                background: '#21CAE7 !important',
                                borderRadius: '5px',
                                fontFamily: 'Nunito',
                                fontStyle: 'normal',
                                fontWeight: 'bold',
                                fontSize: "var(--fontSize14)",
                                textAlign: 'center',
                                color: 'var(--colorBlack)',

                            }}
                            onClick={() => {
                                if (showModal || addNewModal) {
                                    validation(false)
                                }
                            }}
                        >
                            Save
                        </ButtonContained>
                    </Box>



                </div>
            </Box >
        );
    };

    return (
        <Box>
            <ListItem
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginY: "10px",
                }}
                onClick={(e) => {
                    toggleDrawer(true, true)(e);
                    fnsHousehold.getHouseholdList()
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <PersonAddAltOutlinedIcon sx={{ color: "#0C89EF", marginRight: "5px" }} />{' '}
                    <strong>Household</strong>
                </Box>

                <ArrowForwardIosSharp fontSize="10px" />
            </ListItem>

            <Drawer
                variant={"persistent"}
                anchor={"right"}
                open={state}
                onClose={toggleDrawer(false, false)}
                transitionDuration={openDrawer ? 0 : undefined}
                PaperProps={{
                    sx: {
                        width: "100%",
                        backgroundColor: "#EAF9F6",
                        overflow: 'hidden'
                    },
                }}
            >
                {/* <Box
                    sx={{
                        width: "100%",
                        color: "black",
                        backgroundColor: "#EAF9F6",
                    }}
                    role="presentation"
                    display={"flex"}
                    justifyContent={"space-between"}
                    paddingTop={1}
                    alignItems={"center"}
                >
                    <ArrowBack
                        sx={{
                            fontSize: 30,
                            margin: 1,
                            marginLeft: 2,
                            cursor: "pointer",
                        }}
                        onClick={toggleDrawer(false, true)}
                    />

                    <strong
                        style={{
                            fontFamily: "Nunito",
                            fontSize: 24,
                            textAlign: "center",
                        }}
                    >
                        Household
                    </strong>

                    <ArrowBack sx={{ fontSize: 30, margin: 1, visibility: "hidden" }} />
                </Box> */}
                {list("right")}
            </Drawer>

            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={isSavehousehold}
                autoHideDuration={3000}
                onClose={() => setIsSavehousehold(0)}
            >
                <Alert
                    onClose={() => setIsSavehousehold(0)}
                    severity={isSavehousehold == 1 ? "success" : "error"}
                    sx={{ width: "100%" }}
                >
                    {isSavehousehold == 1
                        ? "Success !"
                        : isSavehousehold == -1
                            ? "Error !"
                            : ""}
                </Alert>
            </Snackbar>


        </Box>
    );
}

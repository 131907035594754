import React, { useRef, useState } from "react";
import KamunityLogo from "../../assets/images/kamunity-mob.png";
import CommentLogo from "../../assets/images/reviewLogo.svg";
import ArrowDropdown from "../../assets/images/arrow-down.svg";
import ArrowDropdownActive from "../../assets/images/arrow-up-active.svg";
import CommentFilled from "../../assets/images/reviewTrueLogo.svg";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  atm_showInnerChildL5ReplyIndex,
  atm_transactionInnerChildL5Reviews,
} from "src/state/atomsTransactionData";
import useTransactionsLanding from "./useTransactionsLanding";
import { dateFormateReviews } from "src/global/central";
import { Box, LinearProgress } from "@mui/material";

const InnerChildLevel5 = ({ data, index, innerParentId }) => {
  const { dataTransactions, fnsTransactions } = useTransactionsLanding();

  const elemTextarea = useRef(null);

  const [isChildShowReplyClicked, setIsChildShowReplyClicked] = useState(false);

  const [childShowReplyIndex, setChildShowReplyIndex] = useRecoilState(
    atm_showInnerChildL5ReplyIndex
  );

  const [isOnlyViewAllClicked, setIsOnlyViewAllClicked] = useState(false);

  const [isReplyBoxOpen, setIsReplyBoxOpen] = useState(false);

  const setInnerChildL5Review = useSetRecoilState(
    atm_transactionInnerChildL5Reviews
  );

  // show replies
  const handleShowChildReplies = (i, isViewAll) => {
    if (data.immediateChildReviewCount !== 0 || isViewAll) {
      if (isViewAll) {
        setInnerChildL5Review([]);
      }

      fnsTransactions.getInnerChildReviews(
        data,
        data.reviewId,
        innerParentId,
        5
      );
    }

    if (data.immediateChildReviewCount === 0) {
      setInnerChildL5Review([]);
    }

    if (isViewAll !== "isViewAll") {
      setIsChildShowReplyClicked(true);
    }
  };

  const [childTextValue, setChildTextValue] = useState("");

  const setInnerChildReviewsL5Data = useSetRecoilState(
    atm_transactionInnerChildL5Reviews
  );

  const setInnerChild5ShowReplyIndex = useSetRecoilState(
    atm_showInnerChildL5ReplyIndex
  );

  const handleCloseChildReview = () => {
    setIsChildShowReplyClicked(!isChildShowReplyClicked);

    setIsReplyBoxOpen(false);

    setIsOnlyViewAllClicked(false);

    setInnerChild5ShowReplyIndex(null);

    setInnerChildReviewsL5Data([]);
  };

  // only view all
  const handleViewAllRepliesOnly = (i) => {
    if (data.immediateChildReviewCount > 0) {
      setIsOnlyViewAllClicked(true);
      handleShowChildReplies(i, "isViewAll");
      setIsReplyBoxOpen(true); // Open the reply box
    }
    if (isReplyBoxOpen && childShowReplyIndex === i) {
      handleCloseChildReview();
    }
  };

  const handleChildReviewSubmit = () => {
    fnsTransactions.addInnerChildReview(
      data,
      data.reviewId,
      childTextValue,
      innerParentId,
      5
    );
    setIsOnlyViewAllClicked(true);
    elemTextarea.current.value = "";
  };

  return (
    <>
      <div className="comments-btns-cover">
        <div
          onClick={() => {
            setIsOnlyViewAllClicked(false);
            setChildShowReplyIndex(index);
            handleShowChildReplies(index);
          }}
          className="show-comments"
        >
          <img
            src={
              data.immediateChildReviewCount !== 0 ? CommentFilled : CommentLogo
            }
            alt=""
          />
          <span
            className={data.immediateChildReviewCount !== 0 ? "" : "border"}
          >
            Reply
          </span>
        </div>
        {data.immediateChildReviewCount !== 0 && (
          <div
            onClick={() => {
              setChildShowReplyIndex(index);
              handleViewAllRepliesOnly(index);
            }}
            className="view-all-child"
          >
            <img
              src={
                isOnlyViewAllClicked && childShowReplyIndex === index
                  ? ArrowDropdownActive
                  : ArrowDropdown
              }
              alt=""
            />
            <span>View all {data.immediateChildReviewCount} replies</span>
          </div>
        )}
      </div>

      {/* All Inner Child Replies   */}

      {childShowReplyIndex === index && (
        // inner child review
        <div className="all-replies">
          {dataTransactions.reviewsLoading.innerChildLevel5 && (
            <Box sx={{ width: "100%" }}>
              <LinearProgress
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                }}
              />
            </Box>
          )}
          <div className="type-section">
            {/* <header>
              <div className="title">All Replies</div>
              <img
                onClick={handleCloseChildReview}
                className="close-btn"
                src={CloseIcon}
                alt=""
              />
            </header> */}
            {!isOnlyViewAllClicked && (
              <>
                <div className="textarea-cover">
                  <div className="legend">Add Reply</div>
                  <textarea
                    ref={elemTextarea}
                    onChange={(e) => setChildTextValue(e.target.value)}
                  ></textarea>
                </div>
                <button
                  onClick={handleChildReviewSubmit}
                  className="reply-submit-btn btn"
                >
                  Submit
                </button>
              </>
            )}
          </div>

          <ul className="child-reviews-box replies-section">
            {dataTransactions.transactionInnerChildL5Reviews.map(
              (childReview, i) => {
                return (
                  <li key={i} className="child-review">
                    <div className="row1">
                      <div className="img-name">
                        <img src={KamunityLogo} alt="user" />
                        <div className="username-date">
                          <div className="username">{childReview.userName}</div>
                          <div className="date-time">
                            {dateFormateReviews(childReview.updateDate)}
                          </div>
                        </div>
                      </div>
                    </div>
                    <p>{childReview.review}</p>
                  </li>
                );
              }
            )}
          </ul>
        </div>
      )}
    </>
  );
};

export default InnerChildLevel5;

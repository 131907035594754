import { Box, LinearProgress, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import ForgotPasswordCode from "./ForgotPasswordCode";
import { ValidateEmail } from "src/validations/Email";
import ErrorIcon from "../assets/images/error.svg";
import useForgotPassword from "./useForgotPassword";
import { useRecoilState } from "recoil";
import {
  atm_isCodeSent,
  atm_resetEmail,
} from "src/state/atomForgotPassword";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const { dataPassword, fnsPassword } = useForgotPassword();
  const [isCodeSent, setIsCodeSent] = useRecoilState(atm_isCodeSent);
  const [email, setEmail] = useRecoilState(atm_resetEmail);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const handleForgotPassword = (e) => {
    e.preventDefault();
    if (!ValidateEmail(email)) {
      setError(true);
      return;
    }
    fnsPassword.sendResetPasswordMail(email);
  };

  useEffect(() => {
    return () => {
      setIsCodeSent(false);
    };
  }, []);
  return (
    <div className="forgotPassBox passBox">
      {dataPassword.passwordLoading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
            }}
          />
        </Box>
      )}
      {!isCodeSent ? (
        <form onSubmit={handleForgotPassword}>
          <div className="head">Enter your e-mail address</div>
          <div className="subHead">
            Please enter your email address associated with Kamunity and we’ll
            send you a code to reset your password
          </div>
          <TextField
            label="Email address"
            className="emailInput "
            onChange={(e) => {
              setEmail(e.target.value);
              setError(false);
            }}
            sx={{
              width: "100%",
              marginTop: "14px",
            }}
            error={error}
          />
          <p
            className="error-custom grid-2"
            style={{
              margin: "10px 0px",
              opacity: error ? 1 : 0,
              textAlign: "left",
            }}
          >
            <span>
              <img src={ErrorIcon} style={{ marginRight: 5 }} alt="error" />
              Invalid Email address format
            </span>
          </p>
          <button
            disabled={dataPassword.passwordLoading}
            type="submit"
            className="continueBtn btn"
          >
            Continue
          </button>
        </form>
      ) : (
        <ForgotPasswordCode email={email} />
      )}
      <p className="dontText">
        Don't have an account ?{" "}
        <span onClick={() => navigate("/signup")}>Sign up</span>
      </p>
    </div>
  );
};

export default ForgotPassword;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import LegalsModal from "./LegalsModal";

const Legals = () => {
  const [isModal, setIsModal] = useState(false);
  const [modalInfo, setModalInfo] = useState({ link: "", title: "" });

  const handleModal = (e) => {
    e.preventDefault();
    let link = e.target.href;
    let title = e.target.title;
    setModalInfo({ link, title });
    setIsModal(true);
  };

  return (
    <>
      <div className="legals-row">
        <div className="legals-content">
          <Link
            onClick={handleModal}
            title="terms"
            target="_blank"
            to="https://app.termly.io/document/terms-of-service/0da5d65f-5a2d-4425-9e15-cd6e6a20bf68"
          >
            Terms & Conditions
          </Link>
          <Link
            onClick={handleModal}
            title="privacy"
            target="_blank"
            to="https://app.termly.io/document/privacy-policy/71e89487-cfbd-46c1-be56-3ec32d89d92f"
          >
            Privacy Policy
          </Link>
          <Link
            onClick={handleModal}
            title="cookie"
            target="_blank"
            to="https://app.termly.io/document/cookie-policy/1c6fa428-c368-4247-aaee-4f785cb6b8ac"
          >
            Cookie Policy
          </Link>

          <Link
            onClick={handleModal}
            title="acceptable"
            target="_blank"
            to="https://app.termly.io/document/acceptable-use/5e675ebf-ce69-4322-b7e4-c0840446e50d"
          >
            Acceptable Use Policy
          </Link>
          <Link
            // onClick={handleModal}
            title="acceptable"
            // target="_blank"
            to="#"
          >
            Consent Preferences
          </Link>
          {/* <a href="#" className="termly-display-preferences">Consent Preferences</a> */}
          <Link
            onClick={handleModal}
            title="disclamer"
            target="_blank"
            to="https://app.termly.io/document/disclaimer/87c5ae8c-8b42-4b9f-b0d3-50b743db1bd3"
          >
            Disclaimer
          </Link>
          <Link
            onClick={handleModal}
            title="do_not_share_personal_information"
            target="_blank"
            to="https://app.termly.io/notify/71e89487-cfbd-46c1-be56-3ec32d89d92f"
          >
            Do Not Sell or Share My Personal Information
          </Link>
          <Link
            onClick={handleModal}
            title="limit_sensitive_information"
            target="_blank"
            to="https://app.termly.io/notify/71e89487-cfbd-46c1-be56-3ec32d89d92f"
          >
            Limit The Use Of My Sensitive Personal Information
          </Link>
        </div>
      </div>
      <LegalsModal
        isOpen={isModal}
        setIsOpen={setIsModal}
        title={modalInfo.title}
        link={modalInfo.link}
      />
    </>
  );
};

export default Legals;

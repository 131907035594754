import React, { useRef, useState } from "react";
import KamunityLogo from "../../assets/images/kamunity-mob.png";
import CommentLogo from "../../assets/images/reviewLogo.svg";
import ArrowDropdown from "../../assets/images/arrow-down.svg";
import ArrowDropdownActive from "../../assets/images/arrow-up-active.svg";
import CommentFilled from "../../assets/images/reviewTrueLogo.svg";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  atm_childReviewTextValue,
  atm_innerChildReviewTextValue,
  atm_isChildShowReplyClicked,
  atm_isInnerChildShowReplyClicked,
  atm_onlyChildViewAllClicked,
  atm_onlyInnerChildViewAllClicked,
  atm_showChildReplyIndex,
  atm_showInnerChildL4ReplyIndex,
  atm_showInnerChildL5ReplyIndex,
  atm_showInnerChildReplyIndex,
  atm_transactionChildReviews,
} from "src/state/atomsTransactionData";
import useTransactionsLanding from "./useTransactionsLanding";
import { dateFormateReviews } from "src/global/central";
import InnerChildReview from "./InnerChildReview";
import { Box, LinearProgress } from "@mui/material";

const ChildReview = ({ data, index }) => {
  const { dataTransactions, fnsTransactions } = useTransactionsLanding();

  const elemTextarea = useRef(null);

  const [isChildShowReplyClicked, setIsChildShowReplyClicked] = useRecoilState(
    atm_isChildShowReplyClicked
  );

  const [childShowReplyIndex, setChildShowReplyIndex] = useRecoilState(
    atm_showChildReplyIndex
  );

  const [isOnlyViewAllClicked, setIsOnlyViewAllClicked] = useRecoilState(
    atm_onlyChildViewAllClicked
  );

  const [isReplyBoxOpen, setIsReplyBoxOpen] = useState(false);

  const setChildReview = useSetRecoilState(atm_transactionChildReviews);

  // show replies
  const handleShowChildReplies = (i, isViewAll) => {
    if (data.immediateChildReviewCount !== 0 || isViewAll) {
      if (isViewAll) {
        setChildReview([]);
      }

      fnsTransactions.getChildReviews(data, data.reviewId);
    }

    if (data.immediateChildReviewCount === 0) {
      setChildReview([]);
    }

    if (isViewAll !== "isViewAll") {
      setIsChildShowReplyClicked(true);
    }
  };

  const [childTextValue, setChildTextValue] = useRecoilState(
    atm_childReviewTextValue
  );

  const setInnerChildShowReplyClicked = useSetRecoilState(
    atm_isInnerChildShowReplyClicked
  );
  const setInnerChildViewAllClicked = useSetRecoilState(
    atm_onlyInnerChildViewAllClicked
  );

  const setInnerChildTextValue = useSetRecoilState(
    atm_innerChildReviewTextValue
  );

  const setInnerChildShowReplyIndex = useSetRecoilState(
    atm_showInnerChildReplyIndex
  );
  const setInnerChild4ShowReplyIndex = useSetRecoilState(
    atm_showInnerChildL4ReplyIndex
  );
  const setInnerChild5ShowReplyIndex = useSetRecoilState(
    atm_showInnerChildL5ReplyIndex
  );

  const handleCloseChildReview = () => {
    setIsChildShowReplyClicked(!isChildShowReplyClicked);
    setInnerChildShowReplyClicked(false);

    setIsReplyBoxOpen(false);

    setIsOnlyViewAllClicked(false);
    setInnerChildViewAllClicked(false);

    setChildTextValue("");
    setInnerChildTextValue("");

    setChildShowReplyIndex(null);
    setInnerChildShowReplyIndex(null);
    setInnerChild4ShowReplyIndex(null);
    setInnerChild5ShowReplyIndex(null);

    fnsTransactions.onCloseChildReview();
  };

  // only view all
  const handleViewAllRepliesOnly = (i) => {
    if (data.immediateChildReviewCount > 0) {
      setIsOnlyViewAllClicked(true);
      handleShowChildReplies(i, "isViewAll");
      setIsReplyBoxOpen(true); // Open the reply box
    }
    if (isReplyBoxOpen && childShowReplyIndex === index) {
      handleCloseChildReview();
    }
  };

  const handleChildReviewSubmit = () => {
    fnsTransactions.addChildReview(data, data.reviewId, childTextValue);
    setIsOnlyViewAllClicked(true);
    elemTextarea.current.value = "";
  };
  return (
    <>
      <div className="parent-review outer">
        {/* parent review  */}
        <div className="row1">
          <div className="img-name">
            <img src={KamunityLogo} className="kamunity-logo" alt="user" />
            <div className="username-date">
              <div className="username">{data.userName}</div>
              <div className="date-time">
                {dateFormateReviews(data.updateDate)}
              </div>
            </div>
          </div>
        </div>
        <p>{data.review}</p>
        <div className="comments-btns-cover">
          <div
            onClick={() => {
              setIsOnlyViewAllClicked(false);
              setChildShowReplyIndex(index);
              handleShowChildReplies(index);
            }}
            className="show-comments"
          >
            <img
              src={
                data.immediateChildReviewCount !== 0
                  ? CommentFilled
                  : CommentLogo
              }
              alt=""
            />
            <span
              className={data.immediateChildReviewCount !== 0 ? "" : "border"}
            >
              Reply
            </span>
          </div>
          {data.immediateChildReviewCount !== 0 && (
            <div
              onClick={() => {
                setChildShowReplyIndex(index);
                handleViewAllRepliesOnly(index);
              }}
              className="view-all-child"
            >
              <img
                src={
                  isOnlyViewAllClicked && childShowReplyIndex === index
                    ? ArrowDropdownActive
                    : ArrowDropdown
                }
                alt=""
              />
              <span>View all {data.immediateChildReviewCount} replies</span>
            </div>
          )}
        </div>
      </div>

      {/* All Replies   */}

      {childShowReplyIndex === index && (
        // child review
        <div className="all-replies">
          {dataTransactions.reviewsLoading.childReview && (
            <Box sx={{ width: "100%" }}>
              <LinearProgress
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                }}
              />
            </Box>
          )}
          <div className="type-section">
            {/* <header>
              <div className="title">All Replies</div>
              <img
                onClick={handleCloseChildReview}
                className="close-btn"
                src={CloseIcon}
                alt=""
              />
            </header> */}
            {!isOnlyViewAllClicked && (
              <>
                <div className="textarea-cover">
                  <div className="legend">Add Reply</div>
                  <textarea
                    ref={elemTextarea}
                    onChange={(e) => setChildTextValue(e.target.value)}
                  ></textarea>
                </div>
                <button
                  onClick={handleChildReviewSubmit}
                  className="reply-submit-btn btn"
                >
                  Submit
                </button>
              </>
            )}
          </div>

          <ul className="child-reviews-box replies-section">
            {dataTransactions.transactionChildReviews.map((childReview, i) => {
              return (
                <li key={i} className="child-review">
                  <div className="row1">
                    <div className="img-name">
                      <img
                        src={KamunityLogo}
                        className="kamunity-logo"
                        alt="user"
                      />
                      <div className="username-date">
                        <div className="username">{childReview.userName}</div>
                        <div className="date-time">
                          {dateFormateReviews(childReview.updateDate)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <p>{childReview.review}</p>

                  {/* inner child starts here  */}

                  <InnerChildReview
                    innerParentId={data.reviewId}
                    data={childReview}
                    index={i}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </>
  );
};

export default ChildReview;

import { atom, selector } from "recoil";
import { usdFormate } from "src/global/central";

export const atm_clmnSecuritiesByUser = atom({
    key: "clmnSecuritiesByUser",
    default: [
        { field: 'Ticker', headerName: 'Ticker', width: 200 },
        { field: 'Name', headerName: 'Name', width: 350 },
        { field: 'Quantity', headerName: 'Quantity', width: 150, align: `right` },
        { field: 'costBasis', headerName: 'Cost Basis', width: 150, align: `right` },
        { field: 'totalValue', headerName: 'Current Value', width: 150, align: `right` },
        { field: 'percentage', headerName: 'Percentage', width: 150, align: `right` },

    ],
}
);


export const atm_lstSecuritiesByUser = atom({
    key: "lstSecuritiesByUser",
    default: [],
}
);

export const atm_isSecuritiesByUserListLoading = atom({
    key: "isSecuritiesByUserListLoading",
    default: false,
}
);

export const atm_IsMobHoldingsFilter = atom({
    key: "isMobFilter",
    default: false,
}
);

export const atm_HoldingsChartStats = atom({
    key: "holdingsChartStats",
    default: [],
}

);
export const atm_HoldingsChartLoading = atom({
    key: "holdingsChartLoading",
    default: false,
}
);

export const atm_SelectedSecurityDetails = atom({
    key: "selectedSecurityDetails",
    default: [],
}
);

export const atm_SelectedSecurityDetailsLoading = atom({
    key: "selectedSecurityDetailsLoading",
    default: false,
}
);

export const atm_SelectedHoldingsDateFilter = atom({
    key: "selectedHoldingsDateFilter",
    default: 12,
}
);


// convert securityByUser list into table format
export const atm_tblSecuritiesByUser = selector({
    key: 'tblSecuritiesByUser',
    get: ({ get }) => {
        var securitiesByUserTable = [];
        get(atm_lstSecuritiesByUser).forEach((securityByUser) => {
            securitiesByUserTable.push({
                "id": securityByUser.securityId,
                "Ticker": securityByUser.securityTicker,
                "Name": securityByUser.securityName,
                "Quantity": securityByUser.totalQuantity.toFixed(3).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
                "costBasis": usdFormate(securityByUser.costBasis),
                "totalValue": securityByUser.totalValue != null ? usdFormate(securityByUser.totalValue) : " - ",
                "unformattedTotalValue": securityByUser.totalValue,
                "percentage": (securityByUser.holdingPercentage / 100).toLocaleString("en-US", { style: "percent", minimumFractionDigits: 2 }),
            });
        });
        return securitiesByUserTable;
    },
});
import React from "react";
import KamunityLogo from "../assets/images/Kamunity_Logo_404.png";
import FacebookLogo from "../assets/images/facebook404.svg";
import TwitterLogo from "../assets/images/twitter404.svg";
import LinkdinLogo from "../assets/images/linkedin404.svg";
import InstagramLogo from "../assets/images/instagram404.svg";
import useMainMenu from "../MainMenu/useMainMenu";

const ErrorFooter = () => {
  const { fns } = useMainMenu();
  return (
    <footer>
      <img src={KamunityLogo} alt="" />
      <ul className="footer-navigation">
        <li onClick={fns.onMenuClickHome}>Home</li>
        <li onClick={fns.onMenuClickAbout}>About Us</li>
        <li onClick={fns.onMenuClickHome}>Contact</li>
      </ul>

      <span className="kamunity-text">Kamunity.io</span>
      <p>The one-stop-shop for all consumer convenience</p>
      <ul className="logos">
        <li>
          <a
            href="https://www.facebook.com/profile.php?id=100093866144215"
            target="_blank"
            rel="noreferrer"
          >
            <img src={FacebookLogo} alt="" />
          </a>
        </li>
        <li>
          <a
            href="https://twitter.com/kamunity_io"
            target="_blank"
            rel="noreferrer"
          >
            <img src={TwitterLogo} alt="" />
          </a>
        </li>
        <li>
          <a
            href="https://www.linkedin.com/company/kamunity-io/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={LinkdinLogo} alt="" />
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/kamunity.io/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={InstagramLogo} alt="" />
          </a>
        </li>
      </ul>
    </footer>
  );
};

export default ErrorFooter;

/**********************************************************************
 *
 *   Component generated by Quest
 *
 *   WARNING: By editing this component by hand, you will lose the ability to regenerate the code without conflicts.
 *   To preseve that abilty, always export from Quest to regenerate this file.
 *   To setup props, bindings and actions, use the Quest editor
 *   Code Logic goes in the hook associated with this component
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/

import { styled } from "@mui/material/styles";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import "../../assets/css/holdings.css";
import holdingsBg from "../../../components/assets/images/bg-transaction.png";
import holdingsBgMob from "../../../components/assets/images/bg-insights.png";
import {
  atm_globalFilterCount,
  atm_globalFilterValue,
  atm_isCategoryFilterModified,
} from "../../../state/atoms";
import { Alert } from "@mui/material";
import FiltersMob from "../../assets/images/filters-mob.svg";
import { Helmet } from "react-helmet";

import { atm_isManageCategoryBox } from "../../../state/atomsTransactionData";

import {
  atm_IsMobHoldingsFilter,
  atm_SelectedHoldingsDateFilter,
} from "../../../state/atomsHoldingData";

import { useNavigate } from "react-router-dom";
// import useAccountLanding from "../useAccountLanding";
import useHoldingsLanding from "./useHoldingsLanding";
import { useEffect, useState } from "react";
import { AccountList } from "../AccountList";
import HoldingsChart from "./HoldingsChart";
import { GetWindowWidth, getAuthToken } from "src/global/central";
import FiltersHoldings from "./FiltersHoldings";
import HoldingsTable from "./HoldingsTable";
import MobHoldingsTable from "./MobHoldingsTable";
import {
  atm_isAccountSelectionModified,
  atm_selectedAccountIds,
} from "src/state/atomsAccountList";
import AccountNavigation from "../AccountNavigation";
import MetaTags from "src/components/shared/MetaTags";

const AlertFilled = styled(Alert)({
  alignSelf: `stretch`,
  flexDirection: `row`,
  height: "48px",
  width: "100%",
  marginTop: 10,
});

function AccountLanding() {
  const { fnsHoldings } = useHoldingsLanding();

  const jwt = getAuthToken();

  const setHoldingsDateFilterValue = useSetRecoilState(
    atm_SelectedHoldingsDateFilter
  );

  const isManageCategoryBox = useRecoilValue(atm_isManageCategoryBox);

  let navigate = useNavigate();

  const [isMobFilter, setIsMobFilter] = useRecoilState(atm_IsMobHoldingsFilter);

  const [mobOptions, setMobOptions] = useState(1);

  const globalFilterValue = useRecoilValue(atm_globalFilterValue);

  const [isCategoryFilterModified, setCategoryFilterModified] = useRecoilState(
    atm_isCategoryFilterModified
  );
  const setGlobalFilterCount = useSetRecoilState(
    atm_globalFilterCount
  );

  const selectedAccountIds = useRecoilValue(atm_selectedAccountIds);

  const [isAccountSelectionModified, setIsAccountSelectionModified] =
    useRecoilState(atm_isAccountSelectionModified);

  useEffect(() => {
    fnsHoldings.onHoldingsLandingPageLoad();

    return () => {
      setHoldingsDateFilterValue(12);
      setIsMobFilter(false);
      setIsAccountSelectionModified(false);
    };
  }, []);

  const width = GetWindowWidth();

  useEffect(() => {
    if (
      (globalFilterValue.categoryFilter.excludedCategoryFilter.length ||
        globalFilterValue.categoryFilter.selectedCategoryFilter.length) &&
      isCategoryFilterModified
    ) {
      setGlobalFilterCount((prevValue) => prevValue - 1);
      setCategoryFilterModified(false);
    }
  }, []);

  useEffect(() => {
    if (isAccountSelectionModified) {
      fnsHoldings.refreshHoldingsDataFromProvider();
    }
  }, [selectedAccountIds]);

  if (jwt != "") {
    return (
      <>
        <MetaTags index={"Holdings"} />

        <div id="container-fastlink"></div>
        <img
          src={width > 900 ? holdingsBg : holdingsBgMob}
          alt=""
          className="holdings-bg transactionbg"
        />
        <div className="transaction-body-wrapper-area holdings-page">
          {width < 900 && <AccountNavigation />}
          {/* mobile options  */}

          <div
            className={`holdings-body-inner-area transaction-body-inner-area ${
              width < 900 &&
              ((mobOptions === 1 && "active-ALL") ||
                (mobOptions === 2 && "active-MY-ACCOUNT"))
            } ${isManageCategoryBox && "manageCategoryBoxOpened"}`}
          >
            <FiltersHoldings />

            <AccountList
              mobOptions={mobOptions}
              setMobOptions={setMobOptions}
            />

            {(width > 900 ||
              (width < 900 && (mobOptions === 3 || mobOptions === 1))) && (
              <div className="holdings-right-cover">
                {width > 900 && <AccountNavigation />}
                <div className="holdings-right-side">
                  {width < 900 && mobOptions === 3 && (
                    <ul className="transaction-mob-options">
                      <li
                        className={mobOptions === 1 ? "active" : ""}
                        onClick={() => {
                          setMobOptions(1);
                        }}
                      >
                        All
                      </li>
                      <li
                        className={mobOptions === 2 ? "active" : ""}
                        onClick={() => {
                          setMobOptions(2);
                        }}
                      >
                        My Accounts
                      </li>
                      <li
                        className={mobOptions === 3 ? "active" : ""}
                        onClick={() => {
                          setMobOptions(3);
                        }}
                      >
                        Portfolio Value Trend
                      </li>
                    </ul>
                  )}
                  <div className="holdings-right-inside">
                    <div className="top-area">
                      <h1>
                        <span>Portfolio Value Trend</span>
                        {width < 900 && (
                          <div className="filter-cover">
                            <img
                              onClick={() => setIsMobFilter(true)}
                              src={FiltersMob}
                              alt=""
                            />
                          </div>
                        )}
                      </h1>
                      <div className="right-top-area">
                        <div className="box-round">
                          <span className="blue-box box"></span>
                          <p>
                            <span className="mob">Net Portfolio Value -</span>
                            Profit
                          </p>
                        </div>
                        <div className="box-round">
                          <span className="black-box box"></span>
                          <p>Cost Basis</p>
                        </div>
                        <div className="box-round">
                          <span className="red-box box"></span>
                          <p>
                            <span className="mob">Net Portfolio Value -</span>{" "}
                            Loss
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="scrollable-content">
                      <HoldingsChart />

                      {width > 900 ? <HoldingsTable /> : <MobHoldingsTable />}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* {process.env.REACT_APP_PLAID_ENV === "SANDBOX" && (
            <AlertFilled
              variant="filled"
              severity="info"
              sx={{ marginTop: "15px" }}
            >
              You are in sandbox environment. For connecting to accounts via
              Plaid, pls use username : user_good and password : pass_good. For
              MFA, pls use 1234
            </AlertFilled>
          )} */}
        </div>
      </>
    );
  } else {
    navigate("/login");
  }
}

export default AccountLanding;

import React from "react";
import ErrorFooter from "./ErrorFooter";
import "../assets/css/Error404.css";
import Error404Bg from "../assets/images/error404-bg.png";
import Error404BgMob from "../assets/images/error404-bg-mob.png";
import { GetWindowWidth } from "src/global/central";

const Error404 = () => {
  const width = GetWindowWidth();

  return (
    <div className="error404-main">
      <div className="error-bg-cover">
        <img
          className="error-bg"
          src={width > 750 ? Error404Bg : Error404BgMob}
          alt=""
        />
      </div>
      <ErrorFooter />
    </div>
  );
};

export default Error404;

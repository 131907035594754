import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import {
  atm_globalFilterValue,
  atm_GlobalLoading,
  atm_isCategoryFilterModified,
} from "../../../state/atoms";
import FilterLogo from "../../assets/images/filterLogo.svg";

import {
  atm_MerchantListLoading,
  atm_InsightsMerchantList,
  atm_MerchantLocationStats,
  atm_MerchantChartLoading,
  atm_MerchantCategoryStats,
  atm_MerchantPopStats,
  atm_IsMerchantClicked,
  atm_SelectedMerchant,
  atm_SelectedMerchantPercent,
  atm_SelectedMerchantName,
  atm_RatingStats,
  atm_SelectedMerchantDateFilter,
  atm_MerchantRatingLoading,
  atm_parentReviews,
  atm_globalMerchant,
  atm_MerchantLocationStatsLoading,
  atm_merchantTab,
  atm_merchantParentSelectors,
  atm_PopChartLoading,
} from "src/state/insightsMerchants";
import { compareIds, dateFormateShort, getAuthToken, usdFormate } from "src/global/central";
import axios from "axios";
import useTransactionsLanding from "src/components/Accounts/Transactions/useTransactionsLanding";
import { atm_allTransactionDataCount, atm_IsMobTransactionFilter, atm_pageOptions, atm_transactionsList, atm_transactionsListTable } from "src/state/atomsTransactionData";

const useSpendByMerchant = () => {
  const data = {};

  const { fnsTransactions } = useTransactionsLanding();
  const [merchantListLoading, setMerchantListLoading] = useRecoilState(
    atm_MerchantListLoading
  );
  data.merchantListLoading = merchantListLoading;

  const [merchantListData, setMerchantListData] = useRecoilState(
    atm_InsightsMerchantList
  );
  data.merchantListData = merchantListData;

  const [merchantChartLoading, setMerchantChartLoading] = useRecoilState(
    atm_MerchantChartLoading
  );
  data.merchantChartLoading = merchantChartLoading;

  const [popChartLoading, setPopChartLoading] = useRecoilState(
    atm_PopChartLoading
  );
  data.popChartLoading = popChartLoading;

  const [merchantRatingLoading, setMerchantRatingLoading] = useRecoilState(
    atm_MerchantRatingLoading
  );
  data.merchantRatingLoading = merchantRatingLoading;

  const [locationStats, setLocationStats] = useRecoilState(
    atm_MerchantLocationStats
  );
  data.locationStats = locationStats;

  const [locationStatsLoading, setLocationStatsLoading] = useRecoilState(
    atm_MerchantLocationStatsLoading
  );
  data.locationStatsLoading = locationStatsLoading;

  const [categoryStats, setCategoryStats] = useRecoilState(
    atm_MerchantCategoryStats
  );
  data.categoryStats = categoryStats;

  const [popStats, setPopStats] = useRecoilState(atm_MerchantPopStats);
  data.popStats = popStats;

  // is Clicked on Merchant List ?
  const [isMerchantClicked, setIsMerchantClicked] = useRecoilState(
    atm_IsMerchantClicked
  );
  data.isMerchantClicked = isMerchantClicked;

  const [selectedMerchant, setSelectedMerchant] =
    useRecoilState(atm_SelectedMerchant);
  data.selectedMerchant = selectedMerchant;

  const [selectedMerchantPercent, setSelectedMerchantPercent] = useRecoilState(
    atm_SelectedMerchantPercent
  );
  data.selectedMerchantPercent = selectedMerchantPercent;

  const [selectedMerchantName, setSelectedMerchantName] = useRecoilState(
    atm_SelectedMerchantName
  );
  data.selectedMerchantName = selectedMerchantName;

  const [ratingStats, setRatingStats] = useRecoilState(atm_RatingStats);
  data.ratingStats = ratingStats;

  const [selectedDateFilterState, setSelectedDateFilterState] = useRecoilState(
    atm_SelectedMerchantDateFilter
  );
  data.selectedDateFilterState = selectedDateFilterState;

  const [globalFilterValue, setGlobalFilterValue] = useRecoilState(
    atm_globalFilterValue
  );
  data.globalFilterValue = globalFilterValue;

  const [parentReviews, setParentReviews] = useRecoilState(atm_parentReviews);
  data.parentReviews = parentReviews;

  const [globalMerchant, setGlobalMerchant] =
    useRecoilState(atm_globalMerchant);
  data.globalMerchant = globalMerchant;

  const [globalLoading, setGlobalLoading] = useRecoilState(atm_GlobalLoading);
  data.globalLoading = globalLoading;

  const setIsCategoryFilterModified = useSetRecoilState(
    atm_isCategoryFilterModified
  );

  const pageOptions = useRecoilValue(atm_pageOptions);

  const merchantTab = useRecoilValue(atm_merchantTab);

  const setMerchantParentSelectors = useSetRecoilState(atm_merchantParentSelectors)

  const setTransactionsList = useSetRecoilState(atm_transactionsList);

  const setTransactionsDataCount = useSetRecoilState(atm_allTransactionDataCount);

  const jwt = getAuthToken();

  let selectedDateFilter = globalFilterValue.dateFilter;
  let includeMerchantIds = globalFilterValue.merchantFilter.includeMerchantIds;
  let excludeMerchantIds = globalFilterValue.merchantFilter.excludeMerchantIds;

  const clmnTransactions = [
    {
      cellClassName: "custom-date-cell",
      headerClassName: "custom-date-header",
      field: "date",
      maxWidth: 250,
      flex: 1,
      renderHeader: () => (
        <div className="custom-header date-header">
          <span className="">Date</span>
          <img src={FilterLogo} alt="" />
        </div>
      ),
      valueGetter: (value) => {
        const transactionDate = new Date(value);
        return dateFormateShort(transactionDate);
      },
      renderCell: (cellValues) => {
        return <div className="date-cell">{cellValues.value}</div>;
      },
    },
    {
      field: "fi",
      flex: 1,
      renderHeader: () => (
        <div className="custom-header">
          <span className="">Custodian</span>
          <img src={FilterLogo} alt="" />
        </div>
      ),
      renderCell: (cellValues) => {
        return (
          <div className="custodian-cell">
            <div className="custodian-name">{cellValues.row.fi}</div>
            <div className="account-number">
              {cellValues.row.AccountNameNumber}
            </div>
          </div>
        );
      },
    },
    {
      field: "amount",
      cellClassName: "amount-cell",
      flex: 1,
      maxWidth: 200,

      renderHeader: () => (
        <div className="custom-header">
          <span className="">Amount</span>
          <img src={FilterLogo} alt="" />
        </div>
      ),
      renderCell: (cellValues) => {
        return (
          <div className="amount-cell">{usdFormate(-cellValues.value)}</div>
        );
      },
    },
  ];

  data.clmnTransactions = clmnTransactions;

  // Merchant List Fetching 👇

  function getMerchantListData() {
    setMerchantListLoading(true);
    setGlobalLoading(true);

    let endPoint = "/accounts/insights/transactions/v2/getSpendByMerchant";

    // if (dataProviderId === 1) // Yodlee
    //     endPoint = ;
    // else if (dataProviderId === 2) // Plaid
    //     endPoint = "/accounts/insights/transactions/v1/getSpendByMerchant";

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      },
      body: JSON.stringify({
        dateFilter: selectedDateFilter,
        merchantIds: includeMerchantIds,
        excludeMerchantIds,
      }),
    };

    fetch(
      process.env.REACT_APP_ACCOUNT_API_END_POINT + endPoint,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          if (response.status == "401") {
            console.log("Unable to get Spemning details");
          }
          throw response.text();
        }
        return response.json();
      })
      .then((responseData) => {
        processMerchantListData(responseData);
      })
      .catch((err) => {
        console.log("inside catch block with error : " + err);
      });
  }

  function processMerchantListData(response) {
    if (response.success != true) {
      var errorMessage = response.responseMessage;
      alert("Received error message : " + errorMessage);
    } else {
      setMerchantListData(response.responseData);
      if (response.responseData.length > 0) {
        setSelectedMerchantPercent(
          Math.round(response.responseData[0].spendPercentage)
        );
        setSelectedMerchantName(response.responseData[0].merchantName);

        setSelectedMerchant(response.responseData[0]);
        setGlobalMerchant(response.responseData[0].hqMerchantId);

        refreshRightScreen(response.responseData[0].hqMerchantId);
      } else {
        setCategoryStats([]);
        setTransactionsList([]);
        setTransactionsDataCount(0);
        setLocationStats([]);
        setPopStats([]);
        setRatingStats([]);
        setParentReviews([]);
        setSelectedMerchantPercent(null);
        setSelectedMerchant(null);
        setSelectedMerchantName(null);
        setGlobalMerchant(null);
      }
    }

    setMerchantListLoading(false);
    setGlobalLoading(false);
  }

  // ! On Merchant selected


  // ! Location Stats fetching

  // 1) CHART -> location stats fetching 👇

  function getLocationStats(params, dateValue) {
    if (dateValue) {
      selectedDateFilter = dateValue;
    }
    setLocationStatsLoading(true);

    let endPoint = "";

    //     if (dataProviderId === 1) // Yodlee
    endPoint = "/accounts/insights/transactions/merchant/v2/getLocationStats";
    //   else if (dataProviderId === 2) // Plaid
    //       endPoint = "/accounts/insights/transactions/merchant/v1/getLocationStats";

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      },
      body: JSON.stringify({
        dateFilter: selectedDateFilter,
        merchantIds: includeMerchantIds,
        selectedMerchantId: Number(params),
      }),
    };

    fetch(
      process.env.REACT_APP_ACCOUNT_API_END_POINT + endPoint,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          if (response.status == "401") {
            console.log("Unable to get Spemning details");
          }
          throw response.text();
        }
        return response.json();
      })
      .then((responseData) => {
        processLocationStats(responseData);
      })
      .catch((err) => {
        console.log("inside catch block with error : " + err);
      });
  }

  function processLocationStats(response) {
    if (response.success != true) {
      var errorMessage = response.responseMessage;
      alert("Received error message : " + errorMessage);
    } else {
      setLocationStats(response.responseData);
    }
    setLocationStatsLoading(false);
  }

  // 2) CHART -> Category stats fetching 👇

  function getCategoryStats(params, dateValue) {
    if (dateValue) {
      selectedDateFilter = dateValue;
    }

    setMerchantChartLoading(true);
    setGlobalLoading(true);

    let endPoint = "";

    //   if (dataProviderId === 1) // Yodlee
    endPoint = "/accounts/insights/transactions/merchant/v2/getMoMTrend";
    // else if (dataProviderId === 2) // Plaid
    //     endPoint = "/accounts/insights/transactions/merchant/v1/getMoMTrend";

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      },
      body: JSON.stringify({
        dateFilter: selectedDateFilter,
        selectedMerchantId: Number(params),
      }),
    };

    fetch(
      process.env.REACT_APP_ACCOUNT_API_END_POINT + endPoint,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          if (response.status == "401") {
            console.log("Unable to get Spemning details");
          }
          throw response.text();
        }
        return response.json();
      })
      .then((responseData) => {
        processCategoryStats(responseData);
      })
      .catch((err) => {
        console.log("inside catch block with error : " + err);
      });
  }

  function processCategoryStats(response) {
    if (response.success != true) {
      var errorMessage = response.responseMessage;
      alert("Received error message : " + errorMessage);
    } else {
      setCategoryStats(response.responseData);
      setMerchantChartLoading(false);
      setGlobalLoading(false);
    }
  }

  // 3) CHART -> Population Density stats fetching 👇

  function getPopDensityStats(params, dateValue) {
    if (dateValue) {
      selectedDateFilter = dateValue;
    }

    setPopChartLoading(true);
    setGlobalLoading(true);

    let endPoint = "";

    //  if (dataProviderId === 1) // Yodlee
    endPoint = "/accounts/insights/transactions/merchant/v2/getPopDensityTrend";
    // else if (dataProviderId === 2) // Plaid
    //     endPoint = "/accounts/insights/transactions/merchant/v1/getPopDensityTrend";

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      },
      body: JSON.stringify({
        dateFilter: selectedDateFilter,
        selectedMerchantId: Number(params),
      }),
    };

    fetch(
      process.env.REACT_APP_ACCOUNT_API_END_POINT + endPoint,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          if (response.status == "401") {
            console.log("Unable to get Spemning details");
          }
          throw response.text();
        }
        return response.json();
      })
      .then((responseData) => {
        processPopDensityStats(responseData);
      })
      .catch((err) => {
        console.log("inside catch block with error : " + err);
      });
  }

  function processPopDensityStats(response) {
    if (response.success != true) {
      var errorMessage = response.responseMessage;
      alert("Received error message : " + errorMessage);
    } else {
      setPopStats(response.responseData);
      setPopChartLoading(false);
      setGlobalLoading(false);
    }
  }

  // 4) CHART -> Rating 👇

  function getRatingStats(params) {
    // if (dateValue) {
    //     selectedDateFilter = dateValue
    // }

    setMerchantRatingLoading(true);
    setGlobalLoading(true);

    let endPoint = "";

    //     if (dataProviderId === 1) // Yodlee
    endPoint =
      "/accounts/insights/transactions/merchant/v2/getMoMRatingRepeatabilityTrend";
    // else if (dataProviderId === 2) // Plaid
    //     endPoint = "/accounts/insights/transactions/merchant/v1/getMoMRatingRepeatabilityTrend";

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      },
      body: JSON.stringify({
        dateFilter: selectedDateFilter,
        selectedMerchantId: Number(params),
      }),
    };

    fetch(
      process.env.REACT_APP_ACCOUNT_API_END_POINT + endPoint,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          if (response.status == "401") {
            console.log("Unable to get Spemning details");
          }
          throw response.text();
        }
        return response.json();
      })
      .then((responseData) => {
        processRatingStats(responseData);
      })
      .catch((err) => {
        console.log("inside catch block with error : " + err);
      });
  }

  function processRatingStats(response) {
    if (response.success != true) {
      var errorMessage = response.responseMessage;
      alert("Received error message : " + errorMessage);
    } else {
      setRatingStats(response.responseData);
    }

    setMerchantRatingLoading(false);
    setGlobalLoading(false);
  }

  function getParentReviews(merchantId, ratings) {
    setGlobalLoading(true);

    let api =
      process.env.REACT_APP_ACCOUNT_API_END_POINT +
      "/accounts/reviews/merchant/v1/getParentReviews";

    axios
      .post(
        api,
        {
          hqProviderId: merchantId,
          ratings: ratings ?? [],
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + getAuthToken(),
          },
        }
      )
      .then((res) => {
        let data = res.data.responseData;
        setParentReviews(data);
        setGlobalLoading(false);
      })
      .catch((err) => {
        console.log("error from server " + err);
        setGlobalLoading(false);
      });
  }

  function refreshRightScreen(merchant) {
    if (merchantTab === 1) {
      getCategoryStats(merchant, globalFilterValue.dateFilter);
      fnsTransactions.getTransactionsData(
        pageOptions.pageNumber,
        pageOptions.pageSize,
        null,
        null,
        null,
        merchant
      );
      fnsTransactions.getAllTransactionsDataCount(merchant);
      setMerchantParentSelectors(data => ({...data, mom: merchantTab}))
    } else if (merchantTab === 2) {
      getLocationStats(merchant);
      getPopDensityStats(merchant);
      setMerchantParentSelectors(data => ({...data, byLocation: merchantTab}))
    } else if (merchantTab === 3) {
      getRatingStats(merchant);
      setMerchantParentSelectors(data => ({...data, merchantTrends: merchantTab}))
    } else if (merchantTab === 4) {
      getParentReviews(merchant);
      setMerchantParentSelectors(data => ({...data, ratingReviews: merchantTab}))
    }
  }

  function onMerchantSelected(params) {
    if (globalMerchant != params.row.id) {
      setSelectedMerchant(merchantListData[params.row.index]);

      setSelectedMerchantPercent(merchantListData[params.row.index].spendPercentage);

      setSelectedMerchantName(params.row.col2.merchantName);

      setGlobalMerchant(`${params.row.id}`);

      setIsMerchantClicked(true);

      refreshRightScreen(params.row.id);
    }
  }

  function onChangeDateFilter(dateFilterValue) {
    includeMerchantIds = [];
    excludeMerchantIds = [];
    setGlobalFilterValue({
      ...globalFilterValue,
      dateFilter: dateFilterValue,
      categoryFilter: {
        selectedCategoryFilter: [],
        excludedCategoryFilter: [],
      },
      merchantFilter: {
        includeMerchantIds: [],
        excludeMerchantIds: []
      }
    });

    setSelectedDateFilterState(dateFilterValue);
    selectedDateFilter = dateFilterValue;

    setIsCategoryFilterModified(false);

    getMerchantListData();
    fnsTransactions.getMerchantListing(dateFilterValue);
  }

  function onChangeMerchantFilter(includedIds, excludedIds) {
    const {includedId, excludedId} = compareIds(includedIds, excludedIds)

    includeMerchantIds = includedId;
    excludeMerchantIds = excludedId;

    setGlobalFilterValue({
      ...globalFilterValue,
      merchantFilter: {
        includeMerchantIds,
        excludeMerchantIds,
      },
    });

    getMerchantListData();
  }

  let fns = {
    getMerchantListData,
    getLocationStats,
    onMerchantSelected,
    getCategoryStats,
    getPopDensityStats,
    onChangeDateFilter,
    getRatingStats,
    onChangeMerchantFilter,
    getParentReviews,
    refreshRightScreen
  };
  return { data, fns };
};

export default useSpendByMerchant;

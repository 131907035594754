/**********************************************************************
 *
 *   Component hook generated by Quest
 *
 *   Code Logic for the component goes in this hook
 *   To setup bindings that use the data here or call the functions here, use the Quest editor
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/

import { useState } from "react";
import axios from "axios";
import { removeAuthTokens, setAuthTokens, getAuthToken } from "src/global/central";
import {atm_dataProviderId,atm_firstName, atm_lastName, atm_mainMenuButtonPressed } from "src/state/atoms";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";


const useSignUp = (setDisable) => {
  // let [formSubmitted, setFormSubmitted] = useState(false);

  let navigate = useNavigate();

  let [isErrorMessage, setIsErrorMessage] = useState("");

  let [email, setEmail] = useState("");
  let [emailError, setEmailError] = useState(null);
  let [emailHelperText, setEmailHelperText] = useState(null);

  let [password, setPassword] = useState("");
  let [passwordError, setPasswordError] = useState(null);
  let [passwordHelperText, setPasswordHelperText] = useState(null);

  let [firstName, setFirstName] = useRecoilState(atm_firstName);
  let [firstNameError, setFirstNameError] = useState(null);
  let [firstNameHelperText, setFirstNameHelperText] = useState(null);

  let [lastName, setLastName] = useRecoilState(atm_lastName);
  let [lastNameError, setLastNameError] = useState(null);
  let [lastNameHelperText, setLastNameHelperText] = useState(null);

  let [phoneNo, setPhoneNo] = useState("");
  let [phoneNoError, setPhoneNoError] = useState(null);
  let [phoneNoHelperText, setPhoneNoHelperText] = useState(null);

  let [zipCode, setZipCode] = useState("");
  let [zipCodeError, setZipCodeError] = useState(null);
  let [zipCodeHelperText, setZipCodeHelperText] = useState(null);

  let [alertType, setAlertType] = useState("");

  let [emailValidationCode, setEmailValidationCode] = useState("");
  let [emailValidationSatus, setEmailValidationSatus] = useState(null);
  let [emailValidationErrorMessage, setEmailValidationErrorMessage] = useState(null);
   
  let [emailValidationRequired, setEmailValidationRequired] = useState(false);
   
  const [dataProviderId, setDataProviderId] = useRecoilState(atm_dataProviderId);

  let data = {};
  data.isErrorMessage = isErrorMessage;
  // data.formSubmitted = formSubmitted;
  data.alertType = alertType;

  data.email = email;
  data.emailError = emailError;
  data.emailHelperText = emailHelperText;

  data.password = password;
  data.passwordError = passwordError;
  data.passwordHelperText = passwordHelperText;

  data.firstName = firstName;
  data.firstNameError = firstNameError;
  data.firstNameHelperText = firstNameHelperText;

  data.lastName = lastName;
  data.lastNameError = lastNameError;
  data.lastNameHelperText = lastNameHelperText;

  data.phoneNo = phoneNo;
  data.phoneNoError = phoneNoError;
  data.phoneNoHelperText = phoneNoHelperText;

  data.zipCode = zipCode;
  data.zipCodeError = zipCodeError;
  data.zipCodeHelperText = zipCodeHelperText;

  data.emailValidationCode = emailValidationCode;
  data.emailValidationRequired = emailValidationRequired;
  data.emailValidationErrorMessage = emailValidationErrorMessage;
  data.emailValidationSatus = emailValidationSatus;

  const [mainMenuButtonPressed, setMainMenuButtonPressed] = useRecoilState(
    atm_mainMenuButtonPressed
  );

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
    setEmailError(null);
  };

  const onChangePassword = (e) => {
    setPassword(e.target.value);
    setPasswordError(null);
  };

  const clearErrorMessage = (e) => {
    setIsErrorMessage(null);
  };

  const onChangeFirstName = (e) => {
    setFirstName(e.target.value);
    setFirstNameError(null);
  };

  const onChangeLastName = (e) => {
    setLastName(e.target.value);
    setLastNameError(null);
  };

  const onChangePhoneNo = (e) => {
    setPhoneNo(e.target.value);
    setPhoneNoError(null);
  };

  const onChangeZipCode = (e) => {
    setZipCode(e.target.value);
    setZipCodeError(null);
  };

  const onChangeEmailValidationCode = (e) => {
    setEmailValidationCode(e.target.value);
  };

  function validateEmailCode()
  {

    let URL = process.env.REACT_APP_USER_API_END_POINT + "/users/v1/validateEmailCode";

    axios
      .post(
        URL,
        {
          code: data.emailValidationCode,
        },
        {
          headers: {
						'Content-Type': 'application/json',
						Authorization: 'Bearer ' + getAuthToken()
					}
        }
      )
      .then((res) => {
        setDisable(false);
        console.log(res);
        if (res["data"]["success"] === false) {

          setEmailValidationErrorMessage(res["data"]["errors"][0]['errorMessage']);

        } else {
          setMainMenuButtonPressed("Dashboard");
          navigate("/dashboard");
        }
      })
      .catch((err) => {
        setDisable(false);
      });
  }

  function cancelEmailCodeValidationDialog()
  {
    // if the user does not submit the email code, we cannot have them continue.
      removeAuthTokens();
      setEmailValidationRequired(false);
      setIsErrorMessage("Email validation error. You'll be prompted to enter the code at login");
      setAlertType("error");
  }

  function processCreateUserResponse(responseData) {
    if (!responseData.success) {
      var errorMessage = responseData.responseMessage;
      for (var i = 0; i < responseData.errors.length; i++) {
        var fieldWithError = responseData.errors[i].field;
        var fieldMessage = responseData.errors[i].errorMessage;

        switch (fieldWithError) {
          case "email":
            setEmailError(true);
            setEmailHelperText(fieldMessage);
            break;
          case "password":
            setPasswordError(true);
            setPasswordHelperText(fieldMessage);
            break;
          case "firstName":
            setFirstNameError(true);
            setFirstNameHelperText(fieldMessage);
            break;
          case "lastName":
            setLastNameError(true);
            setLastNameHelperText(fieldMessage);
            break;

          case "phoneNumber":
            setPhoneNoError(true);
            setPhoneNoHelperText(fieldMessage);
            break;

          case "zipCode":
            setZipCodeError(true);
            setZipCodeHelperText(fieldMessage);
            break;
          default:
            break;
        }
      }
      setIsErrorMessage(errorMessage);
      setAlertType("error");
    } else {
      setIsErrorMessage(responseData.responseMessage);
      setAuthTokens(responseData.responseData.token,responseData.responseData.accessTokenExpiryTime,responseData.responseData.refreshToken,responseData.responseData.refreshTokenExpiryTime);
      setEmailValidationRequired(true);
      setAlertType("success");
      setFirstName(responseData.responseData.firstName);
      setLastName(responseData.responseData.lastName);
      setDataProviderId(responseData.responseData.dataProviderId);
      localStorage.setItem("dataProvider",responseData.responseData.dataProviderId)
    }
  }

  const onClickSignUp = (e) => {
    setDisable(true);
    let URL = process.env.REACT_APP_USER_API_END_POINT + "/users/v1/register";

    axios
      .post(
        URL,
        {
          email: email,
          password: password,
          firstName: firstName,
          lastName: lastName,
          phoneNumber: phoneNo,
          zipCode: zipCode,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then((res) => {
        setDisable(false);
        console.log(res);
        if (res["data"]["success"] === false) {
          setIsErrorMessage(res["data"]["errors"][0]['errorMessage'])
          processCreateUserResponse(res["data"]);
          // setAlertType("error")
          // throw res["data"]["responseMessage"];
        } else {
          processCreateUserResponse(res["data"]);
        }
      })
      .catch((err) => {
        console.log(err.response)
        setDisable(false);
        console.log("inside catch block with error : " + err);
      });


  };

  let fns = {
    onChangePassword,
    onChangeEmail,
    onClickSignUp,
    onChangeFirstName,
    onChangeLastName,
    onChangePhoneNo,
    onChangeZipCode,
    clearErrorMessage,
    validateEmailCode,
    onChangeEmailValidationCode,
    cancelEmailCodeValidationDialog
  };
  return { data, fns };
};

export default useSignUp;

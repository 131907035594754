import React from "react";

const Approach = () => {
  return (
    <section className="approach">
      <h1>Our Approach</h1>
      <div className="inshort">
        <p>
          We want to make Kamunity yours and ours - the one-stop-shop for all
          things related to money. If you are as tired of App-hopping as we are,
          we will help you find more while searching less.
        </p>
        <p>
          With a confidential, yet shared dataset of information surrounding
          personal finance, our account aggregation process protects the user's
          data while providing personalized insights. Whether you are just
          curious about current financial trends, or looking to gain insights
          into what the best financial habits look like, <span>Kamunity</span> can help.
        </p>
      </div>
      <div className="circle"></div>
    </section>
  );
};

export default Approach;

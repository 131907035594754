import { atom, selector } from "recoil";

export const atm_InsightsExpensesDateFilter = atom({
  key: "insightsExpensesDateFilter",
  default: [],
});

export const atm_InsightsExpenseCategoriesTrend = atom({
  key: "insightsExpenseCategoriesTrend",
  default: [],
});

export const atm_InsightsCategoryList = atom({
  key: "insightsCategoryList",
  default: [],
});

export const atm_InsightsMerchantList4Category = atom({
  key: "insightsMerchantList4Category",
  default: [],
});

export const atm_MerchentDataLoading = atom({
  key: "insightsMerchantDataLoading",
  default: false,
});

export const atm_CategoryChartLoading = atom({
  key: "categoryChartLoading",
  default: false,
});

export const atm_LocationChartLoading = atom({
  key: "locationChartLoading",
  default: false,
});

export const atm_PopChartLoading = atom({
  key: "popChartLoading",
  default: false,
});

export const atm_InsightLocationStats = atom({
  key: "insightsLocationStats",
  default: [],
});

export const atm_SelectedCategoryAmount = atom({
  key: "selectedCategoryAmount",
  default: "",
});

export const atm_CategoryDataLoading = atom({
  key: "categoryDataLoading",
  default: false,
});

export const atm_SelectedCategoryPercent = atom({
  key: "selectedCategoryPercent",
  default: "",
});

export const atm_InsightCategoryStats = atom({
  key: "insightsCategoryStats",
  default: [],
});

export const atm_InsightPopStats = atom({
  key: "insightsPopStats",
  default: [],
});

export const atm_IsCategoryClicked = atom({
  key: "isCategoryClicked",
  default: false,
});

export const atm_SelectedCategoryValue = atom({
  key: "selectedCategoryValue",
  default: false,
});

export const atm_IsMobCatFilter = atom({
  key: "isMobCatFilter",
  default: false,
});

export const atm_IsMobCatFilterApplied = atom({
  key: "isMobCatFilterApplied",
  default: false,
});

export const atm_SelectedCatDateFilter = atom({
  key: "selectedCatDateFilter",
  default: 12,
});

export const atm_globalCategory = atom({
  key: "globalCategory",
  default: null,
});

export const atm_categoryTab = atom({
  key: "categoryGraph",
  default: 1,
});

export const atm_categoryParentSelectors = atom({
  key: "categoryParentSelectors",
  default: {
    mom: 1,
    byLocation: null,
  },
});

export const atm_InsightsExpenseCategoriesTrendEnhanced = selector({
  key: "insightsExpenseCategoriesTrendEnhanced",
  get: ({ get }) => {
    var expenseTrendMineEnhanced = [];
    get(atm_InsightsExpenseCategoriesTrend).forEach((myTopExpenses) => {
      var meSize = 1;
      expenseTrendMineEnhanced.push({
        expenseCategory: myTopExpenses.extendedCategory,

        countryMin: myTopExpenses.countryMin,
        countryMinToMe:
          myTopExpenses.me - myTopExpenses.countryMin - meSize / 2,
        countryMeToMax:
          myTopExpenses.countryMax - myTopExpenses.me - meSize / 2,
        countryMaxTo100: 100 - myTopExpenses.countryMax,

        stateMin: myTopExpenses.stateMin,
        stateMinToMe: myTopExpenses.me - myTopExpenses.stateMin - meSize / 2,
        stateMeToMax: myTopExpenses.stateMax - myTopExpenses.me - meSize / 2,
        stateMaxTo100: 100 - myTopExpenses.stateMax,

        zipMin: myTopExpenses.zipMin,
        zipMinToMe: myTopExpenses.me - myTopExpenses.zipMin - meSize / 2,
        zipMeToMax: myTopExpenses.zipMax - myTopExpenses.me - meSize / 2,
        zipMaxTo100: 100 - myTopExpenses.zipMax,

        Me: meSize,
        MeValue: myTopExpenses.me,
      });
    });

    return expenseTrendMineEnhanced;
  },
});

import { Box, Modal } from "@mui/material";
import React from "react";
import { useRecoilValue } from "recoil";
import { atm_selectedGoal } from "src/state/atomDashboard";
import useDashboard from "../useDashboard";
import CloseIcon from "../../assets/images/close.svg";

const monthName = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const DeleteGoalBox = ({ isDeleteGoal, setIsDeleteGoal }) => {
  const { fnsDashboard } = useDashboard();
  const selectedGoal = useRecoilValue(atm_selectedGoal);
  const handleNo = () => {
    setIsDeleteGoal(false);
  };
  const handleYes = () => {
    fnsDashboard.deleteGoal(selectedGoal.goalId);
    setIsDeleteGoal(false);
  };
  return (
    <Modal open={isDeleteGoal} onClose={handleNo}>
      <Box className="deleteGoalBox">
        <div className="head">
          Delete Goal <img onClick={handleNo} src={CloseIcon} alt="" />
        </div>
        <div className="content">
          <p className="cofirmation-text">
            Are you sure want to delete this goal -
          </p>
          <b className="goal-name">
            "{selectedGoal?.generalSection?.actionName} :{" "}
            {selectedGoal?.generalSection?.goalTypeName} for{" "}
            {Object.values(selectedGoal?.generalSection?.subjects).join(
              ", "
            )}{" "}
            in {monthName[selectedGoal?.generalSection?.goalByMonth - 1]},{" "}
            {selectedGoal?.generalSection?.goalByYear}" ?
          </b>
          <div className="btns">
            <button className="no" onClick={handleNo}>
              No
            </button>
            <button className="yes" onClick={handleYes}>
              Yes, Delete
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default DeleteGoalBox;

import React, { useRef } from "react";
import SelectDegreeType from "./SelectDegreeType";
import SelectDegreeDuration from "./SelectDegreeDuration";
import SelectSchoolType from "./SelectSchoolType";
import SelectCollegeType from "./SelectCollegeType";
import PencilIcon from "../../../../assets/images/pencil2.svg";
import {
  FormControl,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { NumericFormat } from "react-number-format";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  atm_selectedDetailsData,
  atm_selectedGeneralData,
} from "src/state/atomDashboard";
import { usdUnformat } from "src/global/central";
import { validateYear } from "src/validations/Goals";

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const Details = () => {
  const isMobile = useMediaQuery("(max-width: 750px)");
  const selectedGeneralData = useRecoilValue(atm_selectedGeneralData);
  const [selectedDetailsData, setSelectedDetailsData] = useRecoilState(
    atm_selectedDetailsData
  );
  const yearRef = useRef(null);
  const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
    props,
    ref
  ) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
        prefix={"$ "}
      />
    );
  });

  const handleRadioChange = (e) => {
    if (e.target.value) {
      setSelectedDetailsData((prevData) => ({
        ...prevData,
        drawnBy: e.target.value == "true",
        month: "",
        year: "",
      }));
      yearRef.current.value = "";
    }
  };

  const handleFieldChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setSelectedDetailsData((prevData) => ({
      ...prevData,
      [name]: +value,
    }));
  };

  return (
    <div className="detailsBox">
      {!isMobile && (
        <>
          <div>
            <div className="head">Details</div>
            <div className="subHead">
              Let’s add details about going to College
            </div>
          </div>
          <div className="hr"></div>
        </>
      )}

      {selectedGeneralData.option ? (
        <div className="mainText">
          I am considering
          <SelectDegreeType />
          <SelectDegreeDuration />
          {!isMobile && <div></div>}
          <SelectSchoolType />
          <SelectCollegeType />
        </div>
      ) : (
        <div className="mainText mainText-freeForm">
          <div className="row1">
            In order to achieve my goal,
            <p>
              I need total of
              <TextField
                id="detailsAmount"
                className="amountInput totalInput editable"
                variant="standard"
                label="Enter amount"
                defaultValue={selectedDetailsData.amount}
                onBlur={(e) => {
                  setSelectedDetailsData((prevData) => ({
                    ...prevData,
                    amount: +usdUnformat(e.target.value),
                  }));
                }}
                sx={{
                  width: 118,
                  mx: 3,
                  mt: 0,
                  "& .MuiInputBase-root": {
                    color: "#0c89ef",
                    marginTop: 0,
                  },
                  "& .MuiFormLabel-root": {
                    fontSize: 12,
                    top: -12,
                  },
                }}
                InputProps={{
                  inputComponent: NumericFormatCustom,
                  endAdornment: (
                    <InputAdornment className="dollar" position="start">
                      <img className="pencilIcon" src={PencilIcon} alt="" />
                    </InputAdornment>
                  ),
                }}
              />
            </p>
          </div>
          <div className="row2">
            <div className="text">
              All of the goal amount will be drawn on or by :
            </div>
            <FormControl className="generalForm choiceForm">
              <RadioGroup
                value={selectedDetailsData.drawnBy}
                name="radio-buttons-group"
                onChange={handleRadioChange}
              >
                <div
                  className="option1 option"
                  style={{ width: "100%", display: "flex" }}
                >
                  <FormControlLabel
                    value={true}
                    control={
                      <Radio
                        sx={{
                          color: "var(--color1)",
                          "&.Mui-checked": {
                            color: "var(--color1)",
                          },
                        }}
                      />
                    }
                  />
                  <b style={{ marginRight: 5 }}>Goal Date</b> [
                  {monthNames[selectedGeneralData.month - 1]},{" "}
                  {selectedGeneralData.year}]
                </div>

                <div className="option2 option">
                  <FormControlLabel
                    value={false}
                    control={
                      <Radio
                        sx={{
                          color: "var(--color1)",
                          "&.Mui-checked": {
                            color: "var(--color1)",
                          },
                        }}
                      />
                    }
                  />
                  <div className="monthCover">
                    <label
                      style={{
                        fontSize: 15,
                        opacity: selectedDetailsData.drawnBy ? 0.2 : 0.4,
                      }}
                      hidden={selectedDetailsData.month}
                    >
                      MM
                    </label>
                    <TextField
                      disabled={selectedDetailsData.drawnBy}
                      value={selectedDetailsData.month}
                      name="month"
                      onChange={handleFieldChange}
                      variant="standard"
                      sx={{
                        width: 80,
                      }}
                      select
                    >
                      {monthNames.map((month, index) => (
                        <MenuItem key={index} value={index + 1}>
                          {month}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>

                  <div className="yearCover">
                    <TextField
                      defaultValue={selectedDetailsData.year}
                      disabled={selectedDetailsData.drawnBy}
                      onChange={(e) => {
                        validateYear(e);
                        handleFieldChange(e);
                      }}
                      inputRef={yearRef}
                      name="year"
                      variant="standard"
                      type="tel"
                      sx={{ width: 80 }}
                      inputProps={{ maxLength: 4, placeholder: "YYYY" }}
                    />
                  </div>
                </div>
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      )}
    </div>
  );
};

export default Details;

import React from "react";
import Row from "./Row";
import useMainMenu from "../MainMenu/useMainMenu";
import { useMediaQuery } from "@mui/material";

const LoginSignupMenu = () => {
  const { fns } = useMainMenu();
  const isMobile = useMediaQuery("(max-width: 1000px)");

  return isMobile ? (
    <Row className="login-signup-menu" gap={13}>
      <button className="signUpBtn" onClick={fns.onMenuClickSignUp}>
        Sign Up
      </button>
      <button className="loginBtn" onClick={fns.onMenuClickLogin}>
        Login
      </button>
    </Row>
  ) : null;
};

export default LoginSignupMenu;

import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { DataGrid } from "@mui/x-data-grid";
import FiltersMob from "../../assets/images/filters-mob.svg";
import useTransactionsLanding from "./useTransactionsLanding";
import TransactionChart from "./TransactionChart";
import { GetWindowWidth } from "../../../global/central";
import MobTransTable from "./MobTransTable";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  atm_isBulkEditMode,
  atm_isEditSubCatModalOpen,
  atm_isManageRulesModal,
  atm_isManageTagsModal,
  atm_IsMobTransactionFilter,
  atm_pageOptions,
  atm_selectedDataForBulkEdit,
} from "src/state/atomsTransactionData";
import AccountNavigation from "../AccountNavigation";
import BulkEditBox from "./BulkEditBox";
import useDeviceType from "src/hooks/useDeviceType";
import EditCategoryModal from "../EditCategoryModal/EditCategoryModal";
import ManageTagsModal from "./ManageTagsModal";
import { Row } from "src/components/shared";
import KmnButton from "./KmnButton";
import ManageRulesModal from "../Rules/ManageRulesModal";

export const TrasactionRightSide = ({
  isTransactionListLoading,
  transactionsListTable,
  clmnTransactions,
  mobOptions,
  setMobOptions,
}) => {
  const parentScroller = useRef(null);
  const { dataTransactions, fnsTransactions } = useTransactionsLanding();

  const [dropdownActive, setdropdownActive] = useState(null);

  const setIsMobFilter = useSetRecoilState(atm_IsMobTransactionFilter);

  const [pageOptions, setPageOptions] = useState({
    pageNumber: 0,
    pageSize: 25,
  });

  const setGlobalPageOption = useSetRecoilState(atm_pageOptions);

  const initialRenderRef = useRef(true);

  const width = GetWindowWidth();


  const isBulkEditMode = useRecoilValue(atm_isBulkEditMode)
  const {isMobile } = useDeviceType();

  const [selectedDataForBulkEdit ,setSelectedDataForBulkEdit] = useRecoilState(atm_selectedDataForBulkEdit);

  const isEditSubCatModalOpen = useRecoilValue(atm_isEditSubCatModalOpen)

  const isManageTagsModal = useRecoilValue(atm_isManageTagsModal)

  const [isManageRulesModal, setIsManageRulesModal] = useRecoilState(atm_isManageRulesModal)

  const triggerManageRulesModal = () => {
    setIsManageRulesModal(true);
  }

  const cancelManageRulesModal = () => {
    setIsManageRulesModal(false);
  }

  const handleRowSelection = (rows) => {
    setSelectedDataForBulkEdit(prevData => ({...prevData, transactionIds: rows}))
  }

  const handlePaginationChange = (options) => {
    const { page: pageNumber, pageSize} = options;
    setPageOptions({pageNumber, pageSize})
    setGlobalPageOption({pageNumber, pageSize});
  }

  useEffect(() => {
    if (!initialRenderRef.current) {
      fnsTransactions.getTransactionsData(
        pageOptions.pageNumber,
        pageOptions.pageSize
      );
    } else {
      initialRenderRef.current = false;
    }
  }, [pageOptions]);

  return (
    <>
      {(dropdownActive === "second" ||
        dropdownActive === "first" ||
        dropdownActive === "third" ||
        dropdownActive === "fourth" ||
        dropdownActive === "fifth" ||
        dropdownActive === "sixth") && (
        <span
          className="background-bg"
          onClick={(e) => {
            setdropdownActive(null);
          }}
        ></span>
      )}

      <div className="transaction-right-cover">
        <Row justify="between">
          {width > 900 && <AccountNavigation />}
          {/* <KmnButton variant='secondary' color="primary" onClick={triggerManageRulesModal}>Rules</KmnButton> */}
        </Row>
        <div
          hidden={width < 900 && mobOptions === 2}
          className="transaction-right-side"
        >
          {width < 900 && mobOptions === 3 && (
            <ul className="transaction-mob-options">
              <li
                className={mobOptions === 1 ? "active" : ""}
                onClick={() => {
                  setMobOptions(1);
                }}
              >
                All
              </li>
              <li
                className={mobOptions === 2 ? "active" : ""}
                onClick={() => {
                  setMobOptions(2);
                }}
              >
                My Accounts
              </li>
              <li
                className={mobOptions === 3 ? "active" : ""}
                onClick={() => {
                  setMobOptions(3);
                }}
              >
                Income/Expense Trend
              </li>
            </ul>
          )}
          <div className="transaction-right-inside">
            {(!isBulkEditMode || isMobile) && <div className="top-area">
              <h1>
                <span>Income/Expense Trend</span>
                {width < 900 && (
                  <div className="filter-cover">
                    <img
                      onClick={() => setIsMobFilter(true)}
                      src={FiltersMob}
                      alt=""
                    />
                  </div>
                )}
              </h1>
              <div className="right-top-area">
                <div className="box-round">
                  <span className="blue-box box"></span>
                  <p>Income</p>
                </div>
                <div className="box-round">
                  <span className="red-box box"></span>
                  <p>Expense</p>
                </div>
                <div className="box-round">
                  <span className="yellow-box box"></span>
                  <p>Net cash flow</p>
                </div>
              </div>
            </div>}
            {isBulkEditMode && <BulkEditBox />}
            <div
              id="infinite-scroller"
              className="scrollable-content"
              ref={parentScroller}
            >
              <div className="scrollable-content-inside">
                {!isBulkEditMode && <TransactionChart />}
                {width < 900 ? (
                  <MobTransTable parentScroller={parentScroller} />
                ) : (
                  <div
                    className="transactionTable"
                    style={{
                      width: "100%",
                      height: isBulkEditMode ? "100%" : undefined,
                      position: "relative",
                      marginTop: isBulkEditMode ? 0 : 20
                    }}
                  >
                    {isTransactionListLoading && (
                      <Box sx={{ width: "100%" }}>
                        <LinearProgress
                          sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                          }}
                        />
                      </Box>
                    )}

                    <DataGrid
                      checkboxSelection={isBulkEditMode}
                      disableRowSelectionOnClick
                      rowSelectionModel={selectedDataForBulkEdit?.transactionIds || []}
                      onRowSelectionModelChange={(newRowSelectionModal) => {
                        handleRowSelection(newRowSelectionModal);
                      }}
                      isRowSelectable={params => !params.row.pending}
                      getRowHeight={() => "auto"}
                      getRowClassName={(params) => {
                        if (params.row.pending) {
                          return "pending";
                        }
                      }}
                      autoHeight={!isBulkEditMode}
                      rows={transactionsListTable}
                      columns={clmnTransactions}
                      backgroundColor="black"
                      sx={{
                        borderLeft: 0,
                        borderRight: 0,
                        ".MuiDataGrid-row:hover":{
                          background: 'transparent'
                        },
                        ".MuiDataGrid-cellCheckbox .MuiSvgIcon-root, .MuiDataGrid-columnHeaderTitleContainerContent .MuiSvgIcon-root": {
                          fill: 'var(--color1)'
                        },
                        ".pending .MuiDataGrid-cellCheckbox .MuiSvgIcon-root": {
                          display: "none"
                        }
                      }}
                      pagination
                      paginationModel={{page: pageOptions.pageNumber, pageSize: pageOptions.pageSize}}
                      initialState={{
                        pagination: {
                          paginationModel: {page: pageOptions.pageNumber, pageSize: pageOptions.pageSize},
                        },
                      }}
                      pageSizeOptions={[25, 50, 100]}
                      paginationMode="server"
                      onPaginationModelChange={handlePaginationChange}
                      loading={dataTransactions.isTransactionListLoading}
                      rowCount={dataTransactions.allTransactionDataCount}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {isEditSubCatModalOpen &&<EditCategoryModal />}
      {isManageTagsModal && <ManageTagsModal />}
      {isManageRulesModal && <ManageRulesModal cancelManageRulesModal={cancelManageRulesModal} />}
    </>
  );
};

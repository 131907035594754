import {
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";
import { useRecoilState, useResetRecoilState } from "recoil";
import useDashboard from "src/components/Dashboard/useDashboard";
import { atm_financialTextData, atm_selectedDetailsData } from "src/state/atomDashboard";

const SelectCollegeType = () => {
  const { dataDashboard } = useDashboard();

  const [selectedDetailsData, setSelectedDetailsData] = useRecoilState(
    atm_selectedDetailsData
  );
  const resetFinancialTextData = useResetRecoilState(atm_financialTextData);

  const handleChange = (event) => {
    setSelectedDetailsData({...selectedDetailsData , collegeType: event.target.value});
    resetFinancialTextData();
  };
  return (
    <FormControl
      className="selectionDropdown select"
      variant="standard"
      sx={{ width: 170, textAlign: "center", border: "none" }}
    >
      <InputLabel
        id="labelAction"
        sx={{
          fontSize: 12,
          textAlign: "center",
          left: 5,
          top: -12,
        }}
      >
        Choose your college type
      </InputLabel>
      <Select
        labelId="labelAction"
        id="Action"
        value={selectedDetailsData.collegeType}
        onChange={handleChange}
        sx={{
          paddingBottom: 0,
          textAlign: "center",
        }}
      >
        {dataDashboard.collegeTypes.map((data, index) => [
          <MenuItem value={data.id}>{data.value}</MenuItem>,
          dataDashboard.collegeTypes.length - 1 !== index && (
            <Divider sx={{ mx: "10px" }} />
          ),
        ])}
      </Select>
    </FormControl>
  );
};

export default SelectCollegeType;

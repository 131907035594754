import React, { useState } from "react";
import CloseLogo from "../../../../assets/images/close3.svg";
import SaveLogo from "../../../../assets/images/done2.svg";

import {
  MenuItem,
  Popover,
  TextField,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { useSetRecoilState } from "recoil";
import { atm_goalActivities } from "src/state/atomDashboard";
import {
  validateDay,
  validateYear,
  validateYearOnly,
} from "src/validations/Goals";
import { yearFrom, yearTo } from "src/global/central";

const EditActivity = ({ activity, index, isEdit, anchor, setIsEdit }) => {
  const [updatedActivity, setUpdatedActivity] = useState({
    activityId: activity?.activityId,
    activityName: activity?.activityName,
    activityDate: activity?.activityDate,
    activityMonth: activity?.activityMonth,
    activityYear: activity?.activityYear,
    status: 0,
  });
  const isMobile = useMediaQuery("(max-width: 750px)");
  const setGoalsActivity = useSetRecoilState(atm_goalActivities);
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const handleFieldChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (name === "activityName") {
      setUpdatedActivity((prevData) => ({ ...prevData, [name]: value }));
    } else {
      setUpdatedActivity((prevData) => ({ ...prevData, [name]: +value }));
    }
  };

  const validateData = () => {
    const { activityName, activityMonth, activityYear } = updatedActivity;
    return activityName && activityMonth && activityYear;
  };

  const handleSave = () => {
    if (!validateYearOnly(updatedActivity.activityYear)) {
      return;
    }
    if (validateData()) {
      setGoalsActivity((prevData) => {
        const newData = [...prevData];
        newData[index] = updatedActivity;
        return newData;
      });
      setIsEdit(null);
    } else {
      console.error("Please fill in all fields");
    }
  };

  const handleClose = () => {
    setIsEdit(null);
    setUpdatedActivity({
      activityName: activity?.activityName,
      activityDate: activity?.activityDate,
      activityMonth: activity?.activityMonth,
      activityYear: activity?.activityYear,
      status: 0,
    });
  };

  return (
    <Popover
      sx={{
        ".MuiPaper-root": {
          boxShadow: 0,
          borderRadius: 0,
          width: 800,
          transform: isMobile
            ? "translate(0,-40px) !important"
            : "translate(55px,-40px) !important",
          maxWidth: "90.5%",
        },
      }}
      onClose={handleClose}
      open={isEdit == index}
      anchorEl={anchor}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: 0,
      }}
    >
      <div className="editActivity">
        <div className="heading">
          <div className="text">Edit Activity</div>
          <span className="btns" style={{ display: "flex", gap: 8 }}>
            <img
              height={23.5}
              className="save"
              onClick={handleSave}
              src={SaveLogo}
              alt=""
            />
            <img
              height={23.5}
              className="closeBtn"
              onClick={handleClose}
              src={CloseLogo}
              alt=""
            />
          </span>
        </div>
        <div className="fields">
          <div className="date-box" style={{ width: "50%" }}>
            <TextField
              defaultValue={updatedActivity.activityDate}
              onChange={(e) => {
                validateDay(e);
                handleFieldChange(e);
                if (e.target.value == "") {
                  setUpdatedActivity({
                    ...updatedActivity,
                    [e.target.name]: 1,
                  });
                }
              }}
              name="activityDate"
              variant="standard"
              type="tel"
              sx={{ width: 80 }}
              inputProps={{ maxLength: 2, placeholder: "DD", max: 2 }}
            />

            <div className="monthCover">
              <label hidden={updatedActivity.activityMonth}>MM</label>
              <TextField
                defaultValue={updatedActivity.activityMonth}
                onChange={handleFieldChange}
                name="activityMonth"
                variant="standard"
                sx={{
                  width: 80,
                }}
                select
              >
                {monthNames.map((month, index) => (
                  <MenuItem key={index} value={index + 1}>
                    {month}
                  </MenuItem>
                ))}
              </TextField>
            </div>

            <Tooltip title={`Enter value in range b/w ${yearFrom} - ${yearTo}`}>
              <TextField
                defaultValue={updatedActivity.activityYear}
                onChange={(e) => {
                  validateYear(e);
                  handleFieldChange(e);
                }}
                name="activityYear"
                variant="standard"
                type="tel"
                sx={{ width: 80 }}
                inputProps={{ maxLength: 4, placeholder: "YYYY" }}
              />
            </Tooltip>
          </div>
          <TextField
            defaultValue={updatedActivity.activityName}
            name="activityName"
            label="Activity title"
            sx={{ width: "50%" }}
            onChange={handleFieldChange}
            placeholder="e.g. Arrange accomodation"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
      </div>
    </Popover>
  );
};

export default EditActivity;

import {
  Alert,
  Box,
  IconButton,
  InputAdornment,
  LinearProgress,
  Snackbar,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import ErrorIcon from "../assets/images/error.svg";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { checkPassword } from "src/validations/Password";
import useForgotPassword from "./useForgotPassword";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  atm_resetCode,
  atm_resetEmail,
  atm_responseMessage,
} from "src/state/atomForgotPassword";

const ResetPassword = () => {
  const { dataPassword, fnsPassword } = useForgotPassword();
  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");
  const email = useRecoilValue(atm_resetEmail);
  const code = useRecoilValue(atm_resetCode);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(false);
  const setResponseMessage = useSetRecoilState(atm_responseMessage);
  const handleResetPassword = (e) => {
    e.preventDefault();
    if (!checkPassword(password) || password != cPassword) {
      setError(true);
      return;
    }
    fnsPassword.resetPassword({ email, code, password, cPassword });
  };
  return (
    <>
      <div className="resetPassPage passPage">
        <div className="resetPassBox passBox">
          {dataPassword.passwordLoading && (
            <Box sx={{ width: "100%" }}>
              <LinearProgress
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                }}
              />
            </Box>
          )}
          <form onSubmit={handleResetPassword} style={{ width: "100%" }}>
            <div className="head">Reset Password</div>
            <div className="subHead">Please enter your password</div>
            <TextField
              label="New Password"
              className="passwordInput"
              type={showPassword ? "text" : "password"}
              onChange={(e) => {
                setPassword(e.target.value);
                setError(false);
              }}
              sx={{
                width: "100%",
                marginTop: "14px",
              }}
              error={error}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
                autoComplete: "off",
              }}
            />
            <TextField
              label="Confirm Password"
              className="passwordInput"
              type={showPassword ? "text" : "password"}
              onChange={(e) => {
                setCPassword(e.target.value);
                setError(false);
              }}
              sx={{
                width: "100%",
                marginTop: "14px",
              }}
              error={error}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
                autoComplete: "off",
              }}
            />
            <p
              className="error-custom grid-2"
              style={{
                margin: "10px 0px",
                opacity: error ? 1 : 0,
                textAlign: "left",
              }}
            >
              <span>
                {error && (
                  <img
                    src={ErrorIcon}
                    style={{ marginRight: 10 }}
                    alt="error"
                  />
                )}
                {error &&
                  (password !== cPassword
                    ? "Passwords do not match"
                    : "Password should have a minimum of 6 characters, must have at least one uppercase character, at least one lowercase character, at least one number, and at least one special character")}
              </span>
            </p>
            <button
              disabled={dataPassword.passwordLoading}
              type="submit"
              className="resetBtn btn"
            >
              Reset Password
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;

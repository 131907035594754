import React, { useEffect, useState } from "react";
import { Popover } from "@mui/material";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { atm_isBulkEditMode, atm_isEditSubCatModalOpen, atm_selectedDataForBulkEdit, atm_selectedSubCatForEdit, atm_transactionCategoryID } from "src/state/atomsTransactionData";
import { ArrowDropDownRounded, ArrowDropUpRounded } from "@mui/icons-material";
import useManageCategories from "./useManageCategories";
import AddIcon from "../assets/images/add-icon.svg";
import { SUBCATEGORY_MODE } from "src/global/constants";
import PencilIcon from "../assets/images/pencil2.svg";
import useDeviceType from "src/hooks/useDeviceType";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const CategoryPopUp = ({ id, anchorValue, isOpen, onClose, bulkEdit, setSelectedDataForBulkEdit }) => {
  const [searchQuery, setSearchQuery] = useState("");

  const { data, fnsManageCategories } = useManageCategories();
  const selectedCategoryForBulkEdit = useRecoilValue(atm_selectedDataForBulkEdit);
  const [categoryID, setCategoryID] = useRecoilState(atm_transactionCategoryID);

  const [activeList, setActiveList] = useState([]);
  const [activeList2, setActiveList2] = useState([]);
  const setIsEditSubCatModalOpen = useSetRecoilState(atm_isEditSubCatModalOpen);
  const setSelectedSubCatForEdit = useSetRecoilState(atm_selectedSubCatForEdit);
  const isBulkEditMode = useRecoilValue(atm_isBulkEditMode)
  const {isMobile} = useDeviceType();

  const toggleActiveList = (index) => {
    const isExpanded = activeList.includes(index);

    if (isExpanded) {
      setActiveList(activeList.filter((ind) => ind !== index));
    } else {
      setActiveList([...activeList, index]);
    }
  };

  useEffect(() => {
    const matchedIndices = data.categoriesData.reduce((indices, catData, index) => {
      const matchesInCategory = catData.categories.some((subCategoryLvl2) =>
        subCategoryLvl2.categories.some((subCategoryLvl3) => {
          const combinedName = subCategoryLvl2.name + " - " + subCategoryLvl3.name;

          return combinedName.toLowerCase().includes(searchQuery.toLowerCase());
        })
      );
      if (matchesInCategory && searchQuery.trim().length) {
        indices.push(index);
      }
      return indices;
    }, []);

    setActiveList(matchedIndices);
    setActiveList2(matchedIndices);
  }, [searchQuery]);

  const handleClose = () => {
    if (onClose) onClose();
    setCategoryID(null);
  };

  const handleCategorySelection = (transactionIds, kmnCategoryMasterId, topLevelSourceId, categoryName) => {
    if (onClose) onClose();
    if (bulkEdit) {
      setSelectedDataForBulkEdit((prevData) => ({ ...prevData, kmnCategoryMasterId, topLevelSourceId, categoryName }));
    } else {
      fnsManageCategories.updateCategory({ transactionIds, kmnCategoryMasterId, topLevelSourceId, categoryName });
      setCategoryID(null);
    }
  };

  const handleCreateSubCategory = () => {
    handleClose();
    setIsEditSubCatModalOpen(SUBCATEGORY_MODE.CREATE);
  }
  
  const handleEditSubCategory = (cat, subCat) => {
    handleClose()
    setSelectedSubCatForEdit({
      category: cat,
      subCategory: subCat
    })
    setIsEditSubCatModalOpen(SUBCATEGORY_MODE.EDIT);
  }

  return (
    <Popover
      id={id}
      className="popOverCover"
      open={(categoryID != null && categoryID == id) || isOpen}
      anchorEl={anchorValue}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      sx={{
        ".MuiPaper-root": {
          width: isMobile ?  "calc(100% - 112px)": undefined,
          maxHeight: 250
        }
      }}
    >
      <ul
        className="categoryPopUp"
        style={{
          width: 250,
        }}
      >
        <div className="body">
          <input type="text" placeholder="Search..." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
          {data.categoriesData.map((catData, index) => {
            return (
              (activeList2.includes(index) || searchQuery.trim().length == "") && (
                <li key={index}>
                  <div className="head" onClick={() => toggleActiveList(index)}>
                    {catData.name}
                    {activeList.includes(index) ? <ArrowDropUpRounded sx={{ color: "var(--color1)" }} /> : <ArrowDropDownRounded sx={{ color: "var(--color1)" }} />}
                  </div>
                  {activeList.includes(index) && (
                    <div className="dropdown">
                      {data.categoriesData[index].categories.map((subCategoryLvl2) => {
                        return subCategoryLvl2.categories
                          .filter((subCategoryLvl3) => {
                            const combinedName = subCategoryLvl2.name + " - " + subCategoryLvl3.name;

                            return combinedName.toLowerCase().includes(searchQuery.toLowerCase());
                          })
                          .map((subCategoryLvl3, i) => {
                            const combinedName = subCategoryLvl2.name + " - " + subCategoryLvl3.name;
                            return (
                              <div className={`lvl-2-3 ${selectedCategoryForBulkEdit?.kmnCategoryMasterId === subCategoryLvl3?.kmnId && bulkEdit && "active"}`} key={i}>
                                <div className={`label ${subCategoryLvl3?.editable && 'editableLabel'}`} onClick={() => handleCategorySelection([categoryID], subCategoryLvl3.kmnId, catData.sourceId, combinedName)}>{combinedName}</div> {!isBulkEditMode && subCategoryLvl3?.editable && <img onClick={() => handleEditSubCategory(subCategoryLvl2, subCategoryLvl3)} alt="" src={PencilIcon} />}

                              </div>
                            );
                          });
                      })}
                    </div>
                  )}
                </li>
              )
            );
          })}
        </div>
        {!isBulkEditMode && <button className="addNewSubCatBtn" onClick={handleCreateSubCategory}>
         <AddCircleIcon /> Add New Category
        </button>}
      </ul>
    </Popover>
  );
};

export default CategoryPopUp;

import { Box, Rating } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ReviewLogo from "../../assets/images/reviewLogo.svg";
import ReviewTrueLogo from "../../assets/images/reviewTrueLogo.svg";
import ArrowDown from "../../assets/images/arrow-down2.svg";
import { usdFormate } from "src/global/central";
import useTransactionsLanding from "./useTransactionsLanding";
import { useSetRecoilState } from "recoil";
import { atm_transactionCategoryID } from "src/state/atomsTransactionData";
import CategoryPopUp from "../../ManageCategories/CategoryPopUp";
import Tags from "./Tags";

const TransListDropdown = ({ transactionData, merchant }) => {
  const { fnsTransactions } = useTransactionsLanding();

  const setTransactionCategoryID = useSetRecoilState(atm_transactionCategoryID);

  const categoryElem = useRef(null);

  const [anchorCategory, setAnchorCategory] = useState(categoryElem.current);

  const [active, setActive] = useState(false);

  const formattedDate = new Date(
    transactionData.transactionDate
  ).toLocaleDateString(undefined, {
    day: "numeric",
    month: "short",
    year: "numeric",
  });

  useEffect(() => {
    setAnchorCategory(categoryElem.current);
  }, [active]);

  const handleUserRatingClick = (event, newValue) => {
    fnsTransactions.updateUserRating(transactionData.transactionId, newValue);
  };

  return (
    <div
      className={`mobTransTableRow ${
        transactionData.pending ? "pending" : undefined
      }`}
    >
      <div
        className="mobTransTableHeaders"
        onClick={(e) => {
          if (transactionData.pending) {
            return;
          }
          setActive(!active);
        }}
      >
        {merchant ? (
          <div className="custodian-cell">
            <div className="custodian">{transactionData.accountFI}</div>
            <div className="account-number">{transactionData.accountName}</div>
          </div>
        ) : (
          <div className="merchant">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="5"
              viewBox="0 0 8 5"
              className={active ? "activeSvg" : undefined}
            >
              <path
                d="M2.90573 0.301366L0.305725 2.91352C-0.0109418 3.23166 -0.0816084 3.59569 0.093725 4.0056C0.269058 4.4155 0.581392 4.62079 1.03073 4.62146H6.18072C6.63072 4.62146 6.94339 4.41617 7.11872 4.0056C7.29406 3.59502 7.22306 3.23099 6.90573 2.91352L4.30572 0.301366C4.20572 0.200899 4.09739 0.125549 3.98073 0.0753155C3.86406 0.0250816 3.73906 -3.57628e-05 3.60573 -3.57628e-05C3.47239 -3.57628e-05 3.34739 0.0250816 3.23073 0.0753155C3.11406 0.125549 3.00573 0.200899 2.90573 0.301366Z"
                fill={active ? "var(--color1)" : "#000"}
              />
            </svg>
            {transactionData?.merchantLogoUrl && (
              <img srcSet={transactionData.merchantLogoUrl} alt="logo" />
            )}
            <div className="value">{transactionData.merchantName}</div>
          </div>
        )}
        <div className="amount-date">
          <div
            className="amount cell"
            style={{
              color:
                transactionData.transactionAmount < 0 || merchant
                  ? "#000"
                  : "var(--color1)",
            }}
          >
            {usdFormate(
              merchant
                ? -transactionData.transactionAmount
                : transactionData.transactionAmount
            )}
          </div>
          <div className="date-cell">{formattedDate}</div>
        </div>
      </div>
      {active && !merchant && (
        <div className={`mobTransTableInside ${active && "active"}`}>
          <div className="parentRow">
            <div className="custodian name row">
              <div className="head">CUSTODIAN</div>
              <div className="value">{transactionData.accountFI}</div>
            </div>
            <div className="account name row">
              <div className="head">ACCOUNT</div>
              <div className="value">
                {transactionData.accountName} -{" "}
                {transactionData.maskedAccountNumber}
              </div>
            </div>
          </div>
          <div
            ref={categoryElem}
            className="category sub-category row"
            onClick={(e) => {
              setTransactionCategoryID(transactionData.transactionId);
            }}
          >
            {transactionData?.pfcCategoryLogoUrl && (
              <img srcSet={transactionData.pfcCategoryLogoUrl} alt="" />
            )}
            <div className="category-inside">
              <div className="head">
                <div className="text">CATEGORY</div>
                <img src={ArrowDown} alt="" />
              </div>
              <div className="value">
                {transactionData.transactionCategory} -{" "}
                {transactionData.transactionSubCategory}
              </div>
            </div>
          </div>
          <CategoryPopUp
            id={transactionData.transactionId}
            anchorValue={anchorCategory}
          />
          <div className="tags-box-cover">
            <div className="head">
              <div className="text">TAGS</div>
            </div>
            <Tags isPending={transactionData.pending} transactionId={transactionData.transactionId} associatedTags={transactionData.tagsList} />
          </div>

          <div className="rating-review row">
            <Box>
              <Rating
                name="simple-controlled"
                value={transactionData.userRating}
                onChange={(event, newValue) => {
                  handleUserRatingClick(event, newValue);
                }}
              />
            </Box>
            
            <div
              onClick={() =>
                fnsTransactions.handleUserReviewClick(transactionData)
              }
              className={`review-cell ${
                transactionData.noOfReviews > 0 ? "review-true" : ""
              }`}
            >
              <img
                src={
                  transactionData.noOfReviews > 0 ? ReviewTrueLogo : ReviewLogo
                }
                alt=""
              />
              <span className="review-count">
                Reviews
                {transactionData.noOfReviews > 0 && (
                  <span>{`(${transactionData.noOfReviews})`}</span>
                )}
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TransListDropdown;

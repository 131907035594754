import React from "react";
import focusImage from "../assets/images/focus.webp";
import offer from "../assets/images/offer.svg";
import box from "../assets/images/box.svg";
import add_no from "../assets/images/add_no.svg";
const Focus = () => {
  return (
    <div className="learn-wrapper focus-wrapper">
      <div className="circle"></div>
      <div className="right-side">
        <h2>Our focus is on YOU</h2>{" "}
        <img src={focusImage} alt="Users recommending personal finance platform to peers by pointing at spending and investment graphs and tracking goals on computer screen" className="mobile-pic w-set" />
        <div className="row-area">
          <img src={offer} alt="Personalized deals and offers from merchants based on user’s spending behavior, income range, location and other factors" />
          <div className="presentation">
            <h3>Personalized Offers</h3>
            <p>
              Your preferences and needs are our top priority. We learn from
              your spending and investment behaviors, understand what's
              happening in your neighborhood and make hyper-personalized offer
              to you.
            </p>
          </div>
        </div>
        <div className="row-area">
          <img src={box} alt="Multitude of financial services included in the platform making it a one stop shop for all money decisions" />
          <div className="presentation">
            <h3>One-stop-shop for every consumer convenience</h3>
            <p>
              There are many platforms today that serve your specific needs:
              from account aggregation to merchant ratings and reviews, but none
              of them look at you holistically, and hence provide sub-par
              service. We look at you in totality, make an effort to understand
              you, understand your spending patterns, determine your preferences
              based on repeat spending at a particular merchant, your income
              range, and thus provide you a single platform to manage all of
              your needs in an effective, yet personalized manner.
            </p>
          </div>
        </div>
        <div className="row-area">
          <img src={add_no} alt="Advertisement (Ads) free policy of financial platform enabling better user experience with no distractions" />
          <div className="presentation">
            <h3>No ads. </h3>
            <p>
              We want you to get the most out of the platform. There are No ads
              to distract you, so you can focus on what's important. Get in, and
              get done quickly. [We do have offers, which are tucked away neatly
              in their own sections on the website]
            </p>
          </div>
        </div>
      </div>
      <img src={focusImage} alt="Users recommending personal finance platform to peers by pointing at spending and investment graphs and tracking goals on computer screen" />
    </div>
  );
};

export default Focus;

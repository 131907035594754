import * as React from 'react';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#27c1e4' : '#308fe8',
  },
}));


export const ProgressBar = ({what, status}) => {
  return (
    <div className="linking-account-progress">
      <div className="top">
        <h1>{what}</h1>
        <p>{status}</p>
      </div>
      <Stack sx={{ height: '15px',width: '100%', color: '#27c1e4' }} spacing={5}>
        <BorderLinearProgress color='inherit' />
      </Stack>

      {/* <div className="progress-bar">
        <span></span>
      </div> */}
    </div>
  );
};

/**********************************************************************
 *
 *   Component hook generated by Quest
 *
 *   Code Logic for the component goes in this hook
 *   To setup bindings that use the data here or call the functions here, use the Quest editor
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/

import { useNavigate } from "react-router-dom";

import { useState } from "react";

import { useResetRecoilState, useSetRecoilState } from "recoil";

import {
  atm_isUserLoggedIn,
  atm_mainMenuButtonPressed,
  atm_jwt,
  atm_menuInsightsType,
  atm_globalFilterValue,
  atm_globalFilterCount,
  atm_isCategoryFilterModified,
} from "../../state/atoms";
import {
  atm_accountsList,
  atm_isSelectAllAccountList,
  atm_selectedAccountIds,
  atm_selectedUnchangedAccountIds,
} from "../../state/atomsAccountList";
import {
  atm_transactionsChartStats,
  atm_transactionsList,
} from "../../state/atomsTransactionData";

import {
  atm_HoldingsChartStats,
  atm_lstSecuritiesByUser,
} from "../../state/atomsHoldingData";
import { removeAuthTokens } from "src/global/central";
import { atm_allGoals } from "src/state/atomDashboard";

const useMainMenu = () => {
  let data = {};

  const setIsUserLoggedIn = useSetRecoilState(atm_isUserLoggedIn);
  const setJWT = useSetRecoilState(atm_jwt);
  const setAccountList = useSetRecoilState(atm_accountsList);
  const setTransactionList = useSetRecoilState(atm_transactionsList);
  const setSecuritiesByUserList = useSetRecoilState(atm_lstSecuritiesByUser);

  const setMainMenuButtonPressed = useSetRecoilState(atm_mainMenuButtonPressed);
  const setMenuInsightsType = useSetRecoilState(atm_menuInsightsType);

  const [insightsMenuOpen, setInsightsOpen] = useState(null);
  data.insightsMenuOpen = insightsMenuOpen;

  const setTransactionChartStats = useSetRecoilState(
    atm_transactionsChartStats
  );

  const setHoldingsChartStats = useSetRecoilState(atm_HoldingsChartStats);

  const resetGlobalFilter = useResetRecoilState(atm_globalFilterValue);

  const resetGlobalFilterCount = useResetRecoilState(
    atm_globalFilterCount
  );

  const resetIsCategoryFilterModified = useResetRecoilState(
    atm_isCategoryFilterModified
  );

  const resetSelectedUnchangedAccoutIds = useResetRecoilState(
    atm_selectedUnchangedAccountIds
  );

  const resetIsSelectedAll = useResetRecoilState(atm_isSelectAllAccountList);

  const resetSelectedAccoutIds = useResetRecoilState(atm_selectedAccountIds);

  const resetAllGoals = useResetRecoilState(atm_allGoals);

  const [anchorEl, setAnchorEl] = useState(null);
  data.anchorEl = anchorEl;

  let navigate = useNavigate();
    
  const onMenuClickSignUp = () => {
    setMainMenuButtonPressed("SignUp");

    navigate("/signup");
  };

  const onMenuClickHome = () => {
    navigate("/");
    setMainMenuButtonPressed("Home");
  };

  const onMenuClickAbout = () => {
    navigate("/about");
    setMainMenuButtonPressed("About");
  };

  const onMenuClickCalculators = () => {
    navigate("/calculators/college");
    setMainMenuButtonPressed("Calculators");
  };
  
  const onMenuClickTrends = () => {
    navigate("/trends");
    setMainMenuButtonPressed("Trends");
  };

  const onMenuClickDashboard = () => {
    navigate("/dashboard");
    setMainMenuButtonPressed("Dashboard");
  };

  const onMenuClickLogin = () => {
    setMainMenuButtonPressed("LogIn");

    resetState();
    navigate("/login");
  };

  const onMenuClickLogOut = () => {
    setMainMenuButtonPressed("Home");

    resetState();

    navigate("/");
  };

  const onMenuClickTransactions = () => {
    setMainMenuButtonPressed("Transactions");
    navigate("/transactions");
  };

  const onMenuClickHoldings = () => {
    setMainMenuButtonPressed("Holdings");
    navigate("/holdings");
  };

  const onMenuClickInsightsGraphs = () => {
    setMainMenuButtonPressed("InsightsGraphs");
    setMenuInsightsType("SPEND");
    navigate("/insights");
  };

  const onMenuClickInsights = () => {
    setMainMenuButtonPressed("Insights");
    navigate("/insights");

    //  setMainMenuButtonPressed("Insights");
    //  setMenuInsightsType("SPEND");
  };

  const onClickMyHousehold = () => {
    setMainMenuButtonPressed('HouseHold');
    navigate('/household');
  };

  const onMenuClickInsightsClose = () => {
    setInsightsOpen(false);
    setAnchorEl(null);
  };

  // const onClickInsightSpendByCategory = () => {
  // 	setMainMenuButtonPressed('Insights');
  // 	setMenuInsightsType('SpendByCategory');

  // 	setInsightsOpen(false);
  // 	navigate('/insights/spendByCategory');
  // };

  // new - insights category 👇
  const onClickInsightNewSpendByCategory = () => {
    // setInsightsOpen(false);
    setMainMenuButtonPressed("Insights");
    // setMainMenuButtonPressed('Insights-new');
    // setMenuInsightsType('SpendByCategory-new');
    setMenuInsightsType("SpendByCategory");
    navigate("/insights/spendByCategory");
  };

  // const onClickInsightSpendByMerchant = () => {
  // 	setMainMenuButtonPressed('Insights');
  // 	setMenuInsightsType('SpendByMerchant');

  // 	setInsightsOpen(false);
  // 	navigate('/insights/spendByMerchant');
  // };

  // new - insights merchants 👇
  const onClickInsightNewSpendByMerchant = () => {
    // setMainMenuButtonPressed('Insights-new');
    setMainMenuButtonPressed("Insights");
    // setMenuInsightsType('SpendByMerchant-new');
    setMenuInsightsType("SpendByMerchant");
    navigate("/insights/spendByMerchant");
  };

  const onClickChangePassword = () => {
    setMainMenuButtonPressed("Profile");
    navigate("/changePassword");
  };

  const onClickMyProfile = () => {
    setMainMenuButtonPressed("Profile");
    navigate("/myProfile");
  };

  function resetState() {
    setIsUserLoggedIn(false);
    setJWT("");
    //		window.sessionStorage.removeItem('session-token');
    removeAuthTokens();
    setAccountList([]);
    setTransactionList([]);
    setSecuritiesByUserList([]);
    setTransactionChartStats([]);
    setHoldingsChartStats([]);
    resetGlobalFilter();
    resetGlobalFilterCount();
    resetIsCategoryFilterModified();

    resetSelectedUnchangedAccoutIds();
    resetIsSelectedAll();
    resetSelectedAccoutIds();
	  resetAllGoals();
    localStorage.clear();

  }

  let fns = {
    onMenuClickSignUp,
    onMenuClickHome,
    onMenuClickAbout,
    onMenuClickDashboard,
    onMenuClickLogin,
    onMenuClickLogOut,
    onMenuClickTransactions,
    onMenuClickHoldings,
    onMenuClickInsights,
    onMenuClickInsightsGraphs,
    // onClickInsightSpendByCategory,
    // onClickInsightSpendByMerchant,
    onMenuClickInsightsClose,
    onClickInsightNewSpendByCategory,
    onClickInsightNewSpendByMerchant,
    onClickChangePassword,
    onClickMyProfile,
    onClickMyHousehold,
    onMenuClickCalculators,
    onMenuClickTrends
  };
  return { data, fns };
};

export default useMainMenu;

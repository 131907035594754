import { useState } from 'react';
import { styled } from '@mui/material/styles';

import {
	Alert,
	TextField,
	Drawer,
	Box,
	Button,
	Divider,
	List,
} from '@mui/material';

import { atm_mainMenuButtonPressed } from 'src/state/atoms';
import { useRecoilValue } from 'recoil';

import { atm_firstName, atm_lastName } from 'src/state/atoms';

import {
	KeyboardArrowRightSharp,
	ArrowBack,
} from '@mui/icons-material';

import EditProfileDrawer from './EditProfileDrawer';
import ChangePasswordDrawer from './ChangePasswordDrawer';
import HouseHoldDrawer from './HouseHoldDrawer';


export default function ProfileDrawer() {


	const [state, setState] = useState(false);

	const toggleDrawer = (anchor, open) => () => {
		setState({ ...state, [anchor]: open });
	};

	const mainMenuButtonPressed = useRecoilValue(atm_mainMenuButtonPressed);

	const Name = styled('div')({
		fontSize: "var(--fontSize14)",
		fontWeight: '700',
		marginLeft: 15,
		marginRight: 15,
		textWrap: 'nowrap'
	});

	const firstName = useRecoilValue(atm_firstName);

	const list = anchor => {
		return (
			<List
				sx={{
					margin: '20px',
					borderWidth: '5px',
					borderColor: 'black',
					backgroundColor: 'white',
					boxShadow: '0px 5px 5px -3px rgba(0, 0, 0, 0.2)',
				}}
			>
				<EditProfileDrawer />
				<Divider />
				<ChangePasswordDrawer />
				{/* Change by XL */}
				<Divider />
				<HouseHoldDrawer />

				{/* <Divider /> */}
				{/* <SettingsDrawer /> */}
				{/* <Divider /> */}
				{/* <ListItem
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						marginY: '10px'
					}}
				>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center'
						}}
					>
						<WorkspacePremium
							sx={{ color: '#0C89EF', marginRight: '5px' }}
						/>{' '}
						<strong>Subscription</strong>
					</Box>
					<ArrowForwardIosSharpIcon fontSize='10px' />
				</ListItem>
				<Divider />
				<ListItem
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						marginY: '10px'
					}}
				>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center'
						}}
					>
						<ContactSupport
							sx={{ color: '#0C89EF', marginRight: '5px' }}
						/>{' '}
						<strong>Support</strong>
					</Box>

					<ArrowForwardIosSharpIcon fontSize='10px' />
				</ListItem> */}
			</List>
		);
	};

	return (
		<Box
			sx={{
				width: '100%',
				height: '100%',
				backgroundColor: '#EAF9F6'
			}}
			role='presentation'
			onKeyDown={toggleDrawer('right', true)}
		>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					borderLeft: mainMenuButtonPressed == 'Home' ? 5 : 0,
					borderLeftColor: '#A4379E',
					borderRadius: '5px',
					fontWeight: 'bold',
					padding: 2,
					cursor: "pointer",
				}}
				onClick={toggleDrawer('right', true)}
			>
				{/* <img src={SamplePfp} alt='' height={35} width={35} /> */}

				<Name>Hello, {firstName}</Name>
				<KeyboardArrowRightSharp
					sx={{
						color: 'var(--color1)',
						marginLeft: 'auto'
					}}
				/>
			</Box>
			<Drawer
				variant='persistent'
				anchor={'right'}
				open={state['right']}
				onClose={toggleDrawer('right', false)}
				PaperProps={{
					sx: {
						width: '100%',
						height: '100%',
						backgroundColor: '#EAF9F6'
					}
				}}
			>
				<Box
					sx={{
						width: '100%',
						color: 'black',
						backgroundColor: '#EAF9F6'
					}}
					role='presentation'
					display={'flex'}
					justifyContent={'space-between'}
					paddingTop={1}
					alignItems={'center'}
				>
					<ArrowBack
						sx={{
							fontSize: 30,
							margin: 1,
							marginLeft: 2,
							cursor: 'pointer'
						}}
						onClick={toggleDrawer('right', false)}
					/>

					<strong
						style={{
							fontFamily: 'Nunito',
							fontSize: 24,
							textAlign: 'center'
						}}
					>
						Hello, {firstName}
					</strong>
					<ArrowBack
						sx={{ fontSize: 30, margin: 1, visibility: 'hidden' }}
					/>
				</Box>
				{/* <Box
					display={'flex'}
					flexDirection={'column'}
					alignItems={'center'}
					sx={{
						paddingY: 5,
						backgroundColor: '#EAF9F6'
					}}
				>
					<img src={SamplePfp} alt='' height={150} width={150} />
				</Box> */}
				{list('right')}
			</Drawer>
		</Box>
	);
}

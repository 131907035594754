import React from "react";
import ActivitiesMileStones from "./ActivitiesMilestones";
import { atm_goalActivities } from "src/state/atomDashboard";
import { useRecoilState } from "recoil";
import AddNewActivityPopUp from "./AddNewActivityPopUp";
import Timeline from "@mui/lab/Timeline";
import { useMediaQuery } from "@mui/material";

const Activities = () => {
  const [goalActivities, setGoalActivities] =
    useRecoilState(atm_goalActivities);
  const isMobile = useMediaQuery("(max-width: 750px)");

  const addNewActivity = (newMilestone) => {
    setGoalActivities([...goalActivities, newMilestone]);
  };

  return (
    <div className="activitiesBox">
      {!isMobile && (
        <>
          <div>
            <div className="head">Goal Activities</div>
            <div className="subHead">
              Let’s list the activities needed to make it to college of your
              choice
            </div>
          </div>
          <div className="hr"></div>
        </>
      )}
      <div className="milestone activitiesMilestones scroller">
        <div className="scroller-inside">
          <Timeline
            sx={{
              p: 0,
              pr: !isMobile ? "20px" : 0,
            }}
          >
            {goalActivities.map((data, index) => (
              <div key={index} hidden={data.status}>
                <ActivitiesMileStones key={index} data={data} index={index} />
              </div>
            ))}
          </Timeline>
        </div>
      </div>
      <AddNewActivityPopUp addNewActivity={addNewActivity} />
    </div>
  );
};

export default Activities;

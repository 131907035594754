import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import useTransactionsLanding from "../../Accounts/Transactions/useTransactionsLanding";
import { GetWindowHeight, GetWindowWidth } from "src/global/central";
import { Box, LinearProgress } from "@mui/material";

const CashflowChart = () => {
  const { dataTransactions } = useTransactionsLanding();
  const width = GetWindowWidth();
  const height = GetWindowHeight();

  const monthName = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const formattedCategories = dataTransactions.transactionChartStats.map(
    (item) => {
      const monthIndex = item.month - 1;
      const month = monthName[monthIndex];
      const year = item.year.toString().substring(2);
      return month + ", " + year;
    }
  );
  const [series, setSeries] = useState([
    {
      name: "Income",
      data: [],
    },
    {
      name: "Expense",
      data: [],
    },
    {
      name: "Cash Flow",
      data: [],
      type: "line",
    },
  ]);
  const [maxIncomeValue, setMaxIncomeValue] = useState(12); // Initialize with default value

  useEffect(() => {
    let incomeData = dataTransactions.transactionChartStats.map(
      (item) => item.income / 1000
    ); // Divide income by 1000
    let expenseData = dataTransactions.transactionChartStats.map(
      (item) => item.expense / 1000
    ); // Divide expense by 1000

    let cashFlowData = dataTransactions.transactionChartStats.map(
      (item) => item.cashFlow / 1000
    ); // Divide cash flow by 1000

    setSeries([
      {
        name: "Income",
        type: "bar",
        data: incomeData,
      },
      {
        name: "Expense",
        type: "bar",
        data: expenseData,
      },
      {
        name: "Cash Flow",
        data: cashFlowData,
        type: "line",
      },
    ]);

    const maxIncome = Math.max(...incomeData);
    const maxExpense = Math.max(...expenseData);
    const maxCashFlow = Math.max(...cashFlowData);

    const minIncome = Math.min(...incomeData);
    const minExpense = Math.min(...expenseData);
    const minCashFlow = Math.min(...cashFlowData);

    // setMaxIncomeValue(Math.max(Math.ceil(maxExpense), Math.ceil(maxIncome))); // Save the maximum income value rounded up
    setMaxIncomeValue(
      Math.max(
        Math.abs(maxIncome),
        Math.abs(maxExpense),
        Math.abs(maxCashFlow),
        Math.abs(minIncome),
        Math.abs(minExpense),
        Math.abs(minCashFlow)
      ) + 100
    );
  }, [dataTransactions.transactionChartStats]);

  const yaxisMax = maxIncomeValue;
  const yaxisMin = -yaxisMax;

  const options = {
    chart: {
      events: {
        dataPointMouseEnter: function (event, chartContext, config) {
          let labelsLength = document
            .querySelectorAll(".apexcharts-datalabels")[1]
            .querySelectorAll(".apexcharts-data-labels").length;
          if (
            width < 900 &&
            config.dataPointIndex != 0 &&
            config.dataPointIndex != labelsLength - 1
          ) {
            let labelUp = document
              .querySelectorAll(".apexcharts-datalabels")[0]
              .querySelectorAll(".apexcharts-data-labels")[
              config.dataPointIndex
            ];
            let labelUpFirst = document
              .querySelectorAll(".apexcharts-datalabels")[0]
              .querySelectorAll(".apexcharts-data-labels")[0];

            let labelUpLast = document
              .querySelectorAll(".apexcharts-datalabels")[0]
              .querySelectorAll(".apexcharts-data-labels")[labelsLength - 1];

            let labelDown = document
              .querySelectorAll(".apexcharts-datalabels")[1]
              .querySelectorAll(".apexcharts-data-labels")[
              config.dataPointIndex
            ];
            let labelDownFirst = document
              .querySelectorAll(".apexcharts-datalabels")[1]
              .querySelectorAll(".apexcharts-data-labels")[0];
            let labelDownLast = document
              .querySelectorAll(".apexcharts-datalabels")[1]
              .querySelectorAll(".apexcharts-data-labels")[labelsLength - 1];

            if (labelUp) {
              labelUp.style.opacity = 1;
            }
            if (labelUpFirst) {
              labelUpFirst.style.display = "none";
            }
            if (labelUpLast) {
              labelUpLast.style.display = "none";
            }
            if (labelDown) {
              labelDown.style.opacity = 1;
            }
            if (labelDownFirst) {
              labelDownFirst.style.display = "none";
            }
            if (labelDownLast) {
              labelDownLast.style.display = "none";
            }
          }
        },
        dataPointMouseLeave: function (event, chartContext, config) {
          if (width < 900) {
            let labelsLength = document
              .querySelectorAll(".apexcharts-datalabels")[1]
              .querySelectorAll(".apexcharts-data-labels").length;
            let labelUp = document
              .querySelectorAll(".apexcharts-datalabels")[0]
              .querySelectorAll(".apexcharts-data-labels")[
              config.dataPointIndex
            ];
            let labelUpFirst = document
              .querySelectorAll(".apexcharts-datalabels")[0]
              .querySelectorAll(".apexcharts-data-labels")[0];

            let labelUpLast = document
              .querySelectorAll(".apexcharts-datalabels")[0]
              .querySelectorAll(".apexcharts-data-labels")[labelsLength - 1];

            let labelDown = document
              .querySelectorAll(".apexcharts-datalabels")[1]
              .querySelectorAll(".apexcharts-data-labels")[
              config.dataPointIndex
            ];
            let labelDownFirst = document
              .querySelectorAll(".apexcharts-datalabels")[1]
              .querySelectorAll(".apexcharts-data-labels")[0];
            let labelDownLast = document
              .querySelectorAll(".apexcharts-datalabels")[1]
              .querySelectorAll(".apexcharts-data-labels")[labelsLength - 1];
            if (labelUp) {
              labelUp.style.opacity = 0;
            }
            if (labelUpFirst) {
              labelUpFirst.style.display = "block";
            }
            if (labelUpLast) {
              labelUpLast.style.display = "block";
            }
            if (labelDown) {
              labelDown.style.opacity = 0;
            }
            if (labelDownFirst) {
              labelDownFirst.style.display = "block";
            }
            if (labelDownLast) {
              labelDownLast.style.display = "block";
            }
          }
        },
      },
      animations: {
        enabled: false,
      },
      fontFamily: "Nunito",
      height: "auto",
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      dropShadow: {
        enabled: true,
        top: 1,
        left: 0,
        right: 0,
        blur: width > 900 ? 4 : 2,
        color: "#000",
        opacity: width > 900 ? 0.1 : 0.3,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
      {
        breakpoint: 900,
        options: {
          legend: {
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 5,
        dataLabels: {
          hideOverflowingLabels: false,
          position: "top", // Align data labels at the top of the bars
          enabled: true,
          style: {
            fontSize: "var(--fontSize12)",
            fontWeight: 500,
            colors: ["var(--colorBlack)"], // Set data labels color to black
            rotate: -45,
          },
          rotate: -45,
        },
        line: {
          dataLabels: {
            enabled: false,
          },
        },
      },
    },
    dataLabels: {
      enabled: true,
      hideOverflowingLabels: true,
      formatter: function (val, { seriesIndex }) {
        if (seriesIndex === 0 || seriesIndex === 1) {
          // Format labels for "Cash Flow" series
          if (val < 0) {
            return (
              "-$" +
              (Number.isInteger(val)
                ? Math.abs(val)
                : Math.abs(val).toFixed(1)) +
              " K"
            );
          } else {
            return (
              "$" +
              (Number.isInteger(val)
                ? Math.abs(val)
                : Math.abs(val).toFixed(1)) +
              " K"
            );
          }
        }
      },
      style: {
        colors: ["var(--colorBlack)"], // Set data label color to black
        fontWeight: "bold",
        fontSize:
          dataTransactions.transactionChartStats.length > 10 && width > 900
            ? "var(--fontSize8)"
            : dataTransactions.transactionChartStats.length > 10 && width <= 900
            ? "var(--fontSize8)"
            : "var(--fontSize12)",
        rotate: -48,
      },
      position: "top",
      rotate: -48,
      offsetY: -28,
      hideOverlappingLabels: true,
    },

    xaxis: {
      type: "category",
      categories: formattedCategories,
      axisBorder: {
        show: true,
        color: "#000",
        height: 1,
        width: "120%",
        offsetX: -18,
        offsetY: 0,
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "#000",
        height: 16,
        offsetX: 1,
        offsetY: -5,
      },
      labels: {
        offsetY:
          width < 900 &&
          dataTransactions.transactionChartStats.length < 10 &&
          dataTransactions.transactionChartStats.length > 10
            ? -4
            : dataTransactions.transactionChartStats.length >= 10
            ? 3
            : 0,
        offsetX:
          dataTransactions.transactionChartStats.length > 10
            ? 1
            : width < 650 &&
              dataTransactions.transactionChartStats.length < 10 &&
              dataTransactions.transactionChartStats.length > 5
            ? 3
            : 0,
        rotateAlways:
          width < 650 &&
          dataTransactions.transactionChartStats.length < 10 &&
          dataTransactions.transactionChartStats.length > 5
            ? true
            : dataTransactions.transactionChartStats.length > 10
            ? true
            : false,
        rotate:
          width < 650 &&
          dataTransactions.transactionChartStats.length < 10 &&
          dataTransactions.transactionChartStats.length > 5
            ? -35
            : dataTransactions.transactionChartStats.length > 10
            ? -35
            : 0,
        style: {
          fontSize:
            dataTransactions.transactionChartStats.length > 10 && width > 900
              ? "var(--fontSize10)"
              : dataTransactions.transactionChartStats.length > 10 &&
                dataTransactions.transactionChartStats.length <= 15 &&
                width <= 900
              ? "var(--fontSize8)"
              : dataTransactions.transactionChartStats.length > 15 &&
                width <= 650
              ? "var(--fontSize6)"
              : "var(--fontSize10)",
          fontWeight: 700,
        },
        hideOverlappingLabels: false,
      },
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          if (val < 0) {
            return (
              "-$" +
              (Number.isInteger(val)
                ? Math.abs(val)
                : Math.abs(val).toFixed(0)) +
              " K"
            );
          } else if (val === 0) {
            return ".";
          } else {
            return (
              "$" +
              (Number.isInteger(val)
                ? Math.abs(val)
                : Math.abs(val).toFixed(0)) +
              " K"
            );
          }
        },
        style: {
          fontSize: "var(--fontSize10)",  
          fontWeight: 700,
        },
      },
      max: yaxisMax,
      min: yaxisMin,
      axisBorder: {
        show: true,
        color: "#000",
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "#000",
        width: 22,
        offsetX: 10,
        offsetY: 0,
      },
    },
    fill: {
      opacity: 1,
      colors: ["var(--color1)", "var(--color14)"], // Set colors for income and expense bars
    },
    markers: {
      size: 4,
      colors: ["#FBC548"],
      strokeWidth: 0.3,
      strokeColors: ["#000"], // Set border color for the dots
      strokeDashArray: [0, 2], // Set border style for the dots
      hover: {
        sizeOffset: 2,
      },
    },
    stroke: {
      show: true,
      width: [0, 0, 1],
      colors: ["#000"],
      curve: "straight",
    },
    annotations: {
      yaxis: [
        {
          y: 0,
          strokeDashArray: 0,
          borderColor: "var(--colorBlack)",
          label: {
            borderColor: "var(--colorBlack)",
            style: {
              color: "var(--colorWhite)",
              background: "var(--colorBlack)",
            },
            text: "0",
            position: "left",
            offsetX: -12,
            offsetY: 7,
          },
        },
      ],
    },
    grid: {
      show: true,
      borderColor: "var(--colorBlack)", // Set border color for the grid
      strokeDashArray: 0, // Set border style for the grid lines
      position: "back",
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
      row: {
        colors: ["rgba(214, 127, 62, 0.5)", "rgba(255, 250, 250, 0)"],
        opacity: 0.07,
      },
      padding: {
        top: -10,
        right: 18,
        bottom: 0,
        left: 30,
      },
    },
    legend: {
      show: false,
    },
  };

  const customDataLabelStyle = {
    "& text tspan": {
      transform: "rotate(-20deg)", // Adjust the rotation angle as needed
      transformOrigin: "0% 100%", // Adjust the rotation origin as needed
    },
  };

  return (
    <div className={"chart-container"}>
      {dataTransactions.transactionChartStatsLoading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
            }}
          />
        </Box>
      )}

      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={
          dataTransactions.transactionChartStats.length > 10 && height <= 1050
            ? "100%"
            : "100%"
        }
        style={customDataLabelStyle}
      />
    </div>
  );
};

export default CashflowChart;

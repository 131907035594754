import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { atm_globalFilterValue } from "src/state/atoms";

const NewCategoryFilter = ({ data, callBackFunction }) => {
  const [modifiedData, setModifiedData] = useState([]);
  const [checkedCategories, setCheckedCategories] = useState([]);
  const globalFilterValue = useRecoilValue(atm_globalFilterValue);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const updateLocalDataWithGlobalData = () => {
    const allCategories = data?.flatMap((d) => d?.categories);
    const { selectedCategoryFilter, excludedCategoryFilter } =
      globalFilterValue.categoryFilter;
    if (selectedCategoryFilter.length) {
      setCheckedCategories(
        allCategories.filter((d) =>
          selectedCategoryFilter.includes(d.kmnId)
        )
      );
      return;
    } else if (excludedCategoryFilter.length) {
      setCheckedCategories(
        allCategories.filter(
          (d) => !excludedCategoryFilter.includes(d.kmnId)
        )
      );
      return;
    } else {
      setCheckedCategories([]);
    }
  };

  useEffect(() => {
    if (data.length) {
      updateLocalDataWithGlobalData();
      setModifiedData(
        data.flatMap((cat) =>
          cat?.categories.map((subCat) => ({ category: cat?.name, ...subCat }))
        )
      );
    }
  }, [data]);

  const handleApply = (selections) => {
    let includedIds = [];
    let exludedIds = [];
    if (!selections?.length) {
      callBackFunction(includedIds, exludedIds);
    } else {
      includedIds = selections.map((d) => d.kmnId);
      exludedIds = data.filter((d) => !includedIds.includes(d.kmnId));
      callBackFunction(includedIds, exludedIds)?.map(d => d?.kmnId);
    }

    setCheckedCategories(selections);
  };

  return (
    <div
      className={`filterDropdown categoryFilter ${
        isMenuOpen && "filterFocused"
      }`}
    >
      <span className="label">Category</span>
      <Autocomplete
        onChange={(_, value) => handleApply(value)}
        multiple
        groupBy={(option) => option?.category}
        id="tags-outlined"
        options={modifiedData}
        value={checkedCategories}
        getOptionLabel={(option) => option?.name}
        disableCloseOnSelect
        renderInput={(params) => (
          <TextField
            {...params}
            label={!checkedCategories.length ? "All" : null}
          />
        )}
        size="small"
        onOpen={() => {
          setIsMenuOpen(true);
        }}
        onClose={() => {
          setIsMenuOpen(false);
        }}
        renderGroup={(params) => (
          <li key={params.key}>
            <div style={{ paddingLeft: "1rem", fontWeight: 700 }}>
              {params.group}
            </div>
            <div style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
              {params.children}
            </div>
          </li>
        )}
      />
    </div>
  );
};

export default NewCategoryFilter;

// src/RedditPixel.js
//import React, { useEffect } from 'react';

const RedditPixel = ({event,custom_or_default}) => {
 // useEffect(() => {

    // if (event === 'PageVisit')      
    // {
    //     setReferrer(document.referrer);
    // }
    
  // if (process.env.REACT_APP_PLAID_ENV === 'SANDBOX')
  // {
  //   event = 'SANDBOX-' + event;
  // }
    
     if (process.env.REACT_APP_PLAID_ENV === 'PROD' && document.referrer.includes('reddit.com'))      
  // if(true)
     {
        // Insert Reddit Pixel code here
      (function(w, d) {
        if (!w.rdt) {
          var p = w.rdt = function() {
            p.sendEvent ? p.sendEvent.apply(p, arguments) : p.callQueue.push(arguments);
          };
          p.callQueue = [];
          var t = d.createElement('script');
          t.src = 'https://www.redditstatic.com/ads/pixel.js';
          t.async = true;
          var s = d.getElementsByTagName('script')[0];
          s.parentNode.insertBefore(t, s);
        }
      })(window, document);

      window.rdt('init', 'a2_ey1805uqiuib');
     //   window.rdt('track', 'PageVisit');
     console.log("event : " + event + " custom or default : " + custom_or_default);
     if (custom_or_default === 0)
     {
      // console.log("firing default event : " + event);
       window.rdt('track', event);
    }
     else
     {
     //   console.log("firing custom event : " + event);
        window.rdt('track', 'Custom', {customEventName:event});
    }


     }
 // }, []);

  return null;
};

export default RedditPixel;

import { atom } from "recoil";

export const atm_ManageCategoriesData = atom({
    key: "manageCategoriesData",
    default: []
})

export const atm_ManageCategoriesLoading = atom({
    key: "manageCategoriesLoading",
    default: false
})
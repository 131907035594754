import { useState } from "react";
import { styled } from "@mui/material/styles";

import {
  Alert,
  Drawer,
  Box,
  ListItem,
  Snackbar,
  LinearProgress,
} from "@mui/material";

import { ArrowForwardIosSharp, ArrowBack, Person } from "@mui/icons-material";
import useMyProfile from "../MyProfile/useMyProfile";
import EditProfileForm from "./EditProfileForm";

const AlertFilled = styled(Alert)({
  alignSelf: `stretch`,
  marginBottom: `20px`,
});

export default function EditProfileDrawer() {

  const { data, profileFns } = useMyProfile();

  const [state, setState] = useState(false);


  const toggleDrawer = (open, cancel) => (event) => {
    if (event.type === "keydown") {
      return;
    }

    setState(open);
    profileFns.onChangeEmail(event);
    profileFns.onChangePhoneNo(event);
    profileFns.clearErrorMessage();

    if (!cancel) {
      profileFns.getEditUserData();
      data.mobile = false;
    }
  };

  return (
    <Box
      sx={{ width: "100%" }}
      role="presentation"
      onKeyDown={toggleDrawer(true, true)}
    >
      <ListItem
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginY: "10px",
        }}
        onClick={toggleDrawer(true, false)}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Person sx={{ color: "#0C89EF", marginRight: "5px" }} />{" "}
          <strong>My Profile</strong>
        </Box>

        <ArrowForwardIosSharp fontSize="10px" />
      </ListItem>
      <Drawer
        variant={"persistent"}
        anchor={"right"}
        open={state}
        onClose={toggleDrawer(false, false)}
        SlideProps={{
					unmountOnExit: true
				}}
        PaperProps={{
          sx: {
            width: "100%",
            backgroundColor: "#EAF9F6",
          },
        }}
      >
        <Box
          sx={{
            width: "100%",
            color: "black",
            backgroundColor: "#EAF9F6",
          }}
          role="presentation"
          display={"flex"}
          justifyContent={"space-between"}
          paddingTop={1}
          alignItems={"center"}
        >
          <ArrowBack
            sx={{
              fontSize: 30,
              margin: 1,
              marginLeft: 2,
              cursor: "pointer",
            }}
            onClick={toggleDrawer(false, true)}
          />

          <strong
            style={{
              fontFamily: "Nunito",
              fontSize: 24,
              textAlign: "center",
            }}
          >
            My Profile
          </strong>
          <ArrowBack sx={{ fontSize: 30, margin: 1, visibility: "hidden" }} />
        </Box>
        <Box
          display={"flex"}
          justifyContent={"center"}
          sx={{ backgroundColor: "#EAF9F6" }}
        >
          <div className="sign-up-box">
            {data.profileLoading && (
              <Box sx={{ width: "100%" }}>
                <LinearProgress
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                  }}
                />
              </Box>
            )}
            {/* <Box
							display={'flex'}
							flexDirection={'column'}
							alignItems={'center'}
							sx={{
								marginBottom: 5
							}}
						>
							<img
								src={SamplePfp}
								alt=''
								height={150}
								width={150}
							/>
							<strong
								style={{
									fontFamily: 'Nunito',
									fontSize: "var(--fontSize16)",
									textAlign: 'center',
									marginTop: 10,
									color: '#0C89EF'
								}}
							>
								<Edit fontSize='16px' /> Edit Profile Picture
							</strong>
						</Box> */}
            {data.sysUserError && (
              <AlertFilled
                variant="filled"
                severity={"error"}
                onClose={profileFns.clearErrorMessage}
              >
                {data.sysUserHelperText}
              </AlertFilled>
            )}
            <Alert severity="warning" sx={{ marginTop: "10px" }}>
              Changing your email address will make you re-login to the website
            </Alert>

            <EditProfileForm toggleDrawer={toggleDrawer} />
          </div>
        </Box>
        <Snackbar open={data.alertType} autoHideDuration={3000}>
          <Alert severity="success" sx={{ width: "100%" }}>
            Changes Updated Successfully! Redirecting...
          </Alert>
        </Snackbar>
      </Drawer>
    </Box>
  );
}

/**********************************************************************
 *
 *   Component generated by Quest
 *
 *   WARNING: By editing this component by hand, you will lose the ability to regenerate the code without conflicts.
 *   To preseve that abilty, always export from Quest to regenerate this file.
 *   To setup props, bindings and actions, use the Quest editor
 *   Code Logic goes in the hook associated with this component
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/

import React, { useState } from "react";
import {
  Button,
  Menu,
  MenuItem,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { KeyboardArrowDown, Person, Password } from "@mui/icons-material";
import "../assets/css/dashboard.css";
import logo from "../../components/assets/images/logo.svg";
import logoMob from "../../components/assets/images/kamunity-mob.png";
import SamplePfp from "src/components/assets/images/samplepfp.png";
import { styled } from "@mui/material/styles";
import useMainMenu from "src/components/MainMenu/useMainMenu";
import { useRecoilState, useRecoilValue } from "recoil";
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';

import {
  atm_isUserLoggedIn,
  atm_firstName,
  atm_lastName,
  atm_mainMenuButtonPressed,
  atm_menuInsightsType,
  atm_globalFilterCount,
  atm_globalFilterValue,
} from "../../state/atoms";
import MobileMainMenu from "./MobileMainMenu";
import { GetWindowWidth, getAuthToken } from "../../global/central";
import { atm_isBulkEditMode, atm_IsMobTransactionFilter } from "src/state/atomsTransactionData";
import { atm_IsMobHoldingsFilter } from "src/state/atomsHoldingData";
import { atm_IsMobCatFilter } from "src/state/insightsCategories";
import { atm_IsMobMerchantFilter } from "src/state/insightsMerchants";
import { useLocation } from "react-router-dom";
import LogoutIcon from '@mui/icons-material/Logout';

const ProfileSection = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  margin: "0 10px",
});

const ProfileButton = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  margin: "0 10px",
  cursor: "pointer",
});
const NameEmail = styled("div")({
  display: "flex",
  flexDirection: "column",
  textAlign: "right",
  fontFamily: "Nunito",
});

const Pfp = styled("img")({
  margin: "0 15px",
});

const RightMenu = styled("div")({
  display: "flex",
  flexDirection: "row",
});

const MenuButton = styled(Button)({
  fontWeight: "bold",
  color: "black",
  margin: "0 10px",
  textTransform: "none",
  // fontFamily: 'Nunito',
  fontSize: "var(--fontSize16)",
});

const Logout = styled(Button)({
  fontWeight: "bold",
  color: "var(--color1)",
  margin: "0 10px",
});

function MainMenu() {
  // insight - new
  const [newInsight, setNewInsight] = useState(null);

  const [anchorMainMenu, setAnchorMainMenu] = useState(null);
  const [anchorProfileMenu, setAnchorProfileMenu] = useState(null);
  const [anchorInsightsMenu, setAnchorInsightsMenu] = useState(null);
  const [openMenu, setopenMenu] = useState(false);
  const [openinsight, setopeninsight] = useState(false);
  const [openProfile, setopenProfile] = useState(false);
  const firstName = useRecoilValue(atm_firstName);

  const isUserLoggedIn = useRecoilValue(atm_isUserLoggedIn);
  const openMainMenu = Boolean(anchorMainMenu);
  const openProfileMenu = Boolean(anchorProfileMenu);
  const openInsightsMenu = Boolean(anchorInsightsMenu);

  const menuInsightsType = useRecoilValue(atm_menuInsightsType);

  const globalFilterCount = useRecoilValue(
    atm_globalFilterCount
  );

  const handleMainMenu = (event) => {
    setAnchorMainMenu(event.currentTarget);
  };

  const handleProfileMenu = (event) => {
    setAnchorProfileMenu(event.currentTarget);
  };

  const handleInsightsMenu = (event) => {
    setAnchorInsightsMenu(event.currentTarget);
  };

  const handleMainMenuClose = () => {
    setopenMenu(false);
    setAnchorMainMenu(null);
  };

  const handleProfileMenuClose = () => {
    setAnchorProfileMenu(null);
    setopenProfile(false);
  };

  const handleInsightsMenuClose = () => {
    setAnchorInsightsMenu(null);
    setopeninsight(false);
  };

  const { fns } = useMainMenu();
  const mainMenuButtonPressed = useRecoilValue(atm_mainMenuButtonPressed);

  const [transactionFilter, setTransactionFilter] = useRecoilState(
    atm_IsMobTransactionFilter
  );
  const [holdingsFilter, setHoldingsFilter] = useRecoilState(
    atm_IsMobHoldingsFilter
  );
  const [insightsCatFilter, setInsightsCatFilter] =
    useRecoilState(atm_IsMobCatFilter);
  const [insightsMerchantFilter, setInsightsMerchantFilter] = useRecoilState(
    atm_IsMobMerchantFilter
  );

  const location = useLocation();

  const theme = useTheme();

  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  const width = GetWindowWidth();

  const isBulkEditMode = useRecoilValue(atm_isBulkEditMode)

  const applyFilter = (path) => {
    if (path == "/transactions") {
      setTransactionFilter(true);
    } else if (path == "/holdings") {
      setHoldingsFilter(true);
    } else if (path == "/insights/spendByCategory") {
      setInsightsCatFilter(true);
    } else if (path == "/insights/spendByMerchant") {
      setInsightsMerchantFilter(true);
    }
  };

  const handleFilter = () => {
    if(!isBulkEditMode){
      applyFilter(window.location.pathname)
    }
  }

  return (
    <header className={`dashboard-header ${isUserLoggedIn && 'logged-in-header'} ${location.pathname === "/" || location.pathname === "about" ? 'header-home' : undefined}`}>
      <img
        src={width > 1100 ? logo : logoMob}
        loading="lazy"
        alt={"Kamunity"}
        onClick={fns.onMenuClickHome}
        className="dashboard-header-logo"
      />
      {!isMatch && (
        <>
          {getAuthToken() && (
            <nav className="navigation-system">
              <>
                <MenuButton
                  id="main-menu"
                  aria-controls={openMainMenu ? "main-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openMainMenu ? "true" : undefined}
                  variant="text"
                  disableElevation
                  className={`${(mainMenuButtonPressed == "Home" ||
                    mainMenuButtonPressed == "About") &&
                    "menu-active-border"
                    }`}
                  onClick={(e) => {
                    setopenMenu(true);

                    handleMainMenu(e);
                  }}
                  endIcon={<KeyboardArrowDown />}
                >
                  Main
                </MenuButton>
                <Menu
                  className="menu-main"
                  elevation={24}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  id="main-menu"
                  aria-labelledby="main-menu"
                  anchorEl={anchorMainMenu}
                  open={openMainMenu}
                  onClose={handleMainMenuClose}
                >
                  <MenuItem
                    onClick={() => {
                      handleMainMenuClose();
                      fns.onMenuClickHome();
                    }}
                    style={{
                      color:
                        mainMenuButtonPressed == "Home"
                          ? "var(--color2)"
                          : "",
                    }}
                    disableRipple
                  >
                    Home
                  </MenuItem>
                  <Divider variant="middle" />
                  <MenuItem
                    onClick={() => {
                      handleMainMenuClose();
                      fns.onMenuClickAbout();
                    }}
                    style={{
                      color:
                        mainMenuButtonPressed == "About" ? "#A4379E" : "",
                    }}
                    disableRipple
                  >
                    About Us
                  </MenuItem>
                  <Divider variant="middle" />
                </Menu>
              </>

              <MenuButton
                className={`${mainMenuButtonPressed == "Dashboard" && "menu-active"
                  }`}
                onClick={fns.onMenuClickDashboard}
              >
                Dashboard
              </MenuButton>

              <MenuButton
                className={`${(mainMenuButtonPressed == "Transactions" ||
                  mainMenuButtonPressed == "Holdings") &&
                  "menu-active"
                  }`}
                onClick={fns.onMenuClickTransactions}
              >
                Accounts
              </MenuButton>

              <MenuButton
                id="insights-menu"
                aria-controls={openInsightsMenu ? "insights-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openInsightsMenu ? "true" : undefined}
                variant="text"
                disableElevation
                className={`${mainMenuButtonPressed == "Insights" && "menu-active-border"
                  }`}
                onClick={(e) => {
                  setopeninsight(true);
                  handleInsightsMenu(e);
                }}
                endIcon={<KeyboardArrowDown />}
              >
                Insights
              </MenuButton>
              <Menu
                className="menu-main"
                elevation={24}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                id="insights-menu"
                aria-labelledby="insights-menu"
                anchorEl={anchorInsightsMenu}
                open={openInsightsMenu}
                onClose={handleInsightsMenuClose}
                sx={{
                  borderBottom:
                    mainMenuButtonPressed == "Insights" ? "5px" : "0",
                  borderBottomColor: "#A4379E",
                }}
              >
                <MenuItem
                  onClick={fns.onClickInsightNewSpendByCategory}
                  style={{
                    color:
                      mainMenuButtonPressed == "Insights" &&
                        menuInsightsType == "SpendByCategory"
                        ? "#A4379E"
                        : "",
                  }}
                  disableRipple
                >
                  By Category
                </MenuItem>
                <Divider variant="middle" />
                <MenuItem
                  onClick={fns.onClickInsightNewSpendByMerchant}
                  style={{
                    color:
                      mainMenuButtonPressed == "Insights" &&
                        menuInsightsType == "SpendByMerchant"
                        ? "#A4379E"
                        : "",
                  }}
                  disableRipple
                >
                  By Merchant
                </MenuItem>
              </Menu>

              <MenuButton
                className={`${(mainMenuButtonPressed == "Calculators") &&
                  "menu-active"
                  }`}
                onClick={fns.onMenuClickCalculators}
              >
                Calculators
              </MenuButton>

              <MenuButton
                className={`${(mainMenuButtonPressed == "Trends") &&
                  "menu-active"
                  }`}
                onClick={fns.onMenuClickTrends}
              >
                Trends
              </MenuButton>
            </nav>
          )}
        </>
      )}
      {getAuthToken && (
        <RightMenu sx={{ flexGrow: width < 900 && 1 }}>
          {width > 900 && (
            <ProfileSection>
              {!isMatch && (
                <>
                  <Divider orientation="vertical" variant="full" flexItem />
                  {/*                   
                  <div className="menu-button-right-side">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10 18C11.775 17.9996 13.4988 17.4054 14.897 16.312L19.293 20.708L20.707 19.294L16.311 14.898C17.405 13.4997 17.9996 11.7754 18 10C18 5.589 14.411 2 10 2C5.589 2 2 5.589 2 10C2 14.411 5.589 18 10 18ZM10 4C13.309 4 16 6.691 16 10C16 13.309 13.309 16 10 16C6.691 16 4 13.309 4 10C4 6.691 6.691 4 10 4Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                  <Divider orientation="vertical" variant="full" flexItem /> */}

                  <div className="menu-button-right-side">
                    <div className="filterBtnCover filterSelectionCount">
                      <svg
                        onClick={handleFilter}
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="40"
                        viewBox="0 0 37 37"
                        fill="none"
                      >
                        <rect
                          x="1"
                          y="1"
                          width="35"
                          height="35"
                          rx="2"
                          fill={
                            transactionFilter ||
                              holdingsFilter ||
                              insightsCatFilter ||
                              insightsMerchantFilter
                              ? "#21CAE7"
                              : ""
                          }
                          stroke="#21CAE7"
                        />
                        <path
                          d="M16.4002 26.6582C16.0035 26.6582 15.6708 26.5213 15.402 26.2477C15.1332 25.974 14.9993 25.6357 15.0002 25.2327C15.0002 24.8289 15.1346 24.4901 15.4034 24.2164C15.6722 23.9427 16.0045 23.8063 16.4002 23.8073H19.2002C19.5969 23.8073 19.9296 23.9441 20.1984 24.2178C20.4672 24.4915 20.6011 24.8298 20.6002 25.2327C20.6002 25.6366 20.4658 25.9754 20.197 26.2491C19.9282 26.5228 19.5959 26.6591 19.2002 26.6582H16.4002ZM6.6002 12.4036C6.20353 12.4036 5.8708 12.2668 5.602 11.9931C5.3332 11.7194 5.19927 11.3811 5.2002 10.9782C5.2002 10.5743 5.3346 10.2355 5.6034 9.96184C5.8722 9.68816 6.20447 9.55179 6.6002 9.55274H29.0002C29.3969 9.55274 29.7296 9.68958 29.9984 9.96327C30.2672 10.237 30.4011 10.5753 30.4002 10.9782C30.4002 11.3821 30.2658 11.7209 29.997 11.9945C29.7282 12.2682 29.3959 12.4046 29.0002 12.4036H6.6002ZM10.8002 19.5309C10.4035 19.5309 10.0708 19.3941 9.802 19.1204C9.5332 18.8467 9.39927 18.5084 9.4002 18.1055C9.4002 17.7016 9.5346 17.3628 9.8034 17.0891C10.0722 16.8154 10.4045 16.6791 10.8002 16.68H24.8002C25.1969 16.68 25.5296 16.8169 25.7984 17.0905C26.0672 17.3642 26.2011 17.7025 26.2002 18.1055C26.2002 18.5093 26.0658 18.8481 25.797 19.1218C25.5282 19.3955 25.1959 19.5319 24.8002 19.5309H10.8002Z"
                          fill="black"
                        />
                      </svg>
                      <div className="filterCount">
                        {globalFilterCount}
                      </div>
                    </div>
                  </div>
                  <Divider orientation="vertical" variant="full" flexItem />

                  {/* <div className="menu-button-right-side">
                    <svg
                      width="18"
                      height="25"
                      viewBox="0 0 18 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M16.3337 17.5891C14.8498 15.0264 15.7154 13.6085 15.1321 8.86483C14.6094 4.61467 11.3546 3.39788 9.68751 3.05053V0H7.99126V3.05053C6.32184 3.39788 3.06703 4.61462 2.54557 8.86483C1.96228 13.6063 2.82788 15.0264 1.34396 17.5891C0.949638 18.2655 0.457323 18.8093 0 19.2286V20.218H17.6764V19.2286C17.2203 18.8093 16.7257 18.2655 16.3337 17.5891Z"
                        fill="#CCCCCC"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8.83818 24.9755C9.98309 24.9755 10.9112 24.0665 10.9112 22.9452C10.9112 21.8239 9.98309 20.915 8.83818 20.915C7.69327 20.915 6.76514 21.8239 6.76514 22.9452C6.76514 24.0665 7.69327 24.9755 8.83818 24.9755Z"
                        fill="#CCCCCC"
                      />
                    </svg>
                  </div>
                  <Divider orientation="vertical" variant="full" flexItem /> */}
                </>
              )}
              <ProfileButton
                id="profile-menu"
                aria-controls={openProfileMenu ? "profile-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openProfileMenu ? "true" : undefined}
                variant="text"
                disableElevation
                className={`${openProfile == true ||
                  (mainMenuButtonPressed == "Profile" && "menu-active-border")
                  }`}
                onClick={(e) => {
                  setopenProfile(true);
                  handleProfileMenu(e);
                }}
                endIcon={<KeyboardArrowDown />}
              >
                {!isMatch && (
                  <NameEmail>
                    <strong className="name-header">Hello, {firstName}</strong>
                  </NameEmail>
                )}

                {/* <Pfp
                  src={SamplePfp}
                  width={"35px"}
                  height={mainMenuButtonPressed == "Profile" ? "38px" : "35px"}
                  alt="Profile Picture"
                  sx={{
                    borderBottom: mainMenuButtonPressed == "Profile" ? 5 : 0,
                    borderBottomColor: "#A4379E",
                    borderRadius: "5px",
                    padding: "1px",
                  }}
                /> */}

                {!isMatch && <KeyboardArrowDown />}
              </ProfileButton>

              <Menu
                elevation={24}
                className="menu-main"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                id="profile-menu"
                aria-labelledby="profile-menu"
                anchorEl={anchorProfileMenu}
                open={openProfileMenu}
                onClose={handleProfileMenuClose}
                sx={{
                  borderBottom: mainMenuButtonPressed == "Insights" ? 5 : 0,
                  borderBottomColor: "#A4379E",
                }}
              >
                <MenuItem
                  onClick={() => {
                    fns.onClickMyProfile();
                    handleProfileMenuClose();
                  }}
                  disableRipple
                >
                  <Person sx={{ color: "#0C89EF" }} /> My Profile
                </MenuItem>
                <Divider variant="middle" />
                <MenuItem
                  onClick={() => {
                    fns.onClickChangePassword();
                    handleProfileMenuClose();
                  }}
                  disableRipple
                >
                  <Password sx={{ color: "#0C89EF" }} /> Change Password
                </MenuItem>
                <Divider variant="middle" />

                <MenuItem
                  onClick={() => {
                    fns.onClickMyHousehold()
                    handleProfileMenuClose();
                  }}
                  disableRipple
                >
                  <PersonAddAltOutlinedIcon sx={{ color: "#0C89EF" }} /> Household
                </MenuItem>

                <Divider variant="middle" />
                <MenuItem
                  className="dashboard-header-logout-button"
                  onClick={fns.onMenuClickLogOut}
                  disableRipple
                >
                  <LogoutIcon sx={{ color: "var(--color14)" }} /> <p>Logout</p>
                </MenuItem>
              </Menu>
            </ProfileSection>
          )}
          {isMatch && <MobileMainMenu />}
        </RightMenu>
      )}
    </header>
  );
}

export default MainMenu;

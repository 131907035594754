import { atom, selector } from "recoil";

export const atm_InsightsMerchantExpenseTrend = atom({
  key: "insightsMerchantExpenseTrend",
  default: [],
});

export const atm_MerchantListLoading = atom({
  key: "merchantListLoading",
  default: false,
});

export const atm_MerchantChartLoading = atom({
  key: "merchantChartLoading",
  default: false,
});

export const atm_PopChartLoading = atom({
  key: "popChartLoadingMerchant",
  default: false,
});

export const atm_MerchantRatingLoading = atom({
  key: "merchantRatingLoading",
  default: false,
});

export const atm_InsightsMerchantList = atom({
  key: "insightsMerchantList",
  default: [],
});

export const atm_IsMerchantClicked = atom({
  key: "isMerchantClicked",
  default: false,
});

export const atm_MerchantLocationStats = atom({
  key: "merchantLocationStats",
  default: [],
});

export const atm_MerchantLocationStatsLoading = atom({
  key: "merchantLocationStatsLoading",
  default: false,
});

export const atm_MerchantCategoryStats = atom({
  key: "merchantCategoryStats",
  default: [],
});

export const atm_MerchantPopStats = atom({
  key: "merchantPopStats",
  default: [],
});

export const atm_SelectedMerchant = atom({
  key: "selectedMerchant",
  default: "",
});

export const atm_SelectedMerchantPercent = atom({
  key: "selectedMerchantPercent",
  default: "",
});

export const atm_SelectedMerchantName = atom({
  key: "selectedMerchantName",
  default: "",
});

export const atm_RatingStats = atom({
  key: "ratingStats",
  default: [],
});

export const atm_IsMobMerchantFilter = atom({
  key: "isMobMerchantFilter",
  default: false,
});

export const atm_SelectedMerchantDateFilter = atom({
  key: "selectedDateFilter",
  default: 12,
});

export const atm_CurrentSelectedMerchant4Location = atom({
  key: "currentSelectedMerchant4Location",
  default: null,
});

export const atm_CurrentSelectedMerchant4Category = atom({
  key: "currentSelectedMerchant4Category",
  default: null,
});

export const atm_currentFilterMom = atom({
  key: "currentFilterMom",
  default: null,
});
export const atm_currentFilterByLocation = atom({
  key: "currentFilterByLocation",
  default: null,
});
export const atm_currentFilterMerchantTrends = atom({
  key: "currentFilterMerchantTrends",
  default: null,
});

export const atm_merchantParentSelectors = atom({
  key: "merchantParentSelectors",
  default: {
    mom: 1,
    byLocation: null,
    merchantTrends: null,
    ratingReviews: null,
  },
});

export const atm_globalMerchant = atom({
  key: "globalMerchant",
  default: null,
});

export const atm_merchantTab = atom({
  key: "merchantTab",
  default: 1,
});

export const atm_parentReviews = atom({
  key: "parentReviews",
  default: [],
});

export const atm_selectedRatings = atom({
  key: "selectedRatings",
  default: [],
});

export const atm_insightsMerchantExpenseTrendEnhanced = selector({
  key: "insightsMerchantExpenseTrendEnhanced",
  get: ({ get }) => {
    var expenseTrendMineEnhanced = [];
    get(atm_InsightsMerchantExpenseTrend).forEach((myTopExpenses) => {
      var meSize = 1;
      expenseTrendMineEnhanced.push({
        // Camel Case Creation hack
        merchant: myTopExpenses.merchant
          .substring(0, 25)
          .toLowerCase()
          .replace(/([A-Z])/g, " $1")
          .replace(/^./, function (str) {
            return str.toUpperCase();
          }),

        countryMin: myTopExpenses.countryMin,
        countryMinToMe:
          myTopExpenses.me - myTopExpenses.countryMin - meSize / 2,
        countryMeToMax:
          myTopExpenses.countryMax - myTopExpenses.me - meSize / 2,
        countryMaxTo100: 100 - myTopExpenses.countryMax,

        stateMin: myTopExpenses.stateMin,
        stateMinToMe: myTopExpenses.me - myTopExpenses.stateMin - meSize / 2,
        stateMeToMax: myTopExpenses.stateMax - myTopExpenses.me - meSize / 2,
        stateMaxTo100: 100 - myTopExpenses.stateMax,

        zipMin: myTopExpenses.zipMin,
        zipMinToMe: myTopExpenses.me - myTopExpenses.zipMin - meSize / 2,
        zipMeToMax: myTopExpenses.zipMax - myTopExpenses.me - meSize / 2,
        zipMaxTo100: 100 - myTopExpenses.zipMax,

        Me: meSize,
      });
    });

    return expenseTrendMineEnhanced;
  },
});

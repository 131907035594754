import React, { useState, useRef, useEffect } from "react";
import PencilIcon from "../../assets/images/pencil2.svg";
import { usdFormate } from "src/global/central";

const getMonthName = (monthNumber) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  return monthNames[monthNumber - 1];
};

const KeywordsDetails = ({ data, handleEdit, allData }) => {
  const [expanded, setExpanded] = useState(false);
  const keywordRowRef = useRef(null);

  const handleKeywordCountClick = () => {
    setExpanded(!expanded);
  };

  const handleOutsideClick = (event) => {
    if (
      keywordRowRef.current &&
      !keywordRowRef.current.contains(event.target)
    ) {
      setExpanded(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  if (allData.generalSection.specificGoalType) {
    return (
      <div className={`li  ${expanded ? "expanded" : ""}`}>
        <div className="keywordMain">
          <div className="keywordRow" ref={keywordRowRef}>
            {Object.values(data).map((val, index) => {
              if (isNaN(val) && typeof val != "object") {
                return (
                  <div key={index} className="keyword">
                    {val}
                  </div>
                );
              } else if (val && typeof val == "object") {
                val.map((val) => {
                  return (
                    <div key={index} className="keyword">
                      {val}
                    </div>
                  );
                });
              }
              return null;
            })}
            <div
              hidden={expanded}
              className="keywordCount keyword"
              onClick={handleKeywordCountClick}
            >
              ...
            </div>
          </div>
        </div>
        <img
          onClick={() => handleEdit(allData, 2)}
          className="pencil"
          src={PencilIcon}
          alt=""
        />
      </div>
    );
  } else {
    return (
      <div className={`li  ${expanded ? "expanded" : ""}`}>
        <div className="keywordMain">
          <div className="keywordRow" ref={keywordRowRef}>
            <div className="keyword">
              Total Amount : {usdFormate(data.goalTargetAmount)}
            </div>
            <div className="keyword">
              Drawn By : {getMonthName(data.goalEndByMonth) + " " + data.goalEndByYear}
            </div>
            <div
              hidden={expanded}
              className="keywordCount keyword"
              onClick={handleKeywordCountClick}
            >
              ...
            </div>
          </div>
        </div>
        <img
          onClick={() => handleEdit(allData, 2)}
          className="pencil"
          src={PencilIcon}
          alt=""
        />
      </div>
    );
  }
};

export default KeywordsDetails;

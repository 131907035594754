import { atom } from "recoil";


export const atm_houseHolding = atom({
    key: "houseHolding",
    default: []
})

export const atm_houseHolding2 = atom({
    key: "houseHolding2",
    default: []
})

export const atm_relationships = atom({
    key: "relationship",
    default: []
})

export const atm_household = atom({
    key: "houseModal",
    default: false,
});

export const atm_isSavehouseHold = atom({
    key: "isSavehouseHold",
    default: 0
})

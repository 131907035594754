/**********************************************************************
 *
 *   Component generated by Quest
 *
 *   WARNING: By editing this component by hand, you will lose the ability to regenerate the code without conflicts.
 *   To preseve that abilty, always export from Quest to regenerate this file.
 *   To setup props, bindings and actions, use the Quest editor
 *   Code Logic goes in the hook associated with this component
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/

import React, { useState, useEffect } from "react";
import {
  Alert,
  TextField,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Box,
  LinearProgress,
  Checkbox,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import useSignUp from "./useSignUp";
import "../assets/css/register.css";
import { Link } from "react-router-dom";
import tick from "../assets/images/tick.svg";
import social1 from "../assets/images/facebook.webp";
import social2 from "../assets/images/google.webp";
import social3 from "../assets/images/apple.webp";
import boy from "../assets/images/boy.webp";
import girl from "../assets/images/girl.webp";
import { ValidateEmail } from "../../validations/Email";
import { NameValidation } from "../../validations/Name";
import { checkPassword } from "../../validations/Password";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import LegalsModal from "../shared/LegalsModal";
import MetaTags from "../shared/MetaTags";
import RedditPixel from "../shared/RedittPixel";

const AlertFilled = styled(Alert)({
  alignSelf: `stretch`,
  marginBottom: `20px`,
});

const Email = styled(TextField)({
  alignSelf: `stretch`,
  flexGrow: `1`,
  margin: `0px`,
});

const FirstName = styled(TextField)({
  alignSelf: `stretch`,
  flexGrow: `1`,
  margin: `0px`,
});

const LastName = styled(TextField)({
  alignSelf: `stretch`,
  flexGrow: `1`,
});

const PhoneNo = styled(TextField)({
  alignSelf: `stretch`,
  flexGrow: `1`,
  margin: `0px`,
});

const ZipCode = styled(TextField)({
  alignSelf: `stretch`,
  flexGrow: `1`,
});

const ButtonContained = styled(Button)({
  margin: `0px`,
});

function SignUp(props) {
  const [HeightState, setHeight] = useState(0);
  const [Disable, setDisable] = useState(false);
  const { data, fns } = useSignUp(setDisable);
  const [emailValidate, setemailValidate] = useState(false);
  const [NameValidate, setNameValidateState] = useState(false);
  const [LNameValidate, setLNameValidateState] = useState(false);
  const [ZipCodeState, setZipCodeState] = useState(false);
  const [PasswordF, setPasswordF] = useState(false);
  const [PasswordR, setPasswordR] = useState(false);
  const [PasswordRValue, setPasswordRValue] = useState("");
  const [PhoneNumber, setPhoneNumber] = useState(false);
  const [agreeCheck, setagreeCheck] = useState(false);
  const [DisableError, setDisableError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [modalInfo, setModalInfo] = useState({ link: "", title: "" });

  const handleModal = (e) => {
    e.preventDefault();
    let link = e.target.href;
    let title = e.target.title;
    setModalInfo({ link, title });
    setIsModal(true);
  };

  const handleShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  const HandleResizeWork = () => {
    const WindowHeight = window.innerHeight - 100;
    setHeight(WindowHeight);
  };
  useEffect(() => {
    window.addEventListener("resize", HandleResizeWork);
    HandleResizeWork();
  }, []);
  return (
    <>
      <MetaTags index={"Singup"} />
      <RedditPixel event={"SignUp"} custom_or_default={0}/>

      <div
        className="sign-up-main-wrapper"
        style={{ minHeight: `${HeightState}px` }}
      >
        <div className=" sign-up-box">
          {Disable && (
            <Box sx={{ width: "100%" }}>
              <LinearProgress
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                }}
              />
            </Box>
          )}
          <img src={boy} className="boy-image" alt="" />

          {data.isErrorMessage && (
            <AlertFilled
              variant="filled"
              severity={data.alertType}
              onClose={fns.clearErrorMessage}
            >
              {data.isErrorMessage}
            </AlertFilled>
          )}
          <div className="initial-text">
            <h1>{`Create Your Account`}</h1>
            {/* <p>Lorem ipsum dolor sit amet, consectetur adipi</p> */}

            <Dialog open={data.emailValidationRequired}>
              <DialogTitle>Validate Email</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  We have sent a validation code to your email. Please enter
                  that code to confirm that you own the email address
                </DialogContentText>
                <br></br>
                {data.emailValidationErrorMessage && (
                  <AlertFilled variant="filled" severity="error">
                    {data.emailValidationErrorMessage}
                  </AlertFilled>
                )}

                <TextField
                  autoFocus
                  margin="normal"
                  id="name"
                  label="Validation Code"
                  type="number"
                  value={data.emailValidationCode}
                  onChange={(e) => {
                    fns.onChangeEmailValidationCode(e);
                  }}
                  fullWidth
                  variant="standard"
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={fns.cancelEmailCodeValidationDialog}>
                  Cancel
                </Button>
                <Button onClick={fns.validateEmailCode}>Validate</Button>
              </DialogActions>
            </Dialog>
          </div>
          <div className="input-containers">
            <Email
              variant="outlined"
              className="grid-2"
              size="medium"
              onChange={(e) => {
                setemailValidate(false);
                fns.onChangeEmail(e);
              }}
              label={`Email address`}
              type={"Email"}
              helperText={data.emailHelperText}
              value={data.email}
              error={data.emailError || emailValidate === true}
            />
            {emailValidate && (
              <p className="error-custom grid-2">
                <svg
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.5 0C6.81886 0 5.17547 0.498516 3.77766 1.43251C2.37984 2.3665 1.29037 3.69402 0.647028 5.24719C0.00368293 6.80036 -0.164645 8.50943 0.163329 10.1583C0.491303 11.8071 1.30085 13.3217 2.4896 14.5104C3.67834 15.6992 5.1929 16.5087 6.84174 16.8367C8.49057 17.1646 10.1996 16.9963 11.7528 16.353C13.306 15.7096 14.6335 14.6202 15.5675 13.2223C16.5015 11.8245 17 10.1811 17 8.5C17 6.24566 16.1045 4.08365 14.5104 2.48959C12.9163 0.895533 10.7543 0 8.5 0ZM7.44459 4.25C7.44459 3.96821 7.55653 3.69796 7.75578 3.4987C7.95504 3.29944 8.22529 3.1875 8.50709 3.1875C8.78888 3.1875 9.05913 3.29944 9.25839 3.4987C9.45764 3.69796 9.56959 3.96821 9.56959 4.25V9.13041C9.56959 9.26994 9.5421 9.40811 9.48871 9.53702C9.43531 9.66593 9.35705 9.78305 9.25839 9.88172C9.15972 9.98038 9.04259 10.0586 8.91369 10.112C8.78478 10.1654 8.64662 10.1929 8.50709 10.1929C8.36756 10.1929 8.22939 10.1654 8.10048 10.112C7.97158 10.0586 7.85445 9.98038 7.75578 9.88172C7.65712 9.78305 7.57886 9.66593 7.52546 9.53702C7.47207 9.40811 7.44459 9.26994 7.44459 9.13041V4.25ZM8.5 13.8125C8.25904 13.8125 8.02349 13.741 7.82313 13.6072C7.62278 13.4733 7.46662 13.283 7.37441 13.0604C7.2822 12.8378 7.25807 12.5928 7.30508 12.3565C7.35209 12.1201 7.46812 11.9031 7.63851 11.7327C7.8089 11.5623 8.02598 11.4463 8.26232 11.3992C8.49865 11.3522 8.74362 11.3764 8.96624 11.4686C9.18886 11.5608 9.37914 11.7169 9.51301 11.9173C9.64688 12.1176 9.71834 12.3532 9.71834 12.5942C9.71834 12.9173 9.58998 13.2272 9.36149 13.4557C9.13301 13.6841 8.82312 13.8125 8.5 13.8125Z"
                    fill="#E41616"
                  />
                </svg>
                Invalid Email address format
              </p>
            )}
            <FirstName
              variant="outlined"
              size="medium"
              inputProps={{ maxLength: 30 }}
              onChange={(e) => {
                setNameValidateState(false);
                fns.onChangeFirstName(e);
              }}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^a-zA-Z]/g, "");
              }}
              label={`First Name`}
              helperText={data.firstNameHelperText}
              value={data.firstName}
              error={data.firstNameError || NameValidate === true}
            />
            <LastName
              variant="outlined"
              size="medium"
              onChange={(e) => {
                setLNameValidateState(false);
                fns.onChangeLastName(e);
              }}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^a-zA-Z]/g, "");
              }}
              inputProps={{ maxLength: 50 }}
              label={`Last Name`}
              helperText={data.lastNameHelperText}
              value={data.lastName}
              error={data.lastNameError || LNameValidate === true}
            />

            {NameValidate && (
              <p className="error-custom">
                <svg
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.5 0C6.81886 0 5.17547 0.498516 3.77766 1.43251C2.37984 2.3665 1.29037 3.69402 0.647028 5.24719C0.00368293 6.80036 -0.164645 8.50943 0.163329 10.1583C0.491303 11.8071 1.30085 13.3217 2.4896 14.5104C3.67834 15.6992 5.1929 16.5087 6.84174 16.8367C8.49057 17.1646 10.1996 16.9963 11.7528 16.353C13.306 15.7096 14.6335 14.6202 15.5675 13.2223C16.5015 11.8245 17 10.1811 17 8.5C17 6.24566 16.1045 4.08365 14.5104 2.48959C12.9163 0.895533 10.7543 0 8.5 0ZM7.44459 4.25C7.44459 3.96821 7.55653 3.69796 7.75578 3.4987C7.95504 3.29944 8.22529 3.1875 8.50709 3.1875C8.78888 3.1875 9.05913 3.29944 9.25839 3.4987C9.45764 3.69796 9.56959 3.96821 9.56959 4.25V9.13041C9.56959 9.26994 9.5421 9.40811 9.48871 9.53702C9.43531 9.66593 9.35705 9.78305 9.25839 9.88172C9.15972 9.98038 9.04259 10.0586 8.91369 10.112C8.78478 10.1654 8.64662 10.1929 8.50709 10.1929C8.36756 10.1929 8.22939 10.1654 8.10048 10.112C7.97158 10.0586 7.85445 9.98038 7.75578 9.88172C7.65712 9.78305 7.57886 9.66593 7.52546 9.53702C7.47207 9.40811 7.44459 9.26994 7.44459 9.13041V4.25ZM8.5 13.8125C8.25904 13.8125 8.02349 13.741 7.82313 13.6072C7.62278 13.4733 7.46662 13.283 7.37441 13.0604C7.2822 12.8378 7.25807 12.5928 7.30508 12.3565C7.35209 12.1201 7.46812 11.9031 7.63851 11.7327C7.8089 11.5623 8.02598 11.4463 8.26232 11.3992C8.49865 11.3522 8.74362 11.3764 8.96624 11.4686C9.18886 11.5608 9.37914 11.7169 9.51301 11.9173C9.64688 12.1176 9.71834 12.3532 9.71834 12.5942C9.71834 12.9173 9.58998 13.2272 9.36149 13.4557C9.13301 13.6841 8.82312 13.8125 8.5 13.8125Z"
                    fill="#E41616"
                  />
                </svg>
                Invalid First Name
              </p>
            )}

            {LNameValidate && (
              <p className="error-custom">
                <svg
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.5 0C6.81886 0 5.17547 0.498516 3.77766 1.43251C2.37984 2.3665 1.29037 3.69402 0.647028 5.24719C0.00368293 6.80036 -0.164645 8.50943 0.163329 10.1583C0.491303 11.8071 1.30085 13.3217 2.4896 14.5104C3.67834 15.6992 5.1929 16.5087 6.84174 16.8367C8.49057 17.1646 10.1996 16.9963 11.7528 16.353C13.306 15.7096 14.6335 14.6202 15.5675 13.2223C16.5015 11.8245 17 10.1811 17 8.5C17 6.24566 16.1045 4.08365 14.5104 2.48959C12.9163 0.895533 10.7543 0 8.5 0ZM7.44459 4.25C7.44459 3.96821 7.55653 3.69796 7.75578 3.4987C7.95504 3.29944 8.22529 3.1875 8.50709 3.1875C8.78888 3.1875 9.05913 3.29944 9.25839 3.4987C9.45764 3.69796 9.56959 3.96821 9.56959 4.25V9.13041C9.56959 9.26994 9.5421 9.40811 9.48871 9.53702C9.43531 9.66593 9.35705 9.78305 9.25839 9.88172C9.15972 9.98038 9.04259 10.0586 8.91369 10.112C8.78478 10.1654 8.64662 10.1929 8.50709 10.1929C8.36756 10.1929 8.22939 10.1654 8.10048 10.112C7.97158 10.0586 7.85445 9.98038 7.75578 9.88172C7.65712 9.78305 7.57886 9.66593 7.52546 9.53702C7.47207 9.40811 7.44459 9.26994 7.44459 9.13041V4.25ZM8.5 13.8125C8.25904 13.8125 8.02349 13.741 7.82313 13.6072C7.62278 13.4733 7.46662 13.283 7.37441 13.0604C7.2822 12.8378 7.25807 12.5928 7.30508 12.3565C7.35209 12.1201 7.46812 11.9031 7.63851 11.7327C7.8089 11.5623 8.02598 11.4463 8.26232 11.3992C8.49865 11.3522 8.74362 11.3764 8.96624 11.4686C9.18886 11.5608 9.37914 11.7169 9.51301 11.9173C9.64688 12.1176 9.71834 12.3532 9.71834 12.5942C9.71834 12.9173 9.58998 13.2272 9.36149 13.4557C9.13301 13.6841 8.82312 13.8125 8.5 13.8125Z"
                    fill="#E41616"
                  />
                </svg>
                Invalid Last Name
              </p>
            )}

            <PhoneNo
              variant="outlined"
              size="medium"
              inputProps={{ maxLength: 10 }}
              onChange={(e) => {
                var numbers = /^[0-9]*$/;
                if (e.target.value.match(numbers)) {
                  if (e.target.value.length < 11) {
                    setPhoneNumber(false);
                    fns.onChangePhoneNo(e);
                  }
                }
              }}
              label={`Phone`}
              helperText={data.phoneNoHelperText}
              value={data.phoneNo}
              error={data.phoneNoError || PhoneNumber}
            />
            <ZipCode
              variant="outlined"
              size="medium"
              inputProps={{ maxLength: 5 }}
              onChange={(e) => {
                var numbers = /^[0-9]*$/;
                if (e.target.value.match(numbers)) {
                  setZipCodeState(false);
                  fns.onChangeZipCode(e);
                }
              }}
              label={`Zip Code`}
              helperText={data.zipCodeHelperText}
              value={data.zipCode}
              error={data.zipCodeError || ZipCodeState}
            />

            {PhoneNumber && (
              <p className="error-custom">
                <svg
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.5 0C6.81886 0 5.17547 0.498516 3.77766 1.43251C2.37984 2.3665 1.29037 3.69402 0.647028 5.24719C0.00368293 6.80036 -0.164645 8.50943 0.163329 10.1583C0.491303 11.8071 1.30085 13.3217 2.4896 14.5104C3.67834 15.6992 5.1929 16.5087 6.84174 16.8367C8.49057 17.1646 10.1996 16.9963 11.7528 16.353C13.306 15.7096 14.6335 14.6202 15.5675 13.2223C16.5015 11.8245 17 10.1811 17 8.5C17 6.24566 16.1045 4.08365 14.5104 2.48959C12.9163 0.895533 10.7543 0 8.5 0ZM7.44459 4.25C7.44459 3.96821 7.55653 3.69796 7.75578 3.4987C7.95504 3.29944 8.22529 3.1875 8.50709 3.1875C8.78888 3.1875 9.05913 3.29944 9.25839 3.4987C9.45764 3.69796 9.56959 3.96821 9.56959 4.25V9.13041C9.56959 9.26994 9.5421 9.40811 9.48871 9.53702C9.43531 9.66593 9.35705 9.78305 9.25839 9.88172C9.15972 9.98038 9.04259 10.0586 8.91369 10.112C8.78478 10.1654 8.64662 10.1929 8.50709 10.1929C8.36756 10.1929 8.22939 10.1654 8.10048 10.112C7.97158 10.0586 7.85445 9.98038 7.75578 9.88172C7.65712 9.78305 7.57886 9.66593 7.52546 9.53702C7.47207 9.40811 7.44459 9.26994 7.44459 9.13041V4.25ZM8.5 13.8125C8.25904 13.8125 8.02349 13.741 7.82313 13.6072C7.62278 13.4733 7.46662 13.283 7.37441 13.0604C7.2822 12.8378 7.25807 12.5928 7.30508 12.3565C7.35209 12.1201 7.46812 11.9031 7.63851 11.7327C7.8089 11.5623 8.02598 11.4463 8.26232 11.3992C8.49865 11.3522 8.74362 11.3764 8.96624 11.4686C9.18886 11.5608 9.37914 11.7169 9.51301 11.9173C9.64688 12.1176 9.71834 12.3532 9.71834 12.5942C9.71834 12.9173 9.58998 13.2272 9.36149 13.4557C9.13301 13.6841 8.82312 13.8125 8.5 13.8125Z"
                    fill="#E41616"
                  />
                </svg>
                Phone Number must be 10 digits
              </p>
            )}

            {ZipCodeState && (
              <p className="error-custom">
                <svg
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.5 0C6.81886 0 5.17547 0.498516 3.77766 1.43251C2.37984 2.3665 1.29037 3.69402 0.647028 5.24719C0.00368293 6.80036 -0.164645 8.50943 0.163329 10.1583C0.491303 11.8071 1.30085 13.3217 2.4896 14.5104C3.67834 15.6992 5.1929 16.5087 6.84174 16.8367C8.49057 17.1646 10.1996 16.9963 11.7528 16.353C13.306 15.7096 14.6335 14.6202 15.5675 13.2223C16.5015 11.8245 17 10.1811 17 8.5C17 6.24566 16.1045 4.08365 14.5104 2.48959C12.9163 0.895533 10.7543 0 8.5 0ZM7.44459 4.25C7.44459 3.96821 7.55653 3.69796 7.75578 3.4987C7.95504 3.29944 8.22529 3.1875 8.50709 3.1875C8.78888 3.1875 9.05913 3.29944 9.25839 3.4987C9.45764 3.69796 9.56959 3.96821 9.56959 4.25V9.13041C9.56959 9.26994 9.5421 9.40811 9.48871 9.53702C9.43531 9.66593 9.35705 9.78305 9.25839 9.88172C9.15972 9.98038 9.04259 10.0586 8.91369 10.112C8.78478 10.1654 8.64662 10.1929 8.50709 10.1929C8.36756 10.1929 8.22939 10.1654 8.10048 10.112C7.97158 10.0586 7.85445 9.98038 7.75578 9.88172C7.65712 9.78305 7.57886 9.66593 7.52546 9.53702C7.47207 9.40811 7.44459 9.26994 7.44459 9.13041V4.25ZM8.5 13.8125C8.25904 13.8125 8.02349 13.741 7.82313 13.6072C7.62278 13.4733 7.46662 13.283 7.37441 13.0604C7.2822 12.8378 7.25807 12.5928 7.30508 12.3565C7.35209 12.1201 7.46812 11.9031 7.63851 11.7327C7.8089 11.5623 8.02598 11.4463 8.26232 11.3992C8.49865 11.3522 8.74362 11.3764 8.96624 11.4686C9.18886 11.5608 9.37914 11.7169 9.51301 11.9173C9.64688 12.1176 9.71834 12.3532 9.71834 12.5942C9.71834 12.9173 9.58998 13.2272 9.36149 13.4557C9.13301 13.6841 8.82312 13.8125 8.5 13.8125Z"
                    fill="#E41616"
                  />
                </svg>
                Zip code must be 5 digits
              </p>
            )}

            <FormControl
              variant="outlined"
              sx={{ alignSelf: "stretch" }}
              className="grid-2"
            >
              <InputLabel htmlFor="password">Enter Your Password</InputLabel>
              <OutlinedInput
                id="password"
                label="Enter Your Password"
                type={showPassword ? "text" : "password"}
                className="grid-2"
                onChange={(e) => {
                  setPasswordF(false);
                  fns.onChangePassword(e);
                }}
                helperText={data.passwordHelperText}
                value={data.password}
                error={data.passwordError || PasswordF}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>

            <FormControl
              variant="outlined"
              sx={{ alignSelf: "stretch" }}
              className="grid-2"
            >
              <InputLabel htmlFor="confirm-password">
                Confirm Your Password
              </InputLabel>
              <OutlinedInput
                id="confirm-password"
                type={showPassword ? "text" : "password"}
                className="grid-2"
                onChange={(e) => {
                  setPasswordRValue(e.target.value);
                  setPasswordR(false);
                }}
                label={`Confirm Your Password`}
                helperText={data.passwordHelperText}
                error={PasswordR === true}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>

            {PasswordF && (
              <p className="error-custom grid-2">
                <svg
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.5 0C6.81886 0 5.17547 0.498516 3.77766 1.43251C2.37984 2.3665 1.29037 3.69402 0.647028 5.24719C0.00368293 6.80036 -0.164645 8.50943 0.163329 10.1583C0.491303 11.8071 1.30085 13.3217 2.4896 14.5104C3.67834 15.6992 5.1929 16.5087 6.84174 16.8367C8.49057 17.1646 10.1996 16.9963 11.7528 16.353C13.306 15.7096 14.6335 14.6202 15.5675 13.2223C16.5015 11.8245 17 10.1811 17 8.5C17 6.24566 16.1045 4.08365 14.5104 2.48959C12.9163 0.895533 10.7543 0 8.5 0ZM7.44459 4.25C7.44459 3.96821 7.55653 3.69796 7.75578 3.4987C7.95504 3.29944 8.22529 3.1875 8.50709 3.1875C8.78888 3.1875 9.05913 3.29944 9.25839 3.4987C9.45764 3.69796 9.56959 3.96821 9.56959 4.25V9.13041C9.56959 9.26994 9.5421 9.40811 9.48871 9.53702C9.43531 9.66593 9.35705 9.78305 9.25839 9.88172C9.15972 9.98038 9.04259 10.0586 8.91369 10.112C8.78478 10.1654 8.64662 10.1929 8.50709 10.1929C8.36756 10.1929 8.22939 10.1654 8.10048 10.112C7.97158 10.0586 7.85445 9.98038 7.75578 9.88172C7.65712 9.78305 7.57886 9.66593 7.52546 9.53702C7.47207 9.40811 7.44459 9.26994 7.44459 9.13041V4.25ZM8.5 13.8125C8.25904 13.8125 8.02349 13.741 7.82313 13.6072C7.62278 13.4733 7.46662 13.283 7.37441 13.0604C7.2822 12.8378 7.25807 12.5928 7.30508 12.3565C7.35209 12.1201 7.46812 11.9031 7.63851 11.7327C7.8089 11.5623 8.02598 11.4463 8.26232 11.3992C8.49865 11.3522 8.74362 11.3764 8.96624 11.4686C9.18886 11.5608 9.37914 11.7169 9.51301 11.9173C9.64688 12.1176 9.71834 12.3532 9.71834 12.5942C9.71834 12.9173 9.58998 13.2272 9.36149 13.4557C9.13301 13.6841 8.82312 13.8125 8.5 13.8125Z"
                    fill="#E41616"
                  />
                </svg>
                Password should have minimum of 8 characters, must have at least
                one uppercase character, atleast one lowercase character,
                atleast one number, and atleast one special character
              </p>
            )}

            {PasswordR && (
              <p className="error-custom grid-2">
                <svg
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.5 0C6.81886 0 5.17547 0.498516 3.77766 1.43251C2.37984 2.3665 1.29037 3.69402 0.647028 5.24719C0.00368293 6.80036 -0.164645 8.50943 0.163329 10.1583C0.491303 11.8071 1.30085 13.3217 2.4896 14.5104C3.67834 15.6992 5.1929 16.5087 6.84174 16.8367C8.49057 17.1646 10.1996 16.9963 11.7528 16.353C13.306 15.7096 14.6335 14.6202 15.5675 13.2223C16.5015 11.8245 17 10.1811 17 8.5C17 6.24566 16.1045 4.08365 14.5104 2.48959C12.9163 0.895533 10.7543 0 8.5 0ZM7.44459 4.25C7.44459 3.96821 7.55653 3.69796 7.75578 3.4987C7.95504 3.29944 8.22529 3.1875 8.50709 3.1875C8.78888 3.1875 9.05913 3.29944 9.25839 3.4987C9.45764 3.69796 9.56959 3.96821 9.56959 4.25V9.13041C9.56959 9.26994 9.5421 9.40811 9.48871 9.53702C9.43531 9.66593 9.35705 9.78305 9.25839 9.88172C9.15972 9.98038 9.04259 10.0586 8.91369 10.112C8.78478 10.1654 8.64662 10.1929 8.50709 10.1929C8.36756 10.1929 8.22939 10.1654 8.10048 10.112C7.97158 10.0586 7.85445 9.98038 7.75578 9.88172C7.65712 9.78305 7.57886 9.66593 7.52546 9.53702C7.47207 9.40811 7.44459 9.26994 7.44459 9.13041V4.25ZM8.5 13.8125C8.25904 13.8125 8.02349 13.741 7.82313 13.6072C7.62278 13.4733 7.46662 13.283 7.37441 13.0604C7.2822 12.8378 7.25807 12.5928 7.30508 12.3565C7.35209 12.1201 7.46812 11.9031 7.63851 11.7327C7.8089 11.5623 8.02598 11.4463 8.26232 11.3992C8.49865 11.3522 8.74362 11.3764 8.96624 11.4686C9.18886 11.5608 9.37914 11.7169 9.51301 11.9173C9.64688 12.1176 9.71834 12.3532 9.71834 12.5942C9.71834 12.9173 9.58998 13.2272 9.36149 13.4557C9.13301 13.6841 8.82312 13.8125 8.5 13.8125Z"
                    fill="#E41616"
                  />
                </svg>
                Passwords do not match
              </p>
            )}
            <div className="agremenent-area grid-2">
              <Checkbox
                checked={agreeCheck}
                onChange={(e) => setagreeCheck(e.target.checked)}
                sx={{
                  color: "var(--color1) !important",
                }}
              />
              <p>
                I agree to all{" "}
                <Link
                  onClick={handleModal}
                  title="terms"
                  target="_blank"
                  to="https://app.termly.io/document/terms-of-service/0da5d65f-5a2d-4425-9e15-cd6e6a20bf68"
                >
                  Terms & Conditions
                </Link>
                ,{" "}
                <Link
                  onClick={handleModal}
                  title="privacy"
                  target="_blank"
                  to="https://app.termly.io/document/privacy-policy/71e89487-cfbd-46c1-be56-3ec32d89d92f"
                >
                  Privacy Policy
                </Link>
                ,{" "}
                <Link
                  onClick={handleModal}
                  title="cookie"
                  target="_blank"
                  to="https://app.termly.io/document/cookie-policy/1c6fa428-c368-4247-aaee-4f785cb6b8ac"
                >
                  Cookie Policy
                </Link>
                ,{" "}
                <Link
                  onClick={handleModal}
                  title="acceptable"
                  target="_blank"
                  to="https://app.termly.io/document/acceptable-use/5e675ebf-ce69-4322-b7e4-c0840446e50d"
                >
                  Acceptable Use Policy
                </Link>
                , and{" "}
                <Link
                  onClick={handleModal}
                  title="disclaimer"
                  target="_blank"
                  to="https://app.termly.io/document/disclaimer/87c5ae8c-8b42-4b9f-b0d3-50b743db1bd3"
                >
                  Disclaimer
                </Link>
              </p>
            </div>
            {DisableError && (
              <p className="error-custom grid-2">
                <svg
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.5 0C6.81886 0 5.17547 0.498516 3.77766 1.43251C2.37984 2.3665 1.29037 3.69402 0.647028 5.24719C0.00368293 6.80036 -0.164645 8.50943 0.163329 10.1583C0.491303 11.8071 1.30085 13.3217 2.4896 14.5104C3.67834 15.6992 5.1929 16.5087 6.84174 16.8367C8.49057 17.1646 10.1996 16.9963 11.7528 16.353C13.306 15.7096 14.6335 14.6202 15.5675 13.2223C16.5015 11.8245 17 10.1811 17 8.5C17 6.24566 16.1045 4.08365 14.5104 2.48959C12.9163 0.895533 10.7543 0 8.5 0ZM7.44459 4.25C7.44459 3.96821 7.55653 3.69796 7.75578 3.4987C7.95504 3.29944 8.22529 3.1875 8.50709 3.1875C8.78888 3.1875 9.05913 3.29944 9.25839 3.4987C9.45764 3.69796 9.56959 3.96821 9.56959 4.25V9.13041C9.56959 9.26994 9.5421 9.40811 9.48871 9.53702C9.43531 9.66593 9.35705 9.78305 9.25839 9.88172C9.15972 9.98038 9.04259 10.0586 8.91369 10.112C8.78478 10.1654 8.64662 10.1929 8.50709 10.1929C8.36756 10.1929 8.22939 10.1654 8.10048 10.112C7.97158 10.0586 7.85445 9.98038 7.75578 9.88172C7.65712 9.78305 7.57886 9.66593 7.52546 9.53702C7.47207 9.40811 7.44459 9.26994 7.44459 9.13041V4.25ZM8.5 13.8125C8.25904 13.8125 8.02349 13.741 7.82313 13.6072C7.62278 13.4733 7.46662 13.283 7.37441 13.0604C7.2822 12.8378 7.25807 12.5928 7.30508 12.3565C7.35209 12.1201 7.46812 11.9031 7.63851 11.7327C7.8089 11.5623 8.02598 11.4463 8.26232 11.3992C8.49865 11.3522 8.74362 11.3764 8.96624 11.4686C9.18886 11.5608 9.37914 11.7169 9.51301 11.9173C9.64688 12.1176 9.71834 12.3532 9.71834 12.5942C9.71834 12.9173 9.58998 13.2272 9.36149 13.4557C9.13301 13.6841 8.82312 13.8125 8.5 13.8125Z"
                    fill="#E41616"
                  />
                </svg>
                Please agree to our terms & conditions
              </p>
            )}
            <ButtonContained
              variant="contained"
              className="button-auth-main grid-2"
              size="large"
              color="primary"
              onClick={(e) => {
                setDisableError(false);
                let checkfall = false;

                if (!ValidateEmail(data.email)) {
                  setemailValidate(true);
                  checkfall = true;
                }
                console.log(NameValidation(data.firstName));
                if (!NameValidation(data.firstName)) {
                  setNameValidateState(true);
                  checkfall = true;
                }
                if (!NameValidation(data.lastName)) {
                  setLNameValidateState(true);
                  checkfall = true;
                }

                if (data.zipCode.length !== 5) {
                  setZipCodeState(true);
                  checkfall = true;
                }

                if (!checkPassword(data.password)) {
                  setPasswordF(true);
                  checkfall = true;
                }

                if (data.password !== PasswordRValue) {
                  setPasswordR(true);
                  checkfall = true;
                }

                if (data.phoneNo.length < 10) {
                  setPhoneNumber(true);
                  return;
                }

                if (agreeCheck === false) {
                  checkfall = true;
                }

                if (Disable) {
                  checkfall = true;
                }

                if (agreeCheck === false) {
                  setDisableError(true);
                }
                if (checkfall === true) {
                  return;
                }
                fns.onClickSignUp(e);
              }}
            >
              Sign Up
            </ButtonContained>
          </div>
          {/* <div className='or-login'>
						<span></span>
						<p>or sign up with</p>
						<span></span>
					</div>
					<div className='social-media-wrapper'>
						<button>
							<img src={social1} alt='' />
						</button>
						<button>
							<img src={social2} alt='' />
						</button>
						<button>
							<img src={social3} alt='' />
						</button>
					</div> */}
          <p className="signup-account">
            Already have an account?
            <Link to="/login">Login</Link>
          </p>
          <img src={girl} className="girl-image" alt="" />
        </div>
      </div>
      <LegalsModal
        isOpen={isModal}
        setIsOpen={setIsModal}
        title={modalInfo.title}
        link={modalInfo.link}
      />
    </>
  );
}

export default SignUp;

import React, { useEffect, useState } from "react";
import AddIcon from "../../assets/images/add-icon3.svg";
import MinusIcon from "../../assets/images/goals-minus.svg";
import { useSetRecoilState } from "recoil";
import { atm_goalsModal } from "src/state/atomDashboard";
import GoalsInfo from "./GoalsInfo";
import CashflowChart from "./CashflowChart";
import useDashboard from "../useDashboard";

const MyGoals = () => {
  const { fnsDashboard } = useDashboard();
  const [goalsOption, setGoalsOption] = useState(1);
  const setGoalModal = useSetRecoilState(atm_goalsModal);

  useEffect(() => {
    fnsDashboard.getAllGoals();
  }, []);

  return (
    <div className="goalsBox">
      <div className="goalsBoxHeader goalsOptions">
        <div className="headerText">
          <img src={MinusIcon} alt="" /> My Goals
        </div>
        <div onClick={() => setGoalModal(true)} className="btn btnAddNewGoal">
          <img src={AddIcon} alt="" /> Add New Goal
        </div>
      </div>

      {/* <div className="goalsOptions">
        <div className="option">
          <div
            onClick={() => setGoalsOption(1)}
            className={`btn btnProgress ${goalsOption == 1 && "active"}`}
          >
            Your Goal Progress
          </div>
          <div
            onClick={() => setGoalsOption(2)}
            className={`btn btnCashflow ${goalsOption == 2 && "active"}`}
          >
            Cashflow
          </div>
        </div>
        <div onClick={() => setGoalModal(true)} className="btn btnAddNewGoal">
          <img src={AddIcon} alt="" /> Add New Goal
        </div>
      </div> */}

      {goalsOption == 1 ? <GoalsInfo /> : <CashflowChart />}
    </div>
  );
};

export default MyGoals;

import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
// Default theme
import "@splidejs/react-splide/css";

// or other themes
import "@splidejs/react-splide/css/skyblue";
import "@splidejs/react-splide/css/sea-green";

// or only core styles
import "@splidejs/react-splide/css/core";
const Security = () => {
  return (
    <div className="security">
      <div className="circle"></div>
      <h2>Security First</h2>

      <p>
        At Kamunity, we only bring and keep data that we need to serve you. This
        helps us <br /> keep our sensitive data footprint to minimal.
      </p>

      <Splide
        aria-label="My Favorite Images"
        options={{
          arrows: false,
          perPage: 3,
          gap: 20,
          type: "loop",
          autoWidth: true,
          padding: 30,
          breakpoints: {
            600: {
              perPage: 1,
              autoWidth: false,
            },
          },
        }}
        className="splide-custom"
      >
        <SplideSlide>
          <div className="box">
            <h3>Bank Level Encryption</h3>
            <p>
              All data is encrypted during transit and at rest, and with 256-bit
              SSL encryption and security practices that match with your bank’s,
              your data is well protected.
            </p>
          </div>
        </SplideSlide>
        
        <SplideSlide>
          <div className="box">
            <h3>No bank credentials</h3>
            <p>
              We do not store your bank credentials. We use well established
              third party financial account aggregator services to connect to
              your bank and brokerage accounts. Your banking credentials are
              directly sent to the respective service from your browser.
              Kamunity platform will never see your credentials.
            </p>
          </div>
        </SplideSlide>
        
        <SplideSlide>
          <div className="box">
            <h3>No account numbers</h3>
            <p>
              We only get partial accounts numbers from our account aggregators
              and that’s all we keep in our systems too. We do not make any
              attempt to get your full account numbers.
            </p>
          </div>
        </SplideSlide>
        
         <SplideSlide>
          <div className="box">
            <h3>Walk the talk</h3>
            <p>
            Our Founders were the first users on the platform and their own data is on 
            the platform as well.
            </p>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div className="box">
            <h3>No Social Security Number</h3>
            <p>
              We neither store nor do we ask for your Social Security Number (SSN)
            </p>
          </div>
        </SplideSlide> 
      </Splide>
    </div>
  );
};

export default Security;

import React from "react";
import { atm_InsightsMerchantList4Category } from "src/state/insightsCategories";
import { useRecoilValue } from "recoil";

import MerchantDropdown from "./MerchantDropdown";
import useSpendByCategory from "./useSpendByCategory";
import { LinearProgress } from "@mui/material";

const MobInsightTable = () => {
  const { data } = useSpendByCategory();

  const merchantList4SelectedCategory = useRecoilValue(
    atm_InsightsMerchantList4Category
  );

  return (
    <div className="mobInsightsTable" style={{ marginTop: 8, position: "relative" }}>
      {data.merchantDataLoading && (
        <LinearProgress
          style={{ width: "100%", position: "absolute", top: 0, left: 0 }}
        />
      )}
      {merchantList4SelectedCategory.map((merchantData, index) => (
        <MerchantDropdown
          totalSpend={data.selectedCategoryAmount}
          index={index}
          merchantData={merchantData}
          key={index}
        />
      ))}
    </div>
  );
};

export default MobInsightTable;

/**********************************************************************
 *
 *   Component hook generated by Quest
 *
 *   Code Logic for the component goes in this hook
 *   To setup bindings that use the data here or call the functions here, use the Quest editor
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/

import axios from "axios";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { getAuthToken } from "src/global/central";

import {
  atm_mainMenuButtonPressed,
  atm_firstName,
  atm_lastName,
} from "../../state/atoms";
import {
  atm_alertType,
  atm_profileData,
  atm_profileLoading,
} from "src/state/atomMyProfile";
import useMainMenu from "../MainMenu/useMainMenu";

export default function useMyProfile() {
  let data = {};
  const { fns } = useMainMenu();
  const [globalFirstName, setGlobalFirstName] = useRecoilState(atm_firstName);
  const [globalLastName, setGlobalLastName] = useRecoilState(atm_lastName);
  const [mainMenuButtonPressed, setMainMenuButtonPressed] = useRecoilState(
    atm_mainMenuButtonPressed
  );

  let [formSubmitted, setFormSubmitted] = useState(false);

  let [isErrorMessage, setIsErrorMessage] = useState("");


  let [email, setEmail] = useState("");
  let [emailError, setEmailError] = useState(null);
  let [emailHelperText, setEmailHelperText] = useState(null);

  let [firstName, setFirstName] = useState("");
  let [firstNameError, setFirstNameError] = useState(null);
  let [firstNameHelperText, setFirstNameHelperText] = useState(null);

  let [lastName, setLastName] = useState("");
  let [lastNameError, setLastNameError] = useState(null);
  let [lastNameHelperText, setLastNameHelperText] = useState(null);

  let [phoneNo, setPhoneNo] = useState("");
  let [phoneNoError, setPhoneNoError] = useState(null);
  let [phoneNoHelperText, setPhoneNoHelperText] = useState(null);

  let [zipCode, setZipCode] = useState("");
  let [zipCodeError, setZipCodeError] = useState(null);
  let [zipCodeHelperText, setZipCodeHelperText] = useState(null);

  let [sysUserError, setSysUserError] = useState(null);
  let [sysUserHelperText, setSysUserHelperText] = useState(null);

  let [alertType, setAlertType] = useRecoilState(atm_alertType);
  data.alertType = alertType;

  data.isErrorMessage = isErrorMessage;
  data.formSubmitted = formSubmitted;
  data.ogEmail = "";

  data.mobile = false;

  data.emailError = emailError;
  data.emailHelperText = emailHelperText;

  data.firstNameError = firstNameError;
  data.firstNameHelperText = firstNameHelperText;

  data.lastNameError = lastNameError;
  data.lastNameHelperText = lastNameHelperText;

  data.phoneNoError = phoneNoError;
  data.phoneNoHelperText = phoneNoHelperText;

  data.zipCodeError = zipCodeError;
  data.zipCodeHelperText = zipCodeHelperText;

  data.sysUserError = sysUserError;
  data.sysUserHelperText = sysUserHelperText;

  const [profileData, setProfileData] = useRecoilState(atm_profileData);
  data.profileData = profileData;

  const [profileLoading, setProfileLoading] =
    useRecoilState(atm_profileLoading);
  data.profileLoading = profileLoading;

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
    setEmailError(null);
    setEmailHelperText(null);
  };

  const clearErrorMessage = (e) => {
    setSysUserError(false);
  };

  const onChangeFirstName = (e) => {
    setFirstName(e.target.value);
    setFirstNameError(null);
  };

  const onChangeLastName = (e) => {
    setLastName(e.target.value);
    setLastNameError(null);
  };

  const onChangePhoneNo = useCallback((e) => {
    setPhoneNo(e.target.value);
    setPhoneNoError(null);
    setPhoneNoHelperText(null);
  }, []);

  const onChangeZipCode = (e) => {
    setZipCode(e.target.value);
    setZipCodeError(null);
  };

  function onClickSaveProfileDetails(enteredData) {
    setProfileLoading(true);
    let URL =
      process.env.REACT_APP_USER_API_END_POINT + "/users/v1/changeProfile";

    axios
      .post(
        URL,
        {
          newEmail: enteredData["email"],
          newFirstName: enteredData["firstName"],
          newLastName: enteredData["lastName"],
          newZipCode: enteredData["zipCode"],
          newPhoneNumber: enteredData["phoneNumber"],
          dayOfBirth: enteredData["dayOfBirth"],
          monthOfBirth: enteredData["monthOfBirth"],
          yearOfBirth: enteredData["yearOfBirth"],
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + getAuthToken(),
          },
        }
      )
      .then((res) => {
        setProfileLoading(false);
        processEditUserResponse(res["data"], enteredData);
        setGlobalFirstName(enteredData["firstName"]);
        setGlobalLastName(enteredData["lastName"]);
        // console.log(redirect);
        processEditUserResponse(res["data"], enteredData);
      })
      .catch((err) => {
        setProfileLoading(false);
        console.log("inside catch block with error : " + err);
      });
  }

  function processEditUserResponse(responseData, enteredData) {
    if (!responseData["success"]) {
      for (let i = 0; i < responseData.errors.length; i++) {
        const fieldWithError = responseData.errors[i].field;
        const fieldMessage = responseData.errors[i].errorMessage;

        switch (fieldWithError) {
          case "email":
            setEmailError(true);
            setEmailHelperText(fieldMessage);
            break;
          case "firstName":
            setFirstNameError(true);
            setFirstNameHelperText(fieldMessage);
            break;
          case "lastName":
            setLastNameError(true);
            setLastNameHelperText(fieldMessage);
            break;

          case "phoneNumber":
            setPhoneNoError(true);
            setPhoneNoHelperText(fieldMessage);
            break;

          case "zipCode":
            setZipCodeError(true);
            setZipCodeHelperText(fieldMessage);
            break;
          case "sysUser":
            setSysUserError(true);
            setSysUserHelperText(fieldMessage);
            break;
          default:
            break;
        }
      }
      setAlertType(false);
      // setIsErrorMessage(errorMessage);
    } else {
      setEmailHelperText("");
      setFirstNameHelperText("");
      setLastNameHelperText("");
      setPhoneNoHelperText("");
      setZipCodeHelperText("");
      // setIsErrorMessage(responseData.responseMessage);
      setAlertType(true);
      if (enteredData.email !== profileData.email) {
        setTimeout(() => {
          fns.onMenuClickLogin();
        }, 2000);
      }
      setProfileData(enteredData);
    }
  }
  function getEditUserData() {
    setProfileLoading(true);
    let URL = process.env.REACT_APP_USER_API_END_POINT + "/users/v1/getProfile";
    axios
      .post(
        URL,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + getAuthToken(),
          },
        }
      )
      .then((res) => {
        setProfileLoading(false);
        setProfileData(res["data"]["responseData"]);
      })
      .catch((err) => {
        setProfileLoading(false);
        console.log(err);
      });
  }
  let profileFns = {
    onChangeEmail,
    onChangeFirstName,
    onChangeLastName,
    onChangePhoneNo,
    onChangeZipCode,
    onClickSaveProfileDetails,
    clearErrorMessage,
    getEditUserData,
  };
  return { data, profileFns };
}

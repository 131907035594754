import { CssBaseline, ThemeProvider } from '@mui/material';
import questTheme from 'src/MyDesignSystemLightTheme';

export const AppProviders = ({ children }) => {
  return (
    <ThemeProvider theme={questTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

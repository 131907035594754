const Col = ({ children, gap, justify, align, className = "", onClick, style }) => {
  const getJustify = (justify) => {
    switch (justify) {
      case "start":
        return "flex-start";
      case "between":
        return "space-between";
      case "center":
        return "center";
      case "end":
        return "flex-end";
      default:
        return "";
    }
  };

  const getAlign = (align) => {
    switch (align) {
      case "start":
        return "flex-start";
      case "center":
        return "center";
      case "end":
        return "flex-end";
      default:
        return "";
    }
  };

  const myStyle = {
    display: "flex",
    flexDirection: "column",
    gap: gap ? `${gap}px` : 0,
    justifyContent: getJustify(justify),
    alignItems: getAlign(align),
    ...style
  };
  return <div style={myStyle} className={className} onClick={onClick}>{children}</div>;
};
export default Col;

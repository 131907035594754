import React, { useEffect, useState } from "react";
import { Alert, Autocomplete, Box, MenuItem, Modal, TextField } from "@mui/material";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { atm_isEditSubCatModalOpen, atm_selectedSubCatForEdit } from "src/state/atomsTransactionData";
import CloseIcon from "../../assets/images/close.svg";
import useManageCategories from "src/components/ManageCategories/useManageCategories";
import KmnButton from "../Transactions/KmnButton";
import { Row } from "src/components/shared";
import { SUBCATEGORY_MODE } from "src/global/constants";
import useDeviceType from "src/hooks/useDeviceType";
import useTransactionsLanding from "../Transactions/useTransactionsLanding";
import Loader from "src/components/shared/Loader";
import PencilIcon from "../../assets/images/pencil2.svg";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const EditCategoryModal = () => {
  const { dataTransactions, fnsTransactions } = useTransactionsLanding();
  const { data, fnsManageCategories } = useManageCategories();
  const [isEditSubCatModalOpen, setIsEditSubCatModalOpen] = useRecoilState(atm_isEditSubCatModalOpen);
  const isEditMode = isEditSubCatModalOpen === SUBCATEGORY_MODE.EDIT;
  const isConfirmMode = isEditSubCatModalOpen === SUBCATEGORY_MODE.CONFIRM;
  const selectedCategoryForEdit = useRecoilValue(atm_selectedSubCatForEdit);
  const resetSelectedCategoryForEdit = useResetRecoilState(atm_selectedSubCatForEdit);
  const [changedData, setChangedData] = useState({
    category: selectedCategoryForEdit?.category?.kmnId || "",
    subCategory: selectedCategoryForEdit?.subCategory?.name || "",
  });
  const editedCategoryName = data?.categoriesData?.find((d) => d?.kmnId === changedData?.category)?.name;
  const isDisableSave = !changedData?.subCategory?.trim() || !changedData?.category || dataTransactions.loading || (isEditMode && changedData.category === selectedCategoryForEdit?.category?.kmnId && changedData.subCategory?.trim() === selectedCategoryForEdit?.subCategory?.name?.trim());
  const { isMobile } = useDeviceType();
  const [error, setError] = useState("");

  const handleCatChange = (value) => {
    setChangedData({ ...changedData, category: value });
  };

  const handleSubCatChange = (e) => {
    let value = e.target.value;
    setChangedData({ ...changedData, subCategory: value });
  };

  const resetError = () => {
    if (error) setError("");
  };

  let modalContent = {
    title: "Add New Category",
    subTitle: "Create custom category for your transactions",
    category: (
      <Autocomplete
        disablePortal
        options={data.categoriesData}
        getOptionLabel={(d) => d?.name}
        getOptionKey={(d) => d?.kmnId}
        onChange={(_, newValue) => {
          handleCatChange(newValue?.kmnId);
        }}
        renderInput={(params) => <TextField variant={isMobile ? "standard" : "outlined"} onFocus={resetError} {...params} error={Boolean(error)} name="category" label="Category Group" />}
      />
    ),
    subCategory: (
      <TextField
        variant={isMobile ? "standard" : "outlined"}
        label="Category Name"
        fullWidth
        value={changedData?.subCategory}
        name="subCategory"
        onChange={handleSubCatChange}
        sx={{
          ".MuiInputBase-root::before": {
            borderBottom: isMobile ? "none" : undefined,
          },
          ".MuiInputBase-input:focus + img": {
            display: isMobile ? "none" : undefined,
          },
          ".MuiFormHelperText-root" :{
            display: 'flex',
            alignItems: 'center',
            marginLeft: 0,
            gap: 0.5
          }
        }}
        InputProps={{ endAdornment: <img className="editIcon" src={PencilIcon} alt="" /> }}
        onFocus={resetError}
        error={Boolean(error)}
        helperText={
          error && (
            <>
              <ErrorOutlineIcon
                sx={{
                  fontSize: "12px",
                  marginBottom: "1.5px"
                }}
              />{" "}
              {error}
            </>
          )
        }
      />
    ),
    other: "",
    btnCancel: "Cancel",
    btnSave: "Save",
  };

  if (isEditMode) {
    modalContent = {
      title: "Edit Category",
      subTitle: "Update custom category for your transactions",
      category: (
        <TextField
          variant={isMobile ? "standard" : "outlined"}
          select
          label="Category Group"
          name="category"
          value={changedData?.category}
          onChange={(e) => {
            handleCatChange(e.target.value);
          }}
          fullWidth
          InputProps={{
            readOnly: true,
          }}
        >
          {data.categoriesData.map((d) => (
            <MenuItem key={d?.kmnId} value={d?.kmnId}>
              {d?.name}
            </MenuItem>
          ))}
        </TextField>
      ),
      subCategory: modalContent.subCategory,
      btnCancel: modalContent.btnCancel,
      btnSave: modalContent.btnSave,
    };
  }

  if (isConfirmMode) {
    modalContent = {
      title: "Update Category",
      subTitle: "",
      category: "",
      subCategory: "",
      btnCancel: "No, Exit",
      btnSave: "Yes, Update",
      other: (
        <>
          <div>Are you sure you want to update category:</div>
          <div>
            <b>
              “{selectedCategoryForEdit?.category?.name} - {selectedCategoryForEdit?.subCategory?.name}”
            </b>{" "}
            to{" "}
            <b>
              “{editedCategoryName} - {changedData?.subCategory}”
            </b>
          </div>
          <div className="note">This will update all your historical transactions associated with this category to the new category/category group. Are you sure you want to proceed?</div>
        </>
      ),
    };
  }

  const handleClose = () => {
    setIsEditSubCatModalOpen("");
  };

  const handleSave = async () => {
    if (isEditMode) {
      setIsEditSubCatModalOpen(SUBCATEGORY_MODE.CONFIRM);
    } else {
      if (error) setError("");
      let res = await fnsTransactions.modifyCustomCategory({ kmnId: isEditSubCatModalOpen === SUBCATEGORY_MODE.CONFIRM ? selectedCategoryForEdit?.subCategory?.kmnId : changedData?.category, name: changedData?.subCategory });
      if (res && !res?.data?.success) {
        if(isEditSubCatModalOpen === SUBCATEGORY_MODE.CONFIRM) {
          setIsEditSubCatModalOpen(SUBCATEGORY_MODE.EDIT);
        }
        return setError(res?.data?.responseMessage);
      }
      fnsManageCategories.getCategoriesData();
    }
  };

  useEffect(() => {
    return () => resetSelectedCategoryForEdit();
  }, []);

  return (
    <Modal open={isEditSubCatModalOpen} onClose={handleClose}>
      <Box className={`editCategoryModal ${isConfirmMode && "confirmEditCategoryModal"}`}>
        <img src={CloseIcon} className="closeBtn" alt="close" onClick={handleClose} />
        <div className="head">
          <div className="title">{modalContent.title}</div>
          {modalContent.subTitle && <div className="subTitle">{modalContent.subTitle}</div>}
        </div>
        <div style={{position: 'relative'}}><Loader visible={dataTransactions.loading} /></div>
        <div className="body">
          {modalContent.category && (
            <>
              {modalContent.category}
              {modalContent.subCategory}
            </>
          )}
          {modalContent.other}
        </div>
        <Row justify={isConfirmMode ? "center" : "end"} className="footer" gap={12}>
          <KmnButton b variant="primary" color="primary_outlined" onClick={handleClose}>
            {modalContent.btnCancel}
          </KmnButton>
          <KmnButton b variant="primary" color="primary" onClick={handleSave} isDisabled={isDisableSave}>
            {modalContent.btnSave}
          </KmnButton>
        </Row>
      </Box>
    </Modal>
  );
};

export default EditCategoryModal;

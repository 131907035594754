import React from "react";

const Story = () => {
  return (
    <section className="story">
      <h1>Our Story</h1>
      <div className="inshort">
        <p className="p1">
          At Kamunity.io, we believe in a world where each and every one of us
          has the power to build a financial community where we can share,
          learn, compare spendings, savings, investments, and more in a secure
          way. By blending demographic and historical data, along with your
          behavior, we want to un.personalize finance and offer insights that
          make sense for you!
        </p>

        <p className="p2">
          Kamunity.io
          <span style={{ marginRight: 6 }}>
            &nbsp;originated from our own experiences, pains, and struggles with
            the various platforms available in the market today.
          </span>
           
        </p>
      </div>
      <div className="story-grid">
        <div className="col1 col">
          <div className="main-title"><h2>Personal Finance Management</h2></div>

          <div className="box box1">
            <p className="p1">
              From managing our finances in ledgers, to managing it in
              spreadsheets, and then to online personal finance aggregation and
              tracking platforms; we have been there and done that.
              There was a time and place for each of them, but there doesn’t
              seem to be any major rethinking on <span className="highlight">"What Next"</span>
            </p>
            <p>
              Some of the popular platforms we’ve used are still stuck in the
              past. The new entrants in the market have only brought marginal
              improvements with nothing exciting or pathbreaking.
            </p>
          </div>

          <div className="box box5">
            <div className="title"><h3>Our Challenge</h3></div>
            <p>
              So, we linked all our financial accounts, great; We got a 360°
              view of our entire estate, great; and based on this information,
              we were able to get a good view of our spending habits and
              optimized them too, again great. But then, we reached a point
              where we didn’t know what to optimize further. We wished we could
              talk to someone; friends, family or neighbors. But these are no
              easy talks, as people don’t feel comfortable talking about their
              finances. We scoured online forums, but realized what people say
              and what they actually do, are two different things.
            </p>
            <p>
              So, we took a leap of faith, put ourselves out there, and started
              talking to our friends about needing financial advice. We were
              surprised to find our friends were struggling with the same burning
              questions as we did. We began discovering things we didn’t knew
              earlier: which mortgage company is offering the best deal, which
              car dealer has the best price, which handyman to use for plumbing,
              etc. While no one friend had all the answers, as a group, we
              figured most of them out.
            </p>
          </div>

          <div className="box box3">
            <p>
              <span className="title">And that's where Kamunity began:</span>{" "}
              What if we can do what we did with our friends, at scale, without
              exposing personal information? In case we have additional
              questions, we’d provide a space to ask those questions as well.
              <span style={{ marginLeft: 6 }}>
                There is no better way to know about a great mortgage rate, a
                credit card APR, high yield savings account APR, than from a friend or
                a neighbor that's backed by real data.
              </span>
            </p>
          </div>
        </div>

        <div className="col2 col">
          <div className="main-title"><h2>Merchant Reviews & Ratings</h2></div>
          <div className="box box4">
            <p>
              So, we are in need of a plumber. We scour the web for different
              plumbers, find the ones that service our area, go through their
              ratings and reviews, and then once we find someone that makes
              sense, we hold our breath.
            {/* </p>
            <p> */}
              &nbsp;Following is a whack-a-mole process of requesting quotes,
              coordinating schedules and then if merchant doesn’t show up, go
              through this all over again.{" "}
            {/* </p>
            <p> */}
              More often than not, the price difference between the lowest and
              highest quote is at least 3-5 times, making us wonder where we
              went wrong.
            </p>
          </div>

          <div className="box box5">
            <div className="title"><h3>There must be better way to do this!</h3></div>
            <p>
              So, we looked at the current 5-star ratings system, digging
              further given the significant impact good ratings and reviews have
              on a business. We found that while more than 50% of consumers look
              for reviews before buying, only 5-10% of the users actually give
              ratings and write reviews.{" "}
            </p>
            <p>
              That's a problem: 5-10% of the consumers shouldn’t be influencing
              the decision of majority of consumers.
            </p>
          </div>

          <div className="box box6">
            <p>
              <span className="title" style={{ marginRight: 6 }}>
                And this is exactly what we are changing with Kamunity:
              </span>
              While we'll continue to use the rating and review system,
              <span style={{ marginLeft: 6 }}>
                we are adding additional elements to score a merchant, like
                repeat customers, number of new customers, ratings going up or
                down over time, age of business, and the income of the
                consumers.
              </span>{" "}
              Most importantly, an easy-to-use scheduling platform for both
              consumers and providers to book the services.
            </p>
          </div>
        </div>
      </div>
      <div className="circle"></div>
    </section>
  );
};

export default Story;

import React, { useState } from "react";
import { usdFormate } from "src/global/central";
import { Box, Rating, Slider } from "@mui/material";
import CommentsLogo from "../../assets/images/comments.svg";

const MerchantDropdown = ({ merchantData, index, totalSpend }) => {
  // const categorySpend = data.selectedCategoryAmount || 1;
  // const percentage = Math.round((merchantData.txnTotal / categorySpend) * 100);

  const percent = Math.round((merchantData.txnTotal / totalSpend) * 100);

  const [active, setActive] = useState(false);

  return (
    <div className="mobMerchantRow">
      <div
        className="mobMerchantHeaders"
        onClick={(e) => {
          setActive(!active);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="8"
          height="5"
          viewBox="0 0 8 5"
          className={active ? "activeSvg" : "Svg"}
        >
          <path
            d="M2.90573 0.301366L0.305725 2.91352C-0.0109418 3.23166 -0.0816084 3.59569 0.093725 4.0056C0.269058 4.4155 0.581392 4.62079 1.03073 4.62146H6.18072C6.63072 4.62146 6.94339 4.41617 7.11872 4.0056C7.29406 3.59502 7.22306 3.23099 6.90573 2.91352L4.30572 0.301366C4.20572 0.200899 4.09739 0.125549 3.98073 0.0753155C3.86406 0.0250816 3.73906 -3.57628e-05 3.60573 -3.57628e-05C3.47239 -3.57628e-05 3.34739 0.0250816 3.23073 0.0753155C3.11406 0.125549 3.00573 0.200899 2.90573 0.301366Z"
            fill={active ? "var(--color1)" : "#000"}
          />
        </svg>
        <div className="index">{index + 1}</div>
        <div className="name">
          {merchantData.merchantURL && (
            <img height={25} srcSet={merchantData.merchantURL} alt="" />
          )}{" "}
          {merchantData.merchantName}
        </div>
        <div className="amount">{usdFormate(merchantData.txnTotal)}</div>
      </div>

      {active && (
        <div className={`mobMerchantInside ${active && "active"}`}>
          <div className="spendings">
            <span style={{ fontSize: "var(--fontSize16)", fontWeight: 700 }}>
              % SPEND
            </span>

            <div>{percent}%</div>
          </div>

          <div className="reviews">
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Rating
                value={merchantData.totalUserWithRatings || 0}
                precision={0.5}
                readOnly
              />
              <span
                style={{
                  marginLeft: 5,
                  fontSize: "var(--fontSize16)",
                  fontWeight: 700,
                }}
              >
                {"(" + merchantData.totalUserWithRatings + ")"}
              </span>
            </Box>

            <div>
              <img className="commentsLogo" src={CommentsLogo} alt="" />
              <span
                style={{
                  marginLeft: 5,
                  fontSize: "var(--fontSize16)",
                  fontWeight: 700,
                }}
              >
                Reviews {"(" + merchantData.totalUserWithRatings + ")"}
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MerchantDropdown;

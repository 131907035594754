import React from "react";
import BackIcon from "../../assets/images/back.svg";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  atm_IsMerchantClicked,
  atm_merchantTab,
} from "src/state/insightsMerchants";
import useSpendByMerchant from "./useSpendByMerchant";
import RatingReviews from "./RatingReviews";
import MerchantTrends from "./MerchantTrends";
import MoM from "./MoM";
import ByLocation from "./ByLocation";

const MerchantData = () => {
  const [tab, setTab] = useRecoilState(atm_merchantTab);
  
  const { data } = useSpendByMerchant();

  const setIsMerchantClicked = useSetRecoilState(atm_IsMerchantClicked);


  return (
    <div className="insights-data">
      <div className="title mobile">
        <img
          onClick={() => setIsMerchantClicked(false)}
          src={BackIcon}
          alt=""
        />
        <div className="logo-name">
          {data.selectedMerchant?.merchantURL && (
            <img
              height={25}
              srcSet={data.selectedMerchant?.merchantURL}
              alt="logo"
            />
          )}
          {data.selectedMerchantName}
        </div>
      </div>
      <header>
        <ul>
          <li
            onClick={() => setTab(1)}
            className={tab === 1 ? "active" : undefined}
          >
            Month on Month
          </li>
          <li
            onClick={() => setTab(2)}
            className={tab === 2 ? "active" : undefined}
          >
            By Location
          </li>
          <li
            onClick={() => setTab(3)}
            className={tab === 3 ? "active" : undefined}
          >
            <div className="pc">Merchant</div> Trends
          </li>
          <li
            onClick={() => setTab(4)}
            className={tab === 4 ? "active" : undefined}
          >
            Rating & Reviews
          </li>
        </ul>
      </header>
      <div className="merchant-data insights-data-inside">
        <header className="head">
          <span>Insights - Spend by Merchant</span>
        </header>

        {tab === 1 && <MoM />}
        {tab === 2 && <ByLocation />}
        {tab === 3 && <MerchantTrends />}
        {tab === 4 && <RatingReviews />}
      </div>
    </div>
  );
};

export default MerchantData;

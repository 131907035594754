import React, { useEffect } from "react";
import "../../assets/css/insights.css";
import Filters from "./FiltersMerchant";
import SidebarMerchant from "./SidebarMerchant";
import insightsBg from "../../assets/images/bg-insights.png";

import useSpendByMerchant from "./useSpendByMerchant";

import {
  atm_globalFilterCount,
  atm_globalFilterValue,
  atm_isCategoryFilterModified,
} from "../../../state/atoms";
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import { useNavigate } from "react-router-dom";
import MerchantData from "./MerchantData";
import { GetWindowWidth, getAuthToken } from "src/global/central";
import {
  atm_IsMobMerchantFilter,
  atm_globalMerchant,
  atm_merchantParentSelectors,
  atm_merchantTab,
  atm_selectedRatings,
} from "src/state/insightsMerchants";
import MetaTags from "src/components/shared/MetaTags";

const SpendByMerchant = () => {
  const navigate = useNavigate();

  const { data, fns } = useSpendByMerchant();

  const jwt = getAuthToken();

  const width = GetWindowWidth();

  const setMerchantTab = useSetRecoilState(atm_merchantTab);

  const setMerchantDateFilter = useSetRecoilState(atm_IsMobMerchantFilter);

  const globalFilterValue = useRecoilValue(atm_globalFilterValue);

  const [isCategoryFilterModified, setCategoryFilterModified] = useRecoilState(
    atm_isCategoryFilterModified
  );
  const setGlobalFilterCount = useSetRecoilState(
    atm_globalFilterCount
  );

  const merchantTab = useRecoilValue(atm_merchantTab);

  const setMerchantParentSelectors = useSetRecoilState(atm_merchantParentSelectors)

  const globalMerchant = useRecoilValue(atm_globalMerchant)

  const resetSelectedRatings = useResetRecoilState(atm_selectedRatings);

  useEffect(() => {
    setMerchantParentSelectors({
      mom: merchantTab === 1 || null,
      byLocation: merchantTab === 2 || null,
      merchantTrends: merchantTab === 3 || null,
      ratingReviews: merchantTab === 4 || null,
    })
  },[globalFilterValue,globalMerchant]);
  
  useEffect(() => {
    fns.getMerchantListData();
    if (
      (globalFilterValue.categoryFilter?.excludedCategoryFilter.length ||
        globalFilterValue.categoryFilter?.selectedCategoryFilter.length) &&
      isCategoryFilterModified
    ) {
      setGlobalFilterCount((prevValue) => prevValue - 1);
      setCategoryFilterModified(false);
    }

    return () => {
      setMerchantTab(1);
      setMerchantDateFilter(false);
      resetSelectedRatings();
    };
  }, []);

  if (jwt != "") {
    return (
      <main className="insights insights-merchant">
        <MetaTags index="InsightsByMerchant" />
        <img src={insightsBg} alt="" className="insightsBg" />

        <div className="row2">
          <Filters />
          {/* PC -->  */}
          {width > 900 && (
            <>
              <SidebarMerchant />
              <MerchantData />
            </>
          )}

          {!data.isMerchantClicked && width < 900 && <SidebarMerchant />}
          {data.isMerchantClicked && width < 900 && <MerchantData />}
        </div>
      </main>
    );
  } else {
    navigate("/login");
  }
};

export default SpendByMerchant;

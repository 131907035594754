import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../components/assets/images/logo.svg";
import burger from "../../components/assets/images/burger.svg";
import { useRecoilValue } from "recoil";
import { atm_mainMenuButtonPressed } from "src/state/atoms";
import useMainMenu from "../MainMenu/useMainMenu";
import { useMediaQuery } from "@mui/material";
import { Col, Row } from "../shared";

const Header = () => {
  const [active, setActive] = useState(false);
  const { fns } = useMainMenu();
  const isMobile = useMediaQuery("(max-width: 1000px)");
  const mainMenuButtonPressed = useRecoilValue(atm_mainMenuButtonPressed);

  const Menu = () => {
    return (
      <ul className="header-menu">
        <li
          onClick={fns.onMenuClickHome}
          className={`${mainMenuButtonPressed == "Home" && "menu-active"}`}
        >
          Home
        </li>
        <li
          onClick={fns.onMenuClickAbout}
          className={`${mainMenuButtonPressed == "About" && "menu-active"}`}
        >
          About Us
        </li>
        <li>
          <Link to="https://www.blog.kamunity.io/" target="_blank">
            Blog
          </Link>
        </li>
        <li
          onClick={fns.onMenuClickCalculators}
          className={`${
            mainMenuButtonPressed == "Calculators" && "menu-active"
          }`}
        >
          <Link> Calculators </Link>
        </li>
        <li
          onClick={fns.onMenuClickTrends}
          className={`${
            mainMenuButtonPressed == "Trends" && "menu-active"
          }`}
        >
          <Link> Trends </Link>
        </li>
      </ul>
    );
  };
  return (
    <>
      <Col
        className="header header-home"
      >
        <Row justify={"between"} align={"center"} style={{ width: "100%" }}>
          <div
            id="mobile-sidebar-wrapper"
            className={`mobile-wrapper ${active && "active"}`}
            onClick={(e) => {
              let id = e.target.id;
              if (id === "mobile-sidebar-wrapper") {
                setActive(false);
              }
            }}
          >
            <ul className={`mobile-nav`}>
              <li
                onClick={() => {
                  setActive(false);
                  fns.onMenuClickHome();
                }}
                className={`${
                  mainMenuButtonPressed == "Home" && "menu-active"
                }`}
              >
                <Link>Home</Link>
              </li>
              <li
                onClick={() => {
                  setActive(false);
                  fns.onMenuClickAbout();
                }}
                className={`${
                  mainMenuButtonPressed == "About" && "menu-active"
                }`}
              >
                <Link> About Us </Link>
              </li>
              <li>
                <Link to="https://www.blog.kamunity.io/" target="_blank">
                  Blog
                </Link>
              </li>
              <li
                onClick={() => {
                  setActive(false);
                  fns.onMenuClickCalculators();
                }}
                className={`${
                  mainMenuButtonPressed == "Calculators" && "menu-active"
                }`}
              >
                <Link> Calculators </Link>
              </li>
              <li
                onClick={() => {
                  setActive(false);
                  fns.onMenuClickTrends();
                }}
                className={`${
                  mainMenuButtonPressed == "Trends" && "menu-active"
                }`}
              >
                <Link> Trends </Link>
              </li>
              {/* <li
            onClick={(e) => {
              setActive(false);
            }}
          >
            <Link>Product</Link>
          </li>
          <li
            onClick={(e) => {
              setActive(false);
            }}
          >
            <Link>Forums</Link>
          </li> */}
              <li
                className="login"
                onClick={() => {
                  setActive(false);
                  fns.onMenuClickLogin();
                }}
              >
                <Link to="/login">Login</Link>
              </li>
              <li
                className="signup"
                onClick={() => {
                  setActive(false);
                  fns.onMenuClickSignUp();
                }}
              >
                <Link to="/signup">Sign Up</Link>
              </li>
            </ul>
          </div>
          <Link to="/" onClick={fns.onMenuClickHome}>
            <img src={logo} alt="" />
          </Link>
          {!isMobile && <Menu />}
          <div className="right-area">
            <Link onClick={fns.onMenuClickLogin} to="/login">
              Login
            </Link>
            <Link onClick={fns.onMenuClickSignUp} to="/signup">
              Sign Up
            </Link>
          </div>
          <div
            className="burger-icon"
            onClick={(e) => {
              setActive(!active);
            }}
          >
            <img src={burger} alt="" />
          </div>
        </Row>
        {isMobile && <Menu />}
      </Col>
    </>
  );
};

export default Header;

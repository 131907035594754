import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { atm_tblSecuritiesByUser } from "src/state/atomsHoldingData";
import { usdFormate } from "src/global/central";
import headerArrow from "../../assets/images/headerArrow.svg";
import activeArrow from "../../assets/images/arrow-up-active.svg";
import useHoldingsLanding from "./useHoldingsLanding";
import { Box, LinearProgress } from "@mui/material";

const MobHoldingsTable = () => {
  const mainTableData = useRecoilValue(atm_tblSecuritiesByUser);

  const { fnsHoldings, dataHoldings } = useHoldingsLanding();

  const [expandedRows, setExpandedRows] = useState([]);
  const [expandedViewMoreRows, setExpandedViewMoreRows] = useState([]);

  const [viewMoreRows, setViewMoreRows] = useState([]);

  const [currentExpandedRow, setCurrentExpandedRow] = useState(null);

  const [expandedViewMoreIndex, setExpandedViewMoreIndex] = useState(null);

  const [securitiesInsideRows, setSecuritiesInsideRows] = useState({});

  const handleHeaderClick = (index) => {
    const isRowExpanded = expandedRows.includes(index);

    if (isRowExpanded) {
      setExpandedRows(expandedRows.filter((i) => i !== index));
      setExpandedViewMoreRows(expandedViewMoreRows.filter((i) => i !== index));
    } else {
      setExpandedRows([...expandedRows, index]);
    }
  };

  const handleViewMoreClick = (rowId, rowTotalValue, ticker, index) => {
    const isViewMoreExpanded = expandedViewMoreRows.includes(index);

    if (!isViewMoreExpanded) {
      setExpandedViewMoreRows([...expandedViewMoreRows, index]);

      if (!securitiesInsideRows[ticker]) {
        fnsHoldings.getSecurityByUserId(rowId, rowTotalValue);
      }
    } else {
      setExpandedViewMoreRows(expandedViewMoreRows.filter((i) => i !== index));
    }
  };

  useEffect(() => {
    const updatedInsideRows = dataHoldings.selectedSecurityDetails.map(
      (item) => ({
        id: item.securityId,
        custodian: item.custodianName,
        account: item.accountName ,
        quantity: item.totalQuantity,
        costBasis: usdFormate(item.costBasis),
        currentValue: usdFormate(item.totalValue),
        percentage: `${parseFloat(item.holdingPercentage).toFixed(2)}%`,
      })
    );

    setViewMoreRows(updatedInsideRows);
  }, [dataHoldings.selectedSecurityDetails]);

  useEffect(() => {
    if (viewMoreRows.length) {
      setSecuritiesInsideRows((prevData) => ({
        ...prevData,
        [currentExpandedRow]: viewMoreRows,
      }));
    }
  }, [viewMoreRows]);

  return (
    <div className="mobHoldingsTable">
      {mainTableData.map((mainTableData, index) => {
        return (
          <div key={index} className="mobHoldingsTableRow">
            <div
              className="mobHoldingsTableHeaders"
              onClick={() => handleHeaderClick(index)}
            >
              <div className="row1 row">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="5"
                  viewBox="0 0 8 5"
                  className={
                    expandedRows.includes(index) ? "activeSvg" : undefined
                  }
                >
                  <path
                    d="M2.90573 0.301366L0.305725 2.91352C-0.0109418 3.23166 -0.0816084 3.59569 0.093725 4.0056C0.269058 4.4155 0.581392 4.62079 1.03073 4.62146H6.18072C6.63072 4.62146 6.94339 4.41617 7.11872 4.0056C7.29406 3.59502 7.22306 3.23099 6.90573 2.91352L4.30572 0.301366C4.20572 0.200899 4.09739 0.125549 3.98073 0.0753155C3.86406 0.0250816 3.73906 -3.57628e-05 3.60573 -3.57628e-05C3.47239 -3.57628e-05 3.34739 0.0250816 3.23073 0.0753155C3.11406 0.125549 3.00573 0.200899 2.90573 0.301366Z"
                    fill={
                      expandedRows.includes(index) ? "var(--color1)" : "#000"
                    }
                  />
                </svg>
                <div className="custodian name cell">
                  {mainTableData.Ticker}
                </div>
                <div
                  className="amount cell"
                  style={{
                    color:
                      mainTableData.totalValue < 0
                        ? "rgb(201, 82, 102)"
                        : "inherit",
                  }}
                >
                  {usdFormate(mainTableData.totalValue)}
                </div>
              </div>
              <div className="row2 row">
                <div className="name cell">{mainTableData.Name}</div>
                <div className="percent cell">{mainTableData.percentage}</div>
              </div>
            </div>

            {expandedRows.includes(index) && (
              <>
                <div
                  className={`mobHoldingsTableInside ${
                    expandedRows.includes(index) && "active"
                  }`}
                >
                  {dataHoldings.selectedSecurityDetailsLoading &&
                    expandedViewMoreIndex === index && (
                      <Box
                        sx={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                        }}
                      >
                        <LinearProgress />
                      </Box>
                    )}
                  <div className="row1 row">
                    <div>
                      <div className="head">Quantity</div>
                      <div className="value">{mainTableData.Quantity}</div>
                    </div>

                    <div>
                      <div className="head">Cost Basis</div>
                      <div className="value">{mainTableData.costBasis}</div>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      handleViewMoreClick(
                        mainTableData.id,
                        mainTableData.unformattedTotalValue,
                        mainTableData.Ticker,
                        index
                      );
                      setCurrentExpandedRow(mainTableData.Ticker);
                      setExpandedViewMoreIndex(index);
                    }}
                    className="view-more"
                  >
                    View More
                    <img
                      src={
                        expandedViewMoreRows.includes(index)
                          ? activeArrow
                          : headerArrow
                      }
                      alt=""
                    />
                  </div>
                </div>

                {expandedViewMoreRows.includes(index) &&
                  !dataHoldings.selectedSecurityDetailsLoading && (
                    <div className="viewMoreTable">
                      {securitiesInsideRows[mainTableData.Ticker]?.map(
                        (insideRow, index) => {
                          return (
                            <div key={index} className="mainRow">
                              <div className="row row1">
                                <div className="custodian cell">
                                  <div className="head">Custodian</div>
                                  <div className="value">
                                    {insideRow.account}
                                  </div>
                                </div>
                              </div>
                              <div className="row row2">
                                <div className="quantity cell">
                                  <div className="head">Quantity</div>
                                  <div className="value">
                                    {insideRow.quantity}
                                  </div>
                                </div>
                                <div className="percentage cell">
                                  <div className="head">Percentage</div>
                                  <div className="value">
                                    {insideRow.percentage}
                                  </div>
                                </div>
                              </div>
                              <div className="row row3">
                                <div className="currentValue cell">
                                  <div className="head">Current Value</div>
                                  <div className="value">
                                    {insideRow.currentValue}
                                  </div>
                                </div>
                                <div className="costBasis cell">
                                  <div className="head">Cost Basis</div>
                                  <div className="value">
                                    {insideRow.costBasis}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  )}
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default MobHoldingsTable;

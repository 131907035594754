export const collegeData = {
  2023: [
    {
      collegeType: "Public 2-Yr In District",
      tutionFee: 3990,
      roomAndBoard: 9970,
    },
    {
      collegeType: "Public 4-Yr In State",
      tutionFee: 11260,
      roomAndBoard: 12770,
    },
    {
      collegeType: "Public 4-Yr Out-of-State",
      tutionFee: 29150,
      roomAndBoard: 12770,
    },
    {
      collegeType: "Private 4-Yr",
      tutionFee: 41540,
      roomAndBoard: 14650,
    },
  ],
  2024: [
    {
      collegeType: "Public 2-Yr In District",
      tutionFee: 4093,
      roomAndBoard: 10226,
    },
    {
      collegeType: "Public 4-Yr In State",
      tutionFee: 11537,
      roomAndBoard: 13084,
    },
    {
      collegeType: "Public 4-Yr Out-of-State",
      tutionFee: 30026,
      roomAndBoard: 13154,
    },
    {
      collegeType: "Private 4-Yr",
      tutionFee: 43204,
      roomAndBoard: 15237,
    },
  ],
  2025: [
    {
      collegeType: "Public 2-Yr In District",
      tutionFee: 4198,
      roomAndBoard: 10489,
    },
    {
      collegeType: "Public 4-Yr In State",
      tutionFee: 11820,
      roomAndBoard: 13405,
    },
    {
      collegeType: "Public 4-Yr Out-of-State",
      tutionFee: 30927,
      roomAndBoard: 13549,
    },
    {
      collegeType: "Private 4-Yr",
      tutionFee: 44935,
      roomAndBoard: 15847,
    },
  ],
  2026: [
    {
      collegeType: "Public 2-Yr In District",
      tutionFee: 4306,
      roomAndBoard: 10759,
    },
    {
      collegeType: "Public 4-Yr In State",
      tutionFee: 12110,
      roomAndBoard: 13735,
    },
    {
      collegeType: "Public 4-Yr Out-of-State",
      tutionFee: 31856,
      roomAndBoard: 13956,
    },
    {
      collegeType: "Private 4-Yr",
      tutionFee: 46735,
      roomAndBoard: 16482,
    },
  ],
  2027: [
    {
      collegeType: "Public 2-Yr In District",
      tutionFee: 4416,
      roomAndBoard: 11035,
    },
    {
      collegeType: "Public 4-Yr In State",
      tutionFee: 12408,
      roomAndBoard: 14072,
    },
    {
      collegeType: "Public 4-Yr Out-of-State",
      tutionFee: 32813,
      roomAndBoard: 14375,
    },
    {
      collegeType: "Private 4-Yr",
      tutionFee: 48607,
      roomAndBoard: 17142,
    },
  ],
  2028: [
    {
      collegeType: "Public 2-Yr In District",
      tutionFee: 4530,
      roomAndBoard: 11319,
    },
    {
      collegeType: "Public 4-Yr In State",
      tutionFee: 12713,
      roomAndBoard: 14418,
    },
    {
      collegeType: "Public 4-Yr Out-of-State",
      tutionFee: 33799,
      roomAndBoard: 14806,
    },
    {
      collegeType: "Private 4-Yr",
      tutionFee: 50554,
      roomAndBoard: 17829,
    },
  ],
  2029: [
    {
      collegeType: "Public 2-Yr In District",
      tutionFee: 4646,
      roomAndBoard: 11610,
    },
    {
      collegeType: "Public 4-Yr In State",
      tutionFee: 13025,
      roomAndBoard: 14772,
    },
    {
      collegeType: "Public 4-Yr Out-of-State",
      tutionFee: 34814,
      roomAndBoard: 15251,
    },
    {
      collegeType: "Private 4-Yr",
      tutionFee: 52580,
      roomAndBoard: 18543,
    },
  ],
  2030: [
    {
      collegeType: "Public 2-Yr In District",
      tutionFee: 4766,
      roomAndBoard: 11909,
    },
    {
      collegeType: "Public 4-Yr In State",
      tutionFee: 13345,
      roomAndBoard: 15135,
    },
    {
      collegeType: "Public 4-Yr Out-of-State",
      tutionFee: 35859,
      roomAndBoard: 15709,
    },
    {
      collegeType: "Private 4-Yr",
      tutionFee: 54686,
      roomAndBoard: 19286,
    },
  ],
  2031: [
    {
      collegeType: "Public 2-Yr In District",
      tutionFee: 4888,
      roomAndBoard: 12215,
    },
    {
      collegeType: "Public 4-Yr In State",
      tutionFee: 13673,
      roomAndBoard: 15507,
    },
    {
      collegeType: "Public 4-Yr Out-of-State",
      tutionFee: 36936,
      roomAndBoard: 16181,
    },
    {
      collegeType: "Private 4-Yr",
      tutionFee: 56877,
      roomAndBoard: 20059,
    },
  ],
  2032: [
    {
      collegeType: "Public 2-Yr In District",
      tutionFee: 5014,
      roomAndBoard: 12529,
    },
    {
      collegeType: "Public 4-Yr In State",
      tutionFee: 14009,
      roomAndBoard: 15888,
    },
    {
      collegeType: "Public 4-Yr Out-of-State",
      tutionFee: 38046,
      roomAndBoard: 16667,
    },
    {
      collegeType: "Private 4-Yr",
      tutionFee: 59155,
      roomAndBoard: 20862,
    },
  ],
  2033: [
    {
      collegeType: "Public 2-Yr In District",
      tutionFee: 5143,
      roomAndBoard: 12851,
    },
    {
      collegeType: "Public 4-Yr In State",
      tutionFee: 14353,
      roomAndBoard: 16278,
    },
    {
      collegeType: "Public 4-Yr Out-of-State",
      tutionFee: 39189,
      roomAndBoard: 17168,
    },
    {
      collegeType: "Private 4-Yr",
      tutionFee: 62525,
      roomAndBoard: 21698,
    },
  ],
  2034: [
    {
      collegeType: "Public 2-Yr In District",
      tutionFee: 5275,
      roomAndBoard: 13181,
    },
    {
      collegeType: "Public 4-Yr In State",
      tutionFee: 14706,
      roomAndBoard: 16678,
    },
    {
      collegeType: "Public 4-Yr Out-of-State",
      tutionFee: 40366,
      roomAndBoard: 17683,
    },
    {
      collegeType: "Private 4-Yr",
      tutionFee: 63990,
      roomAndBoard: 22567,
    },
  ],
  2035: [
    {
      collegeType: "Public 2-Yr In District",
      tutionFee: 5411,
      roomAndBoard: 13520,
    },
    {
      collegeType: "Public 4-Yr In State",
      tutionFee: 15067,
      roomAndBoard: 17088,
    },
    {
      collegeType: "Public 4-Yr Out-of-State",
      tutionFee: 41578,
      roomAndBoard: 18214,
    },
    {
      collegeType: "Private 4-Yr",
      tutionFee: 66553,
      roomAndBoard: 23471,
    },
  ],
  2036: [
    {
      collegeType: "Public 2-Yr In District",
      tutionFee: 5550,
      roomAndBoard: 13867,
    },
    {
      collegeType: "Public 4-Yr In State",
      tutionFee: 15437,
      roomAndBoard: 17507,
    },
    {
      collegeType: "Public 4-Yr Out-of-State",
      tutionFee: 42827,
      roomAndBoard: 18762,
    },
    {
      collegeType: "Private 4-Yr",
      tutionFee: 69219,
      roomAndBoard: 24412,
    },
  ],
  2037: [
    {
      collegeType: "Public 2-Yr In District",
      tutionFee: 5692,
      roomAndBoard: 14224,
    },
    {
      collegeType: "Public 4-Yr In State",
      tutionFee: 15816,
      roomAndBoard: 17937,
    },
    {
      collegeType: "Public 4-Yr Out-of-State",
      tutionFee: 44113,
      roomAndBoard: 19325,
    },
    {
      collegeType: "Private 4-Yr",
      tutionFee: 71992,
      roomAndBoard: 25390,
    },
  ],
  2038: [
    {
      collegeType: "Public 2-Yr In District",
      tutionFee: 5839,
      roomAndBoard: 14590,
    },
    {
      collegeType: "Public 4-Yr In State",
      tutionFee: 16205,
      roomAndBoard: 18378,
    },
    {
      collegeType: "Public 4-Yr Out-of-State",
      tutionFee: 45438,
      roomAndBoard: 19905,
    },
    {
      collegeType: "Private 4-Yr",
      tutionFee: 74876,
      roomAndBoard: 26407,
    },
  ],
  2039: [
    {
      collegeType: "Public 2-Yr In District",
      tutionFee: 5989,
      roomAndBoard: 14965,
    },
    {
      collegeType: "Public 4-Yr In State",
      tutionFee: 16603,
      roomAndBoard: 18830,
    },
    {
      collegeType: "Public 4-Yr Out-of-State",
      tutionFee: 46803,
      roomAndBoard: 20503,
    },
    {
      collegeType: "Private 4-Yr",
      tutionFee: 77876,
      roomAndBoard: 27465,
    },
  ],
  2040: [
    {
      collegeType: "Public 2-Yr In District",
      tutionFee: 6143,
      roomAndBoard: 15349,
    },
    {
      collegeType: "Public 4-Yr In State",
      tutionFee: 17011,
      roomAndBoard: 19292,
    },
    {
      collegeType: "Public 4-Yr Out-of-State",
      tutionFee: 48209,
      roomAndBoard: 21119,
    },
    {
      collegeType: "Private 4-Yr",
      tutionFee: 80995,
      roomAndBoard: 28565,
    },
  ],
  2041: [
    {
      collegeType: "Public 2-Yr In District",
      tutionFee: 6301,
      roomAndBoard: 15744,
    },
    {
      collegeType: "Public 4-Yr In State",
      tutionFee: 17429,
      roomAndBoard: 19766,
    },
    {
      collegeType: "Public 4-Yr Out-of-State",
      tutionFee: 49657,
      roomAndBoard: 21753,
    },
    {
      collegeType: "Private 4-Yr",
      tutionFee: 84240,
      roomAndBoard: 29709,
    },
  ],
  2042: [
    {
      collegeType: "Public 2-Yr In District",
      tutionFee: 6463,
      roomAndBoard: 16149,
    },
    {
      collegeType: "Public 4-Yr In State",
      tutionFee: 17857,
      roomAndBoard: 20252,
    },
    {
      collegeType: "Public 4-Yr Out-of-State",
      tutionFee: 51148,
      roomAndBoard: 22407,
    },
    {
      collegeType: "Private 4-Yr",
      tutionFee: 87615,
      roomAndBoard: 30899,
    },
  ],
  2043: [
    {
      collegeType: "Public 2-Yr In District",
      tutionFee: 6629,
      roomAndBoard: 16564,
    },
    {
      collegeType: "Public 4-Yr In State",
      tutionFee: 18296,
      roomAndBoard: 20749,
    },
    {
      collegeType: "Public 4-Yr Out-of-State",
      tutionFee: 52684,
      roomAndBoard: 23080,
    },
    {
      collegeType: "Private 4-Yr",
      tutionFee: 91124,
      roomAndBoard: 32137,
    },
  ],
  2044: [
    {
      collegeType: "Public 2-Yr In District",
      tutionFee: 6799,
      roomAndBoard: 16990,
    },
    {
      collegeType: "Public 4-Yr In State",
      tutionFee: 18745,
      roomAndBoard: 21259,
    },
    {
      collegeType: "Public 4-Yr Out-of-State",
      tutionFee: 54267,
      roomAndBoard: 23773,
    },
    {
      collegeType: "Private 4-Yr",
      tutionFee: 96775,
      roomAndBoard: 33424,
    },
  ],
  2045: [
    {
      collegeType: "Public 2-Yr In District",
      tutionFee: 6974,
      roomAndBoard: 17426,
    },
    {
      collegeType: "Public 4-Yr In State",
      tutionFee: 19206,
      roomAndBoard: 21781,
    },
    {
      collegeType: "Public 4-Yr Out-of-State",
      tutionFee: 55897,
      roomAndBoard: 24487,
    },
    {
      collegeType: "Private 4-Yr",
      tutionFee: 102520,
      roomAndBoard: 34763,
    },
  ],
  2046: [
    {
      collegeType: "Public 2-Yr In District",
      tutionFee: 7153,
      roomAndBoard: 17874,
    },
    {
      collegeType: "Public 4-Yr In State",
      tutionFee: 19678,
      roomAndBoard: 22317,
    },
    {
      collegeType: "Public 4-Yr Out-of-State",
      tutionFee: 57575,
      roomAndBoard: 25223,
    },
    {
      collegeType: "Private 4-Yr",
      tutionFee: 102520,
      roomAndBoard: 36156,
    },
  ],
  2047: [
    {
      collegeType: "Public 2-Yr In District",
      tutionFee: 7337,
      roomAndBoard: 18334,
    },
    {
      collegeType: "Public 4-Yr In State",
      tutionFee: 20161,
      roomAndBoard: 22865,
    },
    {
      collegeType: "Public 4-Yr Out-of-State",
      tutionFee: 59305,
      roomAndBoard: 25980,
    },
    {
      collegeType: "Private 4-Yr",
      tutionFee: 106627,
      roomAndBoard: 37605,
    },
  ],
  2048: [
    {
      collegeType: "Public 2-Yr In District",
      tutionFee: 7526,
      roomAndBoard: 18805,
    },
    {
      collegeType: "Public 4-Yr In State",
      tutionFee: 20656,
      roomAndBoard: 23427,
    },
    {
      collegeType: "Public 4-Yr Out-of-State",
      tutionFee: 61086,
      roomAndBoard: 26760,
    },
    {
      collegeType: "Private 4-Yr",
      tutionFee: 110899,
      roomAndBoard: 39111,
    },
  ],
  2049: [
    {
      collegeType: "Public 2-Yr In District",
      tutionFee: 7719,
      roomAndBoard: 19289,
    },
    {
      collegeType: "Public 4-Yr In State",
      tutionFee: 21164,
      roomAndBoard: 24002,
    },
    {
      collegeType: "Public 4-Yr Out-of-State",
      tutionFee: 62921,
      roomAndBoard: 27564,
    },
    {
      collegeType: "Private 4-Yr",
      tutionFee: 115342,
      roomAndBoard: 40678,
    },
  ],
  2050: [
    {
      collegeType: "Public 2-Yr In District",
      tutionFee: 7918,
      roomAndBoard: 19784,
    },
    {
      collegeType: "Public 4-Yr In State",
      tutionFee: 21684,
      roomAndBoard: 24592,
    },
    {
      collegeType: "Public 4-Yr Out-of-State",
      tutionFee: 64811,
      roomAndBoard: 28392,
    },
    {
      collegeType: "Private 4-Yr",
      tutionFee: 119962,
      roomAndBoard: 42307,
    },
  ],
};

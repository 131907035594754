import React, { useEffect } from "react";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import { StyledEngineProvider } from "@mui/material/styles";

import OurWhyMain from "src/components/OurWhy/OurWhy";
import Login from "src/components/Login/Login";
import SignUp from "src/components/SignUp/SignUp";
import Home from "src/components/Home/Home";
//import Accounts from "src/components/Accounts/AccountLanding";
import Transactions from "src/components/Accounts/Transactions/TransactionsLanding";
import Holdings from "src/components/Accounts/Holdings/HoldingsLanding";
// import Insights from "src/components/Insights_old/Insights";
// import SpendByCategory from "src/components/Insights_old/SpendByCategory/SpendByCategory";
// import SpendByMerchant from "src/components/Insights_old/SpendByMerchant/SpendByMerchant";
// new insights imports
import SpendByCategoryNew from "src/components/Insights/SpendByCategory/SpendByCategory";
import SpendByMerchantNew from "src/components/Insights/SpendByMerchant/SpendByMerchant";
import ChangePassword from "./components/ChangePassword/ChangePassword";

import Header from "./components/Home/Header";
import MainMenu from "./components/MainMenu/MainMenu";
import { ProtectedRoute } from "./ProtectedRoute";
import About from "./components/About/About";
import MyProfile from "./components/MyProfile/MyProfile";
import { AppProviders } from "./providers/app-providers";
import Error404 from "./components/Error404/Eror404";
import { useRecoilValue } from "recoil";
import ManageCategoryBox from "./components/ManageCategories/ManageCategoryBox";
import { atm_isManageCategoryBox } from "./state/atomsTransactionData";
import Dashboard from "./components/Dashboard/Dashboard";
import { getAuthToken, removeAuthTokens } from "./global/central";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import ResetPassword from "./components/ForgotPassword/ResetPassword";
import Legals from "./components/shared/Legals";
import HouseHold from "./components/Household/Household";
import Calculators from "./components/Calculators/Calculators";
import LoginSignupMenu from "./components/shared/LoginSignupMenu";
import Trends from "./components/Trends/Trends";
import "./components/assets/css/shared.css"

function App() {
  const navigate = useNavigate();
  const isUserLoggedIn = getAuthToken();

  const isManageCategoryBox = useRecoilValue(atm_isManageCategoryBox);

  useEffect(() => {
    async function getRefreshToken() {
      let TokenGet = window.sessionStorage.getItem("session-token");
      if (TokenGet) {
        console.log("yes User is Active");
        // add the api if user is active it means the session jwt is in storage
      }
    }

    // you can change the timing right now it is 30 min means 1800000 milli seconds
    setInterval(getRefreshToken, 1800000);
  }, []);

  // this is for not active for 15 mintues logic
  useEffect(() => {
    var warningTimeout = 840000;
    var timoutNow = 60000;
    var warningTimerID, timeoutTimerID;

    function startTimer() {
      // window.setTimeout returns an Id that can be used to start and stop a timer
      warningTimerID = window.setTimeout(warningInactive, warningTimeout);
    }

    function warningInactive() {
      window.clearTimeout(warningTimerID);
      timeoutTimerID = window.setTimeout(IdleTimeout, timoutNow);
    }

    function resetTimer() {
      window.clearTimeout(timeoutTimerID);
      window.clearTimeout(warningTimerID);
      startTimer();
    }

    // Logout the user.
    function IdleTimeout() {
      // let TokenGet = window.sessionStorage.getItem("session-token");
      let TokenGet = getAuthToken();

      if (TokenGet) {
        //window.sessionStorage.removeItem("session-token");
        removeAuthTokens();
        navigate("/");
      }
    }

    const setupTimers = () => {
      document.addEventListener("mousemove", resetTimer, false);
      document.addEventListener("mousedown", resetTimer, false);
      document.addEventListener("keypress", resetTimer, false);
      document.addEventListener("touchmove", resetTimer, false);
      document.addEventListener("onscroll", resetTimer, false);
      startTimer();
    };
    setupTimers();
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <AppProviders>
        <Routes>
          <Route
            path="/"
            element={[
              isUserLoggedIn ? <MainMenu key={1} /> : <Header key={1} />,
              <Home key={2} />,
              <Legals key={51} />,
              <LoginSignupMenu key={81} />,
            ]}
          />

          <Route
            path="/about"
            element={[
              isUserLoggedIn ? <MainMenu key={25} /> : <Header key={24} />,
              <About key={26} />,
              <Legals key={52} />,
              <LoginSignupMenu key={82} />,
            ]}
          />

          <Route
            path="/ourWhy"
            element={[<OurWhyMain />, <Legals key={53} />]}
          />

          <Route
            path="/signup"
            element={[
              <Header key={3} />,
              <SignUp key={4} />,
              <Legals key={54} />,
            ]}
          />
          <Route
            path="/login"
            element={[
              <Header key={5} />,
              <Login key={6} />,
              <Legals key={55} />,
            ]}
          />
          <Route
            path="/calculators"
            element={<Navigate to="/calculators/college" replace />}
          />
          <Route
            path="/calculators/:calculatorType"
            element={[
              isUserLoggedIn ? <MainMenu key={73} /> : <Header key={70} />,
              <Calculators key={71} />,
              <Legals key={72} />,
              !isUserLoggedIn && <LoginSignupMenu key={83} />,
            ]}
          />
          <Route
            path="/trends"
            element={[isUserLoggedIn ? <MainMenu key={86} /> : <Header key={87} />,<Trends key={88} />,<Legals key={89} />,
            !isUserLoggedIn && <LoginSignupMenu key={90} />]}
          />
          <Route
            path="/login/forgotpassword"
            element={
              <div className="passPage">
                <Header key={30} />
                <ForgotPassword key={31} />
                <Legals key={56} />
              </div>
            }
          />
          <Route
            path="/login/resetpassword"
            element={
              <div className="passPage">
                <Header key={32} />
                <ResetPassword key={33} />
                <Legals key={57} />
              </div>
            }
          />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <MainMenu key={9} />
                <Dashboard key={10} />
                <Legals key={58} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/transactions"
            element={
              <ProtectedRoute>
                <MainMenu key={7} />
                <Transactions key={8} />
                <Legals key={59} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/holdings"
            element={
              <ProtectedRoute>
                <MainMenu key={15} />
                <Holdings key={16} />
                <Legals key={60} />
              </ProtectedRoute>
            }
          />

          {/* <Route
            path="/insights"
            element={[<MainMenu key={9} />, <Insights key={10} />]}
          /> */}
          {/* <Route
            path="/insights/spendByCategory"
            element={[<MainMenu key={11} />, <SpendByCategory key={12} />]}
          />
          <Route
            path="/insights/spendByMerchant"
            element={[<MainMenu key={13} />, <SpendByMerchant key={14} />]}
          /> */}

          {/* insights - new  */}

          <Route
            path="/insights/spendByCategory"
            element={
              <ProtectedRoute>
                <MainMenu key={20} />
                <SpendByCategoryNew key={21} />
                <Legals key={61} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/insights/spendByMerchant"
            element={
              <ProtectedRoute>
                <MainMenu key={23} />
                <SpendByMerchantNew key={24} />
                <Legals key={62} />
              </ProtectedRoute>
            }
          />

          <Route
            path="/changePassword"
            element={
              <ProtectedRoute>
                <MainMenu key={17} />
                <ChangePassword key={18} />
                <Legals key={63} />
              </ProtectedRoute>
            }
          />

          <Route
            path="/household"
            element={
              <ProtectedRoute>
                <MainMenu key={17} />
                <HouseHold key={18} />
                <Legals key={65} />
              </ProtectedRoute>
            }
          />

          <Route
            path="/myProfile"
            element={
              <ProtectedRoute>
                <MainMenu key={17} />
                <MyProfile key={18} />
                <Legals key={64} />
              </ProtectedRoute>
            }
          />

          <Route
            path="*"
            element={[
              <Header key={20} />,
              <Error404 key={21} />,
              <Legals key={52} />,
            ]}
          />
        </Routes>
        {isManageCategoryBox && <ManageCategoryBox />}
      </AppProviders>
    </StyledEngineProvider>
  );
}

export default App;

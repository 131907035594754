import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import useApi from "src/global/useApi";
import { atm_accounts, atm_accountsList } from "src/state/atomsAccountList";

export default function useAddManualAccount() {
  const setAccounts = useSetRecoilState(atm_accounts);
  const setAccountsList = useSetRecoilState(atm_accountsList);
  const {
    loading: isAllAccountsLoading,
    data: allAccountsData,
    callApi: callGetAllAcounts,
  } = useApi();

  const {
    loading: isAllSubAccountsLoading,
    data: allSubAccountsData,
    callApi: callGetAllSubAccounts,
  } = useApi();

  const {
    loading: isAddManualAccountLoading,
    callApi: callAddManualAccount,
    error
  } = useApi();

  const isLoading =
    isAllAccountsLoading ||
    isAllSubAccountsLoading ||
    isAddManualAccountLoading;

  function getApi(endpoint) {
    return process.env.REACT_APP_ACCOUNT_API_END_POINT + endpoint;
  }

  function getAllAccountTypes() {
    let api = getApi("/accounts/v1/getAllAccountTypes");

    callGetAllAcounts(api);
  }

  function getAllSubAccountTypes(accountId) {
    let api = getApi("/accounts/v1/getAccountSubTypes");
    let payload = {
      accountTypeId: accountId,
    };
    callGetAllSubAccounts(api, payload);
  }

  async function addManualAccount(userEntries) {
    let api = getApi("/accounts/v1/addManualAccount");

    let payload = {
      accountTypeId: userEntries.accountType,
      accountSubTypeId: userEntries.accountSubType,
      accountName: userEntries.accountName,
      accountBalance: +userEntries.accountBalance,
    };

    let res = await callAddManualAccount(api, payload);
    if(res){
      setAccounts(res);
      setAccountsList(res?.accountTypes)
      return true;
    }
  }
  
  useEffect(() => {
    getAllAccountTypes();
  }, []);

  let data = {
    isLoading,
    getAllAccountTypes,
    getAllSubAccountTypes,
    allAccountsData,
    allSubAccountsData,
    error
  };
  let fns = {
    getAllAccountTypes,
    getAllSubAccountTypes,
    addManualAccount
  };
  return {
    data,
    fns,
  };
}

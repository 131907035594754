import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { usdFormate } from "src/global/central";
import FilterLogo from "../../assets/images/filterLogo.svg";
import {
  atm_clmnSecuritiesByUser,
  atm_tblSecuritiesByUser,
} from "src/state/atomsHoldingData";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  LinearProgress,
} from "@mui/material";
import useHoldingsLanding from "./useHoldingsLanding";

const HoldingsTable = () => {
  const { fnsHoldings, dataHoldings } = useHoldingsLanding();

  const clmnSecuritiesByUser = useRecoilValue(atm_clmnSecuritiesByUser);
  const dataSecuritiesByUser = useRecoilValue(atm_tblSecuritiesByUser);

  const [expandedRows, setExpandedRows] = useState([]);

  const [expandedRowIndex, setExpandedRowIndex] = useState(null);

  const [currentExpandedRow, setCurrentExpandedRow] = useState(null);

  const [securitiesInsideRows, setSecuritiesInsideRows] = useState({});

  const [insideRows, setInsideRows] = useState([]);

  const handleRowClick = (rowId, rowTotalValue, ticker, index) => {
    const isRowExpanded = expandedRows.includes(rowId);
    if (isRowExpanded && expandedRowIndex === index) {
      setExpandedRows(expandedRows.filter((id) => id !== rowId));
      setExpandedRowIndex(null);
    } else {
      setExpandedRows([...expandedRows, rowId]);
      setExpandedRowIndex(index);

      if (!securitiesInsideRows[ticker]) {
        fnsHoldings.getSecurityByUserId(rowId, rowTotalValue);
      }
    }
  };

  useEffect(() => {
    const updatedInsideRows = dataHoldings.selectedSecurityDetails.map(
      (item) => ({
        id: item.securityId,
        custodian: item.custodianName,
        account: item.accountName ,
        quantity: item.totalQuantity,
        costBasis: usdFormate(item.costBasis),
        currentValue: usdFormate(item.totalValue),
        percentage: `${parseFloat(item.holdingPercentage).toFixed(2)}%`,
      })
    );

    setInsideRows(updatedInsideRows);
  }, [dataHoldings.selectedSecurityDetails]);

  useEffect(() => {
    if (insideRows.length) {
      setSecuritiesInsideRows((prevData) => ({
        ...prevData,
        [currentExpandedRow]: insideRows,
      }));
    }
  }, [insideRows]);

  const insideCols = [
    { field: "custodian", headerName: "Custodian", minWidth: 200, flex: 1 },
    { field: "account", headerName: "Account", minWidth: 200, flex: 1 },
    { field: "quantity", headerName: "  Quantity", minWidth: 200, flex: 1 },
    { field: "costBasis", headerName: "Cost Basis", minWidth: 200, flex: 1 },
    {
      field: "currentValue",
      headerName: "Current Value",
      minWidth: 200,
      flex: 1,
    },
    { field: "percentage", headerName: "Percentage", minWidth: 200 },
  ];

  return (
    <div className="holdingsTable">
      {dataHoldings.isSecuritiesByUserListLoading && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
          }}
        >
          <LinearProgress />
        </Box>
      )}

      <TableContainer className={`outsideTable`}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {clmnSecuritiesByUser.map((column) => (
                <TableCell key={column.field}>
                  <div className="custom-header">
                    <span className="">{column.headerName}</span>
                    <img src={FilterLogo} alt="" />
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataSecuritiesByUser.map((row, index) => (
              <React.Fragment key={row.id}>
                <TableRow
                  onClick={() => {
                    handleRowClick(
                      row.id,
                      row.unformattedTotalValue,
                      row.Ticker,
                      index
                    );
                    setCurrentExpandedRow(row.Ticker);
                  }}
                  className={
                    expandedRows.includes(row.id) ? "row-expanded" : ""
                  }
                >
                  {clmnSecuritiesByUser.map((column) => (
                    <TableCell key={`securities${column.field}`}>
                      {row[column.field]}
                    </TableCell>
                  ))}
                </TableRow>

                {expandedRows.includes(row.id) && (
                  <TableCell
                    sx={{ position: "relative" }}
                    colSpan={clmnSecuritiesByUser.length}
                  >
                    {dataHoldings.selectedSecurityDetailsLoading &&
                      expandedRowIndex === index && (
                        <Box
                          sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                          }}
                        >
                          <LinearProgress />
                        </Box>
                      )}
                    <TableContainer className="insideTable">
                      <Table>
                        <TableHead>
                          <TableRow>
                            {insideCols.map((col) => {
                              return (
                                <TableCell key={`insideCol${col.field}`}>
                                  <div className="custom-header">
                                    <span className="">{col.headerName}</span>
                                    <img src={FilterLogo} alt="" />
                                  </div>
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {securitiesInsideRows[row.Ticker]?.map(
                            (insideRow, index) => {
                              return (
                                <TableRow key={`insideRows${index}`}>
                                  {insideCols.map((col) => {
                                    return (
                                      <TableCell>
                                        {insideRow[col.field]}
                                      </TableCell>
                                    );
                                  })}
                                </TableRow>
                              );
                            }
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </TableCell>
                )}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default HoldingsTable;

import React, { useState, useEffect } from "react";
// import useAccountLanding from "./useAccountLanding";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import CheckboxCustom from "./CheckboxCustom";
import { DropdownLeftSide } from "./DropdownLeftSide";
import { ProgressBar } from "../shared/ProgressBar";
import {
  atm_accountsList,
  atm_accountsTotalAmount,
  atm_editAccountModal,
  atm_isAccountSelectionModified,
  atm_isSelectAllAccountList,
  atm_selectedAccountIds,
} from "../../state/atomsAccountList";
import arrow from "../../components/assets/images/arrow.svg";
import blackPlus from "../../components/assets/images/black-plus.svg";
import userCircle from "../../components/assets/images/userCircle.svg";
import cardImp from "../../components/assets/images/card.svg";
import cashImp from "../../components/assets/images/cash.svg";
import calculator from "../../components/assets/images/calculator.svg";
import filter from "../../components/assets/images/filter.svg";
import { usdFormate } from "src/global/central";
import useAccountList from "./useAccountList";
import { GetWindowWidth } from "../../global/central";
import { atm_mainMenuButtonPressed } from "src/state/atoms";
import ProblemAccounts from "./ProblemAccounts";
import PlaidLinkButton from "./PlaidLinkButton";
import { Box, LinearProgress } from "@mui/material";
import EditAccountModal from "./EditAccountModal/EditAccountModal";
import { atm_isAddManualAccountModal, atm_isBulkEditMode } from "src/state/atomsTransactionData";
import { AddManualAccountModal } from "./AddManualAccount/AddManualAccountModal";
import KmnButton from "./Transactions/KmnButton";
import { AddCircleRounded } from "@mui/icons-material";

export const AccountList = ({ mobOptions, setMobOptions }) => {
  const [collapaseState, setCollapse] = useState(false);
  const accountsListTable = useRecoilValue(atm_accountsList);
  
  const { data, fns } = useAccountList();

  const editAccountModal = useRecoilValue(atm_editAccountModal);

  const [totalAmount, setTotalAmount] = useRecoilState(atm_accountsTotalAmount);

  const setSelectedAccountIds = useSetRecoilState(atm_selectedAccountIds);

  const setIsAccountSelectionModified = useSetRecoilState(
    atm_isAccountSelectionModified
  );

  const mainMenuButtonPressed = useRecoilValue(atm_mainMenuButtonPressed);

  const setSelectAll = useSetRecoilState(atm_isSelectAllAccountList);

  const isBulkEditMode = useRecoilValue(atm_isBulkEditMode)

  const [addManualAccountModal ,setAddManualAccountModal] = useRecoilState(atm_isAddManualAccountModal)

  const handleAddManualAccountModal = () => {
    setAddManualAccountModal(true);
  }

  const handleTotal = () => {
    let selectAllInput = document.getElementById("select-all");

    setIsAccountSelectionModified(true);

    if (!selectAllInput.checked) {
      setTotalAmount(0);
      let allInputs = document.getElementsByName("accountChecks");

      let ids = [];
      allInputs.forEach((input) => {
        ids.push(+input.id);
      });

      setSelectedAccountIds({ includedIds: [], excludedIds: ids });
      setSelectAll(false);
    } else {
      let dropdownLeftSide = document.querySelectorAll(".DropdownLeftSide");
      let sidebar = document.querySelector(".account-list");

      dropdownLeftSide.forEach((element) => {
        element.classList.remove("minus-sign");
      });
      sidebar.classList.remove("minus-sign");

      setTotalAmount(0);
      accountsListTable.forEach((data) => {
        setTotalAmount(
          (prevTotalAmount) => prevTotalAmount + data.accountTypeBalance
        );
      });

      setSelectedAccountIds({ includedIds: [], excludedIds: [] });
      setSelectAll(true);
    }
  };

  useEffect(() => {
    fns.onAccountListPageLoad();
    setTotalAmount(0);
  }, []);
  


  const width = GetWindowWidth();

  const onSuccess = (public_token, metadata) => {
    console.log("on success : " + public_token, metadata);
    fns.registerNewPlaidAccounts(public_token, metadata);
  };

  const handleCollapse = () => {
    if(!isBulkEditMode){
      setCollapse(!collapaseState)
    }
  }
  return (
    <>
      <div
        hidden={width < 900 && mobOptions === 3}
        className={`account-list ${(isBulkEditMode || (collapaseState && width > 900)) && "active"}`}
      >
        {data.linkAccountTokenLoading && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
              }}
            />
          </Box>
        )}
        {width < 900 && (
          <div className="transaction-mob-options-cover">
            <ul className="transaction-mob-options">
              <li
                className={mobOptions === 1 ? "active" : ""}
                onClick={() => {
                  setMobOptions(1);
                }}
              >
                All
              </li>
              <li
                className={mobOptions === 2 ? "active" : ""}
                onClick={() => {
                  setMobOptions(2);
                }}
              >
                My Accounts
              </li>
              <li
                className={mobOptions === 3 ? "active" : ""}
                onClick={() => {
                  setMobOptions(3);
                }}
              >
                {mainMenuButtonPressed == "Transactions"
                  ? "Income/Expense Trend"
                  : "Portfolio Value Trend"}
              </li>
            </ul>
          </div>
        )}
        <div className="short-account-list">
          <PlaidLinkButton
            className="link-account-button link-account-button-short"
            onSuccess={onSuccess}
            getToken={() => fns.getLinkToken()}
          >
            <img src={blackPlus} alt="" />
          </PlaidLinkButton>

          <button variant="contained" className="linking-other-account">
            <img src={userCircle} alt="" />
          </button>

          <button variant="contained" className="linking-other-account">
            <img src={cardImp} alt="" />
          </button>

          <button variant="contained" className="linking-other-account">
            <img src={cashImp} alt="" />
          </button>
          <button variant="contained" className="linking-other-account">
            <img src={calculator} alt="" />
          </button>
        </div>

        <div className="headerActions">
          <PlaidLinkButton
            className="link-account-button"
            onSuccess={onSuccess}
            getToken={() => fns.getLinkToken()}
          >
            <img src={blackPlus} alt="" /> Link Accounts
          </PlaidLinkButton>
          <KmnButton className="addManualAccountBtn" variant="primary_thick" color="secondary_outlined" onClick={handleAddManualAccountModal}>
            <AddCircleRounded sx={{fontSize: 18, marginRight: 0.5, color: 'var(--color1)'}} /> Manual
          </KmnButton>
        </div>

        {data.isAccountListLoading && (
          <ProgressBar what="Account Loading" status="In Progress" />
        )}
        {data.isAccountLinking && (
          <ProgressBar what="Account Linking" status="In Progress" />
        )}

        {data?.accounts?.problemAccounts?.length ? <ProblemAccounts /> : null}

        <div className="calculate-header">
          <div className="cal-box">
            <CheckboxCustom
              id="select-all"
              onclick={(e) => {
                handleTotal();
                let parent = e.target.closest(".account-list");
                let allcheckbox = parent.querySelectorAll("input");

                allcheckbox.forEach((EachInput) => {
                  if (e.target.checked === true) {
                    EachInput.checked = true;
                  } else {
                    EachInput.checked = false;
                  }
                });
              }}
            />
            <p>Account</p>
            <img src={filter} alt="" />
          </div>
          <div className="cal-box">
            <p>Balance</p>
            <img src={filter} alt="" />
          </div>
        </div>
        <div className="dropdown-cal-wrapper customScroll">
          {accountsListTable.map((EachList, index) => (
            <DropdownLeftSide
              EachList={EachList}
              key={`dropdownleftside_${EachList["accountTypeId"]}`}
              totalAmount={totalAmount}
              setTotalAmount={setTotalAmount}
              multiIndex={index}
            />
          ))}
        </div>

        <div className="total">
          <div className="text">Total</div>
          <div
            className="price"
            style={{ color: totalAmount < 0 ? "var(--color14)" : "" }}
          >
            {usdFormate(totalAmount)}
          </div>
        </div>

        {width > 900 && (
          <img
            src={arrow}
            alt=""
            className="transactionArrow arrow"
            onClick={handleCollapse}
          />
        )}
      </div>
      {editAccountModal && <EditAccountModal handleTotal={handleTotal} />}
      {addManualAccountModal && <AddManualAccountModal />}
    </>
  );
};

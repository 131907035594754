/**********************************************************************
 *
 *   Component generated by Quest
 *
 *   WARNING: By editing this component by hand, you will lose the ability to regenerate the code without conflicts.
 *   To preseve that abilty, always export from Quest to regenerate this file.
 *   To setup props, bindings and actions, use the Quest editor
 *   Code Logic goes in the hook associated with this component
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/

import React, { useEffect, useState } from "react";
import {
  Alert,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  Snackbar,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import useLogin from "src/components/Login/useLogin";
import "../assets/css/login.css";
import { Link } from "react-router-dom";
// import social1 from "../assets/images/facebook.webp";
// import social2 from "../assets/images/google.webp";
// import social3 from "../assets/images/apple.webp";
import { checkPasswordLogin } from "src/validations/Password";
import { ValidateEmail } from "src/validations/Email";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useSetRecoilState } from "recoil";
import { atm_responseMessage } from "src/state/atomForgotPassword";
import useForgotPassword from "../ForgotPassword/useForgotPassword";
import MetaTags from "../shared/MetaTags";
import RedditPixel from "../shared/RedittPixel";

const AlertFilled = styled(Alert)({
  alignSelf: `stretch`,
  marginBottom: `20px`,
});

const Input1 = styled("div")({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `100%`,
  margin: `2vh 0px 0px 0px`,
});

const TextFieldOutlined = styled(TextField)({
  alignSelf: `stretch`,
  margin: `0px`,
});

const TextFieldOutlined1 = styled(TextField)({
  alignSelf: `stretch`,
  margin: `1.5vh 0px 0px 0px`,
});

const ButtonContained = styled(Button)({
  alignSelf: `stretch`,
  margin: `0px`,
});

function Login(props) {
  const { data, fns } = useLogin();
  const { dataPassword } = useForgotPassword();
  const [HeightState, setHeight] = useState(0);
  const [disableButton, setdisableButton] = useState(false);
  const [emailValidate, setemailValidate] = useState(false);
  const [passValidate, setpassValidate] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const setResponseMessage = useSetRecoilState(atm_responseMessage);

  const HandleResizeWork = () => {
    const WindowHeight = window.innerHeight - 100;
    setHeight(WindowHeight);
  };
  const handleShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    window.addEventListener("resize", HandleResizeWork);
    HandleResizeWork();
  }, []);

  return (
    <>
      <MetaTags index={"Login"} />
      <RedditPixel event={"Login"} custom_or_default={1}/>

      <div
        className="login-main-wrapper"
        style={{ minHeight: `${HeightState}px` }}
      >
        <div className="login-box">
          {data.userLockError && (
            <AlertFilled
              variant="filled"
              severity={"error"}
              onClose={fns.clearErrorMessage}
            >
              {data.userLockErrorHelperText}
            </AlertFilled>
          )}
          <div className="initial-text">
            <h1>{`Welcome Back`}</h1>
            {/* <p>Lorem ipsum dolor sit amet, consectetur adipi</p> */}
          </div>

          <Dialog open={data.emailValidationRequired}>
            <DialogTitle>Validate Email</DialogTitle>
            <DialogContent>
              <DialogContentText>
                We have sent a validation code to your email. Please enter that
                code to confirm that you own the email address
              </DialogContentText>
              <br></br>
              {data.emailValidationErrorMessage && (
                <AlertFilled variant="filled" severity="error">
                  {data.emailValidationErrorMessage}
                </AlertFilled>
              )}

              <TextField
                autoFocus
                margin="normal"
                id="name"
                label="Validation Code"
                type="number"
                value={data.emailValidationCode}
                onChange={(e) => {
                  fns.onChangeEmailValidationCode(e);
                }}
                fullWidth
                variant="standard"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={fns.cancelEmailCodeValidationDialog}>
                Cancel
              </Button>
              <Button onClick={fns.validateEmailCode}>Validate</Button>
            </DialogActions>
          </Dialog>

          <Input1>
            <TextFieldOutlined
              variant="outlined"
              className="field-auth"
              size="medium"
              onChange={(e) => {
                setemailValidate(false);
                fns.onChangeEmail(e);
              }}
              label={`Email address`}
              type={"email"}
              // helperText={data.emailHelperText}
              value={data.email}
              error={data.emailError || emailValidate === true}
            />
            {(data.emailError || emailValidate === true) && (
              <p className="error-custom grid-2" style={{ margin: "10px 0px" }}>
                <svg
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.5 0C6.81886 0 5.17547 0.498516 3.77766 1.43251C2.37984 2.3665 1.29037 3.69402 0.647028 5.24719C0.00368293 6.80036 -0.164645 8.50943 0.163329 10.1583C0.491303 11.8071 1.30085 13.3217 2.4896 14.5104C3.67834 15.6992 5.1929 16.5087 6.84174 16.8367C8.49057 17.1646 10.1996 16.9963 11.7528 16.353C13.306 15.7096 14.6335 14.6202 15.5675 13.2223C16.5015 11.8245 17 10.1811 17 8.5C17 6.24566 16.1045 4.08365 14.5104 2.48959C12.9163 0.895533 10.7543 0 8.5 0ZM7.44459 4.25C7.44459 3.96821 7.55653 3.69796 7.75578 3.4987C7.95504 3.29944 8.22529 3.1875 8.50709 3.1875C8.78888 3.1875 9.05913 3.29944 9.25839 3.4987C9.45764 3.69796 9.56959 3.96821 9.56959 4.25V9.13041C9.56959 9.26994 9.5421 9.40811 9.48871 9.53702C9.43531 9.66593 9.35705 9.78305 9.25839 9.88172C9.15972 9.98038 9.04259 10.0586 8.91369 10.112C8.78478 10.1654 8.64662 10.1929 8.50709 10.1929C8.36756 10.1929 8.22939 10.1654 8.10048 10.112C7.97158 10.0586 7.85445 9.98038 7.75578 9.88172C7.65712 9.78305 7.57886 9.66593 7.52546 9.53702C7.47207 9.40811 7.44459 9.26994 7.44459 9.13041V4.25ZM8.5 13.8125C8.25904 13.8125 8.02349 13.741 7.82313 13.6072C7.62278 13.4733 7.46662 13.283 7.37441 13.0604C7.2822 12.8378 7.25807 12.5928 7.30508 12.3565C7.35209 12.1201 7.46812 11.9031 7.63851 11.7327C7.8089 11.5623 8.02598 11.4463 8.26232 11.3992C8.49865 11.3522 8.74362 11.3764 8.96624 11.4686C9.18886 11.5608 9.37914 11.7169 9.51301 11.9173C9.64688 12.1176 9.71834 12.3532 9.71834 12.5942C9.71834 12.9173 9.58998 13.2272 9.36149 13.4557C9.13301 13.6841 8.82312 13.8125 8.5 13.8125Z"
                    fill="#E41616"
                  />
                </svg>
                {data.emailError && data.emailHelperText}

                {emailValidate && `Invalid Email address format`}
              </p>
            )}
            <TextFieldOutlined1
              variant="outlined"
              className="field-auth"
              size="medium"
              onChange={(e) => {
                setpassValidate(false);
                fns.onChangePassword(e);
              }}
              label={`Enter Your Password`}
              type={showPassword ? "text" : "password"}
              // helperText={data.passwordHelperText}
              value={data.password}
              error={data.passwordError || passValidate === true}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            {(data.passwordError || passValidate) && (
              <p className="error-custom grid-2" style={{ margin: "10px 0px" }}>
                <svg
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.5 0C6.81886 0 5.17547 0.498516 3.77766 1.43251C2.37984 2.3665 1.29037 3.69402 0.647028 5.24719C0.00368293 6.80036 -0.164645 8.50943 0.163329 10.1583C0.491303 11.8071 1.30085 13.3217 2.4896 14.5104C3.67834 15.6992 5.1929 16.5087 6.84174 16.8367C8.49057 17.1646 10.1996 16.9963 11.7528 16.353C13.306 15.7096 14.6335 14.6202 15.5675 13.2223C16.5015 11.8245 17 10.1811 17 8.5C17 6.24566 16.1045 4.08365 14.5104 2.48959C12.9163 0.895533 10.7543 0 8.5 0ZM7.44459 4.25C7.44459 3.96821 7.55653 3.69796 7.75578 3.4987C7.95504 3.29944 8.22529 3.1875 8.50709 3.1875C8.78888 3.1875 9.05913 3.29944 9.25839 3.4987C9.45764 3.69796 9.56959 3.96821 9.56959 4.25V9.13041C9.56959 9.26994 9.5421 9.40811 9.48871 9.53702C9.43531 9.66593 9.35705 9.78305 9.25839 9.88172C9.15972 9.98038 9.04259 10.0586 8.91369 10.112C8.78478 10.1654 8.64662 10.1929 8.50709 10.1929C8.36756 10.1929 8.22939 10.1654 8.10048 10.112C7.97158 10.0586 7.85445 9.98038 7.75578 9.88172C7.65712 9.78305 7.57886 9.66593 7.52546 9.53702C7.47207 9.40811 7.44459 9.26994 7.44459 9.13041V4.25ZM8.5 13.8125C8.25904 13.8125 8.02349 13.741 7.82313 13.6072C7.62278 13.4733 7.46662 13.283 7.37441 13.0604C7.2822 12.8378 7.25807 12.5928 7.30508 12.3565C7.35209 12.1201 7.46812 11.9031 7.63851 11.7327C7.8089 11.5623 8.02598 11.4463 8.26232 11.3992C8.49865 11.3522 8.74362 11.3764 8.96624 11.4686C9.18886 11.5608 9.37914 11.7169 9.51301 11.9173C9.64688 12.1176 9.71834 12.3532 9.71834 12.5942C9.71834 12.9173 9.58998 13.2272 9.36149 13.4557C9.13301 13.6841 8.82312 13.8125 8.5 13.8125Z"
                    fill="#E41616"
                  />
                </svg>
                {data.passwordError && data.passwordHelperText}
                {passValidate &&
                  `Password should have minimum of 6 characters, must have at least
					one uppercase character, atleast one lowercase character,
					atleast one number, and atleast one special character`}
              </p>
            )}
          </Input1>
          <div className="forget-password-link-wrapper">
            <Link
              to="/login/forgotpassword"
              className="forget-password-link"
            >{`Forgot Password?`}</Link>
          </div>

          <ButtonContained
            variant="contained"
            className="button-auth-main"
            size="large"
            color="primary"
            onClick={(e) => {
              let checkfall = false;
              if (!ValidateEmail(data.email)) {
                setemailValidate(true);
                checkfall = true;
              }
              if (
                data.password.length === 0 ||
                !checkPasswordLogin(data.password)
              ) {
                setpassValidate(true);
                checkfall = true;
              }

              if (checkfall === true) {
                return;
              }
              setdisableButton(true);
              setTimeout(() => {
                setdisableButton(false);
              }, 5000);

              fns.onClickLogin();
            }}
            disabled={disableButton}
          >
            Login
          </ButtonContained>

          {/* <div className="or-login">
            <span></span>
            <p>or Login with</p>
            <span></span>
          </div>
          <div className="social-media-wrapper">
            <button>
              <img src={social1} alt="" />
            </button>
            <button>
              <img src={social2} alt="" />
            </button>
            <button>
              <img src={social3} alt="" />
            </button>
          </div> */}
          <p className="signup-account">
            Don’t have an account?
            <Link to="/signup">Sign Up</Link>
          </p>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={Boolean(dataPassword.responseMessage.resetPassword)}
        autoHideDuration={3000}
        onClose={() => setResponseMessage({})}
      >
        <Alert
          onClose={() => setResponseMessage({})}
          severity={"success"}
          sx={{ width: "100%" }}
        >
          Password Change !
        </Alert>
      </Snackbar>
    </>
  );
}

export default Login;

import React, { useEffect } from "react";
import LocationChart from "../LocationChart";
import PopChart from "../PopChart";
import useSpendByCategory from "./useSpendByCategory";
import { useRecoilValue } from "recoil";
import {
  atm_categoryParentSelectors,
  atm_globalCategory,
} from "src/state/insightsCategories";

const ByLocation = () => {
  const { data, fns } = useSpendByCategory();
  const globalCategory = useRecoilValue(atm_globalCategory);
  const categoryParentSelectors = useRecoilValue(atm_categoryParentSelectors);

  useEffect(() => {
    if (!categoryParentSelectors.byLocation && data.categoryListData.length) {
      fns.refreshRightScreen(globalCategory);
    }
  }, [categoryParentSelectors]);
  return (
    <div className="scroller by-location">
      <div className="inside-scroller">
        <LocationChart data={data.locationStats} loading={data.locationChartLoading} me={data?.selectedCategoryPercent} />
        <PopChart data={data?.popStats} loading={data.popChartLoading} />
      </div>
    </div>
  );
};

export default ByLocation;

import React from "react";
import useMainMenu from "../MainMenu/useMainMenu";
import { useRecoilValue } from "recoil";
import { atm_mainMenuButtonPressed } from "src/state/atoms";

const AccountNavigation = () => {
  const { fns } = useMainMenu();
  const mainMenuButtonPressed = useRecoilValue(atm_mainMenuButtonPressed);
  return (
    <div className="accounts-navigation">
      <div
        className={`btn btn1 ${
          mainMenuButtonPressed == "Transactions" && "active"
        }`}
        onClick={fns.onMenuClickTransactions}
      >
        Transactions
      </div>
      <div
        className={`btn btn1 ${
          mainMenuButtonPressed == "Holdings" && "active"
        }`}
        onClick={fns.onMenuClickHoldings}
      >
        Holdings
      </div>
    </div>
  );
};

export default AccountNavigation;

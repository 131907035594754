import React, { useEffect } from "react";
import { DropdownDate } from "../../shared/DateFilter/DropdownDate";
import { useState } from "react";
import CloseIcon from "../../assets/images/close.svg";
import useSpendByCategory from "./useSpendByCategory";
import { atm_IsMobCatFilter } from "src/state/insightsCategories";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { DropdownCategory } from "src/components/shared/CategoryFilter/DropdownCategory";
import { atm_globalFilterCount, atm_globalFilterValue } from "src/state/atoms";
import CategoryFilter from "src/components/shared/CategoryFilter/CategoryFilter";
import useCategoryFilter from "src/components/shared/CategoryFilter/useCategoryFilter";
import NewCategoryFilter from "src/components/shared/CategoryFilter/NewCategoryFilter";

const FiltersCat = () => {
  const { fns } = useSpendByCategory();
  const { fns: fnsCategory, data } = useCategoryFilter();
  const [dropdownActive, setdropdownActive] = useState(null);

  const [isMobFilter, setIsMobFilter] = useRecoilState(atm_IsMobCatFilter);

  const globalFilterValue = useRecoilValue(atm_globalFilterValue);

  const setGlobalFilterCount = useSetRecoilState(atm_globalFilterCount);

  useEffect(() => {
    fnsCategory.onCategoryFilterLoad();
  }, []);

  useEffect(() => {
    let filter = 1;

    const categoryFilter =
      globalFilterValue.categoryFilter.selectedCategoryFilter?.length > 0 ||
      globalFilterValue.categoryFilter.excludedCategoryFilter?.length > 0;

    if (categoryFilter) {
      filter = 2;
    }
    setGlobalFilterCount(filter);
  }, [globalFilterValue]);

  return (
    <>
      <div
        className={`selection-areas filters filtersCategory ${
          isMobFilter === false && "hidden"
        }`}
      >
        <header>
          <span>Filters</span>
          <img
            onClick={() => setIsMobFilter(false)}
            src={CloseIcon}
            alt=""
            className="close-icon"
          />
        </header>

        <div className="row">
          <DropdownDate
            setdropdownActive={setdropdownActive}
            dropdownActive={dropdownActive}
            callBackFunction={fns.onChangeDateFilter}
          />

          {/* <CategoryFilter
            data={data.categoryFilter}
            callBackFunction={fns.onChangeCategoryFilter}
          /> */}

          <NewCategoryFilter
            data={data.categoryFilter}
            callBackFunction={fns.onChangeCategoryFilter}
          />

          {/* <DropdownCategory
            initial_value="All"
            setdropdownActive={setdropdownActive}
            dropdownActive={dropdownActive}
            callBackFunction={fns.onChangeCategoryFilter}
          /> */}
        </div>
      </div>

      {isMobFilter && (
        <div
          className="mob-filter-backdrop"
          onClick={() => setIsMobFilter(false)}
        ></div>
      )}
    </>
  );
};

export default FiltersCat;

import React from "react";
import { Link } from "react-router-dom";
import useMainMenu from "../MainMenu/useMainMenu";

export const Impover = () => {
  const { fns } = useMainMenu();
  return (
    <div className="Impover">
      <h2>
        Empowering you with <br /> limitless possibilities
      </h2>
      <p>Money decisions, made easy</p>
      <Link onClick={fns.onMenuClickSignUp} to="/signup">
        Get Started
      </Link>
    </div>
  );
};

export const checkPassword = str => {
	var re =
		/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_\-+=?])[a-zA-Z\d!@#$%^&*()_\-+=?]{8,}$/;
	return re.test(str);
};


export const checkPasswordLogin = str => {
	var re =
		/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_\-+=?])[a-zA-Z\d!@#$%^&*()_\-+=?]{6,}$/;
	return re.test(str);
};

import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import "../assets/css/calculators.css";
import { Col } from "../shared";
import CollegeCalculator from "./CollegeCalculator";
import MetaTags from "../shared/MetaTags";
import RedditPixel from "../shared/RedittPixel";

const Calculators = () => {
  const { calculatorType } = useParams();
  const [tab, setTab] = useState(1);
  const navigate = useNavigate();
  const RenderCalculator = () => {
    switch (calculatorType) {
      case "college":
        return <CollegeCalculator />;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (!RenderCalculator(calculatorType)) {
      navigate("/calculators/college");
    }
  }, []);

  const handleTab = () => {
    setTab(1);
    navigate("/calculators/college");
  };

  return (
    <Box className="calculators-page">
      <MetaTags index={"Calculators"} />
      <RedditPixel event={"Calculators"} custom_or_default={1}/>

      <img
        src="/static/media/bg-transaction.9ee82bb60f3ef91391a4.png"
        alt=""
        className="calculatorsBg globalBg"
      ></img>
      <Col className="boxMain">
        <Box className="tabs">
          <button className="tab active" onClick={handleTab}>
            College Cost
          </button>
        </Box>
        <RenderCalculator />
      </Col>
    </Box>
  );
};

export default Calculators;

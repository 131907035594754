import { useRecoilState, useRecoilValue } from "recoil";
import { atm_CategorySubCategoryListFilters } from "src/state/filters";
import { atm_globalFilterValue } from "src/state/atoms";

import axios from "axios";
import { getAuthToken } from "src/global/central";

const useCategoryFilter = () => {

  let data = {};

  const jwt = getAuthToken();

  const [categoryFilter, setCategoryFilter] = useRecoilState(atm_CategorySubCategoryListFilters);
  data.categoryFilter = categoryFilter;

  const globalFilterValue = useRecoilValue(atm_globalFilterValue)

  let dateFilterValue = globalFilterValue.dateFilter;

  function onCategoryFilterLoad() {
    //  if(data.categoryFilter.length === 0)
    getCategorySubCategoryFilterData();
  }

  function getCategorySubCategoryFilterData(dateValue) {

    if (dateValue) {
      dateFilterValue = dateValue;
    }

    let url =
      process.env.REACT_APP_ACCOUNT_API_END_POINT +
      "/accounts/transactions/filters/v1/getCategorySubCategoriesForUser";
    axios
      .post(
        url,
        {
          "noOfMonths": dateFilterValue,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + jwt,
          },
        }
      )
      .then((res) => {
        // console.log(res["data"]["responseData"]);
        setCategoryFilter(res["data"]["responseData"]);
      })
      .catch((res) => {
        console.log(res);
      });
  }

  let fns = {
    onCategoryFilterLoad,
    getCategorySubCategoryFilterData
  };
  return { data, fns };

};

export default useCategoryFilter;
import React, { useEffect } from "react";
import {
  atm_globalMerchant,
  atm_merchantParentSelectors,
} from "src/state/insightsMerchants";
import { useRecoilValue } from "recoil";
import useSpendByMerchant from "./useSpendByMerchant";
import PopChart from "../PopChart";
import LocationChart from "../LocationChart";

const ByLocation = () => {
  const { data, fns } = useSpendByMerchant();

  const globalMerchant = useRecoilValue(atm_globalMerchant);

  const merchantParentSelectors = useRecoilValue(atm_merchantParentSelectors);

  useEffect(() => {
    if (!merchantParentSelectors.byLocation && data.merchantListData.length) {
      fns.refreshRightScreen(globalMerchant);
    }
  }, [merchantParentSelectors]);

  return (
    <div className="scroller">
      <div className="inside-scroller">
        <LocationChart data={data.locationStats} loading={data.locationStatsLoading} me={data.selectedMerchantPercent} />
        <PopChart data={data?.popStats} loading={data.popChartLoading} />
      </div>
    </div>
  );
};

export default ByLocation;

import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import useSpendByMerchant from "./useSpendByMerchant";
import {  useRecoilValue } from "recoil";
import {
  atm_globalMerchant,
  atm_merchantParentSelectors,
} from "src/state/insightsMerchants";
import { Box, LinearProgress } from "@mui/material";

const MerchantTrends = () => {
  const { data, fns } = useSpendByMerchant();
  const [chartSeries, setChartSeries] = useState([]);
  const [customerSeries, setCustomerSeries] = useState([]);

  const globalMerchant = useRecoilValue(atm_globalMerchant);

  const merchantParentSelectors = useRecoilValue(atm_merchantParentSelectors);

  useEffect(() => {
    if (!merchantParentSelectors.merchantTrends && data.merchantListData.length) {
      fns.refreshRightScreen(globalMerchant);
    }
  }, [merchantParentSelectors]);

  useEffect(() => {
    if (data.ratingStats) {
      const avgRatings = data.ratingStats.map((item) => item.avgRatings);
      const repeatPercentage = data.ratingStats.map(
        (item) => item.repeatPercentage
      );

      setChartSeries([
        {
          name: "Overall Rating",
          data: avgRatings,
          color: "var(--color1)", // Set the line color
        },
      ]);
      setCustomerSeries([
        {
          name: "Customer repeatability",
          data: repeatPercentage,
          color: "var(--color2)", // Set the line color
        },
      ]);
    }
  }, [data.ratingStats[0]]);

  const chartOptions = {
    chart: {
      height: 250,
      type: "bar",
      fontFamily: "Nunito",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      animations: {
        enabled: false, // Turn off animations
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 2, // Adjust the line thickness as needed
      curve: "straight",
    },
    title: {
      offsetY: 0,
      align: "center",
      style: {
        color: "#000",
        fontSize: "var(--fontSize18)",
        fontWeight: 800,
      },
    },
    grid: {
      show: false,
    },
    xaxis: {
      categories: data.ratingStats.map((item) => {
        const [month] = item.monthYear.split(",");
        return month;
      }),
      position: "bottom",
      crosshairs: {
        fill: {
          type: "gradient",
          gradient: {
            colorFrom: "#D8E3F0",
            colorTo: "#BED1E6",
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
        style: {
          fontSize: "var(--fontSize18)",
        },
      },
      axisBorder: {
        show: true,
        color: "#000",
        height: 1,
        width: "120%",
        offsetX: 0,
        offsetY: 0,
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "#000",
        height: 12,
        offsetX: 1,
        offsetY: 0,
      },
      labels: {
        style: {
          fontSize: "var(--fontSize14)",
          fontWeight: 700,
        },
      },
      tickAmount: data.ratingStats.length,
    },
    yaxis: [
      {
        labels: {
          formatter: function (value) {
            return value;
          },
        },
        tickAmount: 5,
        min: 0,
        max: 5,
        axisBorder: {
          show: true,
          color: "#000",
        },
        axisTicks: {
          show: true,
          borderType: "solid",
          color: "#000",
          width: 12,
          offsetX: 6,
          offsetY: 1,
        },
      },
    ],
    legend: {
      markers: {
        shape: "square",
        radius: 2,
        width: 13,
        height: 13,
        colors: ["#A4379E", "var(--color1)"],
      },
    },
    markers: {
      size: 4,
      strokeWidth: 0,
      hover: {
        size: 6,
      },
      showOnHover: false,
    },
  };
  const customerChartOptions = {
    chart: {
      height: 250,
      type: "bar",
      fontFamily: "Nunito",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      animations: {
        enabled: false, // Turn off animations
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 2, // Adjust the line thickness as needed
      curve: "straight",
    },
    title: {
      offsetY: 0,
      align: "center",
      style: {
        color: "#000",
        fontSize: "var(--fontSize18)",
        fontWeight: 800,
      },
    },
    grid: {
      show: false,
    },
    xaxis: {
      categories: data.ratingStats.map((item) => {
        const [month] = item.monthYear.split(",");
        return month;
      }),
      position: "bottom",
      crosshairs: {
        fill: {
          type: "gradient",
          gradient: {
            colorFrom: "#D8E3F0",
            colorTo: "#BED1E6",
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
        style: {
          fontSize: "var(--fontSize18)",
        },
      },
      axisBorder: {
        show: true,
        color: "#000",
        height: 1,
        width: "120%",
        offsetX: 0,
        offsetY: 0,
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "#000",
        height: 12,
        offsetX: 1,
        offsetY: 0,
      },
      labels: {
        style: {
          fontSize: "var(--fontSize14)",
          fontWeight: 700,
        },
      },
      tickAmount: data.ratingStats.length,
    },
    yaxis: [
      {
        labels: {
          formatter: function (value) {
            return value;
          },
        },
        tickAmount: 5,
        min: 0,
        max: 100,
        axisBorder: {
          show: true,
          color: "#000",
        },
        axisTicks: {
          show: true,
          borderType: "solid",
          color: "#000",
          width: 12,
          offsetX: 6,
          offsetY: 1,
        },
      },
    ],
    legend: {
      markers: {
        shape: "square",
        radius: 2,
        width: 13,
        height: 13,
        colors: ["#A4379E", "var(--color1)"],
      },
    },
    markers: {
      size: 4,
      strokeWidth: 0,
      hover: {
        size: 6,
      },
      showOnHover: false,
    },
  };

  return (
    <div className="scroller">
      <div className="merchantTrends inside-scroller">
        <div className="chart-container">
          {data.merchantRatingLoading && (
            <Box sx={{ width: "100%" }}>
              <LinearProgress
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                }}
              />
            </Box>
          )}
          <span>Overall Rating trend, MoM</span>
          <ReactApexChart
            options={chartOptions}
            series={chartSeries}
            type="bar"
            height={"100%"}
          />
        </div>
        <div className="chart-container">
          {data.merchantRatingLoading && (
            <Box sx={{ width: "100%" }}>
              <LinearProgress
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                }}
              />
            </Box>
          )}
          <span>Customer repeatability trend, MoM</span>
          <ReactApexChart
            options={customerChartOptions}
            series={customerSeries}
            type="bar"
            height={"100%"}
          />
        </div>
      </div>
    </div>
  );
};

export default MerchantTrends;

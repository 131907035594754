import axios from "axios";
import { useState } from "react";
import { useRecoilState } from "recoil";
import { atm_trendsCategories, atm_trendsData } from "src/state/atomTrends";

export default function useTrends({ setActiveTab }) {
  let data = {};

  const [trendsLoading, setTrendsLoading] = useState(false);
  data.trendsLoading = trendsLoading;

  const [trendsCategories, setTrendsCategories] = useRecoilState(atm_trendsCategories);
  data.trendsCategories = trendsCategories;

  const [trendsData, setTrendsData] = useRecoilState(atm_trendsData);
  data.trendsData = trendsData;

  async function getTrendsCategories() {
    setTrendsLoading(true);
    let api = process.env.REACT_APP_GOAL_API_END_POINT + "/goals/public/v1/getAllCategories";
    try {
      let res = await axios.post(api, null, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      let data = res.data.responseData;
      let firstCategory = data[0]?.categoryCode;
      setActiveTab(firstCategory);
      setTrendsCategories(data);
      return firstCategory;
    } catch (err) {
      console.log("error while getting categories: ", err);
    } finally {
      setTrendsLoading(false);
    }
  }

  async function getTrends({ categoryCode, zipCode, city, state }) {
    setTrendsLoading(true);
    let api = process.env.REACT_APP_GOAL_API_END_POINT + "/goals/public/v1/getTrends";

    try {
      let res = await axios.post(
        api,
        {
          dateFilter: 24,
          categoryCode: categoryCode || undefined,
          zipCode: zipCode || undefined,
          city: city || undefined,
          stateCd: state || undefined,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      let data = res.data.responseData;
      setTrendsData(data);
    } catch (err) {
      console.log("error while getting trends: ", err);
    } finally {
      setTrendsLoading(false);
    }
  }

  let fns = {
    getTrendsCategories,
    getTrends,
  };

  return { data, fns };
}

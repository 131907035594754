/**********************************************************************
 *
 *   Component generated by Quest
 *
 *   WARNING: By editing this component by hand, you will lose the ability to regenerate the code without conflicts.
 *   To preseve that abilty, always export from Quest to regenerate this file.
 *   To setup props, bindings and actions, use the Quest editor
 *   Code Logic goes in the hook associated with this component
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/

import React from "react";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

const OurWhy1 = styled("div")({
  backgroundColor: `rgba(238, 238, 238, 1)`,
  borderRadius: `10px`,
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  width: "100%",
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px 40px 20px 40px`,
  boxSizing: `border-box`,
  height: "auto",
});

const OurWhyFrame = styled("div")({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  boxShadow: `0px 4px 4px rgba(0, 0, 0, 0.25)`,
  borderRadius: `10px 10px 0px 0px`,
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `20px 0px 0px 20px`,
  boxSizing: `border-box`,
  margin: `0px`,
});

const OurWhy2 = styled("div")({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Roboto`,
  fontWeight: `700`,
  fontSize: `var(--fontSize30)`,
  letterSpacing: `0.25px`,
  textDecoration: `none`,
  lineHeight: `100%`,
  textTransform: `none`,
  width: `116px`,
  margin: `0px`,
});

const WeBelieveInKeepingTh = styled("div")({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Roboto`,
  fontWeight: `400`,
  fontSize: `var(--fontSize22)`,
  letterSpacing: `0.15000000596046448px`,
  textDecoration: `none`,
  lineHeight: `100%`,
  textTransform: `none`,
  alignSelf: `stretch`,
  width: `1204px`,
  margin: `0px 0px 0px 20px`,
});

const OurWhyReasons = styled("div")({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  boxShadow: `0px 4px 4px rgba(0, 0, 0, 0.25)`,
  borderRadius: `0px 0px 20px 20px`,
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-end`,
  padding: `20px 24px 20px 20px`,
  boxSizing: `border-box`,
  alignSelf: `stretch`,
  margin: `0px`,
});

const CardUsers = styled("div")({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  border: `1px solid rgba(0, 0, 255, 0.2)`,
  boxSizing: `border-box`,
  borderRadius: `10px`,
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `20px`,
  flexGrow: `1`,
  margin: `0px`,
});

const Content = styled("div")({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  margin: `0px`,
});

const OurUsers = styled("div")(({ theme }) => ({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  color: `rgba(25, 118, 210, 1)`,
  fontStyle: `normal`,
  fontFamily: `Roboto`,
  fontWeight: `400`,
  fontSize: `var(--fontSize24)`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `133.39999914169312%`,
  textTransform: `none`,
  width: `310px`,
  margin: `0px`,
}));

const LoremIpsumLoremIpsum = styled("div")(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Roboto`,
  fontWeight: `400`,
  fontSize: `var(--fontSize14)`,
  letterSpacing: `0.17000000178813934px`,
  textDecoration: `none`,
  lineHeight: `142.99999475479126%`,
  textTransform: `none`,
  alignSelf: `stretch`,
  height: `473px`,
  margin: `20px 0px 0px 0px`,
}));

const LeftAction = styled("div")({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-end`,
  alignItems: `flex-end`,
  padding: `0px`,
  boxSizing: `border-box`,
  margin: `20px 0px 0px 0px`,
});

const ButtonText = styled(Button)({
  margin: `0px`,
});

const CardEmployees = styled("div")(({ theme }) => ({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  border: `1px solid rgba(0, 0, 255, 0.2)`,
  boxSizing: `border-box`,
  borderRadius: `10px`,
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `20px`,
  flexGrow: `1`,
  margin: `0px 0px 0px 20px`,
}));

const Content1 = styled("div")({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  margin: `0px`,
});

const OurEmployees = styled("div")(({ theme }) => ({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  color: `rgba(25, 118, 210, 1)`,
  fontStyle: `normal`,
  fontFamily: `Roboto`,
  fontWeight: `400`,
  fontSize: `var(--fontSize24)`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `133.39999914169312%`,
  textTransform: `none`,
  width: `310px`,
  margin: `0px`,
}));

const LoremIpsumLoremIpsum1 = styled("div")(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Roboto`,
  fontWeight: `400`,
  fontSize: `var(--fontSize14)`,
  letterSpacing: `0.17000000178813934px`,
  textDecoration: `none`,
  lineHeight: `142.99999475479126%`,
  textTransform: `none`,
  alignSelf: `stretch`,
  height: `473px`,
  margin: `20px 0px 0px 0px`,
}));

const LeftAction1 = styled("div")({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-end`,
  alignItems: `flex-end`,
  padding: `0px`,
  boxSizing: `border-box`,
  margin: `20px 0px 0px 0px`,
});

const ButtonText1 = styled(Button)({
  margin: `0px`,
});

const CardClients = styled("div")(({ theme }) => ({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  border: `1px solid rgba(0, 0, 255, 0.2)`,
  boxSizing: `border-box`,
  borderRadius: `10px`,
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `20px`,
  flexGrow: `1`,
  margin: `0px 0px 0px 20px`,
}));

const Content2 = styled("div")({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  margin: `0px`,
});

const OurClients = styled("div")(({ theme }) => ({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  color: `rgba(25, 118, 210, 1)`,
  fontStyle: `normal`,
  fontFamily: `Roboto`,
  fontWeight: `400`,
  fontSize: `var(--fontSize24)`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `133.39999914169312%`,
  textTransform: `none`,
  width: `310px`,
  margin: `0px`,
}));

const LoremIpsumLoremIpsum2 = styled("div")(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Roboto`,
  fontWeight: `400`,
  fontSize: `var(--fontSize14)`,
  letterSpacing: `0.17000000178813934px`,
  textDecoration: `none`,
  lineHeight: `142.99999475479126%`,
  textTransform: `none`,
  alignSelf: `stretch`,
  height: `473px`,
  margin: `20px 0px 0px 0px`,
}));

const LeftAction2 = styled("div")({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-end`,
  alignItems: `flex-end`,
  padding: `0px`,
  boxSizing: `border-box`,
  margin: `20px 0px 0px 0px`,
});

const ButtonText2 = styled(Button)({
  margin: `0px`,
});

function OurWhy(props) {
  return (
    <OurWhy1>
      <OurWhyFrame>
        <OurWhy2>{`Our Why`}</OurWhy2>
        <WeBelieveInKeepingTh>
          {`We believe in keeping things simple by focusing on what matters most to us : our Users, our Employees and our Clients.`}
        </WeBelieveInKeepingTh>
      </OurWhyFrame>
      <OurWhyReasons>
        <CardUsers>
          <Content>
            <OurUsers>{`Our Users`}</OurUsers>
          </Content>
          <LoremIpsumLoremIpsum>
            {`lorem ipsum lorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsum`}
          </LoremIpsumLoremIpsum>
          <LeftAction>
            <ButtonText variant="text" size="small" color="primary">
              {" "}
              tell me more{" "}
            </ButtonText>
          </LeftAction>
        </CardUsers>
        <CardEmployees>
          <Content1>
            <OurEmployees>{`Our Employees`}</OurEmployees>
          </Content1>
          <LoremIpsumLoremIpsum1>
            {`lorem ipsum lorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsum`}
          </LoremIpsumLoremIpsum1>
          <LeftAction1>
            <ButtonText1 variant="text" size="small" color="primary">
              {" "}
              tell me more{" "}
            </ButtonText1>
          </LeftAction1>
        </CardEmployees>
        <CardClients>
          <Content2>
            <OurClients>{`Our Clients`}</OurClients>
          </Content2>
          <LoremIpsumLoremIpsum2>
            {`lorem ipsum lorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsum`}
          </LoremIpsumLoremIpsum2>
          <LeftAction2>
            <ButtonText2 variant="text" size="small" color="primary">
              {" "}
              tell me more{" "}
            </ButtonText2>
          </LeftAction2>
        </CardClients>
      </OurWhyReasons>
    </OurWhy1>
  );
}

export default OurWhy;

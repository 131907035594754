import React, { useEffect } from "react";
import { LineChart } from "@mui/x-charts/LineChart";
import Row from "../Row";
import { FormControl, LinearProgress, MenuItem, Select, useMediaQuery } from "@mui/material";
import { usdFormate } from "src/global/central";
import useDashboard from "src/components/Dashboard/useDashboard";
import { atm_filterMonthExpense } from "src/state/atomDashboard";
import { useRecoilState } from "recoil";

const dropdownMenuOptions = [{ label: "This Month Vs Last Month", value: "0" }, { label: "This Month Vs Same Month Last Year", value: "1" }, { label: "This Year Vs Last Year", value: "3" }]

const LineChartOutlined = ({ label = "Expense", data = {}, loader = false }) => {
  const {fnsDashboard} = useDashboard();
  const isMobile = useMediaQuery("(max-width: 750px)");
  const [filter, setFilter] = useRecoilState(atm_filterMonthExpense);
  const handleDropdown = (e) => {
    setFilter(e.target.value);
  };

  const getLegend = () => {
    switch(filter){
      case "0":
      case "1": return "Month";
      case "3": return "Year";
      default: return null;
    }
  }

  const CustomItemTooltipContent = (props) => {
    const { classes, axisData, dataIndex, series } = props;
    let currValue = data?.currExpenses?.[dataIndex]?.cumulativeTxnAmount;
    let prevValue = data?.prevExpenses?.[dataIndex]?.cumulativeTxnAmount;
    let currLabel = data?.currExpenses?.[dataIndex]?.bucketLabel;
    let prevLabel = data?.prevExpenses?.[dataIndex]?.bucketLabel;
    let day = axisData?.x?.value;
    
    return (
      <div className={`custom-${classes.root}`}>
        <table className={classes.table}>
          <thead>
            <tr className={classes.row}>
              <th colSpan={3} className={classes.cell}>
                Day {day}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className={classes.row}>
              <td className={classes.markCell}>
                <div
                  className={classes.mark}
                  style={{ background:  series[0]?.color}}
                ></div>
              </td>
              <td className={classes.labelCell}>{currLabel || "-"}</td>
              <td className={classes.valueCell}>
                {currValue ? usdFormate(currValue)?.slice(0,-1) : currValue === 0 ? "$0" : "-"}
              </td>
            </tr>
            <tr className={classes.row}>
              <td className={classes.markCell}>
                <div
                  className={classes.mark}
                  style={{ background:  series[1]?.color}}
                ></div>
              </td>
              <td className={classes.labelCell}>{prevLabel || "-"}</td>
              <td className={classes.valueCell}>
              {prevValue ? usdFormate(prevValue)?.slice(0,-1) : prevValue === 0 ? "$0" : "-"}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  useEffect(() => {
    fnsDashboard.getExpenseComparisonTrend();
  },[filter]);

  return (
    <div className="outlineChartContainer">
      {loader && (
        <LinearProgress
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
          }}
        />
      )}
      <Row justify={"between"} className="chart-header">
        <span className="chart-label">{label} *</span>
        <FormControl
          sx={{
            ".MuiInputBase-root": {
              "::before": {
                borderBottom: "none !important",
              },
              "::after": {
                borderBottom: "none !important",
              },
            },
            ".MuiInputBase-input": {
              backgroundColor: 'transparent'
            }
          }}
        >
          <Select variant="standard" value={filter} onChange={handleDropdown}>
            {dropdownMenuOptions.map(d => <MenuItem value={d.value}>{d.label}</MenuItem>)}
          </Select>
        </FormControl>
      </Row>
      <LineChart
        margin={{ bottom: 80, top: 25 }}
        sx={{
          width: "100%",
          paddingLeft: isMobile ? 1 : 0,
          ".MuiMarkElement-root": {
            scale: "0",
          },
        }}
        yAxis={[
          {
            valueFormatter: (value) => {
              if (value >= 1000000) {
                return `$${Math.floor(value / 1000000)}M`;
              } else if (value <= -1000000) {
                return `-$${Math.floor(Math.abs(value) / 1000000)}M`;
              } else if (value > 0) {
                return `$${Math.floor(value / 1000)}k`;
              } else if (value < 0) {
                return `-$${Math.floor(Math.abs(value) / 1000)}k`;
              } else {
                return "";
              }
            },
            tickNumber: 5,
            min: 0,
          },
        ]}
        xAxis={[
          {
            scaleType: "point",
            data: data?.prevExpenses?.map((d) => d?.bucketIndex) || [],
            valueFormatter: (value) => {
              return value > 0 ? `Day ${value}` : "";
            },
          },
        ]}
        series={[
          {
            curve: "linear",
            data: data?.currExpenses?.map((d) => d?.cumulativeTxnAmount) || [],
            color: "var(--color2)",
            valueFormatter: (value) => !value ? "-" : `${usdFormate(value).slice(0,-1)}`,
            label: (location) =>
              location === "tooltip" || isMobile
                ? `This ${getLegend()}`
                : `This ${getLegend()} Expense`,
          },
          {
            curve: "linear",
            data: data?.prevExpenses?.map((d) => d?.cumulativeTxnAmount) || [],
            color: "var(--color1)",
            valueFormatter: (value) => !value ? `$${0}`:`${usdFormate(value).slice(0,-1)}`,
            label: (location) => {
              return location === "tooltip" || isMobile
                ? `Last ${getLegend()}`
                : `Last ${getLegend()} Expense`
            }
              
          },
        ]}
        tooltip={{
          trigger: "axis"
        }}
        slots={{
          axisContent: CustomItemTooltipContent
        }}
        slotProps={{
          legend: {
            direction: "row",
            position: { vertical: "bottom", horizontal: "middle" },
            itemGap: 20,
            markGap: 10,
            labelStyle: {
              fontWeight: 700,
            },
          },
        }}
        height={300}
      />
    </div>
  );
};

export default LineChartOutlined;

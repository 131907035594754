import React, { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";
import {
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  CardContent,
  styled,
  Snackbar,
  Drawer,
  Box,
  ListItem,
  Typography,
  Alert,
} from "@mui/material";

import { ArrowBack, Password, ArrowForwardIosSharp } from "@mui/icons-material";

import "../assets/css/register.css";
import axios from "axios";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import { checkPassword } from "src/validations/Password";

import { getAuthToken } from "src/global/central";

const ButtonContained = styled(Button)({
  margin: `0px`,
});

export default function ChangePasswordDrawer() {
  const [openAlert, setOpenAlert] = useState(false);

  let [values, setValues] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const [passwordError, setPasswordError] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  const [HeightState, setHeight] = useState(0);

  const jwt = getAuthToken();

  const HandleResizeWork = () => {
    const WindowHeight = window.innerHeight - 100;
    setHeight(WindowHeight);
  };
  useEffect(() => {
    window.addEventListener("resize", HandleResizeWork);
    HandleResizeWork();
  }, []);

  const handleShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  const handleChange = (event) => {
    setPasswordError("");

    setValues((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === "keydown") {
      return;
    }
    setPasswordError("");
    values.currentPassword = "";
    values.newPassword = "";
    values.confirmNewPassword = "";

    setState(open);
  };

  const handleSubmitChangePassword = (e) => {
    e.preventDefault();

    if (!checkPassword(values.newPassword)) {
      setPasswordError(
        "Your new password should have minimum of 8 characters, must have at least one uppercase character, at least one lowercase character, at least one number, and atleast one special character"
      );

      return;
    }

    if (values.newPassword !== values.confirmNewPassword) {
      setPasswordError("New Passwords do not match");
      return;
      // console.log(passwordErrors);
      // setOpenAlert(true);
    }

    if (values.newPassword === values.currentPassword) {
      setPasswordError(
        "New password must be different from the current password"
      );
      return;
    }

    let URL =
      process.env.REACT_APP_USER_API_END_POINT + "/users/v1/changePassword";

    axios
      .post(
        URL,
        {
          currentPassword: values.currentPassword,
          newPassword: values.newPassword,
          newConfirmPassword: values.confirmNewPassword,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + jwt,
          },
        }
      )
      .then((res) => {
        console.log(passwordError);
        if (res["data"]["success"] === false) {
          console.log("error from server " + res["data"]["responseMessage"]);
          for (let i = 0; i < res["data"]["errors"].length; i++) {
            console.log(res["data"]["errors"][i]["errorMessage"]);

            setPasswordError(res["data"]["errors"][i]["errorMessage"]);
          }
          throw res["data"]["responseMessage"];
        } else {
          setOpenAlert(true);
          setTimeout(() => {
            setState(false);
            toggleDrawer(false, false);
          }, 2000);
        }
      })
      .catch((err) => {
        console.log("inside catch block with error : " + err);
      });
  };

  const handleClose = () => {
    setOpenAlert(false);
  };

  const [state, setState] = useState(false);

  const list = (anchor) => {
    return (
      <Box
        sx={{
          minHeight: `${HeightState}px`,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="login-box">
          <form onSubmit={handleSubmitChangePassword}>
            <CardContent sx={{ display: "flex", flexDirection: "column" }}>
              <FormControl variant="outlined" sx={{ alignSelf: "stretch" }}>
                <InputLabel htmlFor="password">
                  Enter Your Current Password
                </InputLabel>
                <OutlinedInput
                  id="current-password"
                  onKeyDown={(e) => e.stopPropagation()}
                  label="Enter Your Current Password"
                  type={showPassword ? "text" : "password"}
                  className="grid-2"
                  onChange={handleChange}
                  required
                  name="currentPassword"
                  value={values.currentPassword}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>

              <FormControl
                variant="outlined"
                sx={{
                  alignSelf: "stretch",
                  margin: "20px 0px",
                }}
              >
                <InputLabel htmlFor="password">Enter A New Password</InputLabel>
                <OutlinedInput
                  id="password"
                  label="Enter A New Password"
                  type={showPassword ? "text" : "password"}
                  onKeyDown={(e) => e.stopPropagation()}
                  onChange={handleChange}
                  required
                  name="newPassword"
                  value={values.newPassword}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>

              <FormControl
                variant="outlined"
                sx={{
                  alignSelf: "stretch",
                  marginBottom: "25px",
                }}
                c
              >
                <InputLabel htmlFor="confirm-password">
                  Confirm New Password
                </InputLabel>
                <OutlinedInput
                  id="confirm-password"
                  type={showPassword ? "text" : "password"}
                  onKeyDown={(e) => e.stopPropagation()}
                  onChange={handleChange}
                  required
                  name="confirmNewPassword"
                  className="field-auth"
                  value={values.confirmNewPassword}
                  label={`Confirm Your Password`}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {passwordError && (
                  <Typography
                    color="var(--color14)"
                    variant="body2"
                    gutterBottom
                    marginTop={"10px"}
                    display={"flex"}
                    alignItems={"center"}
                  >
                    <svg
                      width="17px"
                      height="17px"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        marginRight: "5px",
                      }}
                    >
                      <path
                        d="M8.5 0C6.81886 0 5.17547 0.498516 3.77766 1.43251C2.37984 2.3665 1.29037 3.69402 0.647028 5.24719C0.00368293 6.80036 -0.164645 8.50943 0.163329 10.1583C0.491303 11.8071 1.30085 13.3217 2.4896 14.5104C3.67834 15.6992 5.1929 16.5087 6.84174 16.8367C8.49057 17.1646 10.1996 16.9963 11.7528 16.353C13.306 15.7096 14.6335 14.6202 15.5675 13.2223C16.5015 11.8245 17 10.1811 17 8.5C17 6.24566 16.1045 4.08365 14.5104 2.48959C12.9163 0.895533 10.7543 0 8.5 0ZM7.44459 4.25C7.44459 3.96821 7.55653 3.69796 7.75578 3.4987C7.95504 3.29944 8.22529 3.1875 8.50709 3.1875C8.78888 3.1875 9.05913 3.29944 9.25839 3.4987C9.45764 3.69796 9.56959 3.96821 9.56959 4.25V9.13041C9.56959 9.26994 9.5421 9.40811 9.48871 9.53702C9.43531 9.66593 9.35705 9.78305 9.25839 9.88172C9.15972 9.98038 9.04259 10.0586 8.91369 10.112C8.78478 10.1654 8.64662 10.1929 8.50709 10.1929C8.36756 10.1929 8.22939 10.1654 8.10048 10.112C7.97158 10.0586 7.85445 9.98038 7.75578 9.88172C7.65712 9.78305 7.57886 9.66593 7.52546 9.53702C7.47207 9.40811 7.44459 9.26994 7.44459 9.13041V4.25ZM8.5 13.8125C8.25904 13.8125 8.02349 13.741 7.82313 13.6072C7.62278 13.4733 7.46662 13.283 7.37441 13.0604C7.2822 12.8378 7.25807 12.5928 7.30508 12.3565C7.35209 12.1201 7.46812 11.9031 7.63851 11.7327C7.8089 11.5623 8.02598 11.4463 8.26232 11.3992C8.49865 11.3522 8.74362 11.3764 8.96624 11.4686C9.18886 11.5608 9.37914 11.7169 9.51301 11.9173C9.64688 12.1176 9.71834 12.3532 9.71834 12.5942C9.71834 12.9173 9.58998 13.2272 9.36149 13.4557C9.13301 13.6841 8.82312 13.8125 8.5 13.8125Z"
                        fill="#E41616"
                      />
                    </svg>{" "}
                    {passwordError}
                  </Typography>
                )}
              </FormControl>
              <Box display={"flex"} flexDirection={"row"}>
                <ButtonContained
                  size="large"
                  sx={{
                    height: "65px",
                    width: "100%",
                    background: "#979797",
                    borderRadius: "5px",
                    fontFamily: "Nunito",
                    fontStyle: "normal",
                    fontWeight: "700",
                    lineHeight: "20px",
                    fontSize: "16px",
                    textAlign: "center",
                    color: "#000000",
                    marginRight: "10px",
                  }}
                  onClick={toggleDrawer(false, true)}
                >
                  Cancel
                </ButtonContained>
                <ButtonContained
                  variant="contained"
                  className="button-auth-main grid-2"
                  size="large"
                  color="primary"
                  type="submit"
                >
                  Save
                </ButtonContained>
              </Box>
            </CardContent>
          </form>
        </div>

        <Snackbar
          open={openAlert}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Password has been changed successfully! Redirecting...
          </Alert>
        </Snackbar>
      </Box>
    );
  };

  return (
    <Box>
      <ListItem
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginY: "10px",
        }}
        onClick={toggleDrawer(true, true)}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Password sx={{ color: "#0C89EF", marginRight: "5px" }} />{" "}
          <strong>Change Password</strong>
        </Box>

        <ArrowForwardIosSharp fontSize="10px" />
      </ListItem>
      <Drawer
        variant={"persistent"}
        anchor={"right"}
        open={state}
        onClose={toggleDrawer(false, false)}
        PaperProps={{
          sx: {
            width: "100%",
            backgroundColor: "#EAF9F6",
          },
        }}
      >
        <Box
          sx={{
            width: "100%",
            color: "black",
            backgroundColor: "#EAF9F6",
          }}
          role="presentation"
          display={"flex"}
          justifyContent={"space-between"}
          paddingTop={1}
          alignItems={"center"}
        >
          <ArrowBack
            sx={{
              fontSize: 30,
              margin: 1,
              marginLeft: 2,
              cursor: "pointer",
            }}
            onClick={toggleDrawer(false, true)}
          />

          <strong
            style={{
              fontFamily: "Nunito",
              fontSize: 24,
              textAlign: "center",
            }}
          >
            Change Password
          </strong>
          <ArrowBack sx={{ fontSize: 30, margin: 1, visibility: "hidden" }} />
        </Box>
        {list("right")}
      </Drawer>
    </Box>
  );
}

import React, { useState } from "react";
import KmnModal from "../../shared/KmnModal";
import { Col } from "../../shared";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import EditRule from "./EditRule";

const data = [
  {
    rule: 1,
    rules: ["Merchant Name", "Contains", "Update", "Category"],
  },
  {
    rule: 2,
    rules: ["Merchant Name", "Contains"],
  },
];

const ManageRulesModal = ({cancelManageRulesModal}) => {
  const [isEditOrCreateRule, setIsEditOrCreateRule] = useState(false);

  const triggerEditOrCreateRule = () => {
    setIsEditOrCreateRule(true);
  };

  const cancelEditOrCreateRule = () => {
    setIsEditOrCreateRule(false);
  }
  return (
    <KmnModal
      isOpen
      onClose={cancelManageRulesModal}
      className="manageRulesModal"
      title="Manage Rules"
      subTitle="Create and manage custom rules to automatically map your transactions to appropriate merchants/ categories and more"
      width={1000}
      hideCancel
      btnVariant="tertiary"
      confirmBtnLabel="Create Rule"
      footerDivider
      disableFooter={isEditOrCreateRule}
      onConfirm={triggerEditOrCreateRule}
      body={
        <>
          <div className="rules">
            {data.map((d, idx) => (
              <Col key={idx}>
                <span className="ruleCount">Rule : Rule {d.rule}</span>
                <div className="ruleBoxes">
                  <div className="ruleBoxesInside">
                    {d.rules.map((ruleName) => (
                      <div className="rule">{ruleName}</div>
                    ))}
                  </div>
                  {!isEditOrCreateRule && (
                    <ModeEditIcon className="editIcon" />
                  )}
                </div>
              </Col>
            ))}

            {isEditOrCreateRule && <EditRule cancelEditOrCreateRule={cancelEditOrCreateRule} />}
          </div>
        </>
      }
    />
  );
};

export default ManageRulesModal;

import React, { useEffect, useRef, useState } from "react";
import KmnModal from "src/components/shared/KmnModal";
import deleteIcon from "../../assets/images/delete.svg";
import editIcon from "../../assets/images/pencil2.svg";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  atm_isManageTagsModal,
  atm_transactionTags,
} from "src/state/atomsTransactionData";
import ErrorIcon from "@mui/icons-material/Error";
import KmnButton from "./KmnButton";
import useTransactionsLanding from "./useTransactionsLanding";
import Loader from "src/components/shared/Loader";
import { Row } from "src/components/shared";
import useDeviceType from "src/hooks/useDeviceType";

const ManageTagsModal = () => {
  const { fnsTransactions, dataTransactions } = useTransactionsLanding();
  const [isManageTagsModal, setIsManageModal] = useRecoilState(
    atm_isManageTagsModal
  );
  const [selectedTagForDeletion, setSelectedTagForDeletion] = useState(null);
  const [selectedTagForEdit, setSelectedTagForEdit] = useState(null);
  const inputRefs = useRef([]);
  const [tagToBeAdded, setTagToBeAdded] = useState(null);
  const [isTagActionVisible, setIsTagActionVisible] = useState(null);
  const disableModifyingTags =
    selectedTagForEdit || selectedTagForDeletion || tagToBeAdded !== null || dataTransactions.loading;
  const transactionTags = useRecoilValue(atm_transactionTags)
  const { isMobile } = useDeviceType();
  const tagRef = useRef([]);

  useEffect(() => {
    if (selectedTagForEdit) {
      const index = transactionTags.findIndex(
        (tag) => tag.tagId === selectedTagForEdit.tagId
      );
      if (index !== -1 && inputRefs.current[index]) {
        inputRefs.current[index].focus();
      }
    } else if (tagToBeAdded !== null) {
      inputRefs.current[transactionTags.length]?.focus();
    }
  }, [selectedTagForEdit, tagToBeAdded, transactionTags]);

  const handleClose = () => {
    setIsManageModal(false);
  };

  const handleTagDeletion = (data) => {
    fnsTransactions.resetError();
    setSelectedTagForDeletion(data);
    setIsTagActionVisible(null);
  };

  const triggerEditTag = (data) => {
    fnsTransactions.resetError();
    setSelectedTagForDeletion(null);
    setIsTagActionVisible(null);
    setSelectedTagForEdit(data);
  };

  const handleEditTag = (e) => {
    if(selectedTagForEdit){
      let value = e.target.value?.trim();
      if (value && selectedTagForEdit?.tagName !== value) {
        fnsTransactions.editTransactionTag({
          tagId: selectedTagForEdit?.tagId,
          tagName: value,
        });
      } else {
        e.target.value = selectedTagForEdit?.tagName;
      }
      setSelectedTagForEdit("");
    }
  };

  const handleDeleteCancel = () => {
    setSelectedTagForDeletion(null);
  };

  const handleDeleteConfirm = async () => {
    fnsTransactions.deleteTransactionTag(selectedTagForDeletion?.tagId);
    handleDeleteCancel();
  };

  const triggerAddTag = () => {
    fnsTransactions.resetError();
    setTagToBeAdded("");
  };

  const handleAddTag = async () => {
    if (tagToBeAdded) {
      await fnsTransactions.addTransactionTag(tagToBeAdded);
    }
    setTagToBeAdded(null);
  };
  
  const handleTagClick = (tagId) => {
    if(isTagActionVisible === tagId || selectedTagForDeletion){
      return
    }else{
      setIsTagActionVisible(tagId)
    }
  };
  const handleClickOutside = (event) => {
    if (tagRef.current.some((ref) => ref && ref.contains(event.target))) {
      return;
    }
        setIsTagActionVisible(null);
  };

  useEffect(() => {
    if(isMobile) {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [isMobile]);

  const Renderbody = () => {
    return <>
    <Loader visible={dataTransactions?.loading} />
    {dataTransactions.error && (
      <span className="error">
        <ErrorIcon /> {dataTransactions.error}
      </span>
    )}
    {selectedTagForDeletion && (
      <div className="confirmation">
        <Row align="center" gap={10}>
          <ErrorIcon />{" "}
          <span>
            Are you sure ? Tag “{selectedTagForDeletion?.tagName}” will be
            removed from all associated transactions
          </span>
        </Row>
        <div className="btns">
          <KmnButton
            variant="secondary"
            color="primary_outlined"
            onClick={handleDeleteCancel}
            isDisabled={dataTransactions.loading}
          >
            No
          </KmnButton>
          <KmnButton
            variant="secondary"
            color="primary"
            onClick={handleDeleteConfirm}
            isDisabled={dataTransactions.loading}
          >
            Yes
          </KmnButton>
        </div>
      </div>
    )}
    <div className="tags">
      {transactionTags.map((d, idx) => (
        <div
          key={d?.tagId}
          className={`tag ${
            (d?.systemTag || disableModifyingTags) && "disabledModifyingTags"
          } ${
            (selectedTagForEdit?.tagId === d?.tagId ||
              selectedTagForDeletion?.tagId === d?.tagId) &&
            "selectedTag"
          }`}
          onClick={() => isMobile && handleTagClick(d?.tagId)}
          ref={(el) => (tagRef.current[idx] = el)}
        >
          <input type="text" />
          <span className={isTagActionVisible === d?.tagId ? "tagActionVisible" : undefined}>
            <div className="skeleton">{d?.tagName}</div>
            <input
              ref={(el) => (inputRefs.current[idx] = el)}
              type="text"
              placeholder="Tag Name"
              onChange={(e) => (e.target.value = e.target.value?.trim())}
              defaultValue={d?.tagName}
              onKeyDown={(e) => e?.code === "Enter" && selectedTagForEdit && handleEditTag(e)}
              maxLength={24}
              readOnly={
                selectedTagForEdit?.tagId !== d?.tagId ||
                dataTransactions.loading
              }
              onBlur={handleEditTag}
            />
          </span>{" "}
          {!d?.systemTag && (isMobile ? isTagActionVisible === d?.tagId : !disableModifyingTags) && (
            <div className="btns">
              ...
              <img
                onClick={() => handleTagDeletion(d)}
                src={deleteIcon}
                alt="delete"
                className="deleteBtn btn"
              />
              <img
                onClick={() => triggerEditTag(d)}
                src={editIcon}
                alt="delete"
                className="editBtn btn"
              />
            </div>
          )}
        </div>
      ))}
      {tagToBeAdded !== null && (
        <div className="tag add-tag">
          <span>{tagToBeAdded}</span>
          <input
            ref={(el) =>
              (inputRefs.current[transactionTags?.length] = el)
            }
            onChange={(e) => setTagToBeAdded(e.target.value)}
            readOnly={dataTransactions.loading}
            value={tagToBeAdded}
            type="text"
            maxLength={24}
            placeholder="Tag Name"
            onBlur={handleAddTag}
            onKeyDown={(e) => e?.code === "Enter" && handleAddTag(e)}
          />
        </div>
      )}
    </div>
  </>
  }

  return (
    <KmnModal
      className="manageTagsModal"
      title="Manage Tags"
      body={<Renderbody />}
      btnVariant={"secondary"}
      isOpen={isManageTagsModal}
      onClose={handleClose}
      footer={
        <KmnButton
          variant="secondary"
          color="primary"
          onClick={triggerAddTag}
          isDisabled={
            selectedTagForEdit ||
            tagToBeAdded !== null ||
            selectedTagForDeletion ||
            dataTransactions.loading
          }
        >
          Add Tag
        </KmnButton>
      }
      disableFooterActions
    />
  );
};

export default ManageTagsModal;

import React from "react";
import "../assets/css/about.css";
import Mission from "./Mission";
import Story from "./Story";
import Approach from "./Approach";
import Explore from "./Explore";
import Footer from "../Home/Footer";
import LoginSignupMenu from "../shared/LoginSignupMenu";
import MetaTags from "../shared/MetaTags";
import RedditPixel from "../shared/RedittPixel";

const About = () => {

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // });

  return (
    <div className="aboutPage">
  
      <MetaTags index={"AboutUs"} />
      <RedditPixel event={"AboutUs"} custom_or_default={1}/>

      <Mission />

      <Story />

      <Approach />

      <Explore />

      <Footer />

      <LoginSignupMenu />
    </div>
  );
};

export default About;

import React, { useRef } from "react";
import aboutBg from "../assets/images/about-bg.png";
import MissionSlide1 from "../assets/images/mission-slide1.png";
import MissionSlide2 from "../assets/images/mission-slide2.png";
import MissionSlide3 from "../assets/images/mission-slide3.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import { Autoplay, EffectCoverflow, Pagination } from "swiper";
import { GetWindowWidth } from "../../global/central";

const Mission = () => {
  const swiperRef = useRef(null);

  const handleSwiperInit = (swiper) => {
    swiperRef.current = swiper;
  };

  const width = GetWindowWidth();

  return (
    <section className="mission">
      <img src={aboutBg} alt="" className="aboutBg" />

      <h1>Our Mission</h1>

      <div className="inshort">
        <p>
          We are all about helping people manage their money better, by
          providing accessible, personalized, and unbiased information.
        </p>
        <p>
          We believe that everyone deserves to have a better understanding of
          their finances, and our platform is dedicated to helping individuals
          reimagine their financial future.
        </p>
      </div>

      <div className="mission-slider">
        <Swiper
          effect="coverflow"
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={width > 900 ? 3 : 1}
          coverflowEffect={{
            rotate: 0,
            stretch: 90,
            depth: 50,
            modifier: 2,
            slideShadows: true,
          }}
          pagination={{ el: ".swiper-pagination", clickable: true }}
          modules={[EffectCoverflow, Pagination, Autoplay]}
          className="swiper-container"
          onSwiper={handleSwiperInit}
          initialSlide={1}
        >
          <SwiperSlide>
            <img src={MissionSlide1} alt="Radial graphs of user spend by category, comparison with others by location, along with merchant ratings and spendings details" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={MissionSlide2} alt="Bar graph showing user income/expense month over month trend, including net cash flow, all accounts and transaction details in one place" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={MissionSlide3} alt="Band graph of user income/expense trend, showing holding statistics by investment type and security name, with net portfolio value and Cost Basis over time" />
          </SwiperSlide>
        </Swiper>
        <div className="swiper-pagination"></div>
      </div>
    </section>
  );
};

export default Mission;

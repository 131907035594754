import {
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import useDashboard from "src/components/Dashboard/useDashboard";
import { atm_editGoal, atm_selectedGeneralData } from "src/state/atomDashboard";

const SelectAction = ({ data }) => {
  const { fnsDashboard } = useDashboard();

  const [generalData, setGeneralData] = useRecoilState(atm_selectedGeneralData);

  const isEditMode = useRecoilValue(atm_editGoal);

  const handleChange = (event) => {
    fnsDashboard.getAllActionTypes(event.target.value);
    setGeneralData({ ...generalData, action: event.target.value });
  };

  return (
    <FormControl
      className="selectAction select"
      variant="standard"
      sx={{ minWidth: 130, textAlign: "center", border: "none" }}
    >
      <InputLabel
        id="labelAction"
        sx={{
          fontSize: 12,
          textAlign: "center",
          left: 3,
          top: -12,
        }}
      >
        Choose your action
      </InputLabel>
      <Select
        labelId="labelAction"
        id="Action"
        value={generalData.action}
        onChange={handleChange}
        disabled={!generalData.option || isEditMode}
        required
        sx={{
          paddingBottom: 0,
          textAlign: "center",
        }}
      >
        {data.map((option, index) => [
          <MenuItem key={index} value={option.actionId}>
            {option.actionName}
          </MenuItem>,
          index < data.length - 1 && (
            <Divider key={`divider-${index}`} sx={{ mx: "10px" }} />
          ),
        ])}
      </Select>
    </FormControl>
  );
};

export default SelectAction;

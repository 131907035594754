import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Col, Row, Text } from "../shared";
import { collegeData } from "./CollegeData";
import { usdFormate } from "src/global/central";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";

const CollegeCalculator = () => {
  const [age, setAge] = useState("");
  const [error, setError] = useState("");
  const [futureYear, setFutureYear] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width: 750px)");
  const [calculatedData, setCalculatedData] = useState(null);

  const handleUserAge = (e) => {
    let value = e.target.value;
    setAge(value);
  };

  const calculateCollegeData = (ageValue) => {
    if (age === "") {
      setError("Please enter the age to see the expected cost.");
      setCalculatedData(null);
      return;
    }
    if (ageValue >= 0 && ageValue <= 18 && !isNaN(ageValue)) {
      navigate({
        pathname: location.pathname,
        search: `?age=${ageValue}`,
      });
    }
  };

  useEffect(() => {
    if (age > 18) {
      setError("Age should be less than 18");
    } else {
      setError("");
    }
  }, [age]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const ageFromParams = params.get("age");

    if (ageFromParams && !isNaN(ageFromParams)) {
      setAge(ageFromParams);
      const futureYear = new Date().getFullYear() + (18 - ageFromParams);
      const collegeDataForYear = collegeData[futureYear];
      setFutureYear(futureYear);
      setCalculatedData(collegeDataForYear);
    }
  }, []);

  const handleReset = () => {
    setAge("");
    setCalculatedData(null);
    navigate(location.pathname);
  };

  const MobileCollegeCalculator = () => {
    return (
      <Col className="mobClgCalculator">
        <Box p={2} sx={{ border: "1px solid #dcdcdc", background: "#f9f9f9" }}>
          <Row justify="between" align="center">
            <Col>
              <Text size={14} weight={800}>
                College Type/Degree
              </Text>
              <Text size={12}>
                {futureYear}-{(futureYear % 2000) + 1}
              </Text>
            </Col>
            <Text size={14} weight={800}>
              Total Cost / Yr
            </Text>
          </Row>
        </Box>
        {calculatedData.map((values, index) => (
          <Accordion
            key={index}
            sx={{
              margin: "0px !important",
            }}
          >
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon />}
              sx={{
                flexDirection: "row-reverse",
                paddingLeft: 0.75,
                paddingTop: 1,
                paddingBottom: 1,
                ".MuiAccordionSummary-content": {
                  justifyContent: "space-between",
                  gap: "10px",
                },
                ".Mui-expanded svg": {
                  fill: "var(--color1)",
                },
              }}
            >
              <Text size={14} weight={800}>
                {values.collegeType}
              </Text>
              <Text size={14} weight={800} className="amount">
                {usdFormate(values.tutionFee + values.roomAndBoard)}
              </Text>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                paddingLeft: "30px",
              }}
            >
              <Col gap={8}>
                <Row justify={"between"} gap={10}>
                  <Text size={14} weight={800}>
                    Tution & Fees
                  </Text>
                  <Text size={14} className="amount">
                    {usdFormate(values.tutionFee)}
                  </Text>
                </Row>
                <Row justify={"between"} gap={10}>
                  <Text size={14} weight={800}>
                    Room and Board
                  </Text>
                  <Text size={14} className="amount">
                    {usdFormate(values.roomAndBoard)}
                  </Text>
                </Row>
                <Row justify={"between"} gap={10}>
                  <Text size={14} weight={800}>
                    Total
                  </Text>
                  <Text size={14} className="amount">
                    {usdFormate(values.tutionFee + values.roomAndBoard)}
                  </Text>
                </Row>
              </Col>
            </AccordionDetails>
          </Accordion>
        ))}
      </Col>
    );
  };
  return (
    <Box className="calculators-box college-calculator">
      <span hidden={isMobile} className="head">
        College Cost Calculator
      </span>
      <Row gap={25} align={"center"} className="age-filling">
        <span className="ageLabel">Age of student</span>
        <TextField
          variant="standard"
          sx={{
            minWidth: isMobile ? "unset" : 240,
            ".MuiFormHelperText-root": {
              position: "absolute",
              bottom: -25,
              whiteSpace: "nowrap",
            },
          }}
          size="small"
          name="age"
          id="age"
          fullWidth={isMobile}
          label="Age in Yrs"
          value={age}
          focused={Boolean(age === 0 || age)}
          onChange={handleUserAge}
          inputProps={{ maxLength: 3 }}
          onInput={(e) => {
            e.target.value = e.target.value.replace(/[^0-9]/g, "");
          }}
          helperText={error}
          error={Boolean(error)}
        ></TextField>
        <Row className="btns" gap={15}>
          <button
            className="calculateBtn"
            onClick={() => calculateCollegeData(age)}
          >
            Calculate
          </button>
          <button className="resetBtn" onClick={handleReset}>
            Reset
          </button>
        </Row>
      </Row>
      {isMobile && calculatedData ? (
        <MobileCollegeCalculator />
      ) : age !== null && calculatedData ? (
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 700 }}
            aria-label="customized table"
            className="calculators-table"
          >
            <TableHead sx={{ background: "#DCDCDC" }}>
              <TableRow>
                <TableCell>

                <Col>
                    <span>College Type/Degree</span>
                    <span className="subHead">
                      [ UnderGrad ]
                    </span>
                  </Col>

                </TableCell>

                <TableCell>
                  <Col>
                    <span>Tution & Fees / Yr</span>
                    <span className="subHead">
                      {futureYear}-{(futureYear % 2000) + 1}
                    </span>
                  </Col>
                </TableCell>
                <TableCell>
                  <Col>
                    <span>Room and Board / Yr</span>
                    <span className="subHead">
                      {futureYear}-{(futureYear % 2000) + 1}
                    </span>
                  </Col>
                </TableCell>
                <TableCell>
                  <Col>
                    <span>Total / Yr</span>
                    <span className="subHead">
                      {futureYear}-{(futureYear % 2000) + 1}
                    </span>
                  </Col>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {calculatedData.map((values, index) => (
                <TableRow key={index}>
                  <TableCell align="center" className="collegeType">
                    {values.collegeType}
                  </TableCell>
                  <TableCell align="center" className="tution">
                    {usdFormate(values.tutionFee)}
                  </TableCell>
                  <TableCell align="center" className="roomAndBoard">
                    {usdFormate(values.roomAndBoard)}
                  </TableCell>
                  <TableCell align="center" className="total">
                    {usdFormate(values.tutionFee + values.roomAndBoard)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        "Please enter the age to see the expected cost."
      )}
      {isMobile && (
        <Col className="need-help-text">
          <span>Need help planning for College?</span>
          <span>Signup to learn more!</span>
        </Col>
      )}
      <span className="note">
        1. The costs mentioned above are per Yr cost .<br></br>
        2. College cost calculations are based on the reference data collected
        from https://www.collegeboard.org as of Jan 01, 2024. <br />
        3. College cost increase rate of 2.9 % per year has been considered,
        based on reference from www.collegeboard.org <br />
        4. We have taken every effort to ensure that the costs are calculated
        correctly. However, there may be errors and omissions beyond our
        control. Please use this information with caution.
      </span>
    </Box>
  );
};

export default CollegeCalculator;

import {
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { atm_editGoal, atm_selectedGeneralData } from "src/state/atomDashboard";

const SelectAim = ({ data }) => {
  const [generalData, setGeneralData] = useRecoilState(atm_selectedGeneralData);
  const isEditMode = useRecoilValue(atm_editGoal);

  const handleChange = (event) => {
    setGeneralData({ ...generalData, aim: event.target.value });
  };
  return (
    <FormControl
      className="selectAim select"
      variant="standard"
      sx={{ minWidth: 120, textAlign: "center", ml: 3 }}
    >
      <InputLabel
        id="labelAim"
        sx={{
          fontSize: 12,
          textAlign: "center",
          left: 5,
          top: -12,
        }}
      >
        Choose your aim
      </InputLabel>
      <Select
        labelId="labelAim"
        id="Aim"
        value={generalData.aim}
        onChange={handleChange}
        disabled={!generalData.option || isEditMode}
        sx={{
          paddingBottom: 0,
          textAlign: "center",
          m: 0,
        }}
      >
        {data.map((option, index) => [
          <MenuItem key={index} value={option.goalTypeId}>
            {option.goalTypeName}
          </MenuItem>,
          index < data.length - 1 && (
            <Divider key={`divider-${index}`} sx={{ mx: "10px" }} />
          ),
        ])}
      </Select>
    </FormControl>
  );
};

export default SelectAim;

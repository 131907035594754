import React, { useEffect } from "react";
import { DropdownDate } from "../../shared/DateFilter/DropdownDate";
import { useState } from "react";
import CloseIcon from "../../assets/images/close.svg";
import useSpendByMerchant from "./useSpendByMerchant";
import { atm_IsMobMerchantFilter } from "src/state/insightsMerchants";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { atm_globalFilterCount, atm_globalFilterValue } from "src/state/atoms";
import { DropdownMerchant } from "src/components/shared/MerchantFilter/DropdownMerchant";
import useTransactionsLanding from "src/components/Accounts/Transactions/useTransactionsLanding";
import MerchantFilter from "src/components/shared/MerchantFilter/MerchantFilter";
import NewMerchantFilter from "src/components/shared/MerchantFilter/NewMerchantFilter";

const FiltersMerchant = () => {
  const { data, fns } = useSpendByMerchant();

  const { dataTransactions, fnsTransactions } = useTransactionsLanding();

  const [dropdownActive, setdropdownActive] = useState(null);

  const [isMobFilter, setIsMobFilter] = useRecoilState(atm_IsMobMerchantFilter);

  const globalFilterValue = useRecoilValue(atm_globalFilterValue);

  const setGlobalFilterCount = useSetRecoilState(atm_globalFilterCount);

  useEffect(() => {
    fnsTransactions.getMerchantListing();
  }, []);

  useEffect(() => {
    let filter = 1;

    const merchantFilter =
      globalFilterValue.merchantFilter.includeMerchantIds?.length > 0 ||
      globalFilterValue.merchantFilter.excludeMerchantIds?.length > 0;

    if (merchantFilter) {
      filter = 2;
    }
    setGlobalFilterCount(filter);
  }, [globalFilterValue]);

  return (
    <>
      <div
        className={`selection-areas filters filtersMerchant ${
          isMobFilter === false && "hidden"
        }`}
      >
        <header>
          <span>Filters</span>
          <img
            onClick={() => setIsMobFilter(false)}
            src={CloseIcon}
            alt=""
            className="close-icon"
          />
        </header>
        <div className="row">
          <DropdownDate
            setdropdownActive={setdropdownActive}
            dropdownActive={dropdownActive}
            callBackFunction={fns.onChangeDateFilter}
          />
          {/* <DropdownMerchant
            initial_value="All"
            setdropdownActive={setdropdownActive}
            dropdownActive={dropdownActive}
            MerchantState={dataTransactions.MerchantState}
            callBackFunction={fns.onChangeMerchantFilter}
          /> */}
          {/* <MerchantFilter
            data={dataTransactions.MerchantState}
            callBackFunction={fns.onChangeMerchantFilter}
            loading={data.globalLoading}
          /> */}

          <NewMerchantFilter
            data={dataTransactions.MerchantState}
            callBackFunction={fns.onChangeMerchantFilter}
            loading={data.globalLoading}
          />
        </div>
      </div>

      {isMobFilter && (
        <div
          className="mob-filter-backdrop"
          onClick={() => setIsMobFilter(false)}
        ></div>
      )}
    </>
  );
};

export default FiltersMerchant;

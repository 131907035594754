import React, { useEffect, useRef } from "react";
import CloseIcon from "../../assets/images/close.svg";
import useTransactionsLanding from "./useTransactionsLanding";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  atm_childReviewTextValue,
  atm_innerChildReviewTextValue,
  atm_isChildShowReplyClicked,
  atm_isInnerChildShowReplyClicked,
  atm_isReviewTransBoxOpen,
  atm_onlyChildViewAllClicked,
  atm_onlyInnerChildViewAllClicked,
  atm_parentReviewTextValue,
  atm_showChildReplyIndex,
  atm_showInnerChildL4ReplyIndex,
  atm_showInnerChildL5ReplyIndex,
  atm_showInnerChildReplyIndex,
  atm_transactionChildReviews,
  atm_transactionInnerChildReviews,
  atm_transactionsParentReviews,
} from "src/state/atomsTransactionData";
import { Box, LinearProgress } from "@mui/material";
import ChildReview from "./ChildReview";

const ParentReview = () => {
  const { dataTransactions, fnsTransactions } = useTransactionsLanding();

  const elemTextarea = useRef(null);

  const setIsReviewBoxOpen = useSetRecoilState(atm_isReviewTransBoxOpen);

  const setParentReviewsData = useSetRecoilState(atm_transactionsParentReviews);

  const setChildReviewsData = useSetRecoilState(atm_transactionChildReviews);

  const setInnerChildReviewsData = useSetRecoilState(
    atm_transactionInnerChildReviews
  );

  const [parentTextValue, setParentTextValue] = useRecoilState(
    atm_parentReviewTextValue
  );

  const setChildTextValue = useSetRecoilState(atm_childReviewTextValue);

  const setInnerChildTextValue = useSetRecoilState(
    atm_innerChildReviewTextValue
  );

  const setIsChildShowReplyClicked = useSetRecoilState(
    atm_isChildShowReplyClicked
  );

  const setIsInnerChildShowReplyClicked = useSetRecoilState(
    atm_isInnerChildShowReplyClicked
  );

  const setOnlyInnerChildViewAllClicked = useSetRecoilState(
    atm_onlyInnerChildViewAllClicked
  );

  const setOnlyChildViewAllClicked = useSetRecoilState(
    atm_onlyChildViewAllClicked
  );

  const setChildShowReplyIndex = useSetRecoilState(atm_showChildReplyIndex);
  const setInnerChildShowReplyIndex = useSetRecoilState(
    atm_showInnerChildReplyIndex
  );
  const setInnerChild4ShowReplyIndex = useSetRecoilState(
    atm_showInnerChildL4ReplyIndex
  );
  const setInnerChild5ShowReplyIndex = useSetRecoilState(
    atm_showInnerChildL5ReplyIndex
  );

  const handleReviewBoxClose = () => {
    setIsReviewBoxOpen(false);

    setIsChildShowReplyClicked(false);
    setIsInnerChildShowReplyClicked(false);

    setOnlyChildViewAllClicked(false);
    setOnlyInnerChildViewAllClicked(false);

    setParentReviewsData([]);
    setChildReviewsData([]);
    setInnerChildReviewsData([]);

    setChildShowReplyIndex(null);
    setInnerChildShowReplyIndex(null);
    setInnerChild4ShowReplyIndex(null);
    setInnerChild5ShowReplyIndex(null);

    setParentTextValue("");
    setChildTextValue("");
    setInnerChildTextValue("");
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    return () => {
      setIsReviewBoxOpen(false);

      setIsChildShowReplyClicked(false);
      setIsInnerChildShowReplyClicked(false);

      setOnlyChildViewAllClicked(false);
      setOnlyInnerChildViewAllClicked(false);

      setParentReviewsData([]);
      setChildReviewsData([]);
      setInnerChildReviewsData([]);

      setChildShowReplyIndex(null);
      setInnerChildShowReplyIndex(null);
      setInnerChild4ShowReplyIndex(null);
      setInnerChild5ShowReplyIndex(null);

      setParentTextValue("");
      setChildTextValue("");
      setInnerChildTextValue("");
    };
  }, []);
  return (
    <div className="user-review-box">
      {dataTransactions.reviewsLoading.parentReview && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
            }}
          />
        </Box>
      )}

      <header>
        <span className="head">Add New Review</span>
        <img onClick={handleReviewBoxClose} src={CloseIcon} alt="" />
      </header>
      <div className="textarea-cover">
        <div className="legend">Add Review</div>
        <textarea
          // ref={elemTextarea}
          onChange={(e) => setParentTextValue(e.target.value)}
        ></textarea>
      </div>
      <button
        onClick={(e) => {
          fnsTransactions.addParentReview(
            dataTransactions.reviewClickedRowData,
            parentTextValue
          );
          elemTextarea.current.value = "";
        }}
        className="add-review-btn btn"
      >
        Add Review
      </button>

      <div className="all-review-box customScroll">
        <div className="inside-all-review-box">
          <div className="title all-review-title">All Review</div>
          {dataTransactions.parentReviewsData.map((data, index) => {
            return <ChildReview data={data} index={index} key={index} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default ParentReview;

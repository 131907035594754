import React, { useEffect } from "react";
import CategoryChart from "./CategoryChart";
import { LinearProgress, useMediaQuery } from "@mui/material";
import MobInsightTable from "./MobInsightTable";
import InsightTable from "./InsightTable";
import { useRecoilValue } from "recoil";
import {
  atm_categoryParentSelectors,
  atm_globalCategory,
} from "src/state/insightsCategories";
import useSpendByCategory from "./useSpendByCategory";

const MoM = () => {
  const { data, fns } = useSpendByCategory();
  const mobile = useMediaQuery("(max-width: 900px)");

  const categoryParentSelectors = useRecoilValue(atm_categoryParentSelectors);

  const globalCategory = useRecoilValue(atm_globalCategory);

  useEffect(() => {
    if (!categoryParentSelectors.mom && data.categoryListData.length) {
      fns.refreshRightScreen(globalCategory);
    }
  }, [categoryParentSelectors]);

  return (
    <>
      {data.categoryChartLoading && (
        <LinearProgress
          style={{ width: "100%", position: "absolute", top: 0, left: 0 }}
        />
      )}
      <div className="scroller">
        <div className="inside-scroller">
          <CategoryChart />
          {mobile ? <MobInsightTable /> : <InsightTable />}
        </div>
      </div>
    </>
  );
};

export default MoM;

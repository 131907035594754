import React, { useState } from "react";
import {
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
  Line,
  LabelList,
} from "recharts";
import useHoldingsLanding from "./useHoldingsLanding";
import { GetWindowWidth, getMaxInterval } from "src/global/central";
import { Box, LinearProgress } from "@mui/material";
import { useRecoilValue } from "recoil";
import { atm_SelectedHoldingsDateFilter } from "src/state/atomsHoldingData";

const getIntersectionColor = (_intersection, isLast) => {
  if (isLast) {
    return _intersection.line1isHigherNext ? "var(--color14)" : "var(--color1)";
  }

  return _intersection.line1isHigher ? "var(--color14)" : "var(--color1)";
};

const getIntersectionColorLine = (_intersection, isLast) => {
  if (isLast) {
    return _intersection.line1isHigherNext ? "#EE4B2B" : "#0000FF";
  }

  return _intersection.line1isHigher ? "#EE4B2B" : "#0000FF";
};

function intersect(x1, y1, x2, y2, x3, y3, x4, y4) {
  // Check if none of the lines are of length 0
  if ((x1 === x2 && y1 === y2) || (x3 === x4 && y3 === y4)) {
    return false;
  }

  const denominator = (y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1);

  // Lines are parallel
  if (denominator === 0) {
    return false;
  }

  let ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator;
  let ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denominator;

  // is the intersection along the segments
  if (ua < 0 || ua > 1 || ub < 0 || ub > 1) {
    return false;
  }

  // Return a object with the x and y coordinates of the intersection
  let x = x1 + ua * (x2 - x1);
  let y = y1 + ua * (y2 - y1);

  const line1isHigher = y1 > y3;
  const line1isHigherNext = y2 > y4;

  return { x, y, line1isHigher, line1isHigherNext };
}

const HoldingsChart = () => {
  const { dataHoldings } = useHoldingsLanding();

  const filterValue = useRecoilValue(atm_SelectedHoldingsDateFilter);

  const monthName = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // add `range` to data for Area
  var dataWithRange;
  if (
    dataHoldings.holdingsChartStats &&
    dataHoldings.holdingsChartStats.length
  ) {
    dataWithRange = dataHoldings.holdingsChartStats.map((d) => ({
      ...d,
      range:
        d.costBasis !== undefined && d.portfolioValue !== undefined
          ? [d.costBasis, d.portfolioValue]
          : [],
      min:
        d.costBasis !== undefined && d.portfolioValue !== undefined
          ? Math.min(d.costBasis, d.portfolioValue)
          : null,
    }));
  }

  // need to find intersections as points where we to change fill color
  var intersections;
  var filteredIntersections;
  if (
    dataHoldings.holdingsChartStats &&
    dataHoldings.holdingsChartStats.length
  ) {
    intersections = dataHoldings.holdingsChartStats
      .map((d, i) =>
        intersect(
          i,
          d.costBasis,
          i + 1,
          dataHoldings.holdingsChartStats[i + 1]?.costBasis,
          i,
          d.portfolioValue,
          i + 1,
          dataHoldings.holdingsChartStats[i + 1]?.portfolioValue
        )
      )
      .filter((d) => d && !isNaN(d.x));
    // filtering out segments without intersections & duplicates (in case end current 2 segments are also
    // start of 2 next segments)
    filteredIntersections = intersections.filter(
      (d, i) =>
        i === intersections.length - 1 || d.x !== intersections[i - 1]?.x
    );
  }

  
  const maxCostBasis = Math.max(...dataHoldings.holdingsChartStats.map(data => data.costBasis));
  const maxPortfolioValue = Math.max(...dataHoldings.holdingsChartStats.map(data => data.portfolioValue));
  const max = Math.max(maxCostBasis,maxPortfolioValue);
  const yMax = getMaxInterval(max);
  
  const formatYAxisLabel = (val) => {
    return "$ " + (Math.abs(val) / 1000).toFixed(1) + "K";
  };

  const [valueCompare, setValueCompare] = useState({
    portfolioValue: null,
    costBasis: null,
  });

  const renderCustomLabel = (props, i) => {
    const { x, y, value, index } = props;
    

    return (
      <text
        key={i}
        x={x}
        y={y}
        dx={10}
        dy={
          value ==
          Math.min(
            dataHoldings.holdingsChartStats[index].portfolioValue,
            dataHoldings.holdingsChartStats[index].costBasis
          )
            ? 10
            : -3
        }
        fill="#000"
        fontSize={
          filterValue == 24
            ? 9
            : width < 750 && filterValue == 24
            ? 4
            : width < 750 && filterValue == 12
            ? 10
            : 12
        }
        textAnchor="end"
      >
         {value === 0 ? "" : value < 100 ? value :  Number.isInteger(Math.abs(value) / 1000)
          ? `$${Math.abs(value) / 1000} k`
          : `$${(Math.abs(value) / 1000).toFixed(1)} k`}
      </text>
    );
  };

  const width = GetWindowWidth();

  return (
    <div
      className={`chart-container ${
        valueCompare.portfolioValue > valueCompare.costBasis
          ? "portfolio-up"
          : "cost-up"
      }`}
    >
      {dataHoldings.holdingsChartLoading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
            }}
          />
        </Box>
      )}
      <ResponsiveContainer>
        <ComposedChart
          data={dataWithRange}
          margin={{
            top: 20,
            right: 25,
            bottom: 0,
            left: -6,
          }}
          style={{
            fontFamily: "Nunito",
            color: "#000",
            fontWeight: "700",
            whiteSpace: "nowrap",
            fill: "var(--colorWhite)",
          }}
        >
          <defs>
            <linearGradient id="colorGradient">
              {filteredIntersections && filteredIntersections.length ? (
                filteredIntersections.map((intersection, i) => {
                  const nextIntersection = filteredIntersections[i + 1];

                  let closeColor = "";
                  let startColor = "";

                  const isLast = i === filteredIntersections.length - 1;

                  if (isLast) {
                    closeColor = getIntersectionColor(intersection);
                    startColor = getIntersectionColor(intersection, true);
                  } else {
                    closeColor = getIntersectionColor(intersection);
                    startColor = getIntersectionColor(nextIntersection);
                  }

                  const offset =
                    intersection.x /
                    (dataHoldings.holdingsChartStats.filter(
                      (d) =>
                        d.costBasis !== undefined &&
                        d.portfolioValue !== undefined
                    ).length -
                      1);

                  return (
                    <React.Fragment key={`gradient-${i}`}>
                      <stop
                        offset={offset}
                        stopColor={closeColor}
                        stopOpacity={1.0}
                      />
                      <stop
                        offset={offset}
                        stopColor={startColor}
                        stopOpacity={1.0}
                      />
                    </React.Fragment>
                  );
                })
              ) : (
                <stop
                  offset={0}
                  stopColor={
                    dataHoldings.holdingsChartStats &&
                    dataHoldings.holdingsChartStats.length &&
                    dataHoldings.holdingsChartStats[0].costBasis >
                      dataHoldings.holdingsChartStats[0].portfolioValue
                      ? "var(--color14)"
                      : "var(--color1)"
                  }
                  stopOpacity={1}
                />
              )}
            </linearGradient>

            <linearGradient id="colorGradientLine">
              {filteredIntersections && filteredIntersections.length ? (
                filteredIntersections.map((intersection, i) => {
                  const nextIntersection = filteredIntersections[i + 1];

                  let closeColor = "";
                  let startColor = "";

                  const isLast = i === filteredIntersections.length - 1;

                  if (isLast) {
                    closeColor = getIntersectionColorLine(intersection);
                    startColor = getIntersectionColorLine(intersection, true);
                  } else {
                    closeColor = getIntersectionColorLine(intersection);
                    startColor = getIntersectionColorLine(nextIntersection);
                  }

                  const offset =
                    intersection.x /
                    (dataHoldings.holdingsChartStats.filter(
                      (d) =>
                        d.costBasis !== undefined &&
                        d.portfolioValue !== undefined
                    ).length -
                      1);

                  return (
                    <React.Fragment key={`gradient-${i}`}>
                      <stop
                        offset={offset}
                        stopColor={closeColor}
                        stopOpacity={1.0}
                      />
                      <stop
                        offset={offset}
                        stopColor={startColor}
                        stopOpacity={1.0}
                      />
                    </React.Fragment>
                  );
                })
              ) : (
                <stop
                  offset={0}
                  stopColor={
                    dataHoldings.holdingsChartStats &&
                    dataHoldings.holdingsChartStats.length &&
                    dataHoldings.holdingsChartStats[0].costBasis >
                      dataHoldings.holdingsChartStats[0].portfolioValue
                      ? "#EE4B2B"
                      : "#0000FF"
                  }
                  stopOpacity={1}
                />
              )}
            </linearGradient>
          </defs>
          <XAxis
            dataKey={(data) =>
              `${monthName[data.month - 1]}, ${data.year
                .toString()
                .substring(2)}`
            }
            strokeWidth={1}
            padding={{ left: 30, right: 0 }}
            tickLine={{ stroke: "#000", y2: 20 }}
            tickSize={10}
            tickMargin={5}
            axisLine={{ stroke: "#000" }}
            angle={
              width < 650 &&
              dataHoldings.holdingsChartStats.length < 10 &&
              dataHoldings.holdingsChartStats.length > 5
                ? -35
                : width < 1700 &&
                  width >= 1250 &&
                  dataHoldings.holdingsChartStats.length > 10
                ? -25
                : width < 1250 &&
                  width >= 600 &&
                  dataHoldings.holdingsChartStats.length > 10
                ? -35
                : width < 600 && dataHoldings.holdingsChartStats.length > 10
                ? -45
                : 0
            }
            interval={0}
            textAnchor={
              dataHoldings.holdingsChartStats.length > 10 && width < 1700
                ? "end"
                : width < 650 &&
                  dataHoldings.holdingsChartStats.length < 10 &&
                  dataHoldings.holdingsChartStats.length > 5
                ? "end"
                : "middle"
            }
            height={
              width < 1700 &&
              width >= 650 &&
              dataHoldings.holdingsChartStats.length < 10 &&
              dataHoldings.holdingsChartStats.length > 5
                ? 43
                : width < 650 &&
                  dataHoldings.holdingsChartStats.length < 10 &&
                  dataHoldings.holdingsChartStats.length > 5
                ? 68
                : dataHoldings.holdingsChartStats.length > 10
                ? 52
                : undefined
            }
            style={{
              whiteSpace: "nowrap",
              fontSize:
                width < 1300 &&
                width >= 650 &&
                dataHoldings.holdingsChartStats.length < 10 &&
                dataHoldings.holdingsChartStats.length > 5
                  ? 12
                  : width < 650 &&
                    dataHoldings.holdingsChartStats.length < 10 &&
                    dataHoldings.holdingsChartStats.length > 5
                  ? 12
                  : width < 1700 &&
                    width >= 900 &&
                    dataHoldings.holdingsChartStats.length > 10
                  ? 10
                  : width < 900 && dataHoldings.holdingsChartStats.length > 10
                  ? 8
                  : 12,
            }}
          />
          <Tooltip
            formatter={(value, name, props, index) => {
              const customNames = ["Range", "Cost Basis", "Portfolio Value"];

              const formattedName = customNames[index];
              const formattedValue = `$ ${(Math.abs(value) / 1000).toFixed(
                1
              )}k`;

              if (
                props.payload.portfolioValue !== valueCompare.portfolioValue
              ) {
                setValueCompare({ portfolioValue: props.payload.portfolioValue, costBasis: props.payload.costBasis});
              }

              return [formattedValue, formattedName];
            }}
            animationDuration={200}
          />
          <CartesianGrid
            strokeDasharray="6 6"
            horizontal={false}
            layer="back"
          />
          <YAxis
            tickCount={6}
            allowDecimals={false}
            strokeWidth={1}
            tickFormatter={formatYAxisLabel}
            padding={{ top: 20 }}
            tickLine={{ stroke: "#000" }}
            axisLine={{ stroke: "#000" }}
            style={{
              fontSize: "var(--fontSize10)",
            }}
            domain={[0,yMax]}
          />
          <defs>
            <filter
              id="areaShadow"
              x="-20%"
              y="-20%"
              width="140%"
              height="140%"
            >
              <feDropShadow
                dx="0"
                dy="2"
                stdDeviation="4"
                floodColor="var(--colorBlack)"
                floodOpacity="0.5"
              />
            </filter>
          </defs>
          <Area
            dataKey="range"
            strokeWidth={0}
            fill="url(#colorGradient)"
            isAnimationActive={false}
            layer="front"
            filter="url(#areaShadow)"
          ></Area>
          <Line
            type="linear"
            dataKey="costBasis"
            strokeWidth={1}
            stroke="var(--colorBlack)"
            dot={false}
            isAnimationActive={false}
          >
            <LabelList content={renderCustomLabel} position="top" />
          </Line>
          <Line
            type="linear"
            dataKey="portfolioValue"
            strokeWidth={1}
            dot={false}
            isAnimationActive={false}
            stroke={`url(#colorGradientLine)`} // Use the first gradient
          >
            <LabelList content={renderCustomLabel} position="bottom" />
          </Line>
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default HoldingsChart;

import React, { useState, useRef, useEffect } from "react";
import PencilIcon from "../../assets/images/pencil2.svg";
import { usdFormate } from "src/global/central";

const KeywordsFinancial = ({ data, handleEdit, allData }) => {
  const [expanded, setExpanded] = useState(false);

  const keywordRowRef = useRef(null);

  const handleKeywordCountClick = () => {
    setExpanded(!expanded);
  };

  const handleOutsideClick = (event) => {
    if (
      keywordRowRef.current &&
      !keywordRowRef.current.contains(event.target)
    ) {
      setExpanded(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  if (allData.generalSection.specificGoalType) {
    return (
      <div className={`li ${expanded ? "expanded" : ""}`}>
        <div className="keywordMain">
          <div className="keywordRow" ref={keywordRowRef}>
            <div className="keyword">
              Total Cost : {usdFormate(data.estimatedCollegeCost)}
            </div>
            <div className="keyword">
              From Savings : {usdFormate(data.fundingViaSavings)}
            </div>
            <div className="keyword">
              From Scholarships : {usdFormate(data.fundingViaScholarships)}
            </div>
            <div className="keyword">
              From Loans : {usdFormate(data.fundingViaLoans)}
            </div>
            <div
              hidden={expanded}
              className="keywordCount keyword"
              onClick={handleKeywordCountClick}
            >
              ...
            </div>
          </div>
        </div>
        <img
          onClick={() => handleEdit(allData, 3)}
          className="pencil"
          src={PencilIcon}
          alt=""
        />
      </div>
    );
  } else {
    return (
      <div className={`li ${expanded ? "expanded" : ""}`}>
        <div className="keywordMain">
          <div className="keywordRow" ref={keywordRowRef}>
            <div className="keyword">
              Total Cost : {usdFormate(allData.freeFormDetailsSection.goalTargetAmount)}
            </div>
            <div
              hidden={expanded}
              className="keywordCount keyword"
              onClick={handleKeywordCountClick}
            >
              ...
            </div>
          </div>
        </div>
        <img
          onClick={() => handleEdit(allData, 3)}
          className="pencil"
          src={PencilIcon}
          alt=""
        />
      </div>
    );
  }
};

export default KeywordsFinancial;

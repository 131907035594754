import React, { useEffect } from "react";
import BackIcon from "../../assets/images/back.svg";
import useSpendByCategory from "./useSpendByCategory";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  atm_IsCategoryClicked,
  atm_categoryTab,
} from "src/state/insightsCategories";
import { LinearProgress } from "@mui/material";
import MoM from "./MoM";
import ByLocation from "./ByLocation";

const InsightsData = () => {
  const { data } = useSpendByCategory();

  const setIsCategoryClicked = useSetRecoilState(atm_IsCategoryClicked);

  const [tab, setTab] = useRecoilState(atm_categoryTab);

  useEffect(() => {
    return () => {
      setTab(1);
    };
  }, []);

  return (
    <div className="insights-data">
      <div className="title mobile">
        <img
          onClick={() => setIsCategoryClicked(false)}
          src={BackIcon}
          alt=""
        />
        <span>
          {data.selectedCategoryValue[0]} - {data.selectedCategoryValue[1]}
        </span>
      </div>
      <header>
        <ul>
          <li
            onClick={() => setTab(1)}
            className={tab === 1 ? "active" : undefined}
          >
            Month on Month
          </li>
          <li
            onClick={() => setTab(2)}
            className={tab === 2 ? "active" : undefined}
          >
            By Location
          </li>
          <li style={{opacity: 0, cursor: "default"}}></li>
          <li style={{opacity: 0, cursor: "default"}}></li>
        </ul>
      </header>
      <div className="insights-data-inside">
        <header className="head">
          <span>Insights - Spend by Category</span>
        </header>

        <div className="scrollable-content scroller">
          {tab === 1 && <MoM />}
          {tab === 2 && <ByLocation />}
        </div>
      </div>
    </div>
  );
};

export default InsightsData;

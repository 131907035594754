import React, { useEffect, useRef, useState } from "react";

import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";

import PencilLogo from "../../../../assets/images/pencil2.svg";
import DeleteLogo from "../../../../assets/images/delete.svg";
import EditActivity from "./EditActivity";
import { atm_goalActivities } from "src/state/atomDashboard";
import { useSetRecoilState } from "recoil";

const shortMonthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const ActivitiesMileStones = ({ data, index }) => {
  const shortMonth = shortMonthNames[data.activityMonth - 1];
  const currentElem = useRef();
  const [isEdit, setIsEdit] = useState(null);

  const setGoalActivities = useSetRecoilState(atm_goalActivities);

  const handleDelete = (index) => {
    setGoalActivities((prevGoalActivities) =>
      prevGoalActivities.map((activity, i) =>
        i === index ? { ...activity, status: 1 } : activity
      )
    );
  };

  return (
    <TimelineItem ref={currentElem} key={index}>
      <TimelineOppositeContent className="milestoneDate">
        {`${data.activityDate} ${shortMonth} ${data.activityYear}`}
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot className={`dot done`} />
        <TimelineConnector className={`connector done`} />
      </TimelineSeparator>
      <TimelineContent
        sx={{ display: "flex", justifyContent: "space-between" }}
        className="milestoneText"
      >
        {data.activityName}
        <div className="editOptions">
          <img
            onClick={(e) => {
              setIsEdit(index);
            }}
            src={PencilLogo}
            alt=""
          />
          <img onClick={() => handleDelete(index)} src={DeleteLogo} alt="" />
          <EditActivity
            activity={data}
            isEdit={isEdit}
            index={index}
            anchor={currentElem.current}
            setIsEdit={setIsEdit}
          />
        </div>
      </TimelineContent>
    </TimelineItem>
  );
};

export default ActivitiesMileStones;

import { Done } from "@mui/icons-material";
import {
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { atm_editGoal, atm_selectedGeneralData } from "src/state/atomDashboard";
import AddNewMemberBox from "./AddNewMemberBox";

const SelectFamily = ({ data }) => {
  const [generalData, setGeneralData] = useRecoilState(atm_selectedGeneralData);

  const [addNewMemberBoxOpen, setAddNewMemberBoxOpen] = useState(false);
  const isEditMode = useRecoilValue(atm_editGoal);
  const isMobile = useMediaQuery("(max-width: 750px)")

  const handleChange = (event) => {
    const selectedValue = +event.target.value;
    const isExist = generalData.familyMembers.includes(selectedValue);

    if (isExist) {
      setGeneralData((prevData) => ({
        ...prevData,
        familyMembers: prevData.familyMembers.filter(
          (id) => id !== selectedValue
        ),
      }));
    } else {
      setGeneralData((prevData) => ({
        ...prevData,
        familyMembers: [...prevData.familyMembers, selectedValue],
      }));
    }
  };

  return (
    <FormControl
      className="selectFamilyMember select"
      variant="standard"
      sx={{ minWidth:  190, maxWidth: !isMobile ? 250 : 'unset', textAlign: "center", mr: 3 }}
    >
      <InputLabel
        id="labelFamilyMembers"
        sx={{
          fontSize: 12,
          textAlign: "center",
          left: 5,
          top: -12,
        }}
      ></InputLabel>
      <Select
        disabled={isEditMode}
        className="selectFamilyMember"
        labelId="labelFamilyMembers"
        id="familyMembers"
        multiple
        required
        value={generalData.familyMembers
          .map((id) => data.find((obj) => obj.id === id)?.value)
          .filter(Boolean)}
        renderValue={(selected) => selected.join(", ")}
        sx={{
          paddingBottom: 0,
          textAlign: "center",
          m: 0,
          maxWidth: "62vw",
        }}
      >
        {data.map((option, index) => [
          <MenuItem key={index} value={option.id}>
            <div className="checkbox" style={{ marginRight: 10 }}>
              <input
                hidden
                value={option.id}
                id={`familyMember${index + 1}`}
                type="checkbox"
                defaultChecked={generalData.familyMembers.includes(option.id)}
                onChange={handleChange}
              />
              <label htmlFor={`familyMember${index + 1}`}>
                <Done
                  fontSize="small"
                  style={{ color: "var(--colorWhite)", fontSize: 13 }}
                />
              </label>
            </div>

            {option.value}
          </MenuItem>,
          <Divider key={`divider-${index}`} sx={{ mx: "10px" }} />,
        ])}
        {!addNewMemberBoxOpen && (
          <MenuItem
            onClick={() => setAddNewMemberBoxOpen(true)}
            className="addNewMemberBtn"
          >
            +Add new member
          </MenuItem>
        )}
        {addNewMemberBoxOpen && (
          <div className="addNewMemberBoxMain">
            {/* <div className="head">Add New Member</div>
            <div className="icons">
              <img
                onClick={() => setAddNewMemberBoxOpen(false)}
                src={DoneIcon}
                alt=""
              />
              <img
                onClick={() => setAddNewMemberBoxOpen(false)}
                src={CloseIcon}
                alt=""
              />
            </div> */}
            <AddNewMemberBox setAddNewMemberBoxOpen={setAddNewMemberBoxOpen} />
          </div>
        )}
      </Select>
    </FormControl>
  );
};

export default SelectFamily;

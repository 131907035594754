import DoneIcon from "../../../../assets/images/done.svg";
import CloseIcon from "../../../../assets/images/close3.svg";
import { Box, MenuItem, TextField } from "@mui/material";
import React, { useState } from "react";
import useDashboard from "src/components/Dashboard/useDashboard";

const AddNewMemberBox = ({ setAddNewMemberBoxOpen }) => {
  const { dataDashboard, fnsDashboard } = useDashboard();
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const [newFamilyMemberDetails, setNewFamilyMemberDetails] = useState({
    relationshipActive: true,
    firstName: "",
    lastName: "",
    dayOfBirth: "",
    dayOfMonth: "",
    dayOfYear: "",
    relationshipToPrimary: "",
    zipCode: "",
    phoneNumber: "",
  });

  const [validationErrors, setValidationErrors] = useState({});

  const handleFieldChange = (e) => {
    let name = e.target.name;
    let value =
      typeof e.target.value === "string"
        ? e.target.value.trim()
        : e.target.value;

    if (value) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }

    if (
      name === "phoneNumber" &&
      value === "" &&
      validationErrors.phoneNumber
    ) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, phoneNumber: "" }));
    }

    if (isNaN(+value)) {
      setNewFamilyMemberDetails((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else {
      setNewFamilyMemberDetails((prevData) => ({
        ...prevData,
        [name]: +value,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!newFamilyMemberDetails.firstName) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        firstName: "First Name is required",
      }));
    }
    if (!newFamilyMemberDetails.relationshipToPrimary) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        relationshipToPrimary: "Relationship is required",
      }));
    }
    if (newFamilyMemberDetails.zipCode.toString().length < 5) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        zipCode: "Zip Code must be 5 digits",
      }));
    }
    if (
      newFamilyMemberDetails.phoneNumber.toString().length > 0 &&
      newFamilyMemberDetails.phoneNumber.toString().length < 10
    ) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        phoneNumber: "Phone Number must be 10 digits",
      }));
    }

    if (
      !newFamilyMemberDetails.firstName ||
      !newFamilyMemberDetails.relationshipToPrimary ||
      newFamilyMemberDetails.zipCode.toString().length < 5 ||
      (newFamilyMemberDetails.phoneNumber &&
        newFamilyMemberDetails.phoneNumber.toString().length > 0 &&
        newFamilyMemberDetails.phoneNumber.toString().length < 10)
    ) {
      return;
    }

    fnsDashboard.saveHousehold({
      ...newFamilyMemberDetails,
      phoneNumber:
        newFamilyMemberDetails.phoneNumber == 0
          ? ""
          : newFamilyMemberDetails.phoneNumber,
    });
    setAddNewMemberBoxOpen(false);
  };

  return (
    <Box
      component={"form"}
      className="addNewMemberBox"
      onSubmit={handleSubmit}
      autoComplete="off"
    >
      <div className="head">Add New Member</div>

      <TextField
        sx={{
          ".MuiInputBase-root": {
            fontSize: 12,
          },
          ".MuiFormLabel-root": {
            fontSize: 12,
          },
          ".MuiFormHelperText-root": {
            fontSize: 10,
            ml: 1,
          },
        }}
        size="small"
        onKeyDown={(e) => e.stopPropagation()}
        // placeholder="First Name"
        name="firstName"
        id="selectFName"
        label="First Name"
        onInput={(e) => {
          e.target.value = e.target.value.replace(/[^a-zA-Z]/g, "");
        }}
        onChange={handleFieldChange}
        helperText={validationErrors.firstName}
        error={Boolean(validationErrors.firstName)}
        multiline
        autoFocus
      />
      <TextField
        sx={{
          ".MuiInputBase-root": {
            fontSize: 12,
          },
          ".MuiFormLabel-root": {
            fontSize: 12,
          },
        }}
        size="small"
        onKeyDown={(e) => e.stopPropagation()}
        name="lastName"
        id="selectLName"
        label="Last Name"
        onInput={(e) => {
          e.target.value = e.target.value.replace(/[^a-zA-Z]/g, "");
        }}
        onChange={handleFieldChange}
        multiline
      />

      <div className="dobField" style={{ height: 34 }}>
        <TextField
          variant="standard"
          sx={{
            width: 50,
            ".MuiInputBase-root": {
              fontSize: 12,
            },
            input: {
              paddingBottom: "3px",
            },
            "input::placeholder": {
              fontSize: 10,
            },
          }}
          size="small"
          onKeyDown={(e) => e.stopPropagation()}
          name="dayOfBirth"
          id="selectDayOfBirth"
          placeholder="DD"
          onChange={handleFieldChange}
          type="tel"
          inputProps={{ maxLength: 2 }}
        />
        <div className="monthCover">
          <label
            hidden={setNewFamilyMemberDetails.monthOfBirth}
            style={{ fontSize: 10 }}
          >
            MM
          </label>
          <TextField
            defaultValue=""
            variant="standard"
            sx={{
              width: 50,
              ".MuiInputBase-root": {
                fontSize: 12,
              },
              ".MuiFormLabel-root": {
                fontSize: 10,
              },
            }}
            size="small"
            name="monthOfBirth"
            id="selectMonthOfBirth"
            onChange={handleFieldChange}
            select
          >
            {monthNames.map((month, index) => (
              <MenuItem key={index} value={index + 1}>
                {month}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <TextField
          variant="standard"
          sx={{
            width: 50,
            ".MuiInputBase-root": {
              fontSize: 12,
            },
            input: {
              paddingBottom: "3px",
            },
            "input::placeholder": {
              fontSize: 10,
            },
          }}
          size="small"
          onKeyDown={(e) => e.stopPropagation()}
          name="yearOfBirth"
          id="selectYearOfBirth"
          onChange={handleFieldChange}
          placeholder="YYYY"
          type="number"
          onInput={(e) => {
            e.target.value = Math.max(0, parseInt(e.target.value))
              .toString()
              .slice(0, 4);
          }}
        />
      </div>

      <TextField
        sx={{
          ".MuiInputBase-root": {
            fontSize: 12,
          },
          ".MuiFormLabel-root": {
            fontSize: 12,
          },
          ".MuiFormHelperText-root": {
            fontSize: 10,
            ml: 1,
          },
        }}
        size="small"
        name="relationshipToPrimary"
        defaultValue=""
        onChange={handleFieldChange}
        id="selectRalationShip"
        select
        label="Relationship"
        helperText={validationErrors.relationshipToPrimary}
        error={Boolean(validationErrors.relationshipToPrimary)}
      >
        {dataDashboard.relationShipTypes.map((option, index) => (
          <MenuItem key={index} value={option.id}>
            {option.value}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        sx={{
          ".MuiInputBase-root": {
            fontSize: 12,
          },
          ".MuiFormLabel-root": {
            fontSize: 12,
          },
          ".MuiFormHelperText-root": {
            fontSize: 10,
            ml: 1,
          },
        }}
        size="small"
        onKeyDown={(e) => e.stopPropagation()}
        name="zipCode"
        id="selectZipName"
        label="Zip Code"
        onChange={handleFieldChange}
        inputProps={{ type: "number" }}
        onInput={(e) => {
          e.target.value = Math.max(0, parseInt(e.target.value))
            .toString()
            .slice(0, 5);
        }}
        helperText={validationErrors.zipCode}
        error={Boolean(validationErrors.zipCode)}
      />

      <TextField
        sx={{
          ".MuiInputBase-root": {
            fontSize: 12,
          },
          ".MuiFormLabel-root": {
            fontSize: 12,
          },
          ".MuiFormHelperText-root": {
            fontSize: 10,
            ml: 1,
          },
        }}
        size="small"
        onKeyDown={(e) => e.stopPropagation()}
        name="phoneNumber"
        id="selectPhoneNumber"
        label="Phone Number"
        onChange={handleFieldChange}
        inputProps={{ type: "number" }}
        onInput={(e) => {
          e.target.value = Math.max(0, parseInt(e.target.value))
            .toString()
            .slice(0, 10);
        }}
        helperText={validationErrors.phoneNumber}
        error={Boolean(validationErrors.phoneNumber)}
      />

      <div className="icons">
        <button style={{ all: "unset" }} type="submit">
          <img src={DoneIcon} alt="" />
        </button>
        <img
          onClick={() => setAddNewMemberBoxOpen(false)}
          src={CloseIcon}
          alt=""
        />
      </div>
    </Box>
  );
};

export default AddNewMemberBox;

import React, { useEffect } from "react";
import "../../assets/css/insights.css";
import SidebarCategory from "./SidebarCategory";
import InsightsData from "./InsightsData";
import insightsBg from "../../assets/images/bg-insights.png";

import useSpendByCategory from "./useSpendByCategory";
import { GetWindowWidth, getAuthToken } from "src/global/central";

import {
  atm_globalFilterValue,
} from "../../../state/atoms";
import {
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import { useNavigate } from "react-router-dom";
import FiltersCat from "./FiltersCat";
import {
  atm_IsMobCatFilter,
  atm_SelectedCatDateFilter,
  atm_categoryParentSelectors,
  atm_categoryTab,
  atm_globalCategory,
} from "src/state/insightsCategories";
import MetaTags from "src/components/shared/MetaTags";

const SpendByCategory = () => {
  const navigate = useNavigate();

  const { data, fns } = useSpendByCategory();

  const jwt = getAuthToken();

  const width = GetWindowWidth();

  const [tab, setTab] = useRecoilState(atm_categoryTab);

  const setIsCatDateFilter = useSetRecoilState(atm_IsMobCatFilter);

  const setSelectedDateFilterState = useSetRecoilState(
    atm_SelectedCatDateFilter
  );

  const globalFilterValue = useRecoilValue(atm_globalFilterValue);

  useEffect(() => {
    fns.getCategoryListData();

    return () => {
      setSelectedDateFilterState(12);
      setTab(1);
      setIsCatDateFilter(false);
    };
  }, []);

  const globalCategory = useRecoilValue(atm_globalCategory);

  const setCategoryParentSelectors = useSetRecoilState(
    atm_categoryParentSelectors
  );

  useEffect(() => {
    setCategoryParentSelectors({
      mom: tab === 1 || null,
      byLocation: tab === 2 || null,
    });

  }, [globalFilterValue, globalCategory]);

  if (jwt != "") {
    return (
      <main className="insights insights-category">
        <MetaTags index="InsightsByCategory" />
        <img src={insightsBg} alt="" className="insightsBg" />

        <div className="row2">
          <FiltersCat />
          {width > 900 ? (
            <>
              <SidebarCategory />
              <InsightsData />
            </>
          ) : data.isCategoryClicked && width < 900 ? (
            <InsightsData />
          ) : (
            <SidebarCategory />
          )}
        </div>
      </main>
    );
  } else {
    navigate("/login");
  }
};

export default SpendByCategory;

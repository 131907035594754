import React, { useState } from "react";
import AlertBell from "../assets/images/alert-bell.svg";
import useAccountList from "./useAccountList";
import { Box, LinearProgress } from "@mui/material";
import PlaidLinkButton from "./PlaidLinkButton";
import { Col, Row, Text } from "../shared";

const ProblemAccounts = () => {
  const [clickedAccount, setClickedAccount] = useState(null);

  const { data, fns } = useAccountList();
  const onSuccess = () => {
    fns.refreshAccountBalanceDataFromProvider(true);
  };

  const handleUserClick = (index) => {
    setClickedAccount(index);
  };
  return (
    <div className="problemAccounts">
      <div className="head">
        <img src={AlertBell} alt="alert" /> Needs Attention
      </div>
      <div className="scroller">
        {data?.accounts?.problemAccounts?.map((values, index) => (
          <div
            key={index}
            className={`problemAccount ${clickedAccount === index && "active"}`}
          >
            {data.reconnectLoading && (
              <Box sx={{ width: "100%" }}>
                <LinearProgress
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                  }}
                />
              </Box>
            )}
            <div className="infoCover">
              <div
                onClick={() => handleUserClick(index)}
                className="accountInfo"
              >
                <div className="fi">{values.fiName}</div>
                <Row justify="between" align="center">
                  <Text size={14} className="lastUpdated">
                    Last Updated :{" "}
                    {new Date(values.lastUpdateDate).toLocaleDateString(
                      "en-GB",
                      {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                      }
                    )}
                  </Text>
                  <PlaidLinkButton
                    className="reconnectBtn"
                    getToken={() =>
                      fns.getAccessTokenForProblemItem(values.userItemId)
                    }
                    onSuccess={onSuccess}
                  >
                    Re-Connect
                  </PlaidLinkButton>
                </Row>
              </div>
              {clickedAccount === index && values.accountDetails?.length && <Col gap={3} className="accountDetails">
                {values.accountDetails.map((data, idx) => (
                    <div key={idx} className="accountNameNumber">
                      {data.accountName} - ****{data.accountNumberMask}
                    </div>
                  ))}
              </Col>}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProblemAccounts;

import { atom } from "recoil";

export const atm_passwordLoading = atom({
  key: "passwordLoading",
  default: false,
});

export const atm_resetEmail = atom({
  key: "resetEmail",
  default: "",
});

export const atm_isCodeSent = atom({
  key: "isCodeSent",
  default: false,
});

export const atm_resetCode = atom({
  key: "resetCode",
  default: "",
});

export const atm_responseMessage = atom({
  key: "responseMessage",
  default: {
    codeValidation: "",
    resetPassword: "",
  },
});

export const atm_forgotPasswordErrors = atom({
  key: "forgotPasswordErrors",
  default: {
    codeError: false,
  },
});

import React from 'react';

import { createTheme } from '@mui/material/styles';

const globalTheme = createTheme({
	palette: {
		mode: 'light'
	},
	typography: {
		fontFamily: 'Nunito',
		fontWeightBold: 700
	},
	customShadows: {}
});

const questTheme = createTheme(
	{
		components: {
			MuiButtonBase: {
				defaultProps: {
					disableRipple: true
				}
			}
		}
	},
	globalTheme
);

export default questTheme;

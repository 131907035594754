const Text = ({
  children,
  i,
  b,
  className = "",
  onClick,
  size,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  weight = 400,
  style
}) => {
  const customStyle = {
    fontSize: `${size}px`,
    fontStyle: i && "italic",
    fontWeight: b ? 700 : weight,
    ...style
  };

  if (h1)
    return (
      <h1 className={className} onClick={onClick} style={{ ...customStyle }}>
        {children}
      </h1>
    );

  if (h2)
    return (
      <h2 className={className} onClick={onClick} style={{ ...customStyle }}>
        {children}
      </h2>
    );

  if (h3)
    return (
      <h3 className={className} onClick={onClick} style={{ ...customStyle }}>
        {children}
      </h3>
    );

  if (h4)
    return (
      <h4 className={className} onClick={onClick} style={{ ...customStyle }}>
        {children}
      </h4>
    );

  if (h5)
    return (
      <h5 className={className} onClick={onClick} style={{ ...customStyle }}>
        {children}
      </h5>
    );

  if (h6)
    return (
      <h6 className={className} onClick={onClick} style={{ ...customStyle }}>
        {children}
      </h6>
    );

  return (
    <span className={className} onClick={onClick} style={{ ...customStyle }}>
      {children}
    </span>
  );
};

export default Text;

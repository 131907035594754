import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import useTransactionsLanding from "./useTransactionsLanding";
import {
  GetWindowHeight,
  GetWindowWidth,
  getMaxInterval,
  usdFormate,
} from "src/global/central";
import { Box, LinearProgress } from "@mui/material";

const TransactionChart = () => {
  const { dataTransactions } = useTransactionsLanding();
  const width = GetWindowWidth();
  const height = GetWindowHeight();

  const monthName = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const formattedCategories = dataTransactions.transactionChartStats.map(
    (item) => {
      const monthIndex = item.month - 1;
      const month = monthName[monthIndex];
      const year = item.year.toString().substring(2);
      return month + ", " + year;
    }
  );

  const [series, setSeries] = useState([
    {
      name: "Income",
      data: [],
    },
    {
      name: "Expense",
      data: [],
    },
    {
      name: "Cash Flow",
      data: [],
      type: "line",
    },
  ]);
  const [maxIncomeValue, setMaxIncomeValue] = useState(12); // Initialize with default value
  useEffect(() => {
    if (dataTransactions.transactionChartStats.length) {
      let incomeData = dataTransactions.transactionChartStats.map(
        (item) => item.income
      );
      let expenseData = dataTransactions.transactionChartStats.map(
        (item) => item.expense
      );
      let cashFlowData = dataTransactions.transactionChartStats.map(
        (item) => item.cashFlow
      );
      setSeries([
        {
          name: "Income",
          type: "bar",
          data: incomeData,
        },
        {
          name: "Expense",
          type: "bar",
          data: expenseData,
        },
        {
          name: "Cash Flow",
          data: cashFlowData,
          type: "line",
        },
      ]);

      const maxIncome = Math.max(...incomeData);
      const maxExpense = Math.max(...expenseData);
      const maxCashFlow = Math.max(...cashFlowData);

      const minIncome = Math.min(...incomeData);
      const minExpense = Math.min(...expenseData);
      const minCashFlow = Math.min(...cashFlowData);

      let maxValue = Math.max(
        Math.abs(maxIncome),
        Math.abs(maxExpense),
        Math.abs(maxCashFlow),
        Math.abs(minIncome),
        Math.abs(minExpense),
        Math.abs(minCashFlow)
      );
      setMaxIncomeValue(maxValue);
    } else {
      setSeries([
        {
          name: "Income",
          data: [0],
        },
        {
          name: "Expense",
          data: [0],
        },
        {
          name: "Cash Flow",
          data: [0],
          type: "line",
        },
      ]);
      setMaxIncomeValue(5);
    }
  }, [dataTransactions.transactionChartStats]);

  const yaxisMax = getMaxInterval(maxIncomeValue);
  const yaxisMin = -yaxisMax;

  const options = {
    chart: {
      events: {
        dataPointMouseEnter: function (event, chartContext, config) {
          let labelsLength = document
            .querySelectorAll(".apexcharts-datalabels")[1]
            .querySelectorAll(".apexcharts-data-labels").length;
          if (width < 900) {
            let labelUp = document
              .querySelectorAll(".apexcharts-datalabels")[0]
              .querySelectorAll(".apexcharts-data-labels")[
              config.dataPointIndex
            ];

            let labelDown = document
              .querySelectorAll(".apexcharts-datalabels")[1]
              .querySelectorAll(".apexcharts-data-labels")[
              config.dataPointIndex
            ];

            if (labelUp) {
              labelUp.style.opacity = 1;
            }
            if (labelDown) {
              labelDown.style.opacity = 1;
            }
          }
        },
        dataPointMouseLeave: function (event, chartContext, config) {
          if (width < 900) {
            let labelUp = document
              .querySelectorAll(".apexcharts-datalabels")[0]
              .querySelectorAll(".apexcharts-data-labels")[
              config.dataPointIndex
            ];
          
            let labelDown = document
              .querySelectorAll(".apexcharts-datalabels")[1]
              .querySelectorAll(".apexcharts-data-labels")[
              config.dataPointIndex
            ];
          
            if (labelUp) {
              labelUp.style.opacity = 0;
            }
            if (labelDown) {
              labelDown.style.opacity = 0;
            }
          }
        },
      },
      animations: {
        enabled: false,
      },
      fontFamily: "Nunito",
      height: "auto",
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      dropShadow: {
        enabled: true,
        top: 1,
        left: 0,
        right: 0,
        blur: width > 900 ? 4 : 2,
        color: "#000",
        opacity: width > 900 ? 0.1 : 0.3,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
      {
        breakpoint: 900,
        options: {
          legend: {
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 5,
        dataLabels: {
          hideOverflowingLabels: false,
          position: "top", // Align data labels at the top of the bars
          enabled: true,
          style: {
            fontSize: "var(--fontSize12)",
            fontWeight: 500,
            colors: ["var(--colorBlack)"], // Set data labels color to black
            rotate: -45,
            width: "100%",
          },
          rotate: -45,
        },
        line: {
          dataLabels: {
            enabled: false,
          },
        },
      },
    },
    dataLabels: {
      enabled: true,
      hideOverflowingLabels: true,
      formatter: function (val, { seriesIndex }) {
        if (seriesIndex === 0 || seriesIndex === 1) {
          // Format labels for "Cash Flow" series
          if (val === 0) {
            return;
          } else if (val > 0 && val < 100) {
            return "$ " + val;
          } else if (val > 100) {
            return "$ " + (val / 1000).toFixed(1) + "k";
          } else if (val < 0 && val > -100) {
            return "-$ " + Math.abs(val);
          } else if (val < -100) {
            return "-$ " + Math.abs(val / 1000).toFixed(1) + "k";
          } else return usdFormate(val);
        }
      },
      style: {
        colors: ["var(--colorBlack)"], // Set data label color to black
        fontWeight: "bold",
        fontSize:
          dataTransactions.transactionChartStats.length > 10 && width > 900
            ? "var(--fontSize8)"
            : dataTransactions.transactionChartStats.length > 10 && width <= 900
            ? "var(--fontSize8)"
            : "var(--fontSize12)",
        rotate: -48,
      },
      position: "top",
      rotate: -48,
      offsetY: -20,
      hideOverlappingLabels: true,
    },

    xaxis: {
      type: "category",
      categories: formattedCategories.length
        ? formattedCategories
        : [`${monthName[new Date().getMonth()]}, ${new Date().getFullYear()}`],
      axisBorder: {
        show: true,
        color: "#000",
        height: 1,
        width: "120%",
        offsetX: -18,
        offsetY: 0,
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "#000",
        height: 12,
        offsetX: 1,
        offsetY: -5,
      },
      labels: {
        offsetY:
          width < 900 &&
          dataTransactions.transactionChartStats.length < 10 &&
          dataTransactions.transactionChartStats.length > 10
            ? -4
            : dataTransactions.transactionChartStats.length >= 10
            ? 3
            : 0,
        offsetX:
          dataTransactions.transactionChartStats.length > 10
            ? 1
            : width < 650 &&
              dataTransactions.transactionChartStats.length < 10 &&
              dataTransactions.transactionChartStats.length > 5
            ? 3
            : 0,
        rotateAlways:
          width < 650 &&
          dataTransactions.transactionChartStats.length < 10 &&
          dataTransactions.transactionChartStats.length > 5
            ? true
            : dataTransactions.transactionChartStats.length > 10
            ? true
            : false,
        rotate:
          width < 650 &&
          dataTransactions.transactionChartStats.length < 10 &&
          dataTransactions.transactionChartStats.length > 5
            ? -35
            : dataTransactions.transactionChartStats.length > 10
            ? -35
            : 0,
        style: {
          fontSize:
            dataTransactions.transactionChartStats.length > 10 && width > 900
              ? "var(--fontSize10)"
              : dataTransactions.transactionChartStats.length > 10 &&
                dataTransactions.transactionChartStats.length <= 15 &&
                width <= 900
              ? "var(--fontSize8)"
              : dataTransactions.transactionChartStats.length > 15 &&
                width <= 650
              ? "var(--fontSize6)"
              : "var(--fontSize10)",
          fontWeight: 700,
        },
        hideOverlappingLabels: false,
      },
    },
    yaxis: {
      max: yaxisMax,
      min: yaxisMin,
      labels: {
        formatter: function (val) {
          if (yaxisMax > -100 && yaxisMax < 100) {
            if (val === 0) {
              return;
            } else if (val > 0) {
              return "$ " + val;
            } else if (val < 0) {
              return "-$ " + Math.abs(val);
            } else return usdFormate(val);
          } else {
            if (val === 0) {
              return;
            } else if (val > 0 && val <= 100) {
              return "$ " + (val / 1000).toFixed(2) + "k";
            } else if (val > 100) {
              return "$ " + (val / 1000).toFixed(1) + "k";
            } else if (val < 0 && val >= -100) {
              return "-$ " + Math.abs(val / 1000).toFixed(2) + "k";
            } else if (val < -100) {
              return "-$ " + Math.abs(val / 1000).toFixed(1) + "k";
            } else return usdFormate(val);
          }
        },
        style: {
          fontSize: "var(--fontSize10)",
          fontWeight: 700,
        },
      },
      axisBorder: {
        show: true,
        color: "#000",
      },
      axisTicks: {
        show: true,
        borderType: "solid",
        color: "#000",
        width: 12,
        offsetX: 6,
        offsetY: 0,
      },
    },
    fill: {
      opacity: 1,
      colors: ["var(--color1)", "var(--color14)"], // Set colors for income and expense bars
    },
    markers: {
      size: 4,
      colors: ["#FBC548"],
      strokeWidth: 0.3,
      strokeColors: ["#000"], // Set border color for the dots
      strokeDashArray: [0, 2], // Set border style for the dots
      hover: {
        sizeOffset: 2,
      },
    },
    stroke: {
      show: true,
      width: [0, 0, 1],
      colors: ["#000"],
      curve: "straight",
    },
    annotations: {
      yaxis: [
        {
          y: 0,
          strokeDashArray: 0,
          borderColor: "var(--colorBlack)",
          label: {
            borderColor: "var(--colorBlack)",
            style: {
              color: "var(--colorWhite)",
              background: "var(--colorBlack)",
            },
            text: "0",
            position: "left",
            offsetX: -12,
            offsetY: 7,
          },
        },
      ],
    },
    grid: {
      show: true,
      borderColor: "var(--colorBlack)", // Set border color for the grid
      strokeDashArray: 0, // Set border style for the grid lines
      position: "back",
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
      row: {
        colors: ["rgba(214, 127, 62, 0.5)", "rgba(255, 250, 250, 0)"],
        opacity: 0.07,
      },
      padding: {
        top: -10,
        right: 18,
        bottom: 0,
        left: 30,
      },
    },
    legend: {
      show: false,
    },
  };

  const customDataLabelStyle = {
    "& text tspan": {
      transform: "rotate(-20deg)", // Adjust the rotation angle as needed
      transformOrigin: "0% 100%", // Adjust the rotation origin as needed
    },
  };

  return (
    <div className={"chart-container"}>
      {dataTransactions.transactionChartStatsLoading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
            }}
          />
        </Box>
      )}

      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={
          dataTransactions.transactionChartStats.length > 10 && height <= 1050
            ? "100%"
            : "100%"
        }
        style={customDataLabelStyle}
      />
    </div>
  );
};

export default TransactionChart;

import React, { useEffect, useState } from "react";
import { TextField, MenuItem, useMediaQuery } from "@mui/material";
import "../assets/css/trends.css";
import { states } from "src/global/central";
import useTrends from "./useTrends";
import TrendsChartContainer from "./TrendsChartContainer";
import { useSetRecoilState } from "recoil";
import { atm_trendsCategories, atm_trendsData } from "src/state/atomTrends";
import MetaTags from "../shared/MetaTags";
import RedditPixel from "../shared/RedittPixel";

const Trends = () => {
  const [userEntry, setUserEntry] = useState({
    zipCode: "",
    city: "",
    state: "",
  });
  const isMobile = useMediaQuery("(max-width: 750px)");
  const [activeTab, setActiveTab] = useState("");
  const { data, fns } = useTrends({ setActiveTab });
  const setTrendsData = useSetRecoilState(atm_trendsData);
  const setCategoryData = useSetRecoilState(atm_trendsCategories);
  const [validationErrors, setValidationErrors] = useState({
    zipCode: "",
    city: "",
    state: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleFieldChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setUserEntry({ ...userEntry, [name]: value });
    setValidationErrors({
      zipCode: "",
      city: "",
      state: "",
    });
  };

  useEffect(() => {
    (async () => {
      let firstCategory = await fns.getTrendsCategories();
      fns.getTrends({ categoryCode: firstCategory || activeTab, ...userEntry });
    })();

    return () => {
      setTrendsData(null);
      setCategoryData([]);
    };
  }, []);

  const isFieldsValid = (type) => {
    let zipLength = userEntry.zipCode.length;
    let cityLength = userEntry.city.length;
    let stateLength = userEntry.state.length;

    if (zipLength > 0 && zipLength < 5 ) {
      setValidationErrors((errors) => ({
        ...errors,
        zipCode: "Zip Code must be 5 digits",
      }));
      return false;
    }

    if (zipLength === 0 && !stateLength && cityLength) {
      setValidationErrors((errors) => ({
        ...errors,
        state: " ",
        zipCode: "Enter either Zip Code or State to proceed",
      }));
      return false;
    }

    if (zipLength === 0 && !stateLength && !cityLength && type === "submit") {
      setValidationErrors((errors) => ({
        ...errors,
        state: "",
        zipCode: "Enter either Zip Code, City/state or State to proceed",
      }));
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    let firstCategory;
    if (
      !data.trendsLoading &&
      isFieldsValid("submit")) {
      setIsSubmitted(true);
      if (!data.trendsCategories.length) {
        firstCategory = await fns.getTrendsCategories();
      }
      fns.getTrends({ categoryCode: firstCategory || activeTab, ...userEntry });
    }
  };

  const handleTabSelection = (code) => {
    setActiveTab(code);
    setTrendsData(null);
    if (isFieldsValid() && activeTab !== code) {
      fns.getTrends({ categoryCode: code, ...userEntry });
    }
  };

  return (
    <div className="trendsPage">
      <MetaTags index="Trends" />
      <RedditPixel event={"Trends"} custom_or_default={1}/>

      <img
        src="/static/media/bg-transaction.9ee82bb60f3ef91391a4.png"
        alt=""
        className="calculatorsBg globalBg"
      ></img>
      <div className="trendsBox">
        <div className="head">
          <div className="head-inside-col">
            <span>Location :</span>
            <TextField
              sx={{
                ".MuiInputBase-root": {
                  fontSize: 12,
                },
                ".MuiFormLabel-root": {
                  fontSize: 12,
                },
                ".MuiFormHelperText-root": {
                  fontSize: 10,
                  position: "absolute",
                  bottom: -20,
                  minWidth: "max-content",
                },
              }}
              variant="standard"
              size="small"
              onKeyDown={(e) => e.stopPropagation()}
              name="zipCode"
              id="selectZipName"
              label="Zip Code"
              onChange={handleFieldChange}
              inputProps={{ type: "number" }}
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 5);
              }}
              helperText={validationErrors.zipCode}
              error={Boolean(validationErrors.zipCode)}
            />
            <span>OR</span>
          </div>
          <div className="head-inside-col">
            <TextField
              sx={{
                ".MuiInputBase-root": {
                  fontSize: 12,
                },
                ".MuiFormLabel-root": {
                  fontSize: 12,
                },
                ".MuiFormHelperText-root": {
                  fontSize: 10,
                  position: "absolute",
                  bottom: -20,
                  minWidth: "max-content",
                },
              }}
              variant="standard"
              size="small"
              onKeyDown={(e) => e.stopPropagation()}
              name="city"
              id="city"
              label="City"
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, "");
              }}
              onChange={handleFieldChange}
              inputProps={{ maxLength: 16 }}
              helperText={validationErrors.city}
              error={Boolean(validationErrors.city)}
              multiline
              autoFocus
            />
            <div className="state-cover">
              <label hidden={userEntry.state} style={{ fontSize: 12 }}>
                State
              </label>
              <TextField
                defaultValue=""
                variant="standard"
                sx={{
                  width: 150,
                  height: isMobile ? 16 : 25,
                  ".MuiInputBase-root": {
                    fontSize: 12,
                  },
                  ".MuiFormLabel-root": {
                    fontSize: 12,
                  },
                  ".MuiFormHelperText-root": {
                    fontSize: 10,
                    position: "absolute",
                    bottom: -25,
                    minWidth: "max-content",
                  },
                }}
                name="state"
                id="state"
                onChange={handleFieldChange}
                select
                helperText={validationErrors.state}
                error={Boolean(validationErrors.state)}
              >
                {states.map((state) => (
                  <MenuItem key={state.name} value={state.code}>
                    {state.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <button className="submitBtn" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </div>
        {!isSubmitted && (
          <span className="fields-title">
            The trends shown are based on the data gathered from across United
            states. Please enter zip, city/state or state to filter for a
            specific location
          </span>
        )}
        <div className="content">
          <ul className="tabs">
            {data.trendsCategories.map((d, i) => (
              <li
                className={
                  "tab " +
                  ((activeTab ? activeTab === d?.categoryCode : i === 0) &&
                    "active")
                }
                key={d?.id}
                onClick={() => handleTabSelection(d?.categoryCode)}
              >
                {d?.categoryDesc}
              </li>
            ))}
          </ul>
          <TrendsChartContainer data={data} />
        </div>
      </div>
    </div>
  );
};

export default Trends;

import { AddCircleRounded } from "@mui/icons-material";
import React from "react";
import KmnTextfield from "src/components/shared/KmnTextfield";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import Tags from "../Transactions/Tags";

const CategoryRuleFields = ({
  categoryRuleFields,
  index,
  addCategoryField,
  removeCategoryField,
}) => {
  const showAddIcon =
    categoryRuleFields?.length - 1 === index && categoryRuleFields?.length < 4;
  const showRemoveIcon = categoryRuleFields?.length > 1;
  const showTags = categoryRuleFields?.length - 1 === index;
  const valueData = [
    {
      label: "Utilities - Water",
      value: "utilities-water",
    },
    {
      label: "Utilities - Water 2",
      value: "utilities-water-2",
    },
  ];

  const categoryData = [
    {
      label: "Category 1",
      value: "category 1",
    },
    {
      label: "Category 2",
      value: "category 2",
    },
    {
      label: "Category 3",
      value: "category 3",
    },
  ];
  return (
    <>
      <KmnTextfield select label="Attribute" options={categoryData} />
      <div className="fieldCover">
        <KmnTextfield select fullWidth label="Value" options={valueData} />
        {showRemoveIcon && (
          <RemoveCircleIcon
            className="removeIcon"
            onClick={() => removeCategoryField(index)}
          />
        )}
      </div>
    </>
  );
};

export default CategoryRuleFields;

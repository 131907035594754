import { Splide, SplideSlide } from "@splidejs/react-splide";
import React from "react";
import ReactApexChart from "react-apexcharts";
import { GetWindowWidth } from "src/global/central";
import { LinearProgress, useMediaQuery } from "@mui/material";

const LocationChart = ({data, me, loading}) => {
  const width = GetWindowWidth();

  const shortHeight = useMediaQuery("(max-height: 1000px)");

  const seriesData = [
    [
      data.zipMaxPercentage || 0,
      data.zipAvgPercentage || 0,
      data.zipMdnPercentage || 0,
      me || 0,
      data.zipMinPercentage || 0,
    ],
    [
      data.cityMaxPercentage || 0,
      data.cityAvgPercentage || 0,
      data.cityMdnPercentage || 0,
      me || 0,
      data.cityMinPercentage || 0,
    ],
    [
      data.countyMaxPercentage || 0,
      data.countyAvgPercentage || 0,
      data.countyMdnPercentage || 0,
      me || 0,
      data.countyMinPercentage || 0,
    ],
    [
      data.stateMaxPercentage || 0,
      data.stateAvgPercentage || 0,
      data.stateMdnPercentage || 0,
      me || 0,
      data.stateMinPercentage || 0,
    ],
    [
      data.countryMaxPercentage || 0,
      data.countryAvgPercentage || 0,
      data.countryMdnPercentage || 0,
      me || 0,
      data.countryMinPercentage || 0,
    ],
  ];

  const labels = ["Max:", "Avg:", "Mdn:", "Me:", "Min:"];

  const chartText = [
    "% SPEND IN ZIP",
    "% SPEND IN CITY",
    "% SPEND IN COUNTY",
    "% SPEND IN STATE",
    "% SPEND IN COUNTRY",
  ];

  const options = {
    chart: {
      type: "radialBar",
      fontFamily: "Nunito, sans-serif",
    },
    plotOptions: {
      radialBar: {
        offsetY: 0,
        startAngle: 0,
        endAngle: 260,
        hollow: {
          margin: 5,
          size: "0%",
          background: "transparent",
          image: undefined,
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            show: false,
          },
        },
      },
    },
    colors: ["var(--color14)",  "grey", 'var(--color24)', "var(--color1)", "#45DCBE"],
    labels: labels,
    legend: {
      show: true,
      floating: true,
      fontSize: shortHeight ? 11 : 14,
      position: "left",
      offsetX: 50,
      offsetY: -10,
      labels: {
        useSeriesColors: true,
      },
      markers: {
        height: 6,
        width: 6,
      },
      height: 150,
      width: 150,
      formatter: function (seriesName, opts) {
        return seriesName + " " + opts.w.globals.series[opts.seriesIndex];
      },
      itemMargin: {
        vertical: -2,
      },
    },
    responsive: [
      {
        breakpoint: 650,
        options: {
          legend: {
            offsetY: -8,
          },
        },
      },
    ],
  };

  return (
    <div className="chart-container location-chart" style={{ display: "flex" }}>
      {loading && (
        <LinearProgress
          style={{ width: "100%", position: "absolute", top: 0, left: 0 }}
        />
      )}
      <span>% Spend insights by location</span>
      <Splide
        options={{
          focus: "0",
          arrows: true,
          perPage: width > 650 ? 5 : 1,
          gap: 20,
          padding: 20,
          height: "100%",
          width: "100%",
          autoWidth: width > 650 ? true : false,
          omitEnd: true,
        }}
        className="splide-custom"
      >
        {seriesData.map((series, index) => (
          <SplideSlide key={index}>
            {series && (
              <ReactApexChart
                key={index}
                options={options}
                series={series}
                type="radialBar"
                height={shortHeight ? 180 : 200}
                style={{
                  margin: "0px 10px 0 10px",
                  border: "2px solid rgb(189 189 189/0.25)",
                  borderRadius: "4.7px",
                  paddingBottom: "10px",
                }}
              />
            )}
            <label>{chartText[index]}</label>
          </SplideSlide>
        ))}
      </Splide>
      <ul className="color-info">
        <li className="min">Minimum</li>
        <li className="my">My Data</li>
        <li className="mdn">Median</li>
        <li className="avg">Average</li>
        <li className="max">Maximum</li>
      </ul>
    </div>
  );
};

export default LocationChart;

import { atom } from "recoil";

export const atm_profileData = atom({
    key: "profileData",
    default: {
        email: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        zipCode: "",
        dayOfBirth: "",
        monthOfBirth: "",
        yearOfBirth: "",
    }
})

export const atm_profileLoading = atom({
    key: "profileLoading",
    default: false
})

export const atm_alertType = atom({
    key: "alertType",
    default: false
})
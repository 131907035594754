import axios from "axios";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { atm_WindowHeight, atm_WindowWidth } from "src/state/atoms";

export const yearFrom = new Date().getFullYear() - 100;
export const yearTo = new Date().getFullYear() + 80;

export const HandleResizeWork = (size, setHeight) => {
  const WindowHeight = window.innerHeight - size;
  setHeight(WindowHeight);
};
export const DateFormate = (date) => {
  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  const dateNew = new Date(date);

  let splitdate = date.split("-");
  let year = splitdate[0];
  let dateday = splitdate[2];
  let month = monthNames[dateNew.getMonth()];

  let formateDate = `${month} ${dateday}, ${year}`;

  return formateDate;
};

export const usdFormate = (amount) => {
  let usdAmount = "-";

  if (typeof amount === "string" && /^0+(\.0+)?$/.test(amount)) {
    usdAmount = "$ " + amount;
  } else if (!isNaN(parseFloat(amount)) && isFinite(amount)) {
    usdAmount = parseFloat(amount).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
    usdAmount = usdAmount.replace(/^(\D+)/, "$1 ");
  }

  return usdAmount;
};

export const usdUnformat = (currencyString) => {
  if (!isNaN(currencyString)) {
    return currencyString;
  }
  const cleanString = currencyString.replace(/[$, ]/g, "");

  const numberValue = parseFloat(cleanString);

  if (!isNaN(numberValue)) {
    return numberValue;
  } else {
    console.error("Invalid currency string:", currencyString);
    return null;
  }
};

export function dateFormateReviews(timeString) {
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const date = new Date(timeString);
  const usDateTimeString = date.toLocaleString("en-US", options);

  return usDateTimeString;
}

export function dateFormateGoals(timeString) {
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  const date = new Date(timeString);
  const usDateTimeString = date.toLocaleString("en-US", options);

  return usDateTimeString;
}

export function dateFormateShort(timeString) {
  const date = new Date(timeString);
  return date.toLocaleDateString("en-US");
}

export function GetWindowWidth() {
  const [windowWidth, setWindowWidth] = useRecoilState(atm_WindowWidth);

  useEffect(() => {
    setWindowWidth(window.innerWidth);

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setWindowWidth]);

  return windowWidth;
}

export function GetWindowHeight() {
  const [windowHeight, setWindowHeight] = useRecoilState(atm_WindowHeight);

  useEffect(() => {
    setWindowHeight(window.innerHeight);

    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setWindowHeight]);

  return windowHeight;
}

export function getMaxInterval(number) {
  if (number <= 100) return 100;
  if (number > 100 && number < 1000) return 1000;

  const ceilDividedNumber = Math.ceil(number / 1000);
  const roundedNumber = Math.ceil(ceilDividedNumber / 5) * 5 * 1000;

  return roundedNumber;
}

export function getMinInterval(number) {
  if (number > -1000 && number < 0) return -1000;

  const ceilDividedNumber = Math.floor(number / 1000);
  const roundedNumber = Math.floor(ceilDividedNumber / 5) * 5 * 1000;

  return roundedNumber;
}

// export function setReferrer(referrer)
// {
//   window.sessionStorage.setItem("referrer", referrer);
// }

// export function getReferrer()
// {
//   return window.sessionStorage.getItem("referrer");
// }

export function setAuthTokens(jwtToken, jwtExpiryMs, refreshToken, refreshTokenExpiryMs) {
  const currentTime = Date.now();
  const jwtExpiryTime = new Date(currentTime + jwtExpiryMs);
  const refreshExpiryTime = new Date(currentTime + refreshTokenExpiryMs);

  window.sessionStorage.setItem("auth-token", jwtToken);
  window.sessionStorage.setItem("auth-refresh-token", refreshToken);
  window.sessionStorage.setItem("auth-token-expiry", jwtExpiryTime);
  window.sessionStorage.setItem("auth-refresh-token-expiry", refreshExpiryTime);
}

export function getAuthToken() {
  let currentTime = new Date();
  let sessionAuthTokenExpiry = window.sessionStorage.getItem("auth-token-expiry");
  let jwtTime = new Date(sessionAuthTokenExpiry);
  let calculatedJwtTime = (jwtTime - currentTime) / 1000;
  if (calculatedJwtTime < 300 && sessionAuthTokenExpiry) {
    let jwtRefreshTime = new Date(window.sessionStorage.getItem("auth-refresh-token-expiry"));
    if (jwtRefreshTime - currentTime > 0) {
      (async () => {
        try {
          let api = process.env.REACT_APP_USER_API_END_POINT + "/users/v1/regenerateToken";
          const res = await axios.post(
            api,
            {
              "JWT REFRESH TOKEN": window.sessionStorage.getItem("auth-refresh-token"),
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + window.sessionStorage.getItem("auth-refresh-token"),
              },
            }
          );
          let data = res.data.responseData;
          setAuthTokens(data);
        } catch (err) {
          console.log(err);
        }
      })();
    } else {
      localStorage.clear();
      window.location.href = "/";
    }
  }
  return window.sessionStorage.getItem("auth-token");
}

export function removeAuthTokens() {
  window.sessionStorage.removeItem("auth-token");
  window.sessionStorage.removeItem("auth-refresh-token");
  window.sessionStorage.removeItem("auth-token-expiry");
  window.sessionStorage.removeItem("auth-refresh-token-expiry");
}

export const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export function compareIds(includedIds, excludedIds) {
  let includedId = [];
  let excludedId = [];
  if (!includedIds.length && excludedIds.length) {
    excludedId = excludedIds;
  } else if (includedIds.length <= excludedIds.length || includedIds.length === 1) {
    includedId = includedIds;
  } else if (includedIds.length > excludedIds.length) {
    excludedId = excludedIds;
  }

  return { includedId, excludedId };
}

export function truncateString(str, maxLength) {
  if (str.length > maxLength) {
      return str.substring(0, maxLength) + " ...";
  }
  return str;
}

export function getHeaders(){
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getAuthToken()
    }
  }
}

export const states = [
  {
    name: "Alabama",
    code: "AL",
  },
  {
    name: "Alaska",
    code: "AK",
  },
  {
    name: "Arizona",
    code: "AZ",
  },
  {
    name: "Arkansas",
    code: "AR",
  },
  {
    name: "California",
    code: "CA",
  },
  {
    name: "Colorado",
    code: "CO",
  },
  {
    name: "Connecticut",
    code: "CT",
  },
  {
    name: "Delaware",
    code: "DE",
  },
  {
    name: "Florida",
    code: "FL",
  },
  {
    name: "Georgia",
    code: "GA",
  },
  {
    name: "Hawaii",
    code: "HI",
  },
  {
    name: "Idaho",
    code: "ID",
  },
  {
    name: "Illinois",
    code: "IL",
  },
  {
    name: "Indiana",
    code: "IN",
  },
  {
    name: "Iowa",
    code: "IA",
  },
  {
    name: "Kansas",
    code: "KS",
  },
  {
    name: "Kentucky",
    code: "KY",
  },
  {
    name: "Louisiana",
    code: "LA",
  },
  {
    name: "Maine",
    code: "ME",
  },
  {
    name: "Maryland",
    code: "MD",
  },
  {
    name: "Massachusetts",
    code: "MA",
  },
  {
    name: "Michigan",
    code: "MI",
  },
  {
    name: "Minnesota",
    code: "MN",
  },
  {
    name: "Mississippi",
    code: "MS",
  },
  {
    name: "Missouri",
    code: "MO",
  },
  {
    name: "Montana",
    code: "MT",
  },
  {
    name: "Nebraska",
    code: "NE",
  },
  {
    name: "Nevada",
    code: "NV",
  },
  {
    name: "New Hampshire",
    code: "NH",
  },
  {
    name: "New Jersey",
    code: "NJ",
  },
  {
    name: "New Mexico",
    code: "NM",
  },
  {
    name: "New York",
    code: "NY",
  },
  {
    name: "North Carolina",
    code: "NC",
  },
  {
    name: "North Dakota",
    code: "ND",
  },
  {
    name: "Ohio",
    code: "OH",
  },
  {
    name: "Oklahoma",
    code: "OK",
  },
  {
    name: "Oregon",
    code: "OR",
  },
  {
    name: "Pennsylvania",
    code: "PA",
  },
  {
    name: "Rhode Island",
    code: "RI",
  },
  {
    name: "South Carolina",
    code: "SC",
  },
  {
    name: "South Dakota",
    code: "SD",
  },
  {
    name: "Tennessee",
    code: "TN",
  },
  {
    name: "Texas",
    code: "TX",
  },
  {
    name: "Utah",
    code: "UT",
  },
  {
    name: "Vermont",
    code: "VT",
  },
  {
    name: "Virginia",
    code: "VA",
  },
  {
    name: "Washington",
    code: "WA",
  },
  {
    name: "West Virginia",
    code: "WV",
  },
  {
    name: "Wisconsin",
    code: "WI",
  },
  {
    name: "Wyoming",
    code: "WY",
  },
];

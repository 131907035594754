import React, { useRef, useState } from "react";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import MerchantRuleFields from "./MerchantRuleFields";
import KmnButton from "../Transactions/KmnButton";
import CategoryRuleFields from "./CategoryRuleFields";
import Tags from "../Transactions/Tags";
import { AddCircleRounded } from "@mui/icons-material";

const EditRule = ({ cancelEditOrCreateRule }) => {
  const [merchantRuleFields, setMerchantRuleFields] = useState([{}]);
  const [categoryRuleFields, setCategoryRuleFields] = useState([{}]);
  const [ruleTitle, setRuleTitle] = useState("New Rule");
  const [isInputFocused, setIsInputFocused] = useState(false);
  const inputRef = useRef(null);
  const [categoryRuleTags, setCategoryRuleTags] = useState([]);

  const addMerchantField = () => {
    if (merchantRuleFields?.length < 4) {
      setMerchantRuleFields((prev) => [...prev, {}]);
    }
  };

  const removeMerchantField = (idx) => {
    let prevData = [...merchantRuleFields];
    prevData.splice(idx, 1);
    setMerchantRuleFields(prevData);
  };

  const addCategoryField = () => {
    if (categoryRuleFields?.length < 4) {
      setCategoryRuleFields((prev) => [...prev, {}]);
    }
  };

  const removeCategoryField = (idx) => {
    let prevData = [...categoryRuleFields];
    prevData.splice(idx, 1);
    setCategoryRuleFields(prevData);
  };

  const handleTitleChange = (e) => {
    setRuleTitle(e.target.value);
  };

  const handleInputFocus = () => {
    setIsInputFocused(true);
    const input = inputRef.current;
    input.focus();
    input.setSelectionRange(input.value.length, input.value.length);
  };

  const resetTitle = (e) => {
    let value = e?.target?.value?.trim();
    if (value) {
      setRuleTitle(value);
    } else {
      setRuleTitle("New Rule");
    }
    setIsInputFocused(false);
  };

  return (
    <div className="editRuleBox">
      <div className="headTitle">
        <div className="headTitleCover">
          <span className="dummyTitle">
            {ruleTitle}
            <input
              readOnly={!isInputFocused}
              type="text"
              value={ruleTitle}
              onBlur={resetTitle}
              onClick={handleInputFocus}
              onChange={handleTitleChange}
              ref={inputRef}
              style={{ cursor: isInputFocused ? "text" : "pointer" }}
            />
          </span>
        </div>{" "}
        {!isInputFocused && (
          <ModeEditIcon onClick={handleInputFocus} className="editIcon" />
        )}
      </div>
      <div className="merchantCategoryRule">
        <div className="merchantRule addRule">
          <div className="title">IF following is TRUE</div>
          <div className="ruleFieldsContainer">
            {merchantRuleFields.map((d, idx) => (
              <MerchantRuleFields
                merchantRuleFields={merchantRuleFields}
                key={idx}
                addMerchantField={addMerchantField}
                removeMerchantField={removeMerchantField}
                index={idx}
              />
            ))}
          </div>
        </div>
        <div className="categoryRule addRule">
          <div className="title">Apply the following updates</div>
          <div className="ruleFieldsContainer">
            <div className="ruleFields">
              {categoryRuleFields.map((d, idx) => (
                <CategoryRuleFields
                  addCategoryField={addCategoryField}
                  key={idx}
                  index={idx}
                  categoryRuleFields={categoryRuleFields}
                  removeCategoryField={removeCategoryField}
                />
              ))}
            </div>
            <div className="rulesTags">
              <Tags
                rules={true}
                associatedTags={categoryRuleTags}
                setTags={setCategoryRuleTags}
              />
              {categoryRuleFields.length < 4 && (
                <AddCircleRounded
                  className="addIcon"
                  onClick={addCategoryField}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="editRuleActions">
        <KmnButton
          variant="secondary"
          color="primary_outlined"
          onClick={cancelEditOrCreateRule}
        >
          Cancel
        </KmnButton>
        <KmnButton variant="secondary" color="primary">
          Save
        </KmnButton>
      </div>
    </div>
  );
};

export default EditRule;

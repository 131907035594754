import { TextField } from "@mui/material";
import AddIcon2 from "../assets/images/add-icon2.svg";
import PencilOnEdit from "../assets/images/pencil1.svg";
import PencilOnNoEdit from "../assets/images/pencil2.svg";
import ArrowDown from "../assets/images/arrow-down.svg";
import ArrowUpActive from "../assets/images/arrow-up-active2.svg";
import React, { useState } from "react";
import SubCategoryRow from "./SubCategoryRow";

const SubCategoryBox = ({ data, index }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isCatEdit, setIsCatEdit] = useState(false);

  const [isAddSubCat, setIsAddSubCat] = useState(false);

  const [catText, setCatText] = useState(`${data.name}`);

  const handleExpand = () => {
    if (!isCatEdit) {
      setIsExpanded(!isExpanded);
    }
  };
  return (
    <div className={`subCategory ${isExpanded && "subCategoryExpanded"}`}>
      <div className="scroller">
        <div className={`label ${isCatEdit && "labelEditOn"}`}>
          <div onClick={handleExpand} className="text">
            <img
              className="arrow"
              src={isExpanded ? ArrowUpActive : ArrowDown}
              alt=""
            />
            <input
              readOnly={!isCatEdit}
              onChange={(e) => setCatText(e.target.value)}
              type="text"
              value={catText}
            />
          </div>

          <img
            onClick={() => setIsCatEdit(!isCatEdit)}
            className="pencil"
            src={isCatEdit ? PencilOnEdit : PencilOnNoEdit}
            alt=""
          />
        </div>

        {isExpanded && (
          <div className="subCategoryInside">
            <ul>
              {data.categories.map((subCatRows, index) => {
                return <SubCategoryRow key={index} data={subCatRows} />;
              })}
            </ul>
          </div>
        )}
      </div>
      {isExpanded && (
        <div className={`addSubCatBtn ${isAddSubCat && "addSubCatToggled"}`}>
          <div
            onClick={() => setIsAddSubCat(!isAddSubCat)}
            className="addSubCatInside"
          >
            <img src={AddIcon2} alt="" />
            <div className="btn">Add New Sub Category</div>
          </div>

          {isAddSubCat && (
            <div className="textEditBox">
              <TextField
                fullWidth
                focused
                className="subCatInput"
                id="outlined-basic"
                label="Sub Category title"
              />
              <div className="saveBtn">Save</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SubCategoryBox;

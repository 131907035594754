import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import PencilIcon from "../assets/images/pencil2.svg";
import DeleteIcon from "../assets/images/delete.svg";
import AddIcon from "../assets/images/add-icon4.svg";
import { useRecoilState } from "recoil";

import RelationshipSelection from "./RelationshipSelection";
import {
  atm_houseHolding,
  atm_houseHolding2,
} from "src/state/atomHouseHold";
import useHouseHold from "./useHouseHold";
import { useNavigate } from "react-router-dom";

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const HouseHoldList = () => {
  const navigate = useNavigate();

  const { houseHoldData, fnsHousehold } = useHouseHold();

  const [showModal, setShowModal] = useState(false);

  const [data, setData] = useRecoilState(atm_houseHolding);
  const [data2, setData2] = useRecoilState(atm_houseHolding2);

  const [cancelModal, setCancelModal] = useState(false);

  // const [activityData, setActivityData] = useState({
  //     activityName: "",
  //     activityDate: 1,
  //     activityMonth: "",
  //     activityYear: "",
  // });

  const [HeightState, setHeight] = useState(0);

  const HandleResizeWork = () => {
    const WindowHeight = window.innerHeight;
    setHeight(WindowHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", HandleResizeWork);
    HandleResizeWork();
  }, []);

  // const selectedGeneralData = useRecoilValue(atm_selectedGeneralData);

  // Days
  const days = Array.from({ length: 31 }, (_, index) => index + 1);

  // Years
  const currentYear = new Date().getFullYear();

  const years = Array.from(
    { length: currentYear - 1944 + 1 },
    (_, index) => 1944 + index
  );

  useEffect(() => {
    if (!houseHoldData.relationship.length) {
      fnsHousehold.getRelationships();
    }

    fnsHousehold.getHouseholdList();

    if (!data.length) {
      setData([
        {
          houseHoldId: null,
          firstName: "",
          lastName: "",
          dayOfBirth: 0,
          monthOfBirth: 0,
          yearOfBirth: 0,
          relationshipToPrimary: 0,
          zipCode: "",
          phoneNumber: "",
          email: "",
          relationshipActive: true,
        },
      ]);
    }
  }, []);

  const addData = () => {
    setData([
      {
        houseHoldId: null,
        firstName: "",
        lastName: "",
        dayOfBirth: 0,
        monthOfBirth: 0,
        yearOfBirth: 0,
        relationshipToPrimary: 0,
        zipCode: "",
        phoneNumber: "",
        email: "",
        relationshipActive: true,
      },
      ...data,
    ]);
  };

  // Remove Data
  const removeData = (index, field) => {
    let newData = [...data];
    let value = false;

    if (data2.length != 0) {
      if (value == data2[index]["relationshipActive"]) {
        setShowModal(false);
      } else {
        setShowModal(true);
      }
    }

    if (newData[index]["houseHoldId"] == null) {
      let tmpData = data.filter((_, i) => i != index);
      setData(tmpData);
    } else {
      newData[index] = {
        ...newData[index],
        [field]: value,
      };

      setData(newData);
    }
  };

  // Undo Data
  const undoData = (index, field) => {
    let newData = [...data];
    let value = true;

    if (data2.length != 0) {
      if (value == data2[index]["relationshipActive"]) {
        setShowModal(false);
      } else {
        setShowModal(true);
      }
    }

    newData[index] = {
      ...newData[index],
      [field]: value,
    };

    setData(newData);
  };

  // ONLY Allow Alphabet not Numbers
  const handleInput = (e) => {
    const inputValue = e.target.value.replace(/[^a-zA-Z]/g, "");
    e.target.value = inputValue;
  };

  // Handle Numeric Input Mode For Only Numeric value allow
  const handleNumericInput = (e) => {
    const inputValue = e.target.value.replace(/[^0-9]/g, "");
    e.target.value = inputValue;
  };

  // Handle Field Change
  const handleFieldChange = (e, index, field) => {
    let newData = [...data];
    let value = e.target.value;

    if (data2.length != 0) {
      if (
        value == data2[index]["firstName"] &&
        value == data2[index]["lastName"] &&
        value == data2[index]["zipCode"] &&
        value == data2[index]["phoneNumber"]
      ) {
        setShowModal(false);
      } else {
        setShowModal(true);
      }
    }

    newData[index] = {
      ...newData[index],
      [field]: value,
    };

    setData(newData);
  };

  // Handle Selection Value Change
  const handleSelectionChange = (e, index, field) => {
    let newData = [...data];
    let value = e.target.value;

    if (data2.length != 0) {
      if (
        value == data2[index]["dayOfBirth"] ||
        value == data2[index]["monthOfBirth"] ||
        value == data2[index]["yearOfBirth"]
      ) {
        setShowModal(false);
      } else {
        setShowModal(true);
      }
    }
    newData[index] = {
      ...newData[index],
      [field]: value,
    };

    setData(newData);
  };

  // Handle Relation Ship Check
  const relationShipCheck = (value, index) => {
    if (data2.length != 0) {
      if (value == data2[index]["relationshipToPrimary"]) {
        setShowModal(false);
      } else {
        setShowModal(true);
      }
    }
  };

  // Validation
  const validation = (exit) => {
    let firstName = data.some(
      (val) => val.firstName == "" && val.relationshipActive == true
    );
    let relationship = data.some(
      (val) => val.relationshipToPrimary == 0 && val.relationshipActive == true
    );
    let zipcode = data.some(
      (val) => val.zipCode == "" && val.relationshipActive == true
    );
    let zipcodeLngth = data.some((val) => val.zipCode.length < 5);
    let phoneNumberLength = data.some(
      (val) =>
        val.phoneNumber != "" &&
        val.phoneNumber.length < 10 &&
        val.relationshipActive == true
    );

    if (firstName == true) {
      // alert('Firstname is required')
      return;
    } else if (relationship == true) {
      // alert('Relationship is required')
      return;
    } else if (zipcode == true) {
      // alert('Zipcode is required')
      return;
    } else if (zipcode == false && zipcodeLngth == true) {
      // alert('Enter 5 digits for zipcode')
      return;
    } else if (phoneNumberLength == true) {
      // alert('Enter 10 digits for phone number')
      return;
    } else {
      fnsHousehold.saveHouseHoldlist();
      setShowModal(false);

      if (exit) {
        navigate(-1);
      }
    }
  };

  const handleSave = () => {
    let isRowDelete = data.filter(
      (values) => values.relationshipActive === false
    ).length;
    if (isRowDelete) {
      fnsHousehold.saveHouseHoldlist();
    } else {
      if (showModal || data2.length == 0) {
        console.log("checkkkkkk data lenght iss", showModal);
        validation(false);
        return;
      }
      fnsHousehold.saveHouseHoldlist();
    }
  };

  return (
    <div className="financialBox" style={{ overflowY: "hidden" }}>
      <div>
        <div className="head">Your Household members</div>
        <div className="subHead">List of your household members</div>

        <div className="hr"></div>
      </div>

      <div className="insideContent">
        <TableContainer
          className="household-table scroller"
          sx={{ height: HeightState - 300 }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell className="account">FIRST NAME</TableCell>
                <TableCell className="account">LAST NAME</TableCell>

                <TableCell className="start-date">DOB</TableCell>
                <TableCell className="account">RELATIONSHIP</TableCell>

                <TableCell className="account">ZIP CODE</TableCell>
                <TableCell className="account">PHONE NUMBER</TableCell>
                <TableCell>
                  <div className="add">
                    <img onClick={addData} src={AddIcon} alt="" />
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {data
                .map((val, index) => {
                  return val.relationshipActive == true ? (
                    <TableRow key={index}>
                      {/* First Name */}
                      <TableCell className="account">
                        <Tooltip
                          title={(() => {
                            return `Enter characters Aa-Zz`;
                          })()}
                        >
                          <TextField
                            id={"FID" + index}
                            placeholder="First Name"
                            className="editable"
                            variant="standard"
                            value={val.firstName}
                            maxRows={2}
                            onChange={(e) => {
                              handleFieldChange(e, index, "firstName");
                            }}
                            // sx={{ width: 130 }}
                            onInput={handleInput}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="start"
                                  className="dollar"
                                >
                                  <img
                                    className="pencilIcon"
                                    src={PencilIcon}
                                    alt=""
                                  />
                                </InputAdornment>
                              ),
                              disableUnderline: true,
                              inputProps: {
                                maxLength: 32, // Set your desired maximum length
                              },
                            }}
                          />
                        </Tooltip>

                        {val.firstName == "" && (
                          <p className="error-custom">
                            <svg
                              width="17"
                              height="17"
                              viewBox="0 0 17 17"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8.5 0C6.81886 0 5.17547 0.498516 3.77766 1.43251C2.37984 2.3665 1.29037 3.69402 0.647028 5.24719C0.00368293 6.80036 -0.164645 8.50943 0.163329 10.1583C0.491303 11.8071 1.30085 13.3217 2.4896 14.5104C3.67834 15.6992 5.1929 16.5087 6.84174 16.8367C8.49057 17.1646 10.1996 16.9963 11.7528 16.353C13.306 15.7096 14.6335 14.6202 15.5675 13.2223C16.5015 11.8245 17 10.1811 17 8.5C17 6.24566 16.1045 4.08365 14.5104 2.48959C12.9163 0.895533 10.7543 0 8.5 0ZM7.44459 4.25C7.44459 3.96821 7.55653 3.69796 7.75578 3.4987C7.95504 3.29944 8.22529 3.1875 8.50709 3.1875C8.78888 3.1875 9.05913 3.29944 9.25839 3.4987C9.45764 3.69796 9.56959 3.96821 9.56959 4.25V9.13041C9.56959 9.26994 9.5421 9.40811 9.48871 9.53702C9.43531 9.66593 9.35705 9.78305 9.25839 9.88172C9.15972 9.98038 9.04259 10.0586 8.91369 10.112C8.78478 10.1654 8.64662 10.1929 8.50709 10.1929C8.36756 10.1929 8.22939 10.1654 8.10048 10.112C7.97158 10.0586 7.85445 9.98038 7.75578 9.88172C7.65712 9.78305 7.57886 9.66593 7.52546 9.53702C7.47207 9.40811 7.44459 9.26994 7.44459 9.13041V4.25ZM8.5 13.8125C8.25904 13.8125 8.02349 13.741 7.82313 13.6072C7.62278 13.4733 7.46662 13.283 7.37441 13.0604C7.2822 12.8378 7.25807 12.5928 7.30508 12.3565C7.35209 12.1201 7.46812 11.9031 7.63851 11.7327C7.8089 11.5623 8.02598 11.4463 8.26232 11.3992C8.49865 11.3522 8.74362 11.3764 8.96624 11.4686C9.18886 11.5608 9.37914 11.7169 9.51301 11.9173C9.64688 12.1176 9.71834 12.3532 9.71834 12.5942C9.71834 12.9173 9.58998 13.2272 9.36149 13.4557C9.13301 13.6841 8.82312 13.8125 8.5 13.8125Z"
                                fill="#E41616"
                              />
                            </svg>
                            {/* {data.emailError && data.emailHelperText} */}
                            First Name is Required
                          </p>
                        )}
                      </TableCell>

                      {/* Last Name */}
                      <TableCell className="account">
                        <Tooltip
                          title={(() => {
                            return `Enter characters Aa-Zz`;
                          })()}
                        >
                          <TextField
                            id={"LID" + index}
                            placeholder="Last Name"
                            className="editable"
                            variant="standard"
                            value={val.lastName}
                            onChange={(e) => {
                              handleFieldChange(e, index, "lastName");
                            }}
                            // sx={{ width: 130 }}
                            onInput={handleInput}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="start"
                                  className="dollar"
                                >
                                  <img
                                    className="pencilIcon"
                                    src={PencilIcon}
                                    alt=""
                                  />
                                </InputAdornment>
                              ),
                              disableUnderline: true,
                              inputProps: {
                                maxLength: 64, // Set your desired maximum length
                              },
                            }}
                          />
                        </Tooltip>
                      </TableCell>

                      {/* DOB */}
                      <TableCell className="monthly-addition">
                        <div className="cell-content">
                          {/* Date */}
                          <div style={{ position: "relative" }}>
                            {!val.dayOfBirth && (
                              <label
                                id={`date-label-${index}`}
                                className="dateFormateTxt"
                                style={{
                                  fontSize: "var(--fontSize13)",
                                  color: "gray",
                                }}
                              >
                                DD
                              </label>
                            )}
                            <Select
                              className="dobTxt"
                              labelId={`date-label-${index}`}
                              value={val.dayOfBirth}
                              style={{ color: "black", fontSize: 13 }}
                              sx={{ width: 60 }}
                              variant="standard"
                              onChange={(e) => {
                                handleSelectionChange(e, index, "dayOfBirth");
                              }}
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 200, // Adjust the max height as needed
                                  },
                                },
                              }}
                            >
                              {days.map((day, index) => (
                                <MenuItem key={index} value={index + 1}>
                                  {day}
                                </MenuItem>
                              ))}
                            </Select>
                          </div>

                          {/* Months */}
                          <div style={{ position: "relative" }}>
                            {!val.monthOfBirth && (
                              <label
                                id={`month-label-end-${index}`}
                                className="dateFormateTxt"
                                style={{
                                  fontSize: "var(--fontSize13)",
                                  color: "gray",
                                }}
                              >
                                MM
                              </label>
                            )}
                            <Select
                              className="monthField"
                              labelId={`month-label-end-${index}`}
                              value={val.monthOfBirth}
                              sx={{ width: 90, fontSize: 13 }}
                              variant="standard"
                              onChange={(e) => {
                                handleSelectionChange(e, index, "monthOfBirth");
                              }}
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 200, // Adjust the max height as needed
                                  },
                                },
                              }}
                            >
                              {monthNames.map((month, index) => (
                                <MenuItem key={index} value={index + 1}>
                                  {month}
                                </MenuItem>
                              ))}
                            </Select>
                          </div>

                          {/* Year */}
                          <div style={{ position: "relative" }}>
                            {!val.yearOfBirth && (
                              <label
                                id={`month-label-end-${index}`}
                                className="dateFormateTxt"
                                style={{
                                  fontSize: "var(--fontSize13)",
                                  color: "gray",
                                }}
                              >
                                YYYY
                              </label>
                            )}
                            <Select
                              className="monthField"
                              labelId={`month-label-end-${index}`}
                              value={val.yearOfBirth}
                              sx={{ width: 90, fontSize: 13 }}
                              variant="standard"
                              onChange={(e) => {
                                handleSelectionChange(e, index, "yearOfBirth");
                              }}
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 200, // Adjust the max height as needed
                                  },
                                },
                              }}
                            >
                              {years.map((year, index) => (
                                <MenuItem key={index} value={year}>
                                  {year}
                                </MenuItem>
                              ))}
                            </Select>
                          </div>
                        </div>
                      </TableCell>

                      {/* RELATIONSHIP */}
                      <TableCell className="start-date">
                        <RelationshipSelection
                          fontSize={"var(--fontSize15)"}
                          width={180}
                          eachData={val}
                          index={index}
                          relationShipCheck={(val) => {
                            relationShipCheck(val, index);
                          }}
                        />
                      </TableCell>

                      {/* ZIP CODE */}
                      <TableCell>
                        <Tooltip
                          title={(() => {
                            return `Enter numbers 0-9`;
                          })()}
                        >
                          <TextField
                            id={"ZID" + index}
                            placeholder="Zip Code"
                            className="editable"
                            variant="standard"
                            value={val.zipCode}
                            onChange={(e) => {
                              handleFieldChange(e, index, "zipCode");
                            }}
                            sx={{ width: 100 }}
                            onInput={handleNumericInput}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="start"
                                  className="dollar"
                                >
                                  <img
                                    className="pencilIcon"
                                    src={PencilIcon}
                                    alt=""
                                  />
                                </InputAdornment>
                              ),
                              disableUnderline: true,
                              inputProps: {
                                maxLength: 5,
                              },
                            }}
                          />
                        </Tooltip>

                        {val.zipCode == "" && (
                          <p className="error-custom">
                            <svg
                              width="17"
                              height="17"
                              viewBox="0 0 17 17"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8.5 0C6.81886 0 5.17547 0.498516 3.77766 1.43251C2.37984 2.3665 1.29037 3.69402 0.647028 5.24719C0.00368293 6.80036 -0.164645 8.50943 0.163329 10.1583C0.491303 11.8071 1.30085 13.3217 2.4896 14.5104C3.67834 15.6992 5.1929 16.5087 6.84174 16.8367C8.49057 17.1646 10.1996 16.9963 11.7528 16.353C13.306 15.7096 14.6335 14.6202 15.5675 13.2223C16.5015 11.8245 17 10.1811 17 8.5C17 6.24566 16.1045 4.08365 14.5104 2.48959C12.9163 0.895533 10.7543 0 8.5 0ZM7.44459 4.25C7.44459 3.96821 7.55653 3.69796 7.75578 3.4987C7.95504 3.29944 8.22529 3.1875 8.50709 3.1875C8.78888 3.1875 9.05913 3.29944 9.25839 3.4987C9.45764 3.69796 9.56959 3.96821 9.56959 4.25V9.13041C9.56959 9.26994 9.5421 9.40811 9.48871 9.53702C9.43531 9.66593 9.35705 9.78305 9.25839 9.88172C9.15972 9.98038 9.04259 10.0586 8.91369 10.112C8.78478 10.1654 8.64662 10.1929 8.50709 10.1929C8.36756 10.1929 8.22939 10.1654 8.10048 10.112C7.97158 10.0586 7.85445 9.98038 7.75578 9.88172C7.65712 9.78305 7.57886 9.66593 7.52546 9.53702C7.47207 9.40811 7.44459 9.26994 7.44459 9.13041V4.25ZM8.5 13.8125C8.25904 13.8125 8.02349 13.741 7.82313 13.6072C7.62278 13.4733 7.46662 13.283 7.37441 13.0604C7.2822 12.8378 7.25807 12.5928 7.30508 12.3565C7.35209 12.1201 7.46812 11.9031 7.63851 11.7327C7.8089 11.5623 8.02598 11.4463 8.26232 11.3992C8.49865 11.3522 8.74362 11.3764 8.96624 11.4686C9.18886 11.5608 9.37914 11.7169 9.51301 11.9173C9.64688 12.1176 9.71834 12.3532 9.71834 12.5942C9.71834 12.9173 9.58998 13.2272 9.36149 13.4557C9.13301 13.6841 8.82312 13.8125 8.5 13.8125Z"
                                fill="#E41616"
                              />
                            </svg>
                            {/* {data.emailError && data.emailHelperText} */}
                            Zipcode is Required
                          </p>
                        )}

                        {val.zipCode != "" && val.zipCode.length < 5 && (
                          <p className="error-custom">
                            <svg
                              width="17"
                              height="17"
                              viewBox="0 0 17 17"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8.5 0C6.81886 0 5.17547 0.498516 3.77766 1.43251C2.37984 2.3665 1.29037 3.69402 0.647028 5.24719C0.00368293 6.80036 -0.164645 8.50943 0.163329 10.1583C0.491303 11.8071 1.30085 13.3217 2.4896 14.5104C3.67834 15.6992 5.1929 16.5087 6.84174 16.8367C8.49057 17.1646 10.1996 16.9963 11.7528 16.353C13.306 15.7096 14.6335 14.6202 15.5675 13.2223C16.5015 11.8245 17 10.1811 17 8.5C17 6.24566 16.1045 4.08365 14.5104 2.48959C12.9163 0.895533 10.7543 0 8.5 0ZM7.44459 4.25C7.44459 3.96821 7.55653 3.69796 7.75578 3.4987C7.95504 3.29944 8.22529 3.1875 8.50709 3.1875C8.78888 3.1875 9.05913 3.29944 9.25839 3.4987C9.45764 3.69796 9.56959 3.96821 9.56959 4.25V9.13041C9.56959 9.26994 9.5421 9.40811 9.48871 9.53702C9.43531 9.66593 9.35705 9.78305 9.25839 9.88172C9.15972 9.98038 9.04259 10.0586 8.91369 10.112C8.78478 10.1654 8.64662 10.1929 8.50709 10.1929C8.36756 10.1929 8.22939 10.1654 8.10048 10.112C7.97158 10.0586 7.85445 9.98038 7.75578 9.88172C7.65712 9.78305 7.57886 9.66593 7.52546 9.53702C7.47207 9.40811 7.44459 9.26994 7.44459 9.13041V4.25ZM8.5 13.8125C8.25904 13.8125 8.02349 13.741 7.82313 13.6072C7.62278 13.4733 7.46662 13.283 7.37441 13.0604C7.2822 12.8378 7.25807 12.5928 7.30508 12.3565C7.35209 12.1201 7.46812 11.9031 7.63851 11.7327C7.8089 11.5623 8.02598 11.4463 8.26232 11.3992C8.49865 11.3522 8.74362 11.3764 8.96624 11.4686C9.18886 11.5608 9.37914 11.7169 9.51301 11.9173C9.64688 12.1176 9.71834 12.3532 9.71834 12.5942C9.71834 12.9173 9.58998 13.2272 9.36149 13.4557C9.13301 13.6841 8.82312 13.8125 8.5 13.8125Z"
                                fill="#E41616"
                              />
                            </svg>
                            {/* {data.emailError && data.emailHelperText} */}
                            Enter 5 digits for zipcode
                          </p>
                        )}
                      </TableCell>

                      {/* PHONE */}
                      <TableCell className="contribution">
                        <Tooltip
                          title={(() => {
                            return `Enter numbers 0-9`;
                          })()}
                        >
                          <TextField
                            id={"PID" + index}
                            placeholder="Phone"
                            className="editable"
                            variant="standard"
                            value={val.phoneNumber}
                            onChange={(e) => {
                              handleFieldChange(e, index, "phoneNumber");
                            }}
                            sx={{ width: 140 }}
                            onInput={handleNumericInput}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="start"
                                  className="dollar"
                                >
                                  <img
                                    className="pencilIcon"
                                    src={PencilIcon}
                                    alt=""
                                  />
                                </InputAdornment>
                              ),
                              disableUnderline: true,
                              inputProps: {
                                maxLength: 10,
                              },
                            }}
                          />
                        </Tooltip>

                        {val.phoneNumber != "" &&
                          val.phoneNumber.length < 10 && (
                            <p className="error-custom">
                              <svg
                                width="17"
                                height="17"
                                viewBox="0 0 17 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.5 0C6.81886 0 5.17547 0.498516 3.77766 1.43251C2.37984 2.3665 1.29037 3.69402 0.647028 5.24719C0.00368293 6.80036 -0.164645 8.50943 0.163329 10.1583C0.491303 11.8071 1.30085 13.3217 2.4896 14.5104C3.67834 15.6992 5.1929 16.5087 6.84174 16.8367C8.49057 17.1646 10.1996 16.9963 11.7528 16.353C13.306 15.7096 14.6335 14.6202 15.5675 13.2223C16.5015 11.8245 17 10.1811 17 8.5C17 6.24566 16.1045 4.08365 14.5104 2.48959C12.9163 0.895533 10.7543 0 8.5 0ZM7.44459 4.25C7.44459 3.96821 7.55653 3.69796 7.75578 3.4987C7.95504 3.29944 8.22529 3.1875 8.50709 3.1875C8.78888 3.1875 9.05913 3.29944 9.25839 3.4987C9.45764 3.69796 9.56959 3.96821 9.56959 4.25V9.13041C9.56959 9.26994 9.5421 9.40811 9.48871 9.53702C9.43531 9.66593 9.35705 9.78305 9.25839 9.88172C9.15972 9.98038 9.04259 10.0586 8.91369 10.112C8.78478 10.1654 8.64662 10.1929 8.50709 10.1929C8.36756 10.1929 8.22939 10.1654 8.10048 10.112C7.97158 10.0586 7.85445 9.98038 7.75578 9.88172C7.65712 9.78305 7.57886 9.66593 7.52546 9.53702C7.47207 9.40811 7.44459 9.26994 7.44459 9.13041V4.25ZM8.5 13.8125C8.25904 13.8125 8.02349 13.741 7.82313 13.6072C7.62278 13.4733 7.46662 13.283 7.37441 13.0604C7.2822 12.8378 7.25807 12.5928 7.30508 12.3565C7.35209 12.1201 7.46812 11.9031 7.63851 11.7327C7.8089 11.5623 8.02598 11.4463 8.26232 11.3992C8.49865 11.3522 8.74362 11.3764 8.96624 11.4686C9.18886 11.5608 9.37914 11.7169 9.51301 11.9173C9.64688 12.1176 9.71834 12.3532 9.71834 12.5942C9.71834 12.9173 9.58998 13.2272 9.36149 13.4557C9.13301 13.6841 8.82312 13.8125 8.5 13.8125Z"
                                  fill="#E41616"
                                />
                              </svg>
                              {/* {data.emailError && data.emailHelperText} */}
                              Enter 10 digits for phoneNumber
                            </p>
                          )}
                      </TableCell>

                      <TableCell className="deleteBtn-coloumn">
                        <img
                          onClick={() =>
                            removeData(index, "relationshipActive")
                          }
                          height={20}
                          className="deleteIcon"
                          src={DeleteIcon}
                          alt=""
                        />
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableRow key={index}>
                      <TableCell colSpan={6}>
                        Data Removed Successfull
                      </TableCell>

                      <TableCell
                        onClick={() => undoData(index, "relationshipActive")}
                      >
                        <p
                          className="text"
                          style={{
                            fontSize: 14,
                            marginBottom: 0,
                            cursor: "pointer",
                          }}
                        >
                          UNDO
                        </p>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <div
        style={{
          width: "100%",
          padding: "1px",
        }}
      >
        <div className="buttons">
          <div className="groupBtn">
            <button
              style={{ backgroundColor: "#979797", borderColor: "#979797" }}
              onClick={() => {
                if (showModal) {
                  setCancelModal(!cancelModal);
                }
              }}
              className="nextBtn btn"
            >
              Cancel
            </button>

            <button
              className="nextBtn btn"
              onClick={handleSave}
            >
              Save
            </button>
          </div>
        </div>
      </div>

      <Dialog open={cancelModal} onClick={() => setCancelModal(false)}>
        <DialogTitle>{"Confirm"}</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to cancel? </p>
        </DialogContent>
        <DialogActions>
          <Button
            size="large"
            sx={{
              height: "50px",
              width: "100%",
              backgroundColor: "#979797 !important",
              borderRadius: "5px",
              fontFamily: "Nunito",
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: "var(--fontSize14)",
              textAlign: "center",
              color: "var(--colorWhite)",
              marginRight: "10px",
            }}
            onClick={() => {
              setCancelModal(false);
              navigate(-1);
            }}
          >
            exit without save
          </Button>

          <Button
            size="large"
            sx={{
              height: "50px",
              width: "100%",
              background: "#21CAE7 !important",
              borderRadius: "5px",
              fontFamily: "Nunito",
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: "var(--fontSize14)",
              textAlign: "center",
              color: "var(--colorBlack)",
            }}
            onClick={() => {
              validation(true);
              setCancelModal(false);
            }}
          >
            save changes and exit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default HouseHoldList;

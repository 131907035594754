function ArrowRightIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6"
      viewBox="0 0 8 13"
      fill="none"
    >
      <path
        d="M1.98948 0.828381L1.80132 0.629073L1.62012 0.834725L0.812426 1.75139L0.66269 1.92133L0.817066 2.08706L4.92758 6.5L0.817066 10.9129L0.66269 11.0787L0.812426 11.2486L1.62012 12.1653L1.80132 12.3709L1.98948 12.1716L7.18179 6.67162L7.34381 6.5L7.18179 6.32838L1.98948 0.828381Z"
        fill="var(--color1)"
        stroke="var(--color1)"
        strokeWidth="0.5"
      />
    </svg>
  );
}

export default ArrowRightIcon;

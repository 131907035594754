import { MenuItem, TextField } from "@mui/material";
import React from "react";
import useDeviceType from "src/hooks/useDeviceType";

const KmnTextfield = ({
  options = [],
  label = "",
  select = false,
  helperText = "",
  optionKey = "",
  optionValue = "",
  optionClassName = "",
  ...otherProps
}) => {
  const { isMobile } = useDeviceType();
  const inputVariant = isMobile ? "standard" : "outlined";
  const inputSize = isMobile ? "small" : "medium";
  let optionsData = options?.map((d) => ({
    label: d?.[optionKey],
    value: d?.[optionValue],
  }));

  return (
    <TextField
      label={label}
      select={select}
      helperText={helperText}
      variant={inputVariant}
      size={inputSize}
      {...otherProps}
    >
      {select &&
        optionsData?.map((d) => (
          <MenuItem
            className={`${optionClassName} kmnTextfieldOption`}
            key={d?.value}
            value={d?.value}
          >
            {d?.label}
          </MenuItem>
        ))}
    </TextField>
  );
};

export default KmnTextfield;

import React, { useEffect } from "react";
import DoneIcon from "@mui/icons-material/Done";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  atm_accountsList,
  atm_accountsTotalAmount,
  atm_isSelectAllAccountList,
  atm_selectedAccountIds,
  atm_selectedUnchangedAccountIds,
} from "src/state/atomsAccountList";
import { useRecoilValue, useSetRecoilState } from "recoil";

function CheckboxCustom({
  id,
  groupIds,
  value,
  singleAccount,
  accountName,
  multiIndex,
  onclick = null,
}) {
  const setTotalAmount = useSetRecoilState(atm_accountsTotalAmount);

  const accountList = useRecoilValue(atm_accountsList)

  const selectedAccountIds = useRecoilValue(atm_selectedAccountIds);

  const selectedUnchangedIds = useRecoilValue(atm_selectedUnchangedAccountIds);

  const isSelectAllChecked = useRecoilValue(atm_isSelectAllAccountList);

  const isChecked = (e) => {
    if (isSelectAllChecked && id == "select-all") {
      return true;
    } else if (!isSelectAllChecked) {
      return false;
    }

    if (
      !selectedAccountIds.includedIds.length &&
      !selectedAccountIds.excludedIds.length
    ) {
      return true;
    } else if (selectedUnchangedIds.includedIds.includes(id)) {
      return true;
    } else if (selectedUnchangedIds.excludedIds.includes(id)) {
      return false;
    }

    if (
      groupIds &&
      groupIds.includes(id) == selectedUnchangedIds.includedIds.includes(id)
    ) {
      return true;
    } else if (
      groupIds &&
      groupIds.includes(id) == selectedUnchangedIds.excludedIds.includes(id)
    ) {
      return false;
    }
  };

  useEffect(() => {
    if (singleAccount && isChecked()) {
      setTotalAmount(
        (prevTotalAmount) => prevTotalAmount + singleAccount.accountBalance
      );
    }
  }, [accountList]);

  return (
    <div className="CheckboxCustom">
      <input
        type="checkbox"
        id={id}
        defaultChecked={isChecked()}
        value={value || ""}
        name={accountName}
        className={`group${multiIndex + 1} inputChild`}
        onChange={(e) => {
          if (onclick) {
            onclick(e);
          }
        }}
      />
      <label htmlFor={id}>
        <div className="done-icon">
          <DoneIcon
            fontSize="small"
            style={{ color: "var(--colorWhite)", fontSize: 13, paddingTop: 2 }}
          />
        </div>
        <div className="remove-icon">
          <RemoveIcon style={{ color: "var(--colorWhite)", fontSize: 13 }} />
        </div>
      </label>
    </div>
  );
}

export default CheckboxCustom;

import { Divider, FormControl, MenuItem, Radio, Select } from "@mui/material";
import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import useDashboard from "src/components/Dashboard/useDashboard";
import { dateFormateGoals, usdFormate } from "src/global/central";
import {
  atm_financialData,
  atm_fundingAccounts,
} from "src/state/atomDashboard";

const AccountSelection = ({ eachData, index }) => {
  const { dataDashboard, fnsDashboard } = useDashboard();
  const [financialData, setFinancialData] = useRecoilState(atm_financialData);
  const fundingAccounts = useRecoilValue(atm_fundingAccounts);

  const handleChange = (event) => {
    let value = event.target.value;

    setFinancialData((prevData) =>
      prevData.map((item, i) =>
        i === index
          ? {
              ...item,
              startingBalance:
                dataDashboard.fundingAccounts[value].currentBalance,
              selectedAccountId:
                dataDashboard.fundingAccounts[value].userItemAccountId,
            }
          : item
      )
    );

    fnsDashboard.calculateAccountContribution(
      {
        ...financialData[index],
        startingBalance: fundingAccounts[value].currentBalance,
        selectedAccountId:
          dataDashboard.fundingAccounts[value].userItemAccountId,
        contribution: financialData[index].contribution,
      },
      index
    );
  };

  return (
    <FormControl
      className="accountSelectionDropdown select"
      variant="standard"
      sx={{ width: 180, textAlign: "center", border: "none" }}
    >
      <Select
        labelId="labelAccount"
        id="Account"
        defaultValue=""
        value={
          eachData.selectedAccountId === ""
            ? ""
            : dataDashboard.fundingAccounts.length
        }
        onChange={handleChange}
        sx={{
          paddingBottom: 0,
          textAlign: "center",
        }}
      >
        {dataDashboard.fundingAccounts.map((data, index) => {
          return [
            <MenuItem key={index} value={index}>
              <Radio
                checked={
                  eachData.selectedAccountId === ""
                    ? false
                    : eachData.selectedAccountId == data.userItemAccountId
                }
                sx={{
                  color: "var(--color1)",
                  "&.Mui-checked": {
                    color: "var(--color1)",
                  },
                }}
              />
              <div className="accounts-cover">
                <div className="name-subName" style={{ marginLeft: 10 }}>
                  <div className="name">{data.accountName}</div>
                  <div className="subName">
                    {data.accountType} - {data.accountNoMask}
                  </div>
                </div>
                <div className="amount-date">
                  <div className="amount">
                    {usdFormate(data.currentBalance)}
                  </div>
                  <div className="date">
                    {dateFormateGoals(data.accountBalanceTimestamp)}
                  </div>
                </div>
              </div>
            </MenuItem>,
            dataDashboard.fundingAccounts.length !== index + 1 && (
              <Divider sx={{ mx: "10px" }} />
            ),
          ];
        })}
        <MenuItem
          sx={{ display: "none" }}
          value={dataDashboard.fundingAccounts.length}
        >
          {
            dataDashboard.fundingAccounts.find(
              (obj) => obj.userItemAccountId == eachData.selectedAccountId
            )?.accountName
          }
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default AccountSelection;

import { Navigate } from "react-router-dom";
import { getAuthToken } from "./global/central";

export const ProtectedRoute = ({ children }) => {
  const jwt = getAuthToken();
  if (!jwt) {
    return <Navigate to="/" replace />;
  }
  return children;
};

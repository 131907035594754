import { useState, useCallback } from "react";
import axios from "axios";
import { getHeaders } from "./central";

const useApi = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const callApi = useCallback(async (endpoint, payload = null) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post(endpoint, payload, getHeaders());
      
      if(response?.data?.success){

        let data = response?.data?.responseData;
        setData(data);
        setLoading(false);
        return data;
      }else{
        let error = response?.data?.errors?.[0]?.errorMessage || response?.data?.responseMessage;
        setError(error);
        throw new Error(error)
      }
    } catch (err) {
      let error = "Error during API fetch: " + err
      console.error(err);
      throw new Error(error)

    } finally {
      setLoading(false);
    }
  }, []);

  return { loading, error, data, callApi };
};

export default useApi;

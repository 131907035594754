import { atom, selector } from "recoil";
import Typography from "@mui/material/Typography";

export const atm_accountsColumn = atom({
  key: "accounts_column",
  default: [
    {
      field: "col1",
      headerName: "Account",
      width: 200,
      renderCell: (params) => (
        <div>
          <Typography variant="body2">{params.value.fi}</Typography>
          <Typography variant="caption" color="textSecondary">
            &nbsp;{params.value.accountMask}
          </Typography>
        </div>
      ),
    },
    {
      field: "col2",
      headerName: "Balance",
      width: 200,
      renderCell: (params) => (
        <div>
          <Typography variant="body2">{params.value.balance}</Typography>
          <Typography variant="caption" color="textSecondary">
            &nbsp;&nbsp;{params.value.balanceDate}
          </Typography>
        </div>
      ),
    },
  ],
});

export const atm_accountsList = atom({
  key: "accounts_list",
  default: [],
});

export const atm_accounts = atom({
  key: "accounts",
  default: [],
});

export const atm_accountsTotalAmount = atom({
  key: "accountsTotalAmount",
  default: 0,
});

export const atm_selectedAccountIds = atom({
  key: "selectedAccountIds",
  default: {
    includedIds: [],
    excludedIds: [],
  },
});
export const atm_selectedUnchangedAccountIds = atom({
  key: "selectedUnchangedAccountIds",
  default: {
    includedIds: [],
    excludedIds: [],
  },
});

export const atm_isAccountSelectionModified = atom({
  key: "isAccountSelectionModified",
  default: false,
});

export const atm_isSelectAllAccountList = atom({
  key: "isSelectAllAccountList",
  default: true,
});

export const atm_editAccountModal = atom({
  key: "editAccountModal",
  default: false,
});

export const atm_editAccountLoading = atom({
  key: "editAccountLoading",
  default: false,
});

export const atm_accountDetailsLoading = atom({
  key: "accountDetailsLoading",
  default: false,
});

export const atm_accountSubTypes = atom({
  key: "accountSubTypes",
  default: [],
});

export const atm_selectedEditAccount = atom({
  key: "selectedEditAccount",
  default: {},
});

export const atm_selectedAccountType = atom({
  key: "selectedAccountType",
  default: "",
});

// convert account list into table format
export const atm_accountsListTable = selector({
  key: "accountsListTable",
  get: ({ get }) => {
    var accountListTable = atm_accountsList;

    // get(atm_accountsList).forEach((account) => {

    //   accountListTable.push({
    //     id: account.userItemAccountId,
    //     // "col1"  : account.accountFinInstitution + ' : ' + account.accountName //+ ' \n - ** ' + account.plaidAccountId
    //     col1: {
    //       fi: account.accountFinInstitution,
    //       accountMask: account.accountName + " - **" + account.accountNoMask,
    //     },
    //     col2: {
    //       balance:
    //         account.currentBalance != null
    //           ? account.currentBalance.toLocaleString("en-US", {
    //               style: "currency",
    //               currency: "USD",
    //             })
    //           : " - ",
    //       balanceDate:
    //         account.balanceTimestamp != null
    //           ? new Date(account.balanceTimestamp).toLocaleDateString("en-US", {
    //               year: "numeric",
    //               month: "short",
    //               day: "numeric",
    //             })
    //           : "-",
    //     },
    //   });
    // });
    return accountListTable;
  },
});

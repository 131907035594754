/**********************************************************************
 *
 *   Component generated by Quest
 *
 *   WARNING: By editing this component by hand, you will lose the ability to regenerate the code without conflicts.
 *   To preseve that abilty, always export from Quest to regenerate this file.
 *   To setup props, bindings and actions, use the Quest editor
 *   Code Logic goes in the hook associated with this component
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/

import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import "../assets/css/header.css";
import Main from "../Home/Main";
import Learn from "../Home/Learn";
import Focus from "../Home/Focus";
import Security from "../Home/Security";
import Experience from "./Experience";
import { Impover } from "../Home/Impover";
import Footer from "../Home/Footer";
import { useRecoilValue } from "recoil";
import { atm_isManageCategoryBox } from "src/state/atomsTransactionData";
import MetaTags from "../shared/MetaTags";

function Home(props) {
  const isManageCategoryBox = useRecoilValue(atm_isManageCategoryBox);
  useEffect(() => {
    document.addEventListener("DOMContentLoaded", function () {
      var lazyloadImages = document.querySelectorAll("img.lazy");
      var lazyloadThrottleTimeout;

      function lazyload() {
        if (lazyloadThrottleTimeout) {
          clearTimeout(lazyloadThrottleTimeout);
        }

        lazyloadThrottleTimeout = setTimeout(function () {
          var scrollTop = window.pageYOffset;
          lazyloadImages.forEach(function (img) {
            if (img.offsetTop < window.innerHeight + scrollTop) {
              img.src = img.dataset.src;
              img.classList.remove("lazy");
            }
          });
          if (lazyloadImages.length == 0) {
            document.removeEventListener("scroll", lazyload);
            window.removeEventListener("resize", lazyload);
            window.removeEventListener("orientationChange", lazyload);
          }
        }, 20);
      }

      document.addEventListener("scroll", lazyload);
      window.addEventListener("resize", lazyload);
      window.addEventListener("orientationChange", lazyload);
    });
  }, []);
  return (
    <div className={"homePage " + (isManageCategoryBox ? "manageCategoryBoxOpens" : "")}>
      
      <MetaTags index={"Home"} />

      {/* Main */}
      <Main />

      {/* Learn */}
      <Learn />

      {/* Focus */}
      <Focus />

      {/* Security */}
      <Security />

      {/* Experience */}
      <Experience />

      {/* Impover */}
      <Impover />

      {/* Footer */}
      <Footer />

    </div>
  );
}

export default Home;

import React from "react";
import { Link } from "react-router-dom";
import useMainMenu from "../MainMenu/useMainMenu";

const Explore = () => {
  const { fns } = useMainMenu();
  return (
    <section className="explore">
      <h1>
        Sign Up to Explore more  
        </h1>
      <h3>Money decisions, made easy</h3>

      <Link onClick={fns.onMenuClickSignUp} to="/signup">
        Sign Up
      </Link>
    </section>
  );
};

export default Explore;
